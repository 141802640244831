import { TiArrowSortedDown } from "react-icons/ti";
import styles from './ZoneFilter.module.scss';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getZonesSuggestions } from "../../../../api";

export default function ZoneFilter({ onZoneChange}) {
  const { token, navCommunity} = useSelector((state) => state.auth);
  const [zoneDivIsVisible, setZoneDivIsVisible] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [zoneOptions, setZoneOptions] = useState([]);

  let zonesQuery = useQuery(["zonesQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getZonesSuggestions(token, navCommunity.id);
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setZoneOptions(data);
        setSelectedZone(data[0]);
      } catch (e) {
        setZoneOptions([]);
        return null;
      }
    }
  });

  // useEffect(() => {
  //   const values = renderTeamYears().sort((a, b) => Number(b) - Number(a));
  //   setZoneOptions(values);
  // }, []);

  useEffect(() => {
    if (onZoneChange && typeof onZoneChange === 'function') {
      onZoneChange(selectedZone);
    }
  }, [onZoneChange, selectedZone]);

  return (
    <div
        className={`${styles.customedSelect} ${zoneDivIsVisible ? styles.customedSelectOpened : ''}`}>
        <div
          className={styles.customedSelectHeader}
          onClick={() => setZoneDivIsVisible(!zoneDivIsVisible)}
        >
          <span>{selectedZone?.label}</span>
          <div className={styles.actions}>
            <div>
              <span className={styles.action}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {zoneDivIsVisible && (
          <div className={styles.customedSelectBody}>
            <div className={styles.customedSelectBodyOptions}>
              {zoneOptions.map((item, index) => (
                <div
                  key={index}
                  className={styles.customedSelectBodyOption}
                  onClick={() => {
                    setSelectedZone(item);
                    setZoneDivIsVisible(false);
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
  )
}
