import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DrawerIframe from "../common/DrawerIframe";
import { TTP_TEAM_URL } from "../../config";
import { useParams } from "react-router-dom";
const TimeSheet = () => {
  const { id, name, startDate, endDate } = useParams();
  const auth = useSelector((state) => state.auth);
  const [fiduciaryId, setFiduciaryId] = useState(null);
  useEffect(() => {
    if (auth.navCommunity) {
      setFiduciaryId(auth.navCommunity.id);
    }
  }, [auth.navCommunity]);

  const preferences =
    auth.user && auth.navCommunity && auth.user.userOrganizations.length > 0
      ? auth.user.userOrganizations.find(
          (organization) => organization.id === auth.navCommunity.id
        ).authorisation.preferences
      : {};

  const params = [];
  let powerTeamUrl;
  if (id) {
    powerTeamUrl = `${TTP_TEAM_URL}/time-sheet/${id}/${name}/${startDate}/${endDate}`;
  } else {
    powerTeamUrl = `${TTP_TEAM_URL}/time-sheet`;
  }

  if (fiduciaryId) {
    params.push("without_header");
    params.push("without_menu");
    params.push("organization_id=" + fiduciaryId);
    params.push("collaborator=" + 1);
    return (
      <DrawerIframe
        src={powerTeamUrl}
        params={params}
        hasAccess={preferences.allowAccessPrestaTS}
      />
    );
  }
  return <></>;
};

export default TimeSheet;
