import { Tooltip } from "antd";
import { Carousel } from "primereact/carousel";
import { Fragment, useEffect, useRef, useState } from "react";
import { TiArrowDown, TiArrowSortedDown } from "react-icons/ti";
import { useQuery } from "react-query";
import {
  Bar,
  BarChart,
  Tooltip as BarTooltip,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  countPlans,
  getBalanceInProgress,
  getBalanceProduction,
  getCollaboratorContributionByCategory,
  getErrorReportCounter,
  getLastAbsences,
  getNonBillableContribution,
  getProductionCapacity,
} from "../../../api";
import _ from "../../../i18n";
import {
  calculatePeriodDates,
  generateFullName,
  getUserNameForAvatar,
  renderDurationDisplay,
} from "../../../utils";
import styles from "../Partner/CapacityProduction.module.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import { AlertCircleIcon } from "../../common/Icons";
import { Matrix } from "../components/Matrix";
import { RedirectCard } from "../components/RedirectCard";
import { FaRegCalendarTimes } from "react-icons/fa";
import { BiMessageError, BiTaskX } from "react-icons/bi";

const periods = ["_week", "_month", "_quarter"];
const { start: initStart, end: initEnd } = calculatePeriodDates("_week");

export default function CollaboratorCapacityProduction({
  token,
  loggedAs,
  organization,
  collaborator,
  year,
}) {
  const { language: lang } = useSelector((state) => state.params);

  const [data, setData] = useState([]);
  const [plansCounter, setPlansCounter] = useState([]);
  const [absences, setAbsences] = useState([]);
  const [errorCounter, setErrorCounter] = useState([]);
  const [balance, setBalance] = useState([]);
  const [categories, setCategories] = useState([]);
  const [nonBillable, setNonBillable] = useState([]);
  const [balanceInProgress, setBalanceInProgress] = useState([]);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState({
    year: year,
    organization,
    period: "_week",
    start: initStart,
    end: initEnd,
  });

  const productionCapacityQuery = useQuery(
    ["getProductionCapacityQuery", token, organization, filter.period],
    async () => {
      if (token && organization && collaborator) {
        try {
          const response = await getProductionCapacity(
            token,
            [collaborator.id],
            organization,
            collaborator.partner?.value,
            year,
            filter.period,
            filter.start,
            filter.end
          );
          const data = response.data.data;
          setData(data);
        } catch (e) {
          setData([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!collaborator,
    }
  );

  const countPlansQuery = useQuery(
    ["countPlansQuery", token, organization, filter.period],
    async () => {
      if (token && organization && collaborator) {
        try {
          const response = await countPlans(
            token,
            [collaborator.id],
            organization,
            collaborator.partner?.value,
            year,
            filter.start,
            filter.end
          );
          const data = response.data.data;
          setPlansCounter(data);
        } catch (e) {
          setPlansCounter([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!collaborator,
    }
  );

  const lastAbsencesQuery = useQuery(
    ["getLastAbsencesQuery", token, organization, filter.year],
    async () => {
      if (token && organization && collaborator) {
        try {
          const response = await getLastAbsences(
            token,
            [collaborator.id],
            organization,
            collaborator.partner?.value,
            year
          );
          const data = response.data.data;
          setAbsences(data);
        } catch (e) {
          setAbsences([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!collaborator,
    }
  );

  const countErrorReport = useQuery(
    ["getErrorReportCounterQuery", token, organization, filter.year],
    async () => {
      if (token && organization && collaborator) {
        try {
          const response = await getErrorReportCounter(
            token,
            [collaborator.id],
            organization,
            collaborator.partner?.value,
            year
          );
          const data = response.data.data;
          setErrorCounter(data[0]);
        } catch (e) {
          setErrorCounter([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!collaborator,
    }
  );

  const getBalanceProductionQuery = useQuery(
    ["getBalanceProductionQuery", token, organization, filter.year],
    async () => {
      if (token && organization && collaborator) {
        try {
          const response = await getBalanceProduction(
            token,
            [collaborator.id],
            organization,
            collaborator.partner?.value,
            year
          );
          const data = response.data.data;
          setBalance(data);
        } catch (e) {
          setBalance([]);
          setError("No data available");
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!collaborator,
    }
  );

  const contributionByCategoryQuery = useQuery(
    ["contributionByCategoryQuery", token, year],
    async () => {
      if (token && organization && collaborator) {
        const { start, end } = calculatePeriodDates("_month");
        try {
          const response = await getCollaboratorContributionByCategory(
            collaborator.id,
            organization.id,
            year,
            token,
            `${year}-${start}`,
            `${year}-${end}`
          );

          const data = response.data.data;
          setCategories(data);
        } catch (e) {
          console.log(e);
          setCategories({});
          return null;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!collaborator,
    }
  );

  // const nonBillableTasksQuery = useQuery(
  //   ["getNonBillableTasksQuery", token, year],
  //   async () => {
  //     if ((token, organization, collaborator, collaborator.partner)) {
  //       try {
  //         const response = await getNonBillableContribution(
  //           token,
  //           organization.id,
  //           collaborator.id,
  //           collaborator.partner?.value,
  //           year,
  //           collaborator.role.type
  //         );

  //         const data = response.data.data;
  //         setNonBillable(data[0]);
  //       } catch (e) {
  //         console.log(e);
  //         setNonBillable({});
  //         return null;
  //       }
  //     }
  //   },
  //   {
  //     enabled: !!token && !!organization && !!collaborator,
  //   }
  // );

  const balanceInProgressQuery = useQuery(
    ["getBalanceInProgress", token, year],
    async () => {
      if ((token, organization, collaborator?.role)) {
        try {
          const response = await getBalanceInProgress(
            token,
            organization.id,
            collaborator.id,
            year,
            collaborator.role.type
          );

          const data = response.data.data;
          setBalanceInProgress(data);
        } catch (e) {
          console.log(e);
          setBalanceInProgress(null);
          return null;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!collaborator,
    }
  );

  useEffect(() => {
    productionCapacityQuery.refetch();
    countPlansQuery.refetch();
    lastAbsencesQuery.refetch();
  }, [filter.period, year]);

  useEffect(() => {
    getBalanceProductionQuery.refetch();
    lastAbsencesQuery.refetch();
    countErrorReport.refetch();
    contributionByCategoryQuery.refetch();
    balanceInProgressQuery.refetch();
    // nonBillableTasksQuery.refetch();
  }, [year]);

  const CustomLegend = ({ data }) => {
    const totalPlanned = data.reduce((sum, item) => sum + item.planned, 0);
    const totalTheoric = data.reduce((sum, item) => sum + item.theoric, 0);
    const totalReal = data.reduce((sum, item) => sum + item.real, 0);
    const totalAbsence = data.reduce((sum, item) => sum + item.absence, 0);

    return (
      <div className={styles.custom_legend}>
        <div className={styles.legend}>
          <div className={styles.legend_label}>
            <div
              className={styles.legend_label_square}
              style={{ backgroundColor: "#C7E8FE" }}
            />
            <div>{_("planned")}</div>
          </div>
          <div className={styles.legend_total}>
            <span>Total:</span>
            <span>{renderDurationDisplay(totalPlanned)}</span>
            <span>
              {totalTheoric > 0
                ? ((totalPlanned * 100) / totalTheoric).toFixed(1)
                : 0}{" "}
              %
            </span>
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.legend}>
          <div className={styles.legend_label}>
            <div
              className={styles.legend_label_square}
              style={{ backgroundColor: "#18A0FB" }}
            />
            <div>{_("realized")}</div>
          </div>
          <div className={styles.legend_total}>
            <span>Total:</span>
            <span>{renderDurationDisplay(totalReal)}</span>
            <span>
              {totalTheoric > 0
                ? ((totalReal * 100) / totalTheoric).toFixed(1)
                : 0}{" "}
              %
            </span>
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.legend}>
          <div className={styles.legend_label}>
            <div
              className={styles.legend_label_square}
              style={{ backgroundColor: "#B2BCC6" }}
            />
            <div>{_("expected")}</div>
          </div>
          <div className={styles.legend_total}>
            <span>Total:</span>
            <span>{renderDurationDisplay(totalTheoric)}</span>
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.legend}>
          <div className={styles.legend_label}>
            <div
              className={styles.legend_label_square}
              style={{ backgroundColor: "#F7953E" }}
            />
            <div>{_("absenceDay")}</div>
          </div>
          <div className={styles.legend_total}>
            <span>Total:</span>
            <span>{renderDurationDisplay(totalAbsence)}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.cp_container}>
      <CPHeader filter={filter} setFilter={setFilter} />

      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        {productionCapacityQuery.isFetched ? (
          data && data.length > 0 ? (
            <div className={styles.chart_container}>
              <CustomLegend data={data} />
              <ResponsiveContainer width="100%" height={250}>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
                  className={styles.chart_container_chart}
                >
                  <CartesianGrid
                    strokeDasharray="3 0"
                    vertical={false}
                    stroke="#F1F2F4"
                  />
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    tickSize={12}
                    tickFormatter={(name) =>
                      filter.period == "_week" ? name.split(".")[0] : _(name)
                    }
                    fill="#29394D"
                  />
                  <YAxis
                    axisLine={false}
                    orientation="right"
                    type="number"
                    tickLine={false}
                    tickSize={10}
                    unit="h"
                    tickMargin={20}
                    fill="#64748B"
                    tickFormatter={(value) =>
                      value % 60 === 0
                        ? (value / 60).toFixed(0)
                        : (value / 60).toFixed(1)
                    }
                  />
                  <BarTooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: "rgba(225, 228, 232, 0.50)" }}
                  />
                  <Bar
                    barSize={12}
                    dataKey="planned"
                    fill="#C7E8FE"
                    name={_("planned")}
                    radius={[3, 3, 0, 0]}
                  />
                  <Bar
                    barSize={3}
                    dataKey="theoric"
                    name={_("expected")}
                    fill="#B2BCC6"
                    shape={(props) => {
                      const { x, y, width, height, payload } = props;
                      const fillColor =
                        payload.absence > 0 ? "#F7953E" : "#B2BCC6";
                      return (
                        <rect
                          x={x}
                          y={y}
                          width={width}
                          height={height}
                          fill={fillColor}
                          radius={[3, 3, 0, 0]}
                        />
                      );
                    }}
                  />
                  <Bar
                    barSize={12}
                    dataKey="real"
                    fill="#18A0FB"
                    name={_("realized")}
                    radius={[3, 3, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="no_data" style={{ height: 100 }}>
              <div className="title">{_("noDataFound")}</div>
            </div>
          )
        ) : (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        )}
        <div className={styles.divider} />
        <div style={{ display: "flex", gap: 10 }}>
          {/* <RedirectCard
            title={_("nonBillableTasks")}
            to={`/${lang}/user-space`}
          >
            {nonBillableTasksQuery.isFetched && nonBillable ? (
              <div className={styles.non_billable_tasks}>
                <div className={styles.non_billable_tasks_head}>
                  <span>{_("individual")}</span>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    {nonBillable.collaborator ? (
                      <>
                        {renderDurationDisplay(nonBillable.collaborator)}
                        <span>{`${parseInt(nonBillable.diff)} %`}</span>
                      </>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
                <div>
                  <div className={styles.bar_container}>
                    <div
                      className={styles.bar}
                      style={{ width: `${nonBillable.diff}%` }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.no_data_alert}>
                <BiTaskX size={20} />
                <em>{_("noTask")}</em>
              </div>
            )}
          </RedirectCard> */}
          <RedirectCard
            title={`${_("planning")} (${balanceInProgress.balance})`}
            to={`/${lang}/user-space?tab=PLANNER`}
          >
            {balanceInProgressQuery.isFetched && balanceInProgress ? (
              <Fragment>
                <div className={styles.non_billable_tasks}>
                  <div className={styles.non_billable_tasks_head}>
                    <span>{_("endDeadline")}</span>
                    <div>{balanceInProgress.echeance}</div>
                  </div>
                  <div>
                    <div className={styles.bar_container}>
                      <div
                        className={styles.bar}
                        style={{
                          width: `${
                            (balanceInProgress.echeance * 100) /
                            balanceInProgress.balance
                          }%`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.non_billable_tasks}>
                  <div className={styles.non_billable_tasks_head}>
                    <span>{_("intermediateSituationBalance")}</span>
                    <div>{balanceInProgress.intermSituation}</div>
                  </div>
                  <div>
                    <div className={styles.bar_container}>
                      <div
                        className={styles.bar}
                        style={{
                          width: `${
                            (balanceInProgress.intermSituation * 100) /
                            balanceInProgress.balance
                          }%`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.non_billable_tasks}>
                  <div className={styles.non_billable_tasks_head}>
                    <span>{_("balancePresentation")}</span>
                    <div>{balanceInProgress.presentation}</div>
                  </div>
                  <div>
                    <div className={styles.bar_container}>
                      <div
                        className={styles.bar}
                        style={{
                          width: `${
                            (balanceInProgress.presentation * 100) /
                            balanceInProgress.balance
                          }%`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className={styles.no_data_alert}>
                <BiTaskX size={25} />
                <em>{_("noBalance")}</em>
              </div>
            )}
          </RedirectCard>
          <RedirectCard title={_("planManagment")} to={null}>
            <div className={styles.counter_bubbles}>
              {plansCounter &&
                plansCounter.length > 0 &&
                plansCounter.map((c) => (
                  <div
                    key={c.status}
                    className={styles.bubble}
                    style={{
                      width: c.width,
                      height: c.width,
                      backgroundColor: c.bgColor,
                      color: c.color,
                      borderRadius: "50%",
                      top: c.top,
                      left: c.left,
                    }}
                  >
                    <div>{c.count}</div>
                    <div>
                      {c.label.length > 9
                        ? `${c.label.slice(0, 8)}...`
                        : c.label}
                    </div>
                  </div>
                ))}
            </div>
          </RedirectCard>
          <RedirectCard title={_("errorReport")} to={null}>
            {errorCounter ? (
              <div className={styles.error_rapport}>
                {errorCounter?.created?.error > 0 && (
                  <div className={styles.error_rapport_column}>
                    <div>
                      <span>{errorCounter?.created?.error}</span>
                      <span>{_("created")}</span>
                    </div>
                    <TiArrowDown />
                    <div>
                      <span>{errorCounter?.created?.client}</span>
                      <span>{_("clientsCount")}</span>
                    </div>
                  </div>
                )}
                {errorCounter?.received?.error > 0 && (
                  <div className={styles.error_rapport_column}>
                    <div>
                      <span>{errorCounter?.received?.error}</span>
                      <span>{_("received")}</span>
                    </div>
                    <TiArrowDown />
                    <div>
                      <span>{errorCounter?.received?.client}</span>
                      <span>{_("clientsCount")}</span>
                    </div>
                  </div>
                )}

                {errorCounter?.created?.error == 0 &&
                  errorCounter?.received?.error == 0 && (
                    <div className={styles.no_data_alert}>
                      <BiMessageError size={25} />
                      <em>{_("noReport")}</em>
                    </div>
                  )}
              </div>
            ) : (
              <div className={styles.no_data_alert}>
                <BiMessageError size={25} /> <em>{_("noReport")}</em>
              </div>
            )}
          </RedirectCard>
          {collaborator?.role?.id === 2 ? (
            <RedirectCard
              title={_("production")}
              to={`/${lang}/user-space?tab=PRODUCTON_TRACKING`}
            >
              {categories && categories.real && categories.real.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    overflowY: "auto",
                  }}
                >
                  {categories.real.map((c) => (
                    <div key={c.id} className={styles.balance_container}>
                      <h2>
                        {`${c.label.slice(4, 12)}.`}
                        <div style={{ display: "flex", gap: 10, fontSize: 10 }}>
                          <span>
                            {renderDurationDisplay(c.duration_minutes)}
                          </span>
                          <span>{`${(
                            (c.duration_minutes * 100) /
                            (categories.theoric * 60)
                          ).toFixed(1)} %`}</span>
                        </div>
                      </h2>
                      <div>
                        <div
                          className={styles.bar_container}
                          style={{ marginTop: -2 }}
                        >
                          <div
                            className={styles.bar}
                            style={{
                              width: `${
                                (c.duration_minutes * 100) /
                                (categories.theoric * 60)
                              }%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.no_data_alert} style={{ height: 'fit-content'}}>
                  <BiMessageError size={25} /> <em>{_("noDataFound")}</em>
                </div>
              )}
            </RedirectCard>
          ) : (
            <RedirectCard
              title={_("production")}
              to={`/${lang}/user-space?tab=PRODUCTON_TRACKING`}
            >
              {getBalanceProductionQuery.isFetched &&
              balance &&
              balance.length > 0 ? (
                balance.map((b) => (
                  <div key={b.type} className={styles.balance_container}>
                    <h2>
                      {`${b.type} ${
                        b?.echeance?.echeance
                          ? "(" + b?.echeance?.echeance + ")"
                          : ""
                      }`}
                      {b.type == "TVA" ? (
                        <span>{`${b?.echeance["intervat"]}/${b?.total}`}</span>
                      ) : (
                        `(${b?.total})`
                      )}
                    </h2>
                    {b.type == "TVA" ? (
                      <div>
                        <div className={styles.bar_container}>
                          <div
                            className={styles.bar}
                            style={{
                              width: `${
                                (b?.echeance["intervat"] * 100) /
                                (b?.total ?? 1)
                              }%`,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 10,
                          width: "100%",
                        }}
                      >
                        {Object.keys(b?.details || {}).map((key) => {
                          const count = b?.details[key]["count"] ?? 0;
                          return count > 0 ? (
                            <div key={key}>
                              <h3>
                                {_(key)}{" "}
                                <span>{`${b?.details[key]["bnb"]}/${count}`}</span>
                              </h3>
                              <div className={styles.bar_container}>
                                <div
                                  className={styles.bar}
                                  style={{
                                    width: `${
                                      (b?.details[key]["bnb"] * 100) / count
                                    }%`,
                                  }}
                                />
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className={styles.no_data_alert}>
                <BiMessageError size={25} /> <em>{_("noData")}</em>
              </div>
              )}
            </RedirectCard>
          )}
          <RedirectCard
            title={_("calendar")}
            to={`/${lang}/user-space?tab=CALENDAR`}
          >
            <div className={styles.calendar}>
              <div className={styles.date}>{moment().format("MMMM YYYY")}</div>
              {absences && absences.length > 0 ? (
                absences.map((absence, index) => (
                  <div key={index} className={styles.absence_item}>
                    <div className={styles.row}>
                      <div
                        style={{
                          width: 5,
                          height: 5,
                          borderRadius: 100,
                          backgroundColor: absence.type_color,
                        }}
                      />
                      <span>{absence.name}</span>
                    </div>
                    <div
                      className={styles.row}
                      style={{ alignItems: "flex-start" }}
                    >
                      <div className={styles.row_child}>
                        <strong>{absence.reason}</strong>
                        <div className={styles[absence.status]}>
                          {_(absence.status)}
                        </div>
                      </div>
                      <div>
                        {JSON.parse(absence.days).map((day, index) => (
                          <div
                            key={index}
                            className={styles.formatted_date}
                            style={{ textTransform: "capitalize" }}
                          >
                            {new Intl.DateTimeFormat("fr-FR", {
                              day: "2-digit",
                              month: "short",
                            }).format(new Date(day))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.no_data_alert}>
                  <FaRegCalendarTimes size={25} /> <em>{_("noAbsence")}</em>
                </div>
              )}
            </div>
          </RedirectCard>
        </div>

        {collaborator && (
          <Matrix
            token={token}
            loggedAs={loggedAs}
            language={lang}
            organization={organization}
            partner={collaborator.partner?.value}
            year={year}
            user={collaborator}
          />
        )}
      </div>
    </div>
  );
}

const CustomTooltip = ({ active, payload }) => {
  if (!active || !payload || payload.length === 0) return null;

  const { name } = payload[0].payload;
  const tooltipItems = payload.map((entry) => ({
    color: entry.color,
    name: entry.name,
    value: entry.value,
  }));

  return (
    <div className={styles.custom_tooltip}>
      <div className={styles.custom_tooltip_child}>{name}</div>
      {tooltipItems.map((item) => (
        <div key={item.name} className={styles.custom_tooltip_child}>
          <div>
            <div
              className={styles.square}
              style={{ backgroundColor: item.color }}
            />
            <span>{item.name}</span>
          </div>
          <div>{renderDurationDisplay(item.value)}</div>
        </div>
      ))}
    </div>
  );
};

const CPHeader = ({ filter, setFilter }) => {
  const ref = useRef();
  const [priodDivIsVisible, setPriodDivIsVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setPriodDivIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setPriodDivIsVisible]);

  const handlePriodChange = (period) => {
    const { start, end } = calculatePeriodDates(period);
    setFilter((prevFilter) => ({ ...prevFilter, period, start, end }));
  };

  const renderPeriodSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (priodDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
          width: "136px",
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setPriodDivIsVisible(!priodDivIsVisible)}
        >
          <span>{_(filter.period)}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {priodDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {periods.map((item) => (
                <div
                  key={item.col_id}
                  className={"customed_select_body_option"}
                  onClick={() => handlePriodChange(item)}
                >
                  {_(item)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.cp_container_header}>
      <div>
        <h2>{_("capacityProduction")}</h2>
        <p>{_("colCapacityProductionDesc")}</p>
      </div>
      <div className={styles.cp_container_header_actions}>
        {renderPeriodSelect()}
      </div>
    </div>
  );
};
