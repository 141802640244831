const ADMIN_SUB_TAB = "admin";
const ACTIONS_SUB_TAB = "actions";

export const SUB_TABS = [
  {
    key: ADMIN_SUB_TAB,
    label: "Admin"
  },
  {
    key: ACTIONS_SUB_TAB,
    label: "Actions"
  }
]

export const PAGINATION_CURRENT_PAGE = 0;
export const PAGINATION_START = 0;
export const PAGINATION_LIMIT = 5;

// those columns exist in all tabs
const COMMUN_COLUMNS = [
  {
    field: "month",
    header: "Mois",
    style: {
      width: "40px"
    },
  },
  {
    field: "clientName",
    header: "Nom",
    resizeable: true,
    align: 'left',
    style: {
      width: "200px"
    },
  },
  {
    field: "status",
    header: "Statut",
    alignHeader: 'center',
    style: {
      width: "210px"
    },
  },
  {
    field: "manager",
    header: "GD",
    alignHeader: 'center',
    style: {
      width: '200px'
    }
  },
]

export const SUB_TABS_COLUMNS = {
  [ADMIN_SUB_TAB]: [
    ...COMMUN_COLUMNS,
    {
      field: "lmProposition",
      header: "LM/LAB envoyée",
      alignHeader: 'center',
    },
    {
      field: "isMandatesActivated",
      header: "Mise En Place",
      alignHeader: 'center',
    },
    {
      field: "oneShot",
      header: "Forfait de démarrage",
      alignHeader: 'center',
    },
    {
      field: "billingType",
      header: "Type de facturation",
      alignHeader: 'center',
      align: 'center',
      style: {
        textAlign: 'center'
      }
    },
    // {
    //   field: "selectedPlan",
    //   header: "Pack",
    //   alignHeader: 'center',
    //   align: 'center',
    //   style: {
    //     textAlign: 'center'
    //   }
    // },
    {
      field: "feeYearN",
      header: "Annuel N",
      alignHeader: 'center',
      align: 'center',
    },
    {
      field: "feeYearN1",
      header: "Annuel N+1",
      alignHeader: 'center',
      align: 'center',
    },
    // {
    //   field: "waitingForClient",
    //   header: "En attente client",
    //   alignHeader: 'center',
    // },
    // {
    //   field: "todoDeg",
    //   header: "À faire Deg",
    //   alignHeader: 'center',
    // },
    // {
    //   field: "orderSent",
    //   header: "OP envoyée",
    //   alignHeader: 'center',
    // },
    // {
    //   field: "orderSigned",
    //   header: "OP Signée",
    //   alignHeader: 'center',
    // },
    // {
    //   field: "lmReception",
    //   header: "Gagné/Perdu",
    //   alignHeader: 'center',
    // },
    // {
    //   field: "planning",
    //   header: "A/D",
    //   alignHeader: 'center',
    //   align: 'right',
    // },
    // {
    //   field: "governance",
    //   header: "Régie",
    //   alignHeader: 'center',
    // },
  ],
  [ACTIONS_SUB_TAB]: [
    ...COMMUN_COLUMNS,
    {
      field: "rdv",
      header: "RDV",
      alignHeader: 'center',
      style: {
        width: "160px"
      },
    },
    {
      field: "isPresentationDone",
      header: "Présentation Gestionnaire",
      alignHeader: 'center',
      style: {
        width: '200px'
      }
    },
    {
      field: "appDemo",
      header: "APP démo",
      alignHeader: 'center',
    },
    // {
    //   field: "oneMonth",
    //   header: "1 mois",
    //   alignHeader: 'center',
    //   style: {
    //     width: "232px"
    //   },
    // },
    // {
    //   field: "nineMonths",
    //   header: "9 mois",
    //   alignHeader: 'center',
    //   style: {
    //     width: "232px"
    //   },
    // },
    {
      field: "volumetry",
      header: "Volumétrie",
      alignHeader: 'center',
      style: {
        width: "232px"
      },
    },
    {
      field: "actions",
      header: "Actions",
      alignHeader: 'center',
      style: {
        width: "100px"
      },
    }
  ]
}
