import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { CustumedMatrix } from "tamtam-components";
import {
  getCollaboratorsBonuses,
  getCollaboratorsByRole,
} from "../../../../api";
import _ from "../../../../i18n";
import { parsePreference } from "../../../../utils";
import CollaboratorDevelopmentCard from "../../../Collaborators/CollaboratorDevelopmentCard";
import TTPPaginator from "../../../common/TTPPaginator";
import GroupFilter from "../../GroupFilter";
import "../Bonuses.local.scss";

const BonusBE = ({ filter, collaborator, preferences }) => {
  const { token, navCommunity, lng, user, loggedAs } = useSelector((state) => state.auth);

  const allowViewDataBy =
    parsePreference(preferences).individualDevBe.allowViewDataBy;

  const [showSideBar, setShowSideBar] = useState(null);
  const [data, setData] = useState(null);
  const [loadingCollaborators, setLoadingCollaborators] = useState(true);

  const [filters, setFilters] = useState({
    ...filter,
    bu: allowViewDataBy === 100 && collaborator ? [collaborator.bu] : [],
    zone: allowViewDataBy === 101 && collaborator ? collaborator.zoneId : null,
    partner: loggedAs == "MANAGER" ? user?.id : null,
    collaborators:
      [10, 110].includes(allowViewDataBy) && collaborator
        ? [collaborator.id]
        : null,
  });

  const [columns, setColumns] = useState([
    {
      id: "powerteam",
      label: _("Powerteam"),
      isEditable: true,
      widthPercentage: 12,
    },
    {
      id: "balance",
      label: _("Balance"),
      isEditable: true,
      widthPercentage: 15,
    },
    {
      id: "ipp_cr",
      label: _("Ipp CR"),
      isEditable: true,
      widthPercentage: 20,
    },
    {
      id: "ipp",
      label: _("Ipp"),
      isEditable: true,
      widthPercentage: 14,
    },
    {
      id: "horus",
      label: _("Horus"),
      isEditable: true,
      widthPercentage: 6,
    },
    {
      id: "intermediateSituation",
      label: _("Int. Situation"),
      isEditable: true,
      widthPercentage: 10,
    },
  ]);

  // TODO: Add Year filter
  let reportingsQuery = useQuery(
    ["getCollaboratorsBonuses", token, navCommunity, filters],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsBonuses(
            token,
            navCommunity.id,
            filters
          );
          const defaultCollaborator = {
            id: null,
            name: "Tous les collaborateurs",
          };
          setData(response.data.data);
          setFilters({ ...filters, nbResult: response.data.nbResult });
          setLoadingCollaborators(false);
        } catch (e) {
          setLoadingCollaborators(false);
          return null;
        }
      }
    }
  );

  const handleColumns = (column, line) => {
    if (column.id === "balance") {
      return (
        <div
          className="tags"
          style={{ margin: "0px 10px 2px 2px" }}

          // style={{ marginTop: "20px", justifyContent: "center" }}
        >
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.createdTasks} %</div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Blian fini deg")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.balanceFinishedDeg}</div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.balance.delay}</div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "horus") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
          // className="labled-tag no-border"
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("client horus")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.horus.clientsHorus} %</div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "powerteam") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
          // className="labled-tag no-border"
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Planner")}</span>}
            <div
              className="tag"
              style={{
                borderLeft: "none",
                justifyContent: "center",
                display: "flex",
                background: "none",
                width: "40px",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  background: "none",
                  width: "40px",
                }}
              >
                {line.powerteam.countOverHoursByWeek}
              </div>
            </div>
          </div>
          <div
          // className="labled-tag no-border"
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Plan de correction")}</span>}
            <div
              className="tag"
              style={{
                borderLeft: "none",
                justifyContent: "center",
                display: "flex",
                background: "none",
                width: "40px",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  background: "none",
                  width: "30px",
                }}
              >
                {line.powerteam.countDevelopementCard}
              </div>
            </div>
          </div>
          <div
          // className="labled-tag no-border"
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Rapport d erreur ")}</span>}
            <div
              className="tag"
              style={{
                borderLeft: "none",
                justifyContent: "center",
                display: "flex",
                background: "none",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  background: "none",
                }}
              >
                {line.powerteam.countErrorReport}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "ipp_cr") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              // style={{ borderLeft: "none", justifyContent: "flex-end" }}
              style={{
                borderLeft: "none",
                justifyContent: "center",
                display: "flex",
                background: "none",
              }}
            >
              <div style={{ width: "30px" }}>{line.ippCr.createdTasks} %</div>
            </div>
          </div>
          <div
          // className={`no-border ${
          //   data.indexOf(line) === 0 ? "labled-tag " : ""
          // }`}
          >
            {data.indexOf(line) === 0 && (
              <span>{_("Presentation déclaré")}</span>
            )}
            <div
              className="tag"
              style={{
                borderLeft: "none",
                justifyContent: "flex-end",
                width: "30px",
              }}
            >
              <div style={{ width: "30px" }}>
                {line.ippCr.isPresentationDeclared}{" "}
              </div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches completes")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div style={{ width: "30px" }}>{line.ippCr.completedTaskes} </div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ippCr.delay} </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "ipp") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.createdTasks} %</div>
            </div>
          </div>

          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches comptetes")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.completedTaskes} </div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("retard")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div>{line.ipp.delay} </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "intermediateSituation") {
      return (
        <div
          className="tags"
          style={{ margin: "1px 0px 2px 2px" }}
          // style={{ justifyContent: "center", marginTop: "20px" }}
        >
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("taches créées")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div style={{ width: "30px" }}>
                {line.intermediateSituation.createdTasks} %
              </div>
            </div>
          </div>
          <div
            className={`no-border ${
              data.indexOf(line) === 0 ? "labled-tag " : ""
            }`}
          >
            {data.indexOf(line) === 0 && <span>{_("Presentation Si")}</span>}
            <div
              className="tag"
              style={{ borderLeft: "none", justifyContent: "flex-end" }}
            >
              <div style={{ width: "30px" }}>
                {line.intermediateSituation.isPresentationSi}{" "}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (column.id === "timesheet") {
      return (
        <div className="tags" style={{ margin: "20px 10px" }}>
          <div className="labled-tag no-border">
            <span>{_("Encodage Réalisé")}</span>
            <div className="tag">
              <div>
                {/* {renderDurationDisplay(line.timesheet.realizedEncodage)} */}
              </div>
            </div>
          </div>

          <div className="labled-tag no-border">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <span>{_("Nettoyage Réalisé")}</span>
            </div>
            <div className="tag">
              <div>
                {/* {renderDurationDisplay(line.timesheet.realizedNettoyage)} */}
              </div>
            </div>
          </div>
          <div className="labled-tag no-border">
            <span>{_("Bilan Réalisé")}</span>
          </div>

          <div className="labled-tag no-border">
            <div
              style={{
                display: "flex",
                width: "85%",
              }}
            >
              <span>{_("Rapport")}</span>
            </div>
            <div className="tag">
              <div
                style={{
                  color:
                    line.timesheet.rapport > 2
                      ? " #06D9B1"
                      : line.timesheet.rapport > 1.5
                      ? "#EA8124"
                      : "#eb5757",
                  backgroundColor:
                    line.timesheet.rapport > 2
                      ? " rgba(6, 217, 177, 0.1)"
                      : line.timesheet.rapport > 1.5
                      ? "rgb(235 152 87 / 17%)"
                      : "rgba(235, 87, 87, 0.1)",
                }}
              >
                {line.timesheet.rapport}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  const handleTitle = (item) => {
    const isFirstItem = data.indexOf(item) === 0;
    return (
      <div
        className="matrix_title"
        style={{ paddingTop: isFirstItem ? "50px" : 0, minWidth: "20 px" }}
      >
        <div>{item.name}</div>
      </div>
    );
  };

  const renderDevelopementCardSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${showSideBar !== null && "display"}`}>
          <CollaboratorDevelopmentCard
            lng={lng}
            year={filters.year}
            organization={navCommunity.id}
            collaborator={showSideBar}
            token={token}
            onClose={() => setShowSideBar(null)}
            user={user ? user.firstName + " " + user.lastName : ""}
          />
        </div>
      </div>
    );
  };

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilters({ ...filters, paginationPage: selected + 1 })
        }
        pageSize={filters.pageSize}
        nbResult={filters.nbResult}
        paginationPage={filters.paginationPage}
      />
    );
  };

  return (
    <>
      <GroupFilter
        extraFilters={[]}
        filter={filters}
        nbResult={filters.nbResult}
        updateFilter={setFilters}
        allowViewDataBy={
          parsePreference(preferences).individualDevBe.allowViewDataBy
        }
        collaborator={collaborator}
        preferences={preferences}
        subPreference="individualDevBe"
      />
      <div>
        <div className="bonuses_table">
          {loadingCollaborators ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : (
            data && (
              <div>
                <div
                  style={{
                    overflowX: "auto",
                    marginTop: 10,
                  }}
                >
                  <div
                    className="production-frame"
                    style={{ width: "125%", overflowX: "auto", margin: 0 }}
                  >
                    <CustumedMatrix
                      columns={columns}
                      items={data}
                      handleCellule={handleColumns}
                      handleTitle={handleTitle}
                      headerTagBG={"#FFFFFF"}
                    />
                  </div>
                </div>
                {renderPaginate()}
              </div>
            )
          )}
        </div>
        {showSideBar && renderDevelopementCardSideBarFrame()}
      </div>
    </>
  );
};

export default BonusBE;
