import React from 'react';
import classNames from 'classnames';
import styles from './HolidayFilters.module.scss';

const HolidayFilters = ({ filters, activeFilter, onFilterChange }) => (
  <div className={styles.categories_filters}>
    {filters.map((filter) => (
      <div
        key={filter.key}
        className={classNames({
          [styles.filter_item]: true,
          [styles[`filter_item_${filter.key.toLowerCase()}`]]: true,
          [styles.filter_item_active]: filter.key === activeFilter,
        })}
        onClick={() => onFilterChange(filter)}
      >
        <div className={styles.filter_item_tag}>
          {filter.label}
          <div className={styles.filter_item_tag_count}>
            {filter.count}
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default HolidayFilters;
