import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import _ from "../../../i18n";
import moment from "moment";

import {
  getReportingErreurContribution,
  getCategoriesSuggestions,
  getCollaboratorsAvatars,
} from "../../../api";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { Tooltip as TooltipAntd } from "antd";
import { RepeatIcon, AlertCircleIcon } from "../../common/Icons";
import { parsePreference, renderAvatar } from "../../../utils";

import { CustumedMatrix } from "tamtam-components";
import ErrorReportingFilter from "./ErrorReportingFilter";
import "./ErrorReporting.local.scss";
import GroupFilter from "../GroupFilter";
import { useSelector } from "react-redux";

const columns = [
  {
    id: "reports",
    label: _("nbrReports"),
    isEditable: true,
    widthPercentage: 20,
  },
  {
    id: "folders",
    label: _("nbrFolders"),
    isEditable: true,
    widthPercentage: 20,
  },
  {
    id: "lastInsert",
    label: _("lastInsert"),
    isEditable: true,
    widthPercentage: 20,
  },
];

const colors = [
  "#1B435D",
  "#78BBE6",
  "#D5EEFF",
  "#FF895D",
  "#FBA834",
  "#333A73",
  "#387ADF",
  "#50C4ED",
  "#E88D67",
  "#F3F7EC",
  "#006989",
  "#005C78",
  "#01204E",
  "#028391",
  "#F6DCAC",
  "",
  "#FEAE6F",
];

const ErrorReporting = ({ token, navCommunity, parentFilter, language, collaborator, preferences }) => {
  const { user, loggedAs } = useSelector((state) => state.auth);
  const allowViewDataBy =
    parsePreference(preferences).supervision.allowViewDataBy;
  const [filter, setFilter] = useState({
    ...parentFilter,
    keyWord: "",
    bu: allowViewDataBy === 100 && collaborator ? [collaborator.bu] : [],
    zone: allowViewDataBy === 101 && collaborator ? collaborator.zoneId : null,
    partner: loggedAs == "MANAGER" ? user?.id : null,
    collaborators:
      [10, 110].includes(allowViewDataBy) && collaborator
        ? [collaborator.id]
        : [],
    order: "BY_COLLABORATOR",
    year: new Date().getFullYear().toString(),
  });
  const [contribution, setContribution] = useState({});
  const [progression, setProgression] = useState({});
  const [categories, setCategories] = useState({});
  const [avatars, setAvatars] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  let reportingErrorsQuery = useQuery(
    ["getReportingErrors", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getReportingErreurContribution(
            filter.year,
            navCommunity.id,
            token,
            filter.zone,
            filter.order,
            filter.partner,
            filter.collaborators,
            filter.bu
          );
          let dataResponse = response.data.data;
          setContribution(dataResponse.data);
          setProgression(dataResponse.chart);
          setSelectedCollaborator(
            filter.order === "BY_COLLABORATOR"
              ? Object.keys(dataResponse.data).filter(
                  (key) => key !== "label"
                )[0]
              : Object.keys(Object.values(dataResponse.data)[0]).filter(
                  (key) => key !== "label"
                )[0]
          );
          setSelectedCategory(
            filter.order === "BY_COLLABORATOR"
              ? Object.keys(Object.values(dataResponse.data)[0]).filter(
                  (key) => !["name", "uid"].includes(key)
                )[0]
              : Object.keys(dataResponse.data).filter(
                  (key) => key !== "label"
                )[0]
          );
        } catch (e) {
          setProgression([]);
          setContribution([]);
          return null;
        }
      }
    }
  );

  let avatarsQuery = useQuery(
    ["getCollaboratorAvatars", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsAvatars(
            navCommunity.id,
            token
          );
          setAvatars(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let categoriesQuery = useQuery(
    ["categoriesQuery", navCommunity.id],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCategoriesSuggestions(
            navCommunity.id,
            token
          );
          let categoriesResponse = {};
          response.data.data.forEach((item) => {
            categoriesResponse[item.id] = {
              id: item.id,
              label: item.label[language],
              color: colors[item.id],
            };
          });
          setCategories(categoriesResponse);
        } catch (e) {
          setCategories([]);
          return null;
        }
      }
    }
  );

  const renderCollaboratorsList = (data, subClass) => {
    let contribution = subClass ? data[subClass] : data;
    return (
      <div className="columns list">
        {contribution &&
          Object.keys(contribution).map((item) => {
            if (item === "label") {
              return;
            }
            return (
              <div
                className={`tag-item ${
                  item === selectedCollaborator && "active"
                } `}
                onClick={() => {
                  setSelectedCollaborator(item);
                  if (!subClass) {
                    setSelectedCategory(
                      Object.keys(contribution[item]).filter(
                        (key) => !["name", "uid"].includes(key)
                      )[0]
                    );
                  }
                }}
              >
                <div className="avatar" key={item}>
                  {renderAvatar(
                    avatars[contribution[item].uid]
                      ? avatars[contribution[item].uid].avatarUrl
                      : "",
                    contribution[item].name
                  )}
                </div>
                <div className="name">{contribution[item].name}</div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderCategoriesList = (data, subClass) => {
    let contribution = subClass ? data[subClass] : data;
    return (
      <div className="columns list">
        {contribution &&
          Object.keys(contribution).map((item) => {
            if (["name", "uid"].includes(item)) {
              return;
            }
            return (
              <div
                className={`tag-item ${item === selectedCategory && "active"} `}
                onClick={() => {
                  setSelectedCategory(item);
                  if (!subClass) {
                    setSelectedCollaborator(
                      Object.keys(contribution[item]).filter(
                        (key) => key !== "label"
                      )[0]
                    );
                  }
                }}
              >
                <div className="name">{contribution[item].label}</div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderFiltredData = () => {
    let data =
      Object.keys(contribution).length > 0 &&
      selectedCategory &&
      selectedCollaborator
        ? contribution[
            filter.order === "BY_COLLABORATOR"
              ? selectedCollaborator
              : selectedCategory
          ][
            filter.order === "BY_COLLABORATOR"
              ? selectedCategory
              : selectedCollaborator
          ]
        : {};
    let filtredData = { ...data };
    delete filtredData.label;
    delete filtredData.name;
    delete filtredData.uid;
    filtredData = Object.values(filtredData);
    filtredData.unshift({
      label: "total",
      folders: [],
      reports: [],
      lastInsert: "",
    });
    return filtredData;
  };

  const renderChartsFrame = () => {
    let dataChart1 = [];
    let dataChart2 = [];
    let barsChart1 = [];
    let warningChart1 = null;
    if (!reportingErrorsQuery.isFetching) {
      // CHART 1
      let affectedFolders =
        progression.chart1 &&
        progression.chart1.affectedFolders &&
        progression.chart1.affectedFolders[selectedCollaborator];
      if (affectedFolders === 0 || affectedFolders === "noRole") {
        warningChart1 =
          affectedFolders === "noRole"
            ? "noRoleConfigured"
            : "noAffectedFolders";
      } else {
        dataChart1 =
          progression &&
          progression.chart1 &&
          progression.chart1.data && Object.keys(
            progression.chart1.data[selectedCollaborator]
        ).map((key) => {
          let percentages = [];
          Object.keys(
            progression.chart1.data[selectedCollaborator][key]
          ).forEach((catKey) => {
            percentages[catKey] = parseFloat(
              (progression.chart1.data[selectedCollaborator][key][catKey]
                .length *
                100) /
                affectedFolders
            ).toFixed(0);
            if (!barsChart1.includes(catKey)) {
              barsChart1.push(catKey);
            }
          });
          return {
            ...percentages,
            label: moment()
              .month(parseInt(key) - 1)
              .format("MMM"),
          };
        });
      }
      //CHART 2
      dataChart2 =
        progression &&
        progression.chart2 &&
        Object.keys(progression.chart2[selectedCollaborator]).map(
        (key) => {
          return {
            ...progression.chart2[selectedCollaborator][key],
            label: progression.chart2[selectedCollaborator][key].label.substr(
              0,
              10
            ),
            gap: parseFloat(
              progression.chart2[selectedCollaborator][key].total -
                progression.chart2[selectedCollaborator][key].isSerious
            ).toFixed(2),
          };
        }
      );
    }
    let name =
      filter.order === "BY_COLLABORATOR" && contribution
        ? contribution[selectedCollaborator]?.name || ""
        : contribution[selectedCategory]?.[selectedCollaborator]?.name || "";

    return (
      <div className="chart-pannel">
        <div className="chart-frame">
          <div className="title">
            {_("Graphe du taux d'erreur")}{" "}
            {selectedCollaborator ? `: ${name}` : ""}{" "}
          </div>
          <div className="labels">
            {barsChart1.map((bar) => {
              return (
                <div className="label">
                  <div
                    className="dot"
                    style={{ background: categories[bar].color }}
                  />
                  {categories[bar].label.substr(0, 15)}
                  {categories[bar].label.length > 15 ? "." : ""}
                </div>
              );
            })}
          </div>
          {warningChart1 ? (
            <div className="no_data">
              <div className="title">{_(warningChart1)}</div>
              <div className="subTitle">{_(`${warningChart1}Message`)}</div>
            </div>
          ) : (
            <div className="body">
              <div className="body_titles">
                <div>
                  {_("rate")} {"%"}
                </div>
              </div>
              <div className="bar_chart">
                <ComposedChart
                  width={500}
                  height={350}
                  data={dataChart1}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" vertical={false} />
                  <XAxis
                    dataKey="label"
                    height={60}
                    interval={0}
                    tick={{ fontWeight: 400 }}
                  />
                  <YAxis type="number" tickLine={false} />
                  <Tooltip content={<CustomTooltip />} />
                  {barsChart1.map((bar) => (
                    <Bar
                      dataKey={bar}
                      barSize={26}
                      stackId="a"
                      fill={categories[bar].color}
                    />
                  ))}
                </ComposedChart>
              </div>
            </div>
          )}
        </div>
        <div className="chart-frame">
          <div className="title">
            {_("Types d'Erreurs")}
            {selectedCollaborator ? `: ${name}` : ""}
          </div>
          <div className="labels">
            <div className="label">
              <div className="dot" style={{ background: "#EB5757" }} />
              {_("serious")}
            </div>
          </div>
          <div className="body">
            <div className="body_titles">
              <div>{_("Nbr erreur")}</div>
            </div>
            <div className="bar_chart">
              <ComposedChart
                width={500}
                height={350}
                data={dataChart2}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid strokeDasharray="1 1" vertical={false} />
                <XAxis
                  dataKey="label"
                  interval={0}
                  height={60}
                  tick={{ fontWeight: 400 }}
                  padding={{ left: 17, right: 17 }}
                />
                <YAxis type="number" tickLine={false} />
                <Tooltip content={<CustomTooltip2 />} />
                <Bar dataKey="gap" barSize={20} stackId="a" fill="#b2bcc6" />
                <Bar
                  dataKey="isSerious"
                  barSize={20}
                  stackId="a"
                  fill="#EB5757"
                />
              </ComposedChart>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip-2">
          {Object.keys(payload[0].payload).map((key) => {
            if (!["label"].includes(key)) {
              return (
                <p>{`${categories[key].label.substr(0, 15)}${
                  categories[key].label.length > 15 ? "." : ""
                }   : ${parseFloat(payload[0].payload[key]).toFixed(0)}%`}</p>
              );
            }
          })}
        </div>
      );
    }

    return null;
  };

  const CustomTooltip2 = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip-2">
          <span>{categories[payload[0].payload.id].label}</span>
          {Object.keys(payload[0].payload).map((key) => {
            if (!["items", "gap", "id", "label"].includes(key)) {
              return (
                <p>
                  {key}: {payload[0].payload[key]}
                </p>
              );
            }
          })}
          <span>{_("details")}</span>
          {Object.keys(payload[0].payload.items).map((key) => {
            return (
              <p>
                {payload[0].payload.items[key].label}:{" "}
                {`[ total: ${payload[0].payload.items[key].total}, grave: ${payload[0].payload.items[key].isSerious} ]`}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="error_container">
      {/* <ErrorReportingFilter filter={{ ...filter }} updateFilter={setFilter} /> */}
      <GroupFilter
          extraFilters={[]}
          filter={filter}
          updateFilter={setFilter}
          allowViewDataBy={
            parsePreference(preferences).supervision.allowViewDataBy
          }
          collaborator={collaborator}
          preferences={preferences}
          subPreference="supervision"
        />
      <div className="result_label">
        <div
          className="action"
          onClick={() =>
            setFilter({
              ...filter,
              order:
                filter.order === "BY_COLLABORATOR"
                  ? "BY_CATEGORY"
                  : "BY_COLLABORATOR",
            })
          }
        >
          <RepeatIcon />
        </div>
        {filter.order === "BY_COLLABORATOR"
          ? `${_("collaborator")} -> ${_("category")}`
          : `${_("category")} -> ${_("collaborator")}`}
      </div>

      {reportingErrorsQuery.isFetching ? (
        <div className="ripple_loading">
          <img src="/img/Ripple-1s-150px.svg" alt="Loading" />
        </div>
      ) : (
        <div className="content grid-x">
          {contribution && (
            <div style={{ display: "block" }}>
              <div style={{ display: "flex", width: "100%" }}>
                {filter.order === "BY_COLLABORATOR"
                  ? renderCollaboratorsList(contribution, null)
                  : renderCategoriesList(contribution, null)}
                {filter.order === "BY_COLLABORATOR"
                  ? renderCategoriesList(contribution, selectedCollaborator)
                  : renderCollaboratorsList(contribution, selectedCategory)}

                <div className="columns body-content" style={{ width: "50%" }}>
                  <CustumedMatrix
                    columns={columns}
                    items={renderFiltredData()}
                    handleCellule={(column, line) => {
                      if (["folders", "reports"].includes(column.id)) {
                        return line.label === "total" ? (
                          ""
                        ) : (
                          <div class="tag">
                            <div>{line[column.id].length}</div>
                            <TooltipAntd
                              key={`tab-20}`}
                              placement="top"
                              title={
                                <div>
                                  {_(
                                    column.id === "reports"
                                      ? "responsables"
                                      : "folders"
                                  )}
                                  <ul>
                                    {line[
                                      column.id === "reports"
                                        ? "reportResponsableNames"
                                        : "folderNames"
                                    ].map((item) => (
                                      <li>{item}</li>
                                    ))}
                                  </ul>
                                </div>
                              }
                            >
                              <div>
                                <AlertCircleIcon />
                              </div>
                            </TooltipAntd>
                          </div>
                        );
                      }
                      return line.label === "total" ? (
                        ""
                      ) : (
                        <div class="tag">
                          <div>{line[column.id]}</div>
                        </div>
                      );
                    }}
                    handleTitle={(title) =>
                      title.label === "total" ? "" : title.label
                    }
                    headerTagBG="#FFFFFF"
                  />
                </div>
              </div>
              <div>{renderChartsFrame()}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ErrorReporting;
