import React from "react";

export const VerticalEllipsisIcon = ({ size = 13, fill = "#29394D" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.00016 8.66671C8.36835 8.66671 8.66683 8.36823 8.66683 8.00004C8.66683 7.63185 8.36835 7.33337 8.00016 7.33337C7.63197 7.33337 7.3335 7.63185 7.3335 8.00004C7.3335 8.36823 7.63197 8.66671 8.00016 8.66671Z"
      stroke="#6D7F92"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00016 3.99996C8.36835 3.99996 8.66683 3.70148 8.66683 3.33329C8.66683 2.9651 8.36835 2.66663 8.00016 2.66663C7.63197 2.66663 7.3335 2.9651 7.3335 3.33329C7.3335 3.70148 7.63197 3.99996 8.00016 3.99996Z"
      stroke="#6D7F92"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00016 13.3333C8.36835 13.3333 8.66683 13.0349 8.66683 12.6667C8.66683 12.2985 8.36835 12 8.00016 12C7.63197 12 7.3335 12.2985 7.3335 12.6667C7.3335 13.0349 7.63197 13.3333 8.00016 13.3333Z"
      stroke="#6D7F92"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
