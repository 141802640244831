import { fr } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import "./../../../../../../node_modules/react-day-picker/dist/style.css";
import './CustomDayPicker.scss';

export default function CustomDayPicker({
  month,
  selectedDays,
  onSelect,
  disabledDays,
  modifiers,
  modifiersClassNames
}) {
  let defaultModifiers = {
    weekend: { dayOfWeek: [0, 6] },
    absence_day_start: typeof selectedDays?.from === 'string' ? new Date(selectedDays?.from) : selectedDays?.from,
    absence_day_end: typeof selectedDays?.to === 'string' ? new Date(selectedDays?.to) : selectedDays?.to,
  };

  return (
    <DayPicker
      mode='range'
      locale={fr}
      showOutsideDays
      canChangeMonth={false}
      month={month}
      className="custom_day_picker"
      selected={selectedDays}
      onSelect={onSelect}
      disableNavigation
      disabled={disabledDays}
      modifiers={{...defaultModifiers, ...modifiers}}
      modifiersClassNames={{
        absence_day_start: "absence_day_start",
        absence_day_end: "absence_day_end",
        ...modifiersClassNames
      }}
    />
  )
}
