import styles from './index.module.scss';

export default function CardBody({
  title,
  children
}) {
  return (
    <div className={styles.card_body}>
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.body_content}>
        {children}
      </div>
    </div>
  )
}
