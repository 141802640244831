import React from "react";
import _ from "../../../i18n";
import ReportingCard from "./ReportingCard/ReportingCard";
import "./ReportingCardsFilter.local.scss";

const ReportingCardsFilter = ({ tabs, selectTab }) => {
  return (
      <div className="grid">
        {tabs.map((tab) => (
          <div className="grid-item" key={tab.id}>
            <ReportingCard
              tab={tab}
              onClick={selectTab}
            />
          </div>
        ))}
      </div>
  );
};

export default ReportingCardsFilter;