import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { CustumedMatrix } from "tamtam-components";
import { Link } from "react-router-dom";
import { getCollaboratorNonFactorableContribution } from "../../../../api";
import _ from "../../../../i18n";
import { FlagIcon, ShortcutIcon } from "../../../common/Icons";
import CollaboratorCorrectionCard from "./CollaboratorCorrectionCard";
import "./EncodingProgressionContent.local.scss";
import { Modal, Table, Typography } from "antd";
import { parseDurationDisplay, renderDurationDisplay } from "../../../../utils";

const NonFacturableContent = ({
  id,
  year,
  lng,
  name,
  token,
  user,
  filter,
  loggedAs,
  collaborator,
  organization,
}) => {
  const [expandedClient, setExpandedClient] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [showSideBar, setShowSideBar] = useState(null);
  const [contribution, setContribution] = useState([]);
  const [filtredContribution, setFiltredContribution] = useState([]);
  const [columns, setColumns] = useState([
    {
      id: "total",
      label: _("total"),
      isEditable: true,
      widthPercentage: 10,
    },
    {
      id: "detail",
      label: _("cpDetails"),
      isEditable: true,
      widthPercentage: 40,
    },
  ]);

  const codes = ["NF", "RE", "MA", "AUTRE"];

  let collaboratorContributionQuery = useQuery(
    ["collaboratorContributionQuery", token, year, filter],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorNonFactorableContribution(
            id,
            organization,
            year,
            token,
            [filter.category.id],
            filter.period && filter.period.id,
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.start
                : year + "-" + filter.subPeriod.start
              : filter.start
              ? moment(filter.start).format("YYYY-MM-DD")
              : year + "-01-01",

            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.end
                : year + "-" + filter.subPeriod.end
              : filter.end
              ? moment(filter.end).format("YYYY-MM-DD")
              : year + "-12-31",
            filter.keyWord,
            filter.category.label.split(".")[0]
          );
          setContribution(response.data.data);
          setFiltredContribution(response.data.data);
        } catch (e) {
          setContribution([]);
          return null;
        }
      }
    }
  );

  const renderPannelFrame = () => {
    return (
      <div className="production-frame">
        {collaboratorContributionQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : !collaboratorContributionQuery.isFetching &&
          filtredContribution &&
          filtredContribution.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          collaboratorContributionQuery.isFetched && (
            <div className="production-frame_scroll">
              <div className="total">
                {_("folderCount")} : {contribution.length - 1}
              </div>
              <CustumedMatrix
                columns={columns}
                items={filtredContribution}
                handleCellule={handleColumns}
                handleTitle={handleTitle}
                headerTagBG={"#FFFFFF"}
              />
            </div>
          )
        )}
      </div>
    );
  };

  const renderChildrenData = () => {
    const data = expandedClient?.length && expandedClient[0];

    const columns = [
      {
        title: <span className="custom-column-header">{_("type")}</span>,
        dataIndex: "label",
        key: "label",
        width: 350,
        fixed: "left",
        className: "custom-cell",
        onCell: (record) => ({
          style: {
            borderRadius: "5px",
            backgroundColor:
              record.code.split("-")[0] === "record"
                ? "transparent"
                : "#e5e8eb",
          },
        }),
      },
      {
        title: <span className="custom-column-header-v2">{_("realized")}</span>,
        dataIndex: "duration",
        key: "duration",
        width: 110,
        className: "custom-cell-spacing",
        onCell: () => ({
          style: {
            backgroundColor: "#e5e8eb",
          },
        }),
      },
      {
        title: <span className="custom-column-header-v2">{_("date")}</span>,
        dataIndex: "date",
        key: "date",
        width: 150,
        className: "custom-cell-spacing",
        onCell: () => ({
          style: {
            backgroundColor: "#e5e8eb",
          },
        }),
      },
      {
        title: (
          <span className="custom-column-header-v2" style={{ borderRight: 0 }}>
            {_("observations")}
          </span>
        ),
        dataIndex: "observation",
        key: "observation",
        className: "custom-cell-spacing",
        width: "100%",
        onCell: () => ({
          style: {
            backgroundColor: "#e5e8eb",
          },
        }),
      },
    ];

    const transformData = (data) => {
      const formatRecord = (record, index) => ({
        code: `record-${index}`,
        label: "",
        duration: renderDurationDisplay(record.value * 60) || (
          <div className="no-value-cell" />
        ),
        date: record.date || <div className="no-value-cell" />,
        observation: record.observation || <div className="no-value-cell" />,
      });

      const result = Object.keys(data.details).map(
        (mainCategory, mainIndex) => ({
          code: `main-${mainCategory}-${mainIndex}`,
          label: mainCategory,
          duration:
            renderDurationDisplay(data.details[mainCategory].toFixed(2) * 60) ||
            0,
          date: <div className="no-value-cell" />,
          observation: <div className="no-value-cell" />,
          children: Object.keys(data.children[mainCategory]?.details || {}).map(
            (subCategory, subIndex) => {
              const subCategoryChildren = (
                data.children[mainCategory].details[subCategory].types || []
              ).map((record, recordIndex) => formatRecord(record, recordIndex));

              const subCategoryDuration = subCategoryChildren.reduce(
                (total, child) =>
                  total + (parseDurationDisplay(child.duration) || 0),
                0
              );

              return {
                code: `sub-${mainCategory}-${subCategory}-${subIndex}`,
                label:
                  data.children[mainCategory].details[subCategory].label ||
                  subCategory,
                duration: renderDurationDisplay(subCategoryDuration),
                date: <div className="no-value-cell" />,
                observation: <div className="no-value-cell" />,
                children: subCategoryChildren,
              };
            }
          ),
        })
      );

      return result;
    };

    return (
      <Modal
        open={expanded}
        title={<h4>{data?.title?.label || "No Title"}</h4>}
        onCancel={() => setExpanded(false)}
        width="80%"
        footer={null}
      >
        <Table
          columns={columns}
          dataSource={transformData(data)}
          size="small"
          pagination={false}
          scroll={{ y: 535, x: columns.length * 175 }}
          rowKey="code"
          bordered={false}
          style={{ padding: 30 }}
          rowClassName="custom-row-spacing"
        />
      </Modal>
    );
  };

  const prepareExpandedClient = (data) => {
    console.log("ee", data[0]);
    setExpandedClient(data);
    setExpanded(true);
  };

  const handleColumns = (column, line) => {
    if (line.title.label === "TOTAL") {
      if (column.id === "total") {
        return (
          <div
            className="tags"
            style={{ flexDirection: "column", justifyContent: "space-around" }}
          >
            <div className="tag no-border sm-margin">
              <span>{_("total")}</span>
            </div>
            <div className="tag no-border sm-margin">
              <div>
                {renderDurationDisplay(parseFloat(line.total).toFixed(2) * 60)}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <Fragment>
            <div
              className="tags"
              style={{
                padding: "0",
                margin: "0px 2px",
                justifyContent: "space-around",
              }}
            >
              {codes.map((code, index) => (
                <div
                  className="tag no-border sm-margin"
                  style={{ padding: index === 0 ? "none" : "er" }}
                >
                  <span>{code}</span>
                </div>
              ))}
            </div>
            <div
              className="tags"
              style={{
                padding: "0",
                margin: "0px 2px",
                justifyContent: "space-around",
              }}
            >
              {codes.map((code, index) => (
                <div
                  className="tag no-border sm-margin"
                  style={{ padding: index === 0 ? "none" : "er" }}
                >
                  <div>
                    {renderDurationDisplay(
                      parseFloat(line.details[code]).toFixed(2) * 60
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Fragment>
        );
      }
    } else {
      if (column.id === "total") {
        return (
          line.title.type !== "child" && (
            <div
              className="tags"
              style={{
                flexDirection: "column",
              }}
              onClick={() => prepareExpandedClient(line.children)}
            >
              <div
                className="tag no-border sm-margin"
                style={{ marginTop: 12 }}
              >
                <div>
                  {renderDurationDisplay(
                    parseFloat(line.total).toFixed(2) * 60
                  )}
                </div>
              </div>
            </div>
          )
        );
      } else {
        return (
          line.title.type !== "child" && (
            <Fragment>
              <div onClick={() => prepareExpandedClient(line.children)}>
                <div
                  className="tags"
                  style={{
                    padding: "0",
                    margin: "0px 2px",
                    justifyContent: "space-around",
                  }}
                >
                  {codes.map((code, index) => (
                    <div
                      className="tag no-border sm-margin"
                      style={{
                        padding: index === 0 ? "none" : "er",
                        marginTop: 12,
                      }}
                    >
                      <div>
                        {renderDurationDisplay(
                          parseFloat(line.details[code]).toFixed(2) * 60
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Fragment>
          )
        );
      }
    }
  };

  const handleTitle = (title) => {
    const data = filtredContribution.filter(
      (contribution) => contribution.id == title.id
    );
    return (
      <div
        onClick={
          title.title.type === "child"
            ? null
            : () => prepareExpandedClient(data)
        }
        className={`${
          title.title.label === "TOTAL"
            ? "matrix_title_header sm-header"
            : title.title.type === "child"
            ? "matrix_sub_title"
            : "matrix_title"
        }`}
        id={
          title.title.label === "total"
            ? "total-line"
            : expandedClient === title.id
            ? "expanded"
            : title.title.type === "child"
            ? `sub-line-child`
            : ""
        }
      >
        <div
          className="title_infos"
          style={{ width: title.title.type !== "child" ? "51%" : "100%" }}
        >
          <div>{title.title.type === "child" ? "" : title.title.label}</div>
        </div>

        {title.title.label !== "TOTAL" &&
          title.title.type !== "child" &&
          collaborator &&
          !collaborator.isDeleted && (
            <div className="actions">
              <Link to={`/${lng}/fiche-client/${title.id}`}>
                <div className={`title_action action_doh`}>
                  <ShortcutIcon size="16" />
                </div>
              </Link>
              <div
                className={`title_action ${
                  title.title.state !== "danger" && title.title.count === 0
                    ? "action_doh"
                    : ""
                }`}
                style={{ width: title.title.count > 0 ? "" : "51%" }}
                onClick={() => setShowSideBar(title)}
              >
                <FlagIcon />
                <div>{title.title.count > 0 ? title.title.count : "+"}</div>
              </div>
            </div>
          )}

        {title.title.type !== "child" && (
          <div
            className="tags"
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
              marginTop: -5,
            }}
          ></div>
        )}
      </div>
    );
  };

  const renderSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${showSideBar && "display"}`}>
          <CollaboratorCorrectionCard
            organization={organization}
            category={filter.category}
            token={token}
            year={year}
            loggedAs={loggedAs}
            creator={user}
            onClose={() => {
              document
                .getElementsByClassName("off-canvas-wrapper")[0]
                .classList.remove("off-canvas-wrapper-fixed");
              setShowSideBar(null);
            }}
            client={showSideBar}
            lng={lng}
          />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderPannelFrame()}
      {expanded && renderChildrenData()}
      {showSideBar && renderSideBarFrame()}
    </Fragment>
  );
};

export default NonFacturableContent;
