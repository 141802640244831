import { Tooltip } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import { FaSyncAlt } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustumedMatrix } from "tamtam-components";
import {
  exportRevisionForfait,
  getClientForfaitReviewStatus,
  getDebtorInvoices,
  getListClient,
  getRevisionForfait,
  saveForfaitMissionLetter,
  updateClientForfaitReviewStatus,
  updateClientVolumetry,
} from "../../api";
import _ from "../../i18n";
import { BriefCaseIcon, FlagIcon, IconExport } from "../common/Icons";
import PageHeader from "../common/PageHeader/PageHeader";
import Synchronizer from "../common/Recovr/Synchronizer";
import TTPTab2 from "../common/Tab/TTPTab2";
import styles from "./Forfait.module.scss";
import ForfaitSidePanel from "./ForfaitSidePanel";
import TTPFilterHorizontalBar from "./TTPFilterHorizontalBar";
import { parsePreference } from "../../utils";
import { BeatLoader } from "react-spinners";

const tabs = [
  {
    id: "INVOICE",
    label: "Facturation",
    subTitle: "Analyse du Facturation",
  },
  {
    id: "IMP_R_F",
    label: "Impacts Réalisés/Facturés",
    subTitle: "Analyse des Impacts Réalisés Vs Facturés",
  },
  {
    id: "VOLUMETRY",
    label: "Volumétrie",
    subTitle: "Volumétrie du Dossier",
  },
  {
    id: "PRESTATION",
    label: "Prestation",
    subTitle: "Analyse des Prestations",
  },
];

const ENCODING_TYPE = [
  { label: "amount_purchases", value: "Factures d'achat" },
  { label: "amount_sales", value: "Factures de vente" },
  { label: "financial_transactions", value: "Banques" },
];

const ENCODING_ANALYSE_TYPE = [
  { label: "worked_hours", value: "Heurs realisés (h)" },
  { label: "billed_hours", value: "Heurs facturés (h)" },
  {
    label: "minimum_reference_schedule",
    value: "Horaire minimum de référence (€)",
  },
  {
    label: "effectif_schedule",
    value: "Horaire effectif (facturation honoraires / 80€)",
  },
  {
    label: "minimum_billing_realized_fee",
    value: "Facturation minimum pour les honoraires réalisés (€)",
  },
  { label: "billing_impact", value: "Impact sur facturation (€)" },
];

const ENCODING_FACTURATION_TYPE = [
  { label: "fee_billing", value: "Facturation honoraires HTVA (€)" },
  { label: "non_fee_billing", value: "Facturation hors honoraires HTVA (€)" },
  { label: "global_billing", value: "Facturation globale HTVA (€)" },
  { label: "paid_by_client", value: "Payé par le client TVAc (€)" },
  { label: "remaining_to_pay", value: "Reste à payer TVAc (€)" },
];

const Forfait = () => {
  const { clientID } = useParams();
  const { token, navCommunity, user } = useSelector((state) => state.auth);
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const columns = [];
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [visible, setVisible] = useState(true);
  const [reviewStatus, setReviewStatus] = useState("NOT_REVIEWED");

  for (let year = currentYear; year >= startYear; year--) {
    columns.push({
      id: year.toString(),
      label: _(year.toString()),
      isEditable: true,
      widthPercentage: 10,
    });
  }
  columns.push({
    id: "mission_letter",
    label: _("Lettre de Mission"),
    isEditable: true,
    widthPercentage: 12,
  });
  const [clientDetails, setClientDetails] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const queryClient = useQueryClient();
  const [client, setClient] = useState(clientID);
  const [volumetryData, setVolumetryData] = useState([]);
  const [prestationData, setPrestationData] = useState([]);
  const [facturationData, setFacturationData] = useState([]);
  const [analyseData, setAnalyseData] = useState([]);
  const [missionLetterData, setMissionLetterData] = useState([]);
  const [sidePanelVisible, setSidePanelVisible] = useState(false);
  const [filter, setFilter] = useState({
    tab: tabs[0].id,
    subTitle: tabs[0].subTitle,
  });
  const [exporting, setExporting] = useState(false);

  const { status, error, refetch } = useQuery(
    ["getClientRevisionForfait", token, navCommunity, client],
    async () => {
      if (token && navCommunity && client) {
        try {
          const response = await getRevisionForfait(
            token,
            navCommunity.id,
            client
          );
          setVolumetryData(response.data.data.volumetry);
          setPrestationData(response.data.data.prestation);
          setFacturationData(response.data.data.facturation);
          setAnalyseData(response.data.data.analyse);
          setClientDetails(response.data.data.client);
          const f = response.data.data.facturation.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});

          const v = response.data.data.volumetry.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});

          const p = response.data.data.prestation
            .filter((i) => i.type !== "")
            .reduce((acc, i) => {
              acc[i.type] = i.value[3].amount;
              return acc;
            }, {});

          const imp = response.data.data.analyse.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});

          setMissionLetterData((prevState) => ({
            ...prevState,
            ...f,
            ...v,
            ...p,
            ...imp,
          }));

          return response.data.data.volumetry;
        } catch (e) {
          throw new Error(e.message);
        }
      }
    },
    { enabled: !!token && !!navCommunity }
  );

  const handleExportRevisionForfait = async (token, organization, client) => {
    try {
      setExporting(true);
      await exportRevisionForfait(token, organization, client);
    } catch (e) {
      console.error("Error >> Export Forfait: ", e);
    } finally {
      setExporting(false);
    }
  };

  const { status: statusList, error: errorList } = useQuery(
    ["getListClient", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getListClient(token, navCommunity.id);
          setClientOptions(response.data.data);
          return response.data.data;
        } catch (e) {
          throw new Error(e.message);
        }
      }
    },
    { enabled: !!token && !!navCommunity }
  );

  useEffect(() => {
    if (token && navCommunity && client) {
      setIsDataFetching(true);
      getRevisionForfait(token, navCommunity.id, client)
        .then((response) => {
          setVolumetryData(response.data.data.volumetry);
          setPrestationData(response.data.data.prestation);
          setFacturationData(response.data.data.facturation);
          setAnalyseData(response.data.data.analyse);
          setClientDetails(response.data.data.client);
          const f = response.data.data.facturation.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});

          const v = response.data.data.volumetry.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});

          const p = response.data.data.prestation
            .filter((i) => i.type !== "")
            .reduce((acc, i) => {
              acc[i.type] = i.value[3].amount;
              return acc;
            }, {});

          const imp = response.data.data.analyse.reduce((acc, i) => {
            acc[i.type] = i.value[3].amount;
            return acc;
          }, {});

          setMissionLetterData((prevState) => ({
            ...prevState,
            ...f,
            ...v,
            ...p,
            ...imp,
          }));
        })
        .catch((e) => {
          throw new Error(e.message);
        })
        .finally(() => {
          setIsDataFetching(false);
        });
    }
  }, [client, queryClient, token, navCommunity]);

  const clientForfaitReviewStatus = useQuery(
    ["getClientForfaitReviewStatus", token, navCommunity, client],
    async () => {
      if (token && navCommunity && client) {
        try {
          const response = await getClientForfaitReviewStatus(
            token,
            navCommunity.id,
            client
          );
          setReviewStatus(response.data.data.status);
          return response.data.data;
        } catch (e) {
          throw new Error(e.message);
        }
      }
    },
    { enabled: !!token && !!navCommunity }
  );

  const updateReviewStatus = async (status) => {
    try {
      await updateClientForfaitReviewStatus({
        token,
        organization: navCommunity.id,
        client,
        status,
      })
        .then((res) => {
          if (res.data.result === "OK") {
            setReviewStatus(status);
            toast.success(_("successfully_updated"));
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.error("Error updating review state:", error);
    }
  };

  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences?.financial
      : {};

  const handleOnVolumetryEnter = (event, columnId, lineType) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const value = event.target.value;

      const newData = volumetryData.map((volumetryItem) => {
        if (volumetryItem.type == lineType) {
          const updatedValues = volumetryItem.value.map((valueItem) => {
            if (valueItem.label == columnId) {
              return { ...valueItem, amount: Number(value) };
            }
            return valueItem;
          });
          return { ...volumetryItem, value: updatedValues };
        }
        return volumetryItem;
      });

      const payload = {
        token,
        organization: navCommunity && navCommunity.id,
        client,
        type: lineType,
        year: columnId,
        value,
      };

      updateClientVolumetry(payload)
        .then(() => {
          setVolumetryData(newData);
          toast.success(_("successfully_updated"));
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const handleInputChange = (value, column) => {
    setMissionLetterData({
      ...missionLetterData,
      [column]: parseFloat(value),
    });
  };

  const handleOnEnter = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  const handleSave = () => {
    const data = {
      client,
      organization: navCommunity && navCommunity.id,
      params: JSON.stringify(missionLetterData),
      token,
    };

    saveForfaitMissionLetter(data)
      .then((resp) => {
        if (resp.data.result === "OK") {
          toast.success(_("successfully_added"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  const handleSyncClientPayments = async () => {
    if (clientDetails?.recovrId) {
      try {
        localStorage.setItem("recovr-syncing", true);
        await getDebtorInvoices(
          token,
          navCommunity.id,
          client,
          clientDetails?.recovrId
        )
          .then((res) => {
            refetch();
            toast.success(_("successfully_added"));
            return res.data.data;
          })
          .catch((err) => {
            console.error("Debtor invoices >> Err", err);
            toast.error(_("error"));
          });
      } catch (error) {
        console.error("Recovr >> Err", error);
      } finally {
        localStorage.setItem("recovr-syncing", false);
      }
    } else {
      toast.error(_("Identifiant Recovr introuvable !"));
    }
  };

  const handleColumnsVolumetry = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) =>
              column.id !== "mission_letter" ? (
                <span key={index}>
                  {column.id == data.label ? (
                    <>
                      <div className={styles.editable_zone}>
                        <input
                          type="text"
                          className={styles.editable_text}
                          style={{
                            textAlign: "center",
                            width: "50%",
                            marginLeft: -5,
                          }}
                          defaultValue={data.amount}
                          required={true}
                          onChange={(event) => void 0}
                          onKeyDown={(event) =>
                            handleOnVolumetryEnter(event, column.id, line.type)
                          }
                        />
                        <BsArrowReturnLeft className={styles.key_enter} />
                        {data.origin === "adminis" ? (
                          <span style={{ color: "#2495E1", marginLeft: 5 }}>
                            •
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </span>
              ) : column.id == data.label ? (
                <div className={styles.editable_zone}>
                  <input
                    type="text"
                    className={styles.editable_text}
                    style={{
                      textAlign: "center",
                      width: "50%",
                      marginLeft: -5,
                    }}
                    defaultValue={missionLetterData[line.type]}
                    required={true}
                    onChange={(event) =>
                      handleInputChange(event.target.value, line, line.type)
                    }
                    onKeyDown={handleOnEnter}
                  />
                  <BsArrowReturnLeft className={styles.key_enter} />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>
    );
  };
  const convertToHoursMinutes = (value) => {
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);
    return `${hours}h ${minutes}m`;
  };
  const handleColumnsPrestation = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) =>
              column.id !== "mission_letter" ? (
                <span key={index} style={{ padding: 5 }}>
                  {column.id == data.label
                    ? convertToHoursMinutes(data.amount)
                    : ""}
                </span>
              ) : column.id == data.label ? (
                <div className={styles.editable_zone}>
                  <input
                    type="text"
                    className={styles.editable_text}
                    style={{
                      textAlign: "center",
                      width: "50%",
                      marginLeft: -5,
                    }}
                    defaultValue={missionLetterData[line.type]}
                    required={true}
                    onChange={(event) =>
                      handleInputChange(event.target.value, line.type)
                    }
                    onKeyDown={handleOnEnter}
                  />
                  <BsArrowReturnLeft className={styles.key_enter} />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>
    );
  };
  const handleColumnsFacturation = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) =>
              column.id !== "mission_letter" ? (
                <span
                  key={index}
                  style={{
                    padding: 5,
                    color:
                      line.type == "remaining_to_pay" &&
                      parseFloat(data.amount) > 0
                        ? "#e74c3c"
                        : "",
                  }}
                >
                  {column.id == data.label ? data.amount : ""}
                </span>
              ) : column.id == data.label ? (
                <div className={styles.editable_zone}>
                  <input
                    type="text"
                    className={styles.editable_text}
                    style={{
                      textAlign: "center",
                      width: "50%",
                      marginLeft: -5,
                    }}
                    defaultValue={missionLetterData[line.type]}
                    required={true}
                    onChange={(event) =>
                      handleInputChange(event.target.value, line.type)
                    }
                    onKeyDown={handleOnEnter}
                  />
                  <BsArrowReturnLeft className={styles.key_enter} />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>
    );
  };
  const handleColumnsAnalyse = (column, line) => {
    return (
      <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
        <style>
          {`
        .CustumedMatrix_matrix_body_line__11lbb:first-child {
          border-bottom: 1px solid #d8dde2;
        }
          .CustumedMatrix_matrix__10BiN {
          background-color: white;
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #29394d;
        }
      `}
        </style>
        <div className="labeled-tag no-border">
          <div className="tags">
            {line.value.map((data, index) =>
              column.id !== "mission_letter" ? (
                <span
                  key={index}
                  style={{
                    padding: 5,
                    color:
                      line.type === "billing_impact"
                        ? parseFloat(data.amount) < 0
                          ? "red"
                          : "green"
                        : "",
                  }}
                >
                  {column.id == data.label ? data.amount : ""}
                </span>
              ) : column.id == data.label ? (
                <div className={styles.editable_zone}>
                  <input
                    type="text"
                    className={styles.editable_text}
                    style={{
                      textAlign: "center",
                      width: "50%",
                      marginLeft: -5,
                    }}
                    defaultValue={missionLetterData[line.type]}
                    required={true}
                    onChange={(event) =>
                      handleInputChange(event.target.value, line.type)
                    }
                    onKeyDown={handleOnEnter}
                  />
                  <BsArrowReturnLeft className={styles.key_enter} />
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleTitleVolumetry = (title) => (
    <Fragment>
      <div>
        <div className="title_infos" style={{ marginTop: 20 }}>
          {ENCODING_TYPE.map((data) => {
            if (data.label === title.type) return data.value;
          })}
        </div>
      </div>
    </Fragment>
  );

  const handleTitlePrestation = (title) => (
    <Fragment>
      <div>
        <div className="title_infos" style={{ marginTop: 20 }}>
          {title.type}
        </div>
      </div>
    </Fragment>
  );
  const handleTitleFacturation = (title) => (
    <Fragment>
      <div>
        <div className="title_infos" style={{ marginTop: 20 }}>
          {ENCODING_FACTURATION_TYPE.map((data) => {
            if (data.label === title.type) return data.value;
          })}
        </div>
      </div>
    </Fragment>
  );
  const handleTitleAnalyse = (title) => (
    <Fragment>
      <div>
        <div className="title_infos" style={{ marginTop: 20 }}>
          {ENCODING_ANALYSE_TYPE.map((data) => {
            if (data.label === title.type) return data.value;
          })}
        </div>
      </div>
    </Fragment>
  );

  const renderCustumeMatrix = () => {
    switch (filter.tab) {
      case "INVOICE":
        return (
          <CustumedMatrix
            columns={columns}
            items={facturationData}
            handleCellule={handleColumnsFacturation}
            handleTitle={handleTitleFacturation}
            headerTagBG={"#FFFFFF"}
          />
        );
      case "IMP_R_F":
        return (
          <CustumedMatrix
            columns={columns}
            items={analyseData}
            handleCellule={handleColumnsAnalyse}
            handleTitle={handleTitleAnalyse}
            headerTagBG={"#FFFFFF"}
          />
        );
      case "VOLUMETRY":
        return (
          <CustumedMatrix
            columns={columns}
            items={volumetryData}
            handleCellule={handleColumnsVolumetry}
            handleTitle={handleTitleVolumetry}
            headerTagBG={"#FFFFFF"}
          />
        );
      case "PRESTATION":
        return (
          <CustumedMatrix
            columns={columns}
            items={prestationData}
            handleCellule={handleColumnsPrestation}
            handleTitle={handleTitlePrestation}
            headerTagBG={"#FFFFFF"}
          />
        );
      default:
        <></>;
    }
  };

  const renderInfo = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          flexDirection: "column",
          padding: 20,
          color: "#29394d",
        }}
      >
        {filter.tab === "VOLUMETRY" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <span
              style={{
                color: "#2495E1",
                fontSize: 30,
              }}
            >
              •
            </span>
            <span>
              AdminIs<sup>*</sup>
            </span>
          </div>
        )}
        <div>
          Appuyez sur Entrée <BsArrowReturnLeft /> pour sauvegarder<sup>*</sup>
        </div>
      </div>
    );
  };

  return (
    <div className="page-content" style={{ paddingBottom: 20 }}>
      <Synchronizer
        sync={handleSyncClientPayments}
        progress={20}
        offset={20}
        tip={_("syncPayments")}
      />
      <PageHeader
        header={_("revisionForfait")}
        subHeader={_(`${filter.subTitle}`)}
        icon={<BriefCaseIcon fill="#29394D" size="36" />}
      >
        {parsePreference(preferences).forfait.allowLaunchRevision && (
          <div>
            {reviewStatus === "NOT_REVIEWED" && (
              <button
                onClick={() => updateReviewStatus("IN_REVIEW")}
                className={styles.review_btn}
              >
                {_("review")}
              </button>
            )}
            {reviewStatus === "IN_REVIEW" && (
              <button
                onClick={() => updateReviewStatus("REVIEWED")}
                className={styles.review_btn}
                style={{ backgroundColor: "#f1c40f", color: "#FFF" }}
              >
                {_("finishReview")}
              </button>
            )}
            {reviewStatus === "REVIEWED" && (
              <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
                <Tooltip placement="top" title={_("reset")}>
                  <FaSyncAlt
                    cursor="pointer"
                    size={12}
                    fill="#29394d"
                    onClick={() => updateReviewStatus("NOT_REVIEWED")}
                  />
                </Tooltip>
                <button
                  onClick={() => updateReviewStatus("IN_REVIEW")}
                  className={styles.review_btn}
                  style={{ backgroundColor: "#07bc0c", color: "#FFF" }}
                >
                  {_("re_review")}
                </button>
              </div>
            )}
          </div>
        )}

        {client && (
          <div
            className={styles.forfait__export_btn}
            onClick={() =>
              handleExportRevisionForfait(token, navCommunity.id, client)
            }
          >
            {exporting ? (
              <BeatLoader color="#3498db" size={10} />
            ) : (
              <>
                <IconExport size={20} />
                Exporter
              </>
            )}
          </div>
        )}
      </PageHeader>

      <div className="tab-container">
        <TTPTab2
          tabs={tabs}
          activeTab={filter.tab}
          width="fit-content"
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id, subTitle: item.subTitle });
          }}
        />
      </div>

      <div
        className={styles.toggle_btn}
        onClick={() => setSidePanelVisible(true)}
      >
        <FlagIcon size={18} className={styles.flag_icon} />
        <span>{_("forfaitAnalysisProposals")}</span>
      </div>

      <ForfaitSidePanel
        visible={sidePanelVisible}
        onClose={() => setSidePanelVisible(false)}
        client={client}
        organization={navCommunity ? navCommunity.id : null}
        token={token}
        year={currentYear}
      />

      <div className="ttp-tab-container">
        <div
          className="content-body large-content"
          style={{ padding: "0 0 0" }}
        >
          {status === "success" && (
            <>
              <TTPFilterHorizontalBar
                filterFormIsVisible={visible}
                setFilterFormIsVisible={setVisible}
                client={parseInt(client)}
                setClient={setClient}
                review={reviewStatus}
                data={clientDetails}
                button
                bg="#FFF"
                subBG="F8F9FA"
                filters={[
                  {
                    type: "SELECT",
                    options: {
                      placeholder: "Client",
                      options: clientOptions,
                      values: client ? [client] : [],
                      isMulti: false,
                      isClearable: true,
                      isSimple: true,
                      action: setClient,
                    },
                  },
                ]}
                cssClass="medium-12"
              />
              {client ? (
                isDataFetching ? (
                  <div className="ripple_loading">
                    <img src={"/img/Ripple-1s-150px.svg"} alt="" />
                  </div>
                ) : (
                  <div style={{ marginTop: 25 }}>
                    {renderCustumeMatrix()}
                    {renderInfo()}
                  </div>
                )
              ) : (
                <div className="no_data" style={{ height: "130px" }}>
                  <div className="title">{_("Aucun client sélectionné !")}</div>
                  <div className="subTitle">
                    {_("Veuillez choisir un client")}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        {status === "loading" && (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        )}
        {status === "error" && <p>Error: {error.message}</p>}
      </div>
    </div>
  );
};

export default Forfait;
