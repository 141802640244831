import React from "react";

export const IconPlus = ({ size = 14, fill = "#6D7F92" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2402 6.02423H7.97672V0.760695C7.97672 0.520919 7.65113 0.00100708 6.99997 0.00100708C6.34881 0.00100708 6.02322 0.520948 6.02322 0.760695V6.02426H0.759688C0.519941 6.02423 0 6.34982 0 7.00095C0 7.65208 0.519941 7.9777 0.759688 7.9777H6.02325V13.2413C6.02325 13.481 6.34881 14.001 7 14.001C7.65119 14.001 7.97675 13.481 7.97675 13.2413V7.9777H13.2403C13.48 7.9777 14 7.65214 14 7.00095C14 6.34977 13.48 6.02423 13.2402 6.02423Z"
      fill={fill}
    />
  </svg>
);
