export function CloudUploadIcon({ size = 44, fill = '#6D7F92', className }) {
    return (
      <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 44 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.8877 16.7673C34.7959 10.059 29.3102 4.62964 22.5804 4.62964C19.5868 4.62964 16.7026 5.71864 14.4595 7.69605C12.4575 9.46078 11.088 11.8191 10.5456 14.4083C10.506 14.4078 10.4667 14.4075 10.4276 14.4075C4.67791 14.4075 0 19.0853 0 24.8351C0 30.5848 4.67791 35.2626 10.4276 35.2626H17.7102C18.2283 35.2626 18.6486 34.8425 18.6486 34.3243C18.6486 33.806 18.2283 33.3859 17.7102 33.3859H10.4276C5.7126 33.3859 1.8767 29.55 1.8767 24.8351C1.8767 20.1201 5.7126 16.2842 10.4276 16.2842C10.6788 16.2842 10.9444 16.2977 11.2396 16.3255C11.7399 16.3723 12.1891 16.0164 12.2572 15.518C12.5951 13.0413 13.818 10.7633 15.7005 9.10379C17.6007 7.4287 20.0439 6.50625 22.5803 6.50625C28.3325 6.50625 33.0121 11.186 33.0121 16.9381C33.0121 17.1452 32.9968 17.3587 32.9805 17.5847L32.9736 17.6808C32.954 17.9568 33.0573 18.2274 33.256 18.4202C33.4545 18.6129 33.7277 18.7082 34.0034 18.6806C34.253 18.6554 34.5048 18.6428 34.7518 18.6428C38.8163 18.6428 42.1233 21.9496 42.1233 26.0143C42.1233 30.0789 38.8164 33.3857 34.7518 33.3857H27.0938C26.5757 33.3857 26.1555 33.8058 26.1555 34.3241C26.1555 34.8424 26.5757 35.2624 27.0938 35.2624H34.7518C39.8512 35.2624 44 31.1138 44 26.0143C43.9999 20.9606 39.9248 16.8403 34.8877 16.7673Z"
          fill={fill}
        />
        <path
          d="M22.5811 9.03989C18.6574 9.03989 15.2986 11.9722 14.7682 15.8605C14.6982 16.374 15.0578 16.847 15.5712 16.9171C15.6142 16.9229 15.6569 16.9258 15.6991 16.9258C16.161 16.9258 16.5636 16.5847 16.6276 16.1142C17.0317 13.1511 19.5912 10.9166 22.5811 10.9166C23.0993 10.9166 23.5194 10.4965 23.5194 9.97824C23.5195 9.46003 23.0993 9.03989 22.5811 9.03989Z"
          fill={fill}
        />
        <path
          d="M26.8474 27.5045L23.4341 24.4554C22.8457 23.9295 21.958 23.9296 21.3693 24.4554L17.9561 27.5046C17.5695 27.8498 17.5362 28.443 17.8814 28.8296C18.2266 29.2159 18.8197 29.2495 19.2064 28.9042L21.4634 26.888V38.432C21.4634 38.9503 21.8835 39.3704 22.4018 39.3704C22.92 39.3704 23.3401 38.9503 23.3401 38.432V26.8878L25.5973 28.9042C25.7762 29.0641 25.9995 29.1427 26.222 29.1427C26.48 29.1427 26.7368 29.0371 26.9222 28.8295C27.2673 28.4429 27.2339 27.8498 26.8474 27.5045Z"
          fill={fill}
        />
      </svg>
    );
  }
