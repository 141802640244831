import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "../../i18n";

import {
  useInterval,
  getMonthShortLabelObject,
  getUserNameForAvatar,
  generateFullName,
} from "../../utils";
import {
  getBU,
  getManagerNotification,
  sendTimeSheetCollaboratorReminder,
  getCollaboratorsTimeSheetNotification,
} from "../../api";
import { setManagerNotification } from "../../store";

import { Tooltip } from "antd";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  AlertTriangleIcon,
  IconSend,
  CheckIcon,
  IconClose,
  SynchroRuningIcon,
} from "../common/Icons";
import LigthPageHeader from "../common/PageHeader/LigthPageHeader";
import "./NotificationPage.local.scss";
import "./NotificationActivitePage.local.scss";
import collaborator from "../../store/collaborator";

const NotificationsActivitePage = () => {
  const auth = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const dispatch = useDispatch();
  const { managerNotification, loggedAs } = auth;
  const [interval, setInterval] = useState({ start: 10, limit: 10 });
  const { synchronizationList } = auth;
  const [filter, setFilter] = useState({
    bu: { id: null, label: "allActivities" },
    months: [],
  });
  const [collaboratorData, setCollaboratorData] = useState([]);
  const [filtredCollaboratorByBu, setFiltredCollaboratorByBu] = useState([]);
  const [buOptions, setBuOptions] = useState([]);

  const preferences = auth.user
    ? auth.user.userOrganizations.find(
        (organization) => organization.id === auth.navCommunity.id
      ).authorisation.preferences
    : {};

  const renderAvatar = (avatarUrl, firstName, lastName) => {
    if (!avatarUrl && !firstName && !lastName) {
      return (
        <div className="avatar_not_exist">
          <p style={{ margin: "auto" }}>-</p>
        </div>
      );
    }
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className="avatar_not_exist">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div className="avatar_exist">
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }

    return avatarDiv;
  };

  useInterval(() => {
    let allowedNotifications = [];
    if (
      preferences.activateCalendarNotifications ||
      loggedAs === "COLLABORATOR"
    ) {
      allowedNotifications.push("COLLABORATOR_ABSENCE");
    }
    if (preferences.activateCarteNotification || loggedAs === "COLLABORATOR") {
      allowedNotifications.push("CORRECTION_CARD");
      allowedNotifications.push("REPORT_ERROR_CARD");
    }
    try {
      let managerNotificationQuery = getManagerNotification(
        auth.user.uid,
        auth.navCommunity.id,
        auth.token,
        loggedAs,
        allowedNotifications
      );
      managerNotificationQuery.then((result) => {
        if (
          result.data.data &&
          managerNotification.nbrResult < result.data.data.nbrResult
        ) {
          const data = [...result.data.data.data];
          dispatch(
            setManagerNotification({
              nbrResult: managerNotificationQuery.nbrResult,
              data,
            })
          );
          setInterval({
            ...interval,
            start: 10,
          });
        }
      });
    } catch (e) {
      return null;
    }
  }, 10000);

  useEffect(() => {
    async function fetchCapacityProgression() {
      try {
        let day = moment().date();
        let month = moment().month() + 1;
        let months =
          filter.months.length === 0
            ? [
                [
                  "01-01",
                  (month < 10 ? "0" : "") +
                    month +
                    "-" +
                    (day < 10 ? "0" : "") +
                    day,
                ],
              ]
            : filter.months.map((m) => {
                var d = new Date(new Date().getFullYear(), m, 0);
                return [
                  (m < 10 ? "0" : "") + m + "-01",
                  (m < 10 ? "0" : "") +
                    m +
                    "-" +
                    (m === month ? (day < 10 ? "0" : "") + day : d.getDate()),
                ];
              });
        const response = await getCollaboratorsTimeSheetNotification(
          months,
          auth.navCommunity.id,
          auth.token
        );
        setCollaboratorData(response.data.data.listeCollaboarators);

        const filteredCollaborators = filter.bu.id
          ? response.data.data.listeCollaboarators.filter(
              (collaborator) => collaborator.BusinessUnit === filter.bu.id
            )
          : response.data.data.listeCollaboarators;
        setFiltredCollaboratorByBu(filteredCollaborators);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    }
    fetchCapacityProgression();
  }, [filter.months]);

  useEffect(() => {
    const filteredCollaborators = filter.bu.id
      ? collaboratorData.filter(
          (collaborator) => collaborator.BusinessUnit === filter.bu.id
        )
      : collaboratorData;
    setFiltredCollaboratorByBu(filteredCollaborators);
  }, [filter.bu]);

  useEffect(() => {
    if (synchronizationList && synchronizationList.listeCollaboarators) {
      const filteredCollaborators = filter.bu.id
        ? synchronizationList.listeCollaboarators.filter(
            (collaborator) => collaborator.BusinessUnit === filter.bu.id
          )
        : synchronizationList.listeCollaboarators;
      setCollaboratorData(filteredCollaborators);
      setFiltredCollaboratorByBu(filteredCollaborators);
    }
  }, [synchronizationList]);

  useEffect(() => {
    async function fetchBUData() {
      try {
        const responseBu = await getBU({
          token: auth.token,
          organizationId: auth.navCommunity.id,
        });
        if (responseBu) {
          setBuOptions([
            { id: null, label: "allActivities" },
            ...responseBu.data.data,
          ]);
        } else {
          setBuOptions([{ id: null, label: "allActivities" }]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données BU:", error);
      }
    }
    fetchBUData();
  }, []);

  const getTabCount = (tab) => {
    if (collaboratorData.length) {
      if (tab.id === null) {
        return collaboratorData.length;
      }

      let count = 0;
      for (let i = 0; i < collaboratorData.length; i++) {
        if (tab.id === collaboratorData[i].BusinessUnit) {
          ++count;
        }
      }

      return count;
    }
    return 0;
  };

  const handleSendTimeSheetCollaboratorReminder = async (id) => {
    const { token, navCommunity } = auth;
    const payload = {
      token: token,
      organization: navCommunity?.id,
      collaborator: id,
    };
    try {
      await sendTimeSheetCollaboratorReminder(payload);
      toast.success(_("successfully_sent"));
    } catch (e) {
      toast.error(_("error"));
      console.error("TimeSheet >> Reminders", e);
    }
  };

  const renderTabContent = () => {
    return buOptions.map((tab) => (
      <div
        key={tab.id}
        className={`tab ${filter.bu.id === tab.id ? "tab-active" : ""} tab`}
        onClick={() => {
          setFilter({
            ...filter,
            bu: tab,
          });
        }}>
        {_(tab.label)}
        <span className="count">{getTabCount(tab)}</span>
      </div>
    ));
  };

  const renderListNotifications = () => {
    return (
      <div className="content-frame">
        <div className="content-header">
          <div className="selected-tab-title">{_(filter.bu.label)}</div>
        </div>
        <hr className="divider" />

        {synchronizationList && synchronizationList.synchro_is_runing && (
          <Fragment>
            <div className="additional-line">
              <div
                className={`tag ${filter.months.length === 0 ? "active" : ""}`}
                onClick={() => setFilter({ ...filter, months: [] })}>
                {_("annualCumule")}
              </div>
              {getMonthShortLabelObject(language).map((tab) => {
                return (
                  <div
                    className={`tag ${
                      filter.months.includes(tab.id) ? "active" : ""
                    } ${tab.id > moment().month() + 1 && "disabled"}`}
                    onClick={(e) => {
                      if (tab.id > moment().month() + 1) {
                        return;
                      }
                      let selectedMonths = [...filter.months];
                      if (filter.months.includes(tab.id)) {
                        selectedMonths = selectedMonths.filter(
                          (m) => m !== tab.id
                        );
                        setFilter({ ...filter, months: selectedMonths });
                      } else {
                        selectedMonths.push(tab.id);
                        setFilter({ ...filter, months: selectedMonths });
                      }
                    }}>
                    {tab.label}
                  </div>
                );
              })}
              <div
                className={`tag min-tag`}
                onClick={() => setFilter({ ...filter, months: [] })}>
                <SynchroRuningIcon size={14} fill="#6D7F92" />
              </div>
            </div>
            <div className={"notifications-list"}>
              {filtredCollaboratorByBu && filtredCollaboratorByBu.length > 0 ? (
                filtredCollaboratorByBu.map((notif) => (
                  <div className="notification-item" key={notif.id}>
                    <div className="infos">
                      <div className="avatar" style={{ margin: "auto" }}>
                        {renderAvatar(notif.avatarUrl, notif.name)}
                      </div>
                      <div className="notification-content">
                        <div className="notification-content-title">
                          {notif.name}
                        </div>
                        <div className="body">
                          {notif.BusinessUnit
                            ? buOptions.map((tab) => {
                                return tab.id === notif.BusinessUnit
                                  ? tab.label
                                  : null;
                              })
                            : "tous"}
                        </div>
                      </div>
                    </div>
                    {notif.state === "NOT_CONFIGURED" ? (
                      <Fragment>
                        <Tooltip
                          key={`tool-2-${notif.id}`}
                          placement="top"
                          title={_("regimeNotConfigurated")}
                          mouseLeaveDelay={0}>
                          <div className="tag tag-alert">
                            <AlertTriangleIcon fill="#EB5757" />
                          </div>
                        </Tooltip>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className={`tag tag-default`}>
                          <span>
                            {`${parseFloat(
                              parseFloat(notif.real).toFixed(2)
                            )}H/${parseFloat(
                              parseFloat(notif.capacity).toFixed(2)
                            )} H`}
                          </span>
                          <div
                            className={`tag tag-min ${
                              notif.capacity > notif.real
                                ? notif.capacity * 0.9 > notif.real
                                  ? "tag-danger"
                                  : ""
                                : "tag-success"
                            }`}>
                            {notif.capacity > notif.real ? (
                              notif.capacity * 0.9 < notif.real ? (
                                ""
                              ) : (
                                <IconClose size="8" fill="#DE4848" />
                              )
                            ) : (
                              <CheckIcon size="8" fill="#02af8e" />
                            )}
                          </div>
                        </div>
                        <div
                          className={`tag ${
                            notif.capacity > notif.real
                              ? notif.capacity * 0.9 > notif.real
                                ? "tag-danger"
                                : "tag-warning"
                              : "tag-hidden"
                          }`}>
                          {notif.capacity > notif.real ? (
                            <TiArrowSortedDown />
                          ) : (
                            ""
                          )}
                          <span>{`${parseFloat(
                            parseFloat(
                              notif.capacity > notif.real
                                ? notif.capacity - notif.real
                                : 0
                            ).toFixed(2)
                          )} H`}</span>
                        </div>
                        <Tooltip
                          key={`tool-2-${notif.id}`}
                          placement="top"
                          title={_("sendReminderTs")}
                          mouseLeaveDelay={0}>
                          <div
                            className={`tag ${
                              notif.capacity > notif.real
                                ? "tag-button"
                                : "tag-hidden"
                            }`}
                            onClick={() =>
                              handleSendTimeSheetCollaboratorReminder(notif.id)
                            }>
                            <IconSend width="16" />
                          </div>
                        </Tooltip>
                      </Fragment>
                    )}
                  </div>
                ))
              ) : (
                <div style={{ padding: 10 }}>
                  {" "}
                  Aucune notification n'est disponible{" "}
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <div className="page-content">
      <LigthPageHeader
        title={{
          label: "Activités",
        }}
      />
      <div className="content-notification">
        <div className="filter-tabs">{renderTabContent()}</div>
        {renderListNotifications()}
      </div>
    </div>
  );
};

export default NotificationsActivitePage;
