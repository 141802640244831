import React, { useState } from "react";
import EncodageFilter from "./EncodingFilter";
import _ from "../../../../i18n";
import { ShortcutIcon } from "../../../common/Icons";
import { useSelector } from "react-redux";
import { CustumedMatrix } from "tamtam-components";
import { useQuery } from "react-query";
import { getRecapEncodingReporting } from "../../../../api";
import { Link } from "react-router-dom";
import { parsePreference } from "../../../../utils";
import GroupFilter from "../../GroupFilter";

const Encoding = ({ filter, setFilter, collaborator, preferences }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);

  const [filtredContribution, setFiltredContribution] = useState([]);
  const [columns, setColumns] = useState([
    {
      id: "volumetry",
      label: _("volumetry"),
      isEditable: true,
      widthPercentage: 10,
    },
    {
      id: "timesheet",
      label: _("timesheet"),
      isEditable: true,
      widthPercentage: 60,
    },
  ]);

  const handleColumns = (column, item) => {
    switch (column.id) {
      case "volumetry":
        if (filtredContribution.indexOf(item) === 0) {
          return (
            <div
              className="tags"
              style={{ margin: "20px 10px", justifyContent: "center" }}
            >
              <div className="labled-tag no-border">
                <span>{_("realized")}</span>
                <div className="tag" style={{ marginTop: "0px" }}>
                  <div>{item.volumetry} p</div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="tags" style={{ justifyContent: "center" }}>
              <div
                className="labled-tag no-border"
                style={{ justifyContent: "center" }}
              >
                <div className="tag" style={{ marginTop: "0px" }}>
                  <div>{item.volumetry} p</div>
                </div>
              </div>
            </div>
          );
        }
      case "timesheet":
        if (filtredContribution.indexOf(item) === 0) {
          return (
            <div className="tags" style={{ marginTop: "20px" }}>
              {Object.keys(item.timesheet).map((key) => (
                <div
                  className="labled-tag no-border"
                  key={key}
                  style={{ justifyContent: "flex-end" }}
                >
                  <span>{_(key)}</span>
                  <div
                    className={`tag ${
                      "ecart" === key &&
                      (item.timesheet[key] <= 0 ? "tag-success" : "tag-danger")
                    }`}
                    style={{ marginTop: "0px" }}
                  >
                    {key === "ecart" ||
                    key === "theoric" ||
                    key === "realized" ? (
                      <div>
                        {key === "ecart" &&
                          (item.timesheet[key] > 0 ? "+" : "")}
                        {Math.floor(item.timesheet[key])}h {Math.round((item.timesheet[key] % 1) * 60)}m
                      </div>
                    ) : key === "ppp" ? (
                      <div>{item.timesheet[key]}%</div>
                    ) : (
                      <div>{item.timesheet[key]}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          );
        } else {
          return (
            <div className="tags">
              {Object.keys(item.timesheet).map((key) => (
                <div
                  className="labled-tag no-border"
                  key={key}
                  style={{ justifyContent: "center" }}
                >
                  <div
                    className={`tag ${
                      "ecart" === key &&
                      (item.timesheet[key] <= 0 ? "tag-success" : "tag-danger")
                    }`}
                    style={{ marginTop: "0px" }}
                  >
                    {key === "ecart" ||
                    key === "theoric" ||
                    key === "realized" ? (
                      <div>
                        {key === "ecart" &&
                          (item.timesheet[key] > 0 ? "+" : "")}
                         {Math.floor(item.timesheet[key])}h {Math.round((item.timesheet[key] % 1) * 60)}m
                      </div>
                    ) : key === "ppp" ? (
                      <div>{item.timesheet[key]}%</div>
                    ) : (
                      <div>{item.timesheet[key]}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          );
        }
      default:
        return <></>;
    }
  };

  const renderTitleLine = (title) => {
    const isFirstItem = filtredContribution.indexOf(title) === 0;
    return (
      <div
        className="matrix_title"
        style={isFirstItem ? { paddingTop: "50px" } : {}}
      >
        {title.name}
        <Link to={`/${language}/fiche-collaborator/${title.id}`}>
          <div className="title_action action_doh">
            <ShortcutIcon size="16" />
          </div>
        </Link>
      </div>
    );
  };

  const { data, isLoading, isError, error } = useQuery(
    ["recapEncodingReporting", filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getRecapEncodingReporting(
            navCommunity.id,
            token,
            filter
          );
          return response.data;
        } catch (e) {
          throw new Error("Failed to fetch data");
        }
      }
    },
    {
      onSuccess: (data) => {
        setFiltredContribution(data.data);
      },
      onError: (error) => {
        console.error("An error occurred:", error);
      },
    }
  );
  const handleChildTitle = (child) => {
    return <div>{child.clientName}</div>;
  };

  const handleChildCellule = (column, line, child) => {
    switch (column.id) {
      case "volumetry":
        return (
          <div className="tags" style={{ justifyContent: "center" }}>
            <div
              className="labled-tag no-border"
              style={{ justifyContent: "center" }}
            >
              <div className="tag" style={{ margin: "0px" }}>
                <div>{child.volumetry} p</div>
              </div>
            </div>
          </div>
        );
      case "timesheet":
        return (
          <div className="tags" style={{ justifyContent: "flex-start" }}>
            {Object.keys(child).map((key) =>
              key !== "clientName" && key !== "volumetry" ? (
                <div
                  className="labled-tag no-border"
                  key={key}
                  style={{ justifyContent: "center", marginRight: "130px" }}
                >
                  <div
                    className={`tag ${
                      key === "ecart" &&
                      (child.ecart <= 0 ? "tag-success" : "tag-danger")
                    }`}
                    style={{ marginTop: "0px" }}
                  >
                    {key === "ecart" ||
                    key === "theoric" ||
                    key === "realized" ? (
                      <div>
                        {key === "ecart" && (child.ecart > 0 ? "+" : "-")}
                        {child[key]}H
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : null
            )}
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <GroupFilter
        extraFilters={[]}
        filter={filter}
        updateFilter={setFilter}
        allowViewDataBy={
          parsePreference(preferences).production.allowViewDataBy
        }
        collaborator={collaborator}
        preferences={preferences}
        subPreference="production"
      />
      {isLoading ? (
        <div className="ripple_loading">
          <img src={"/img/Ripple-1s-150px.svg"} alt="" />
        </div>
      ) : (
        <div className="production-frame">
          {filtredContribution && filtredContribution.length === 0 && (
            <div className="no_data" style={{ height: 38 }}>
              <div className="title">{_("Oops")}</div>
              <div className="subTitle">{_("noDataFound")}</div>
            </div>
          )}
          {filtredContribution && filtredContribution.length > 0 && (
            <div className="production-frame_scroll">
              <CustumedMatrix
                columns={columns}
                items={filtredContribution}
                handleCellule={handleColumns}
                handleTitle={renderTitleLine}
                headerTagBG={"#FFFFFF"}
                handleChildCellule={handleChildCellule}
                handleChildTitle={handleChildTitle}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Encoding;
