import styles from './CalendarsColumn.module.scss';
import { CheckMarkIcon, IconPencil, LoadingIcon, ReloadIcon } from '../../../../common/Icons';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import classNames from 'classnames';
import { Input } from 'antd';
import DocumentUploader from '../DocumentUploader/DocumentUploader';
import Select from 'react-select';
import CustomDayPicker from '../CustomDayPicker/CustomDayPicker';
import { useState } from 'react';

const ACCEPTED_FILE_FORMATS = [".png", ".jpeg", ".jpg", ".pdf"];

const MonthHeader = ({ month, index, showEditFormForCalendar, handlers, isSavingAbsence }) => (
  <div className={styles.month_item_header}>
    <h6 className={styles.month_item_label}>{month}</h6>
    {showEditFormForCalendar === index && (
      <ActionButtons handlers={handlers} isSavingAbsence={isSavingAbsence} />
    )}
    <ChevronButton
      index={index}
      openedMonthCalendar={handlers.openedMonthCalendar}
      handleOpenCalendar={handlers.handleOpenCalendar}
    />
  </div>
);

const ActionButtons = ({ handlers, isSavingAbsence }) => (
  <div className={styles.month_item_action_buttons}>
    <span
      className={styles.month_item_action_button}
      onClick={handlers.handleReset}
    >
      <ReloadIcon size={12} />
    </span>
    <span
      className={styles.month_item_action_button}
      onClick={() => {
        if (!isSavingAbsence) {
          handlers.handleSaveAbsence();
        }
      }}
    >
      {
        isSavingAbsence ? (
          <LoadingIcon size={16} />
        ) : <CheckMarkIcon size={12} />
      }
    </span>
  </div>
);

const ChevronButton = ({ index, openedMonthCalendar, handleOpenCalendar }) => (
  <span
    className={styles.month_item_chevron_down}
    onClick={() => handleOpenCalendar(index)}
  >
    {openedMonthCalendar != null && openedMonthCalendar === index ? (
      <FaChevronDown size={12} />
    ) : (
      <FaChevronUp size={12} />
    )}
  </span>
);

const HolidayTypeSelector = ({ selectedOption, onOptionSelect }) => (
  <div className={styles.month_item_footer_bloc}>
    <div
      className={classNames({
        [styles.month_item_footer_item]: true,
        [styles.month_item_footer_item_absence]: true,
        [styles.month_item_footer_item_absence_active]: selectedOption === 'absence'
      })}
      onClick={() => onOptionSelect('absence')}
    >
      <span className={styles.month_item_footer_item_option} />
      <span className={styles.month_item_footer_label}>Congé payé</span>
    </div>
    <div
      className={classNames({
        [styles.month_item_footer_item]: true,
        [styles.month_item_footer_item_other]: true,
        [styles.month_item_footer_item_other_active]: selectedOption === 'other'
      })}
      onClick={() => onOptionSelect('other')}
    >
      <span className={styles.month_item_footer_item_option} />
      <span className={styles.month_item_footer_label}>Autres congés</span>
    </div>
    <div
      className={classNames({
        [styles.month_item_footer_item]: true,
        [styles.month_item_footer_item_partial]: true,
        [styles.month_item_footer_item_partial_active]: selectedOption === 'partial'
      })}
      onClick={() => onOptionSelect('partial')}
    >
      <span className={styles.month_item_footer_item_option} />
      <span className={styles.month_item_footer_label}>Congé partiel</span>
    </div>
  </div>
);

const AbsenceForm = ({
  editDescription,
  setEditDescription,
  collaboratorsOptions,
  selectedCollaborator,
  setSelectedCollaborator
}) => (
  <>
    <div className={styles.month_item_footer_bloc}>
      <Input
        className={styles.month_item_footer_input}
        placeholder='Description...'
        value={editDescription}
        onChange={(e) => setEditDescription(e.target.value)}
        suffix={
          <IconPencil />
        }
      />
    </div>
    <div className={styles.month_item_footer_bloc}>
      <Select
        className={styles.month_item_footer_select}
        placeholder="Binôme"
        options={collaboratorsOptions}
        value={selectedCollaborator}
        onChange={setSelectedCollaborator}
      />
    </div>
  </>
);

const OtherHolidayForm = ({
  holidaysTypesOptions,
  selectedHolidayTypeOption,
  setSelectedHolidayTypeOption,
  attachments,
  setAttachments
}) => (
  <>
    <div className={styles.month_item_footer_bloc}>
      <Select
        className={styles.month_item_footer_select}
        placeholder="Type de congé"
        options={holidaysTypesOptions}
        value={selectedHolidayTypeOption}
        onChange={setSelectedHolidayTypeOption}
      />
    </div>
    <div className={styles.month_item_footer_bloc}>
      <DocumentUploader
        attachments={attachments}
        allowMultipleSelect={true}
        acceptedFormats={ACCEPTED_FILE_FORMATS}
        onDropItems={setAttachments}
      />
    </div>
  </>
);

const PartialForm = ({
  partialAbsenceHours,
  setPartialAbsenceHours,
}) => {
  const [isValid, setIsValid] = useState(true);

  const validateNumber = (value) => {
    const isValidNumber = value === '' || (value !== '' && !isNaN(value));
    // console.log(value, isValidNumber)
    setIsValid(isValidNumber);
    if (isValidNumber) {
      setPartialAbsenceHours(value);
    }
  };

  return (
    <div className={styles.month_item_footer_bloc}>
      <Input
        className={styles.month_item_footer_input}
        placeholder="Nombre d'heures"
        value={partialAbsenceHours}
        onChange={(e) => validateNumber(e.target.value)}
        suffix={
          partialAbsenceHours == '' ?  <IconPencil size={12} /> : null
        }
      />
    </div>
  );
};

export default function CalendarsColumn({
  year,
  months,
  showEditFormForCalendar,
  setShowEditFormForCalendar,
  selectedDays,
  setSelectedDays,
  setSelectedHolidayOption,
  selectedHolidayOption,
  handleOpenCalendar,
  openedMonthCalendar,
  dayPickerParams,
  handleDayClick,
  editDescription,
  setEditDescription,
  collaboratorsOptions,
  selectedCollaborator,
  setSelectedCollaborator,
  holidaysTypesOptions,
  selectedHolidayTypeOption,
  setSelectedHolidayTypeOption,
  attachments,
  setAttachments,
  handleSaveAbsence,
  isSavingAbsence,
  partialAbsenceHours,
  setPartialAbsenceHours,
}) {
  const handleReset = () => {
    setShowEditFormForCalendar(null);
    setSelectedDays([]);
    setSelectedHolidayOption(null);
  };

  const renderMonthContent = (index) => {
    if (openedMonthCalendar !== index) return null;

    return (
      <>
        <div className={styles.month_item_body}>
          {dayPickerParams && (
            <CustomDayPicker
              month={new Date(year, index)}
              selectedDays={selectedDays}
              onSelect={handleDayClick}
              disabledDays={dayPickerParams?.disabled}
              modifiers={dayPickerParams?.modifiers}
              modifiersClassNames={dayPickerParams?.modifiersClassNames}
            />
          )}
        </div>

        <div className={styles.month_item_footer}>
          <HolidayTypeSelector
            selectedOption={selectedHolidayOption}
            onOptionSelect={setSelectedHolidayOption}
          />

          {showEditFormForCalendar === index && selectedHolidayOption === 'absence' && (
            <AbsenceForm
              editDescription={editDescription}
              setEditDescription={setEditDescription}
              collaboratorsOptions={collaboratorsOptions}
              selectedCollaborator={selectedCollaborator}
              setSelectedCollaborator={setSelectedCollaborator}
            />
          )}

          {showEditFormForCalendar === index && selectedHolidayOption === 'other' && (
            <OtherHolidayForm
              holidaysTypesOptions={holidaysTypesOptions}
              selectedHolidayTypeOption={selectedHolidayTypeOption}
              setSelectedHolidayTypeOption={setSelectedHolidayTypeOption}
              attachments={attachments}
              setAttachments={setAttachments}
            />
          )}

          {showEditFormForCalendar === index && selectedHolidayOption === 'partial' && (
            <PartialForm
              partialAbsenceHours={partialAbsenceHours}
              setPartialAbsenceHours={setPartialAbsenceHours}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div className={styles.months_column}>
      {months.map(({month, abbr, index, id}) => (
        <div key={id} className={styles.month_item}>
          <MonthHeader
            month={month}
            index={index}
            showEditFormForCalendar={showEditFormForCalendar}
            handlers={{
              handleReset,
              handleSaveAbsence,
              openedMonthCalendar,
              handleOpenCalendar
            }}
            isSavingAbsence={isSavingAbsence}
          />
          {renderMonthContent(index)}
        </div>
      ))}
    </div>
  );
}
