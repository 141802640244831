import React, { useEffect } from "react";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import Layout from "./components/Layout";
import { setHeader, setSubMenu } from "./store";
import DashboardPartner from "./components/Dashboard/Partner";
import Clients from "./components/Clients";
import ClientSpace from "./components/ClientSpace/ClientSpace";
import CollaboratorSpace from "./components/CollaboratorSpace/CollaboratorSpace";
import TimeSheet from "./components/TimeSheetIframe";
import CollaboratorTimeSheet from "./components/TimeSheetIframe/CollaboratorTimeSheet";
import Users from "./components/UsersIframe";
import Parameters from "./components/ParametersIframe";
import Synchronisation from "./components/Synchronisation";
import CapacityPlaning from "./components/CapacityPlaning";
import FinancialSheet from "./components/FinancialSheetIframe";
import DynamicTable from "./components/DynamicTableIframe";
import DynamicTable11 from "./components/DynamicTable";
import Collaborators from "./components/Collaborators/Collaborators";
import Reporting from "./components/Reporting";
import Budget from "./components/Budget";
import Forfait from "./components/Forfait";
import ModelesSettings from "./components/ModelesSettings";
import CategorySettings from "./components/CategorySettings";
import TaskType from "./components/TaskTypeIframe";
import UserSpace from "./components/UserSpace";
import NotificationPage from "./components/Notifications/NotificationPage";
import NotificationsActivitePage from "./components/Notifications/NotificationsActivitePage";
import CalendarSettings from "./components/CalendarSettings";
import CollaboratorSettings from "./components/CollaboratorSettingsIframe";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import auth from "./store/auth";
import CroissancePage from "./components/Croissance";
import DashboardCollaborator from "./components/Dashboard/Collaborator";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function HandleLanguageRedirect() {
  const dispatch = useDispatch();
  var query = window.location.search.substring(1);
  var vars = query.split("&");

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === "without_header") {
      dispatch(setHeader(false));
    }
    if (pair[0] === "without_submenu") {
      dispatch(setSubMenu(false));
    }
  }

  const lng = localStorage.getItem("lng") || "fr";
  return <Navigate to={`/${lng}`} />;
}

// const Home = connect((state) => ({
//   currentOrganization: selectCurrentOrganization(state),
//   user: selectUser(state),
//   loggedAs: selectLoggedAs(state),
//   spaceAs: state.auth && state.auth.spaceAs,
// }))(({ loggedAs, spaceAs, user }) => {
//   return isManagerAccess(loggedAs, spaceAs) ? (
//     <Redirect to={`/clients`} />
//   ) : isCollaboratorAccess(loggedAs, spaceAs) ? (
//     <Redirect to={`/collaborator-space`} />
//   ) : isSupervisorAccess(loggedAs) ? (
//     <Redirect to={`/clients`} />
//   ) : (
//     <Redirect to={`/not_found`} />
//   );
// });

const TrackCurrentPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.parent.postMessage(
      {
        type: "PATHNAME_CHANGE",
        pathname: location.pathname,
      },
      "https://unitedassociates.be/collaborator?APP=POWERTEAM"
      // "http://local.unitedassociates.be:3001/collaborator?APP=POWERTEAM"
    );
  }, [location.pathname]);

  return null;
};

function App() {
  useEffect(() => {
    $("#app-loader")
      .fadeOut()
      .promise()
      .done(() => $("#app-loader").remove());
  });

  return (
    <BrowserRouter>
      <TrackCurrentPage />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Layout />}>
            <Route
              path="/"
              // element={<HandleLanguageRedirect to={`/clients`} />}
            />
            <Route path=":lang">
              <Route path="notifications" element={<NotificationPage />} />
              <Route path="Notifications-activite" element={<NotificationsActivitePage />} />
              <Route path="dashboard" element={<DashboardPartner />} />
              <Route path="collaborator/dashboard" element={<DashboardCollaborator />} />
              <Route path="clients" element={<Clients />} />
              <Route path="collaborators" element={<Collaborators />} />
              <Route path="capacity-planing" element={<CapacityPlaning />} />
              <Route path="fiche-client/:id" element={<ClientSpace />} />
              <Route
                path="fiche-collaborator/:id"
                element={<CollaboratorSpace />}
              />
              <Route path="budget/:clientID/:year" element={<Budget />} />
              <Route path="forfait/:clientID" element={<Forfait />} />
              <Route path="forfait" element={<Forfait />} />
              <Route path="croissance" element={<CroissancePage />} />
              <Route path="modeles-settings" element={<ModelesSettings />} />
              <Route path="collaborators" element={<Collaborators />} />
              <Route path="reporting" element={<Reporting />} />
              <Route path="fiche-client/:id" element={<ClientSpace />} />
              <Route path="user-space" element={<UserSpace />} />
              <Route path="time-sheet/:id/:name/:startDate/:endDate" element={<TimeSheet />} />
              <Route path="time-sheet" element={<TimeSheet />} />
              <Route
                path="collaborator/time-sheet"
                element={<CollaboratorTimeSheet />}
              />
              <Route path="authorizations" element={<Users />} />
              <Route path="parameter-settings" element={<Parameters />} />
              <Route path="synchronisation-page" element={<Synchronisation />} />
              <Route path="financial-sheet" element={<FinancialSheet />} />
              <Route path="dynamic-table" element={<DynamicTable />} />
              <Route path="dynamicTable" element={<DynamicTable11 />} />
              <Route path="category-settings" element={<CategorySettings />} />
              <Route path="task-types" element={<TaskType />} />
              <Route path="calendar-settings" element={<CalendarSettings />} />
              <Route
                path="collaborators-settings"
                element={<CollaboratorSettings />}
              />
              <Route
                path="collaborators-settings/:name"
                element={<CollaboratorSettings />}
              />
            </Route>
          </Route>
        </Routes>
        <ToastContainer autoClose={3000} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
