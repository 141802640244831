import axios from "axios";
import { TTP_API_URL } from "../config";
import qs from "qs";

export const getCollaboratorsEcheance = (
  token,
  organizationId,
  partner,
  collaborators,
  year,
  zoneId,
  bu
) => {
  const requestUrl = `${TTP_API_URL}/team/collaboratorsBalance`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      partner: partner,
      collaborators: collaborators,
      year: year,
      zoneId: zoneId,
      bu: bu,
    },
  });
};

export const getBalancePlannerRecap = (
  token,
  organizationId,
  partner,
  year,
  collaborators,
  zone,
  bu,
  keyWord,
  status
) => {
  const requestUrl = `${TTP_API_URL}/team/balance-planner-recap`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      year: year,
      collaborators:
        collaborators && collaborators.length ? collaborators : undefined,
      ...(partner && { partner: Number(partner) }),
      ...(keyWord && { keyWord }),
      ...(status && { status }),
      ...(zone && { zone: zone }),
      bu: bu.length > 0 ? bu : [],
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "brackets" }),
  });
};

export const getCollaboratorBalance = (
  token,
  organizationId,
  partner,
  year,
  collaboratorId,
  zoneId,
  bu,
  typeBalance,
  closingDate,
  searchWord
) => {
  const requestUrl = `${TTP_API_URL}/team/balance`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      partner: partner,
      collaboratorId: collaboratorId,
      year: year,
      typeBalance: typeBalance,
      closingDate: closingDate,
      zoneId: zoneId,
      bu: bu,
      // partnerZoneId: partnerZoneId
    },
  });
};

export const getCollaboratorsBonuses = (token, organizationId, filter) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators_bonuses`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organizationId,
      partner: filter.partner,
      year: filter.year,
      limit: filter.pageSize || 10,
      start: !filter.paginationPage
        ? 0
        : (filter.paginationPage - 1) * filter.pageSize,
      zoneId: filter.zone,
      bu: filter.bu,
      collaborators: filter.collaborators
    },
  });
};
