const ADMIN_SUB_TAB = "admin";
const TODO_SUB_TAB = "todo";

export const SUB_TABS = [
  {
    key: ADMIN_SUB_TAB,
    label: "Admin"
  },
  {
    key: TODO_SUB_TAB,
    label: "To Do"
  }
]

export const PAGINATION_CURRENT_PAGE = 0;
export const PAGINATION_START = 0;
export const PAGINATION_LIMIT = 5;

// those columns exist in all tabs
const COMMUN_COLUMNS = [
  {
    field: "date",
    header: "Date",
    style: {
      width: "40px"
    },
  },
  {
    field: "clientName",
    header: "Nom",
    resizeable: true,
    align: 'left',
    style: {
      width: "240px"
    },
  }
]

export const SUB_TABS_COLUMNS = {
  [ADMIN_SUB_TAB]: [
    ...COMMUN_COLUMNS,
    {
      field: "rdv",
      header: "RDV",
      alignHeader: 'center',
      style: {
        width: "280px"
      },
    },
    {
      field: "prestaTs",
      header: "Presta",
      alignHeader: 'center',
    },
    // {
    //   field: "todo",
    //   header: "To Do",
    //   alignHeader: 'center',
    // },
    // {
    //   field: "orderSigned",
    //   header: "OP Signée",
    //   alignHeader: 'center',
    // },
    // {
    //   field: "lmProposition",
    //   header: "LM envoyée",
    //   alignHeader: 'center',
    // },
    // {
    //   field: "selectedPlan",
    //   header: "Formule choisie",
    //   alignHeader: 'center',
    //   align: 'center',
    //   style: {
    //     textAlign: 'center'
    //   }
    // },
    {
      field: "billingType",
      header: "Type de facturation",
      alignHeader: 'center',
    },
    {
      field: "compensation",
      header: "Indemnités",
      alignHeader: 'center',
      align: "center"
    },
    {
      field: "departDate",
      header: "Date de départ",
      alignHeader: 'center',
      align: "center"
    },
    {
      field: "reasonForLeaving",
      header: "Cause de départ",
      alignHeader: 'center',
      align: 'center',
      style: {
        textAlign: 'center',
        width: '200px'
      }
    },
    // {
    //   field: "observation",
    //   header: "Remarque",
    //   alignHeader: 'center',
    //   align: 'right',
    // },
    {
      field: "budgetPreviousYear",
      header: "Année N-1",
      alignHeader: 'center',
    },
    {
      field: "budgetCurrentYear",
      header: "Année N",
      alignHeader: 'center',
      align: 'right',
    },
    {
      field: "impactCurrentYear",
      header: "Impact N",
      alignHeader: 'center',
      align: 'right',
    },
    {
      field: "budgetNextYear",
      header: "Annuel N+1",
      alignHeader: 'center',
      align: 'right',
    },
    {
      field: "observation",
      header: "Remarque",
      alignHeader: 'center',
      align: 'center',
    }
  ],
  [TODO_SUB_TAB]: [
    ...COMMUN_COLUMNS,
    {
      field: "mandats",
      header: "Mandats",
      alignHeader: 'center'
    },
    {
      field: "software",
      header: "Logiciels",
      alignHeader: 'center',
    },
    {
      field: "backup",
      header: "Back-up",
      alignHeader: 'center',
    },
    {
      field: "invoicingClosed",
      header: "Facturation clôturée",
      alignHeader: 'center',
    },
    {
      field: "departCategories",
      header: "Prestations à faire",
      alignHeader: 'center',
      align: 'left',
      style: {
        width: '130px'
      }
    },
  ]
}
