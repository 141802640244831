import axios from "axios";

import { TTP_API_URL } from "../config";

export const getCorrectionCardReasons = (organizationId, token) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/reasons`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const getCorrectionCardAnomalies = (organizationId, token) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/anomalies`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const getErrorReports = (id, year, organizationId, token) => {
  const requestUrl = `${TTP_API_URL}/team/error-reports`;
  return axios.get(requestUrl, {
    params: {
      year: year,
      client: id,
      access_token: token,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const getReportingErreurContribution = (
  year,
  organizationId,
  token,
  zone,
  order,
  partner,
  collaborators,
  bu
) => {
  const requestUrl = `${TTP_API_URL}/team/error-reporting`;
  return axios.get(requestUrl, {
    params: {
      year,
      zone,
      order,
      access_token: token,
      organization_id: organizationId,
      organization: organizationId,
      partner,
      collaborators: collaborators,
      bu: bu,
    },
  });
};

export const getCorrectionCards = (id, year, organizationId, token) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/cards`;
  return axios.get(requestUrl, {
    params: {
      year: year,
      client: id,
      access_token: token,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const getPlans = (token, organization, id, filter) => {
  const requestUrl = `${TTP_API_URL}/team/plans`;

  return axios.get(requestUrl, {
    params: {
      collaborators: JSON.stringify(id),
      filter: JSON.stringify(filter),
      access_token: token,
      organization_id: organization,
      organization: organization,
    },
  });
};

export const saveCorrectionCard = ({ id, token, organization, card }) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/card`;

  let formData = new FormData();
  id && formData.append("id", id);
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("card", JSON.stringify(card));

  return axios.post(requestUrl, formData);
};

export const saveErrorReport = ({ id, token, organization, report, files }) => {
  const requestUrl = `${TTP_API_URL}/team/error-report`;

  let formData = new FormData();
  id && formData.append("id", id);
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("report", JSON.stringify(report));
  files.forEach((element, i) => {
    formData.append("files[" + i + "]", element, element.name);
  });

  return axios.post(requestUrl, formData);
};

export const saveCorrectionPlan = ({
  organization,
  token,
  card,
  plan,
  user,
}) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/plan`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("plan", JSON.stringify(plan));
  card && formData.append("card", card);
  user && formData.append("user", user);

  return axios.post(requestUrl, formData);
};

export const saveCommentPlan = ({
  organization,
  token,
  comment,
  card,
  plan,
  user,
}) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/comment`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("comment", JSON.stringify(comment));
  plan && formData.append("plan", plan);
  card && formData.append("card", card);
  user && formData.append("user", user);

  return axios.post(requestUrl, formData);
};

export const deleteCommentPlan = ({
  code,
  plan,
  card,
  token,
  organization,
}) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/comment/remove`;

  let formData = new FormData();
  code && formData.append("code", code);
  plan && formData.append("plan", plan);
  card && formData.append("card", card);
  formData.append("organization", organization);
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

export const saveReason = ({
  id,
  token,
  organization,
  type,
  parent,
  label,
  isSerious,
  taskCategory,
}) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/reason`;

  let formData = new FormData();
  id && formData.append("id", organization);
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("label", label);
  formData.append("type", type);
  parent && formData.append("parent", parent);
  taskCategory && formData.append("taskCategory", taskCategory);
  isSerious && formData.append("isSerious", isSerious);

  return axios.post(requestUrl, formData);
};

export const saveAnomaly = ({ id, token, organization, label }) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/anomaly`;

  let formData = new FormData();
  id && formData.append("id", organization);
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("label", label);

  return axios.post(requestUrl, formData);
};

export const deleteReason = ({ id, token, organization, type }) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/reason/remove`;

  let formData = new FormData();
  id && formData.append("id", id);
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("type", type);

  return axios.post(requestUrl, formData);
};

export const deleteAnomaly = ({ id, token, organization }) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/anomaly/remove`;

  let formData = new FormData();
  id && formData.append("id", id);
  formData.append("organization", organization);
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

export const deleteCorrectionPlan = ({ organization, token, plan }) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/plan/remove`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("id", plan);

  return axios.post(requestUrl, formData);
};

export const deleteCorrectionCard = ({ organization, token, card }) => {
  const requestUrl = `${TTP_API_URL}/team/correction-card/card/remove`;

  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("id", card);

  return axios.post(requestUrl, formData);
};
