import axios from "axios";
import { graphConfig } from "../config/msal-config";


export async function getUserDetails(accessToken, userId) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  }
  if (userId) {
    return axios.get(graphConfig.getUserDetailsEndpoint.replace("@@USER_ID@@", userId), options)
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  return axios.get(graphConfig.meEndpoint, options)
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function getMyCalendars(accessToken) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  return axios.get(graphConfig.getMyCalendarsEndpoint, options)
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function createEvent(accessToken, eventDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  return axios.post(
    graphConfig.createEventEndpoint,
    JSON.stringify(eventDetails),
    options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function updateEvent(accessToken, eventId, eventDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const updateEventEndpoint = graphConfig.updateEventEndpoint.replace("@@EVENT_ID@@", eventId);

  return axios.patch(
    updateEventEndpoint,
    JSON.stringify(eventDetails),
    options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function accetpEvent(accessToken, eventId) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const acceptEventEndpoint = graphConfig.acceptEventEndpoint.replace("@@EVENT_ID@@", eventId);

  return axios.post(
    acceptEventEndpoint,
    options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function deleteEvent(accessToken, eventId){
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const deleteEventEndpoint = graphConfig.deleteEventEndpoint.replace("@@EVENT_ID@@", eventId);

  return axios.delete(
      deleteEventEndpoint,
      options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function getEventCategories(accessToken) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  return axios.get(graphConfig.getEventCategoriesEndpoint, options)
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function createEventCategory(accessToken, categoryDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  return axios.post(
      graphConfig.getEventCategoriesEndpoint,
      JSON.stringify(categoryDetails),
      options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function createCalendarForUser(accessToken, userId, calendarDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const createCalendarForUserEndpoint = graphConfig.createCalendarForUserEndpoint.replace("@@USER_ID@@", userId);

  return axios.post(
    createCalendarForUserEndpoint,
    JSON.stringify(calendarDetails),
    options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function createEventForUser(accessToken, userId, calendarId, eventDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const createEventForUserEndpoint = graphConfig
    .createEventForUserEndpoint
    .replace("@@USER_ID@@", userId)
    .replace('@@CALENDAR_ID@@', calendarId);

  return axios.post(
    createEventForUserEndpoint,
    JSON.stringify(eventDetails),
    options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function getCalendarGroupsForUser(accessToken, userId) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const getCalendarGroupsForUserEndpoint = graphConfig
    .getCalendarGroupsForUserEndpoint
    .replace("@@USER_ID@@", userId);

  return axios.get(getCalendarGroupsForUserEndpoint, options)
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function getUserCalendars(accessToken, userId) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const getUserCalendarsEndpoint = graphConfig
    .getUserCalendarsEndpoint
    .replace("@@USER_ID@@", userId);

  return axios.get(getUserCalendarsEndpoint, options)
  .then(response => response.data)
  .catch(error => console.log(error));
}

export async function createCalendarGroupsForUser(accessToken, userId, calendarId, eventDetails) {
  const options = {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  };

  const createEventForUserEndpoint = graphConfig
    .createCalendarGroupsForUserEndpoint
    .replace("@@USER_ID@@", userId);

  return axios.post(
    createEventForUserEndpoint,
    JSON.stringify(eventDetails),
    options
  )
  .then(response => response.data)
  .catch(error => console.log(error));
}
