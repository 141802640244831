import styles from './index.module.scss';
import { formatTime } from '../../../../../utils';
import { useState } from "react";
import { Pagination } from 'antd';
import classNames from 'classnames';

export default function RespectProcessingTime({
  period,
  collaboratorId,
  data,
  dataToDisplay,
  onPaginationChange
}) {
  const [pageSize, setPageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.client_list}>
          {dataToDisplay.map(item => (
            <div key={item.clientId} className={styles.client_card}>
              <p className={styles.client_name}>{item.clientName}</p>
              <span className={`${styles.client_gap} ${styles.client_gap_negatif}`}>
                +{formatTime(item.timesheet.ecart, true)}
              </span>
            </div>
          ))}
        </div>

        <Pagination
          className={`${styles.pagination} bonus_custom_table_pagination`}
          pageSize={pageSize}
          current={currentPage}
          total={data.length}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);

            // const startIndex = (page - 1) * pageSize;
            // const endIndex = startIndex + pageSize;
            onPaginationChange(page, pageSize);
          }}
          itemRender={(page, type) => {
            if (type === 'prev') {
              return (
                <button
                  className={classNames({
                    'pagination_button': true,
                    'pagination_button_disabled': currentPage === 1
                  })}
                  disabled={currentPage === 1}
                >
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none">
                    <path d="M5 9L1 5L5 1" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              );
            }
            if (type === 'next') {
              return (
                <button
                  className={classNames({
                    'pagination_button': true,
                    'pagination_button_disabled': currentPage === data.length / pageSize
                  })}
                  disabled={currentPage * pageSize >= data.length}
                >
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none">
                    <path d="M1 1L5 5L1 9" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              );
            }
            return null;
          }}
          showTotal={(total, range) => (
            <p className='pagination_total_text'>
              {Math.ceil(range[0] / 6)} sur {Math.ceil(total / 6)}
            </p>
          )}
        />
      </div>
    </div>
  )
}
