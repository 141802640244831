import React, { useState, useRef, useEffect } from "react";
import _ from "../../i18n";
import { useOnClickOutside, renderTeamYears, viewDataBy } from "../../utils";
import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../common/Filter/TTPFilterHorizontalBar";
import { getPartners } from "../../api/partners";
import { useQuery } from "react-query";
import {
  getBU,
  getCollaboratorsSuggestions,
  getZonesSuggestions,
} from "../../api";
import { useSelector } from "react-redux";

const GroupFilter = ({
  extraFilters = [],
  addsOn,
  nbResult,
  filter = {},
  updateFilter,
  collaborator,
  allowViewDataBy,
  preferences,
  subPreference,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  const { token, navCommunity, user } = useSelector((state) => state.auth);

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);

  useEffect(() => {
    const { partner } = filter;
    const options = partner
      ? collaborators.filter((c) => c.partner == filter.partner)
      : collaborators;
    setCollaboratorOptions(options);
  }, [filter?.partner, collaborators]);

  let partnersQuery = useQuery(
    ["partnersQuery", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const response = await getPartners({
          token,
          organizationId: navCommunity?.id,
        });
        const data = response.data.data.map((partner) => ({
          value: Number(partner.id),
          label: partner.name,
          zone: partner.zone,
        }));
        let zone;
        if (allowViewDataBy === 11) {
          zone = data.find((p) => p.value === Number(user?.id)).zone;
        }
        setPartnerOptions(data);
        updateFilter({ ...filter, partnerZoneId: zone });
        return data;
      }
    },
    {
      enabled: !!token && !!navCommunity,
    }
  );

  let collaboratorsQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data;
          let list = viewDataBy(preferences, data, collaborator, subPreference);
          data = list
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
                partner: t?.partner?.value,
                isActif: t.isActif,
              };
            });
          setCollaborators(
            list.map((c) => ({
              value: c.id,
              label: c.name,
              partner: c?.partner?.value,
              isActif: c.isActif,
            }))
          );
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let buQuery = useQuery(["businessUnitsQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getBU({
          token,
          organizationId: navCommunity.id,
        });
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setBuOptions(data);
      } catch (e) {
        setBuOptions([]);
        return null;
      }
    }
  });

  let zonesQuery = useQuery(["zonesQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getZonesSuggestions(token, navCommunity.id);
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setZoneOptions(data);
      } catch (e) {
        setZoneOptions([]);
        return null;
      }
    }
  });

  const handlePartnerChange = (partner) => {
    updateFilter({ ...filter, partner: Number(partner) });
  };

  const handleBuChange = (items) => {
    updateFilter({ ...filter, bu: items });
  };

  const handleZoneChange = (item) => {
    updateFilter({ ...filter, zone: item ? Number(item) : null });
  };

  const handleCollaboratorChange = (items) => {
    const actifs = collaborators
      .filter((item) => item.isActif)
      .map((item) => item.value);

    const updatedSelection = [...actifs, ...items];

    const updated = [10, 110].includes(allowViewDataBy)
      ? [...new Set(updatedSelection)]
      : items;

    updateFilter({ ...filter, collaborators: updated });
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        isSearch: true,
        isDisabled: allowViewDataBy === 101 || subPreference == "individualDevMa",
        placeholder: _("zone"),
        options: zoneOptions,
        values: filter.zone,
        action: handleZoneChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: ["vacation", "timesheet"].includes(subPreference),
        isMulti: !["vacation", "timesheet"].includes(subPreference),
        isClearable: true,
        isSearch: true,
        isDisabled: allowViewDataBy === 100 || subPreference == "individualDevMa",
        placeholder: _("bu"),
        options: buOptions,
        values: filter.bu,
        action: handleBuChange,
      },
    },
    ...extraFilters,
  ];

  if (subPreference !== "individualDevMa") {
    filters.unshift({
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: allowViewDataBy !== 10,
        isSearch: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborators,
        action: handleCollaboratorChange,
      },
    });
  }

  if (
    [1, 11].includes(allowViewDataBy) &&
    subPreference !== "individualDevMa"
  ) {
    filters.unshift({
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        isDisabled: allowViewDataBy == 11,
        placeholder: _("Partner"),
        options: partnerOptions,
        values: filter.partner,
        action: handlePartnerChange,
      },
    });
  }

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    handleChangeYear(item);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleChangeYear = (year) => {
    updateFilter({
      ...filter,
      year: year,
    });
  };

  const renderCustomAddOn = () => {
    return (
      <div className="filter_custom" style={{ width: 0 }}>
        {renderYearSelect()}
        {addsOn}
      </div>
    );
  };

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          bg="#FFF"
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          // pageSize={filter.pageSize}
          customAddOn={renderCustomAddOn()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default GroupFilter;
