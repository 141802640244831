import React, { useState, Fragment, useEffect } from "react";
import { useQuery } from "react-query";
import moment from "moment";

import {
  getTimeSheetProgression,
  getCollaboratorsAvatars,
  getTimeSheetProgressionByCollaborator,
  getClientsSuggestions,
  getBillingTypes,
} from "../../../api";
import _ from "../../../i18n";

import { Table, Tooltip } from "antd";
import TTPPaginator from "../../common/TTPPaginator";
import { TiArrowSortedDown, TiArrowSortedUp, TiWarning } from "react-icons/ti";
import TimeSheetProgressionFilter from "./TimeSheetProgressionFilter";
import "./TimeSheetProgression.local.scss";
import classNames from "classnames";
import AnimatedLoadingIcon2 from "../../common/Icons/AnimatedLoadingIcon2";
import { formatAmount, parsePreference } from "../../../utils";
import GroupFilter from "../GroupFilter";
import { COLLABORATOR_ROLE_FILTER, DELETED_STATUS } from "../../../config";
import { useSelector } from "react-redux";

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
  },
  {
    title: _("budgetedHours"),
    dataIndex: "budget",
    key: "budget",
    width: "100px",
  },
  {
    title: _("realBillableHours"),
    dataIndex: "realBillable",
    key: "realBillable",
    width: "100px",
  },
  {
    title: _("realNonBillableHours"),
    dataIndex: "realNonBillable",
    key: "realNonBillable",
    width: "100px",
  },
  {
    // title: _("gap"),
    title: "Écart avec le budget",
    dataIndex: "gap",
    key: "gap",
    width: "100px",
  },
  {
    // title: _("cp"),
    title: "Temps supplémentaire",
    dataIndex: "cp",
    key: "cp",
    width: "15%",
  },
];

const TimeSheetProgression = ({
  token,
  navCommunity,
  parentFilter,
  language,
  collaborator,
  preferences,
}) => {
  const { user, loggedAs } = useSelector((state) => state.auth);
  const allowViewDataBy =
    parsePreference(preferences).timesheet.allowViewDataBy;
  const [progression, setProgression] = useState([]);
  const [nbResult, setNbResult] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [billingTypeOptions, setBillingTypeOptions] = useState([]);
  const [filter, setFilter] = useState({
    ...parentFilter,
    year: new Date().getFullYear().toString(),
    bu: allowViewDataBy === 100 && collaborator ? collaborator.bu : null,
    zone: allowViewDataBy === 101 && collaborator ? collaborator.zoneId : null,
    partner: loggedAs == "MANAGER" ? user?.id : null,
    collaborators:
      [10, 110].includes(allowViewDataBy) && collaborator
        ? [collaborator.id]
        : [],
    clients: [],
    role: null,
    paginationPage: 1,
    pageSize: 10,
    sort: null,
    billingType: null,
  });
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [currentExpandedRowKey, setCurrentExpandedRowKey] = useState(null);

  let clientsdQuery = useQuery(
    ["getClients", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getClientsSuggestions(
            token,
            filter.clientCRMGroup,
            navCommunity.id,
            "isEntity",
            filter.year
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setClientOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let billingTypesQuery = useQuery(
    ["getBillingTypes", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getBillingTypes({
            token,
            organizationId: navCommunity.id,
          });

          let data = response.data.data
            .filter((item) => item !== null)
            .map((t, index) => {
              return {
                value: String(index + 1),
                label: t,
              };
            });
          setBillingTypeOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let timesheetQuery = useQuery(
    [("getTimeSheetProgression", token, navCommunity, filter)],
    async () => {
      if (token && navCommunity) {
        try {
          const sanitizedFilter = {
            ...filter,
            billingType: !filter.billingType ? null : filter.billingType.label,
          };
          const response = await getTimeSheetProgression(
            navCommunity.id,
            token,
            sanitizedFilter
          );
          setProgression(response.data.data.data);
          setNbResult(response.data.data.nbrResult);
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    }
  );

  let avatarsQuery = useQuery(
    ["getCollaboratorAvatars", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsAvatars(
            navCommunity.id,
            token
          );
          setAvatars(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const handleRowExpande = (isExpanded, record) => {
    if (isExpanded) {
      if (record.type === "COLLABORATOR") {
        setCurrentExpandedRowKey(record.key);
        setIsLoadingClients(true);
        // reset record children to null
        setProgression((prevState) => {
          return prevState.map((item) => {
            if (item.key === record.key) {
              if (item.children[0]) {
                item.children[0].children = null;
              }
              if (item.children[1]) {
                item.children[1].children = null;
              }
            }
            return item;
          });
        });
        const sanitizedFilter = {
          ...filter,
          billingType: !filter.billingType ? null : filter.billingType.label,
        };
        getTimeSheetProgressionByCollaborator({
          token,
          organizationId: navCommunity.id,
          collaboratorId: parseInt(record.key),
          filter: sanitizedFilter,
        })
          .then((response) => {
            const data = response.data.data;

            if (!Array.isArray(data)) {
              setProgression((prevState) => {
                return prevState.map((item) => {
                  if (item.key === record.key) {
                    item.children = data.children;
                  }
                  return item;
                });
              });
            }
          })
          .finally(() => {
            setIsLoadingClients(false);
          });
      }
    }
  };

  const renderTimeSheetTree = () => {
    let columnsTest = [];

    columnsTest = [
      {
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div style={{ height: 43, textAlign: "right" }}>
                <span class="ant-table-column-title" style={{ width: "99.5%" }}>
                  SYNTHETIQUE
                </span>
              </div>
            </span>
          );
        },
        dataIndex: "label",
        key: "label",
        align: "left",
        render: (text, record) => {
          return (
            <div
              className={"balance-sheet-key-indicator-treasury-bold"}
              style={{
                minHeight: record.type === "COLLABORATOR" ? 72 : 43,
                width: "99.5%",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
                className={
                  record.type === "CLIENT" && "ant-table-column-title_sub"
                }
              >
                <div className="timesheet-table-title">
                  <p
                    className={classNames({
                      "timesheet-table-client-type-padding":
                        record.type === "CLIENT_TYPE",
                      "timesheet-table-client-name-padding":
                        record.type === "CLIENT",
                      "timesheet-table-category-padding":
                        record.type === "CATEGORY",
                    })}
                  >
                    {record.name && record.name.length >= 46
                      ? `${record.name.slice(0, 32)}...`
                      : record.name}
                  </p>

                  {record.type === "CLIENT_TYPE" && (
                    <span className="timesheet-table-client-type-count-badge">
                      {isLoadingClients &&
                      currentExpandedRowKey == record.key.split("-")[1] ? (
                        <AnimatedLoadingIcon2 />
                      ) : (
                        record.count
                      )}
                    </span>
                  )}
                </div>
                {record.type === "CLIENT" &&
                  record.role !== null &&
                  record.role.length > 0 && (
                    <span className="timesheet-table-client-role-badge">
                      {record.role.join(", ")}
                    </span>
                  )}
              </div>
            </div>
          );
        },
        width: 350,
      },
    ];

    for (let i = 1; i < columns.length; i++) {
      columnsTest.push({
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div style={{ height: 43 }}>
                <span class="ant-table-column-title">
                  {_(columns[i].title)} {columns[i].key === "gap" ? "" : "(h)"}
                  <span
                    class="sorter"
                    style={{
                      color:
                        filter.sort && filter.sort.includes(columns[i].key)
                          ? "#e4525d"
                          : "#fff",
                    }}
                    onClick={() => {
                      if (!filter.sort) {
                        setFilter({
                          ...filter,
                          sort: `${columns[i].key}_desc`,
                        });
                      } else {
                        setFilter({
                          ...filter,
                          sort:
                            filter.sort === `${columns[i].key}_desc`
                              ? `${columns[i].key}_asc`
                              : `${columns[i].key}_desc`,
                        });
                      }
                    }}
                  >
                    {filter.sort === `${columns[i].key}_asc` ? (
                      <TiArrowSortedUp />
                    ) : (
                      <TiArrowSortedDown />
                    )}
                  </span>
                </span>
              </div>
            </span>
          );
        },
        dataIndex: columns[i].dataIndex,
        key: columns[i].key,
        render: (text, record) => {
          const isCollaboratorRow = record.type === "COLLABORATOR";
          const isClientTypeRow = record.type === "CLIENT_TYPE";
          const isClientRow = record.type === "CLIENT";
          const isCategoryRow = record.type === "CATEGORY";

          let realBillable = record.realBillable ?? 0;
          let realNonBillable = record.realNonBillable ?? 0;
          let budgetedHours = record.budget ?? 0;
          let realHours = realBillable + realNonBillable;
          let nbrWorkingDays =
            moment().diff(moment([filter.year, 0, 1]), "days") + 1;
          let budgetedHoursUntilNow = (budgetedHours * nbrWorkingDays) / 365;
          let gapWithBudget = (realHours - budgetedHoursUntilNow).toFixed(2);

          return columns[i].key === "cp" ? (
            record.cp_config === "NO_CONFIGURATION" ? (
              <span
                className={"balance-sheet-td-component"}
                style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}
              >
                <Tooltip
                  key={`role-${record.key}`}
                  placement="top"
                  title={_("regimeNotConfigurated")}
                >
                  <div className="tag" style={{ color: "red", marginTop: 10 }}>
                    <TiWarning />
                  </div>
                </Tooltip>
              </span>
            ) : (
              <span
                className={"balance-sheet-td-component"}
                style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}
              >
                <div>
                  <span
                    className={
                      text && text.length >= 46
                        ? "balance-sheet-label-wrap"
                        : ""
                    }
                  >
                    <div
                      className={classNames({
                        "timesheet-table-balance-is-negative": record.gap < 0,
                      })}
                    >
                      {isCollaboratorRow &&
                        formatAmount(record.gap, false) +
                          (record.type === "COLLABORATOR" ? "%" : "")}
                    </div>
                  </span>
                  {record.type === "COLLABORATOR" && (
                    <div>
                      <Tooltip
                        key={`role-${record.key}`}
                        placement="top"
                        title={_("cp")}
                      >
                        <span className={"balance-sheet-td-percentage"}>
                          <span className={"percentage__positive"}>
                            {formatAmount(record.cp ? record.cp : 0, false)}h
                          </span>
                        </span>
                      </Tooltip>
                      <Tooltip
                        key={`role-${record.key}`}
                        placement="top"
                        title={_("realized")}
                      >
                        <span className={"balance-sheet-td-percentage"}>
                          <span className={"percentage__positive"}>
                            <span>
                              {formatAmount(realHours ? realHours : 0, false)}h
                            </span>
                          </span>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </span>
            )
          ) : columns[i].key === "gap" ? (
            <span
              className={"balance-sheet-td-component"}
              style={{ minHeight: record.type === "COLLABORATOR" ? 72 : 43 }}
            >
              <div>
                <span
                  className={classNames({
                    "balance-sheet-label-wrap": text && text.length >= 46,
                    "timesheet-table-balance-is-negative": gapWithBudget < 0,
                  })}
                >
                  <div>
                    {isClientTypeRow &&
                    isLoadingClients &&
                    typeof record.key == "string" &&
                    currentExpandedRowKey == record?.key?.split("-")[1]
                      ? "--"
                      : formatAmount(gapWithBudget, false) + "h"}
                  </div>
                </span>
                {record.type === "COLLABORATOR" && (
                  <div>
                    <Tooltip
                      key={`role-${record.key}`}
                      placement="top"
                      title={_("proportionnalBudget")}
                    >
                      <span className={"balance-sheet-td-percentage"}>
                        <span className={"percentage__positive"}>
                          <span>
                            {formatAmount(budgetedHoursUntilNow, false)}h
                          </span>
                        </span>
                      </span>
                    </Tooltip>
                    <Tooltip
                      key={`role-${record.key}`}
                      placement="top"
                      title={_("realized")}
                    >
                      <span className={"balance-sheet-td-percentage"}>
                        <span className={"percentage__positive"}>
                          <span>{formatAmount(realHours, false)}h</span>
                        </span>
                      </span>
                    </Tooltip>
                  </div>
                )}
              </div>
            </span>
          ) : (
            <span
              className={"balance-sheet-td-component"}
              style={{
                minHeight: record.type === "COLLABORATOR" ? 72 : 43,
                padding:
                  record.type === "COLLABORATOR"
                    ? "20px 0 20px 0px"
                    : "10px 0 10px 0px",
              }}
            >
              <span
                className={
                  text && text.length >= 46 ? "balance-sheet-label-wrap" : ""
                }
              >
                <div>
                  {isLoadingClients &&
                  typeof record.key == "string" &&
                  currentExpandedRowKey == record?.key?.split("-")[1]
                    ? "--"
                    : formatAmount(
                        record[columns[i].dataIndex]
                          ? record[columns[i].dataIndex]
                          : 0,
                        false
                      ) + "h"}
                </div>
              </span>
            </span>
          );
        },
        width: "180px",
      });
    }

    return (
      <div style={{ paddingTop: "0rem" }}>
        <div className={"rmodal__center"}>
          <Table
            columns={columnsTest}
            dataSource={progression}
            pagination={false}
            bordered={false}
            style={{ margin: "0 0 0 0" }}
            className="timesheet-progression-table"
            indentSize={14}
            expandable={{
              onExpand: handleRowExpande,
            }}
          />
        </div>
      </div>
    );
  };

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={10}
        nbResult={nbResult}
        paginationPage={filter.paginationPage}
      />
    );
  };

  const handleClientsChange = (items) => {
    setFilter({ ...filter, clients: items });
  };

  const handleCollaboratorRoleChange = (item) => {
    setFilter({ ...filter, role: item });
  };

  const handleSetDeletedStatus = (status) => {
    setFilter({ ...filter, deletedStatus: status });
  };

  return (
    <div className="portfolio_container">
      <div className="container">
        {/* <TimeSheetProgressionFilter
          filterFormIsVisible={false}
          filter={filter}
          nbResult={nbResult}
          updateFilter={setFilter}
          fetching={timesheetQuery.isFetching}
        /> */}
        <GroupFilter
          extraFilters={[
            {
              type: "SELECT",
              options: {
                isSimple: false,
                isMulti: true,
                isClearable: true,
                isSearch: true,
                placeholder: _("client"),
                options: clientOptions,
                values: filter.clients,
                action: handleClientsChange,
              },
            },
            {
              type: "SELECT",
              options: {
                isSimple: true,
                isClearable: true,
                placeholder: _("role"),
                options: COLLABORATOR_ROLE_FILTER,
                values: filter.role,
                action: handleCollaboratorRoleChange,
              },
            },
            {
              type: "SELECT",
              options: {
                isSimple: true,
                isClearable: true,
                placeholder: _("clientState"),
                options: DELETED_STATUS,
                values: filter.deletedStatus,
                action: handleSetDeletedStatus,
              },
            },
            {
              type: "SELECT",
              options: {
                isSimple: true,
                isClearable: true,
                placeholder: _("billingType"),
                options: billingTypeOptions,
                values: filter.billingType ? filter.billingType.value : null,
                action: (value) => {
                  if (value == null) {
                    let cleanFilter = { ...filter };
                    delete cleanFilter.billingType;
                    setFilter({ ...cleanFilter });
                  } else {
                    setFilter({
                      ...filter,
                      billingType: billingTypeOptions.filter(
                        (option) => option.value == value
                      )[0],
                    });
                  }
                },
              },
            },
          ]}
          filter={filter}
          nbResult={nbResult}
          updateFilter={setFilter}
          allowViewDataBy={
            parsePreference(preferences).timesheet.allowViewDataBy
          }
          collaborator={collaborator}
          preferences={preferences}
          subPreference="timesheet"
        />
        <div className="medium-11">
          {timesheetQuery.isFetching ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : Object.keys(progression).length ? (
            <Fragment>
              {renderTimeSheetTree()}
              {renderPaginate()}
            </Fragment>
          ) : (
            <div className="no_data">
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noClientsFound")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeSheetProgression;
