export const MONTHS = [
  { month: "Janvier", abbr: "JAN", index: 0, id: 1 },
  { month: "Février", abbr: "FEV", index: 1, id: 2 },
  { month: "Mars", abbr: "MAR", index: 2, id: 3 },
  { month: "Avril", abbr: "AVR", index: 3, id: 4 },
  { month: "Mai", abbr: "MAI", index: 4, id: 5 },
  { month: "Juin", abbr: "JUN", index: 5, id: 6 },
  { month: "Juillet", abbr: "JUL", index: 6, id: 7 },
  { month: "Août", abbr: "AOU", index: 7, id: 8 },
  { month: "Septembre", abbr: "SEP", index: 8, id: 9 },
  { month: "Octobre", abbr: "OCT", index: 9, id: 10 },
  { month: "Novembre", abbr: "NOV", index: 10, id: 11 },
  { month: "Décembre", abbr: "DEC", index: 11, id: 12 }
];

export const MODIFIERS_DEFAULT_DATA = {
  absence_1: [],
  absence_2: [],
  absence_3: [],
  annualHolidays: [],
  holidays: [],
  workingDays: []
};

export const HOLIDAY_FILTERS = [
  {
    key: 'ALL',
    label: 'Tous'
  },
  {
    key: 'APPROVED',
    label: 'Approuvés'
  },
  {
    key: 'PENDING',
    label: 'En attente'
  },
  {
    key: 'REJECTED',
    label: 'Rejetés'
  }
];

export const CATEGORIES_DATA = [
  {
    key: 'holiday',
    label: 'Fériés',
    count: 0,
    className: 'holiday'
  },
  {
    key: 'annual_holiday',
    label: 'Congé annuel',
    count: 0,
    className: 'annual_holiday'
  },
  {
    key: 'absence',
    label: 'Mes congés',
    count: 0,
    className: 'my_holidays'
  },
  {
    key: 'other',
    label: 'Autres',
    count: 0,
    className: 'other_holiday'
  }
];
