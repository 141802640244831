import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import "./BalancesRecap.local.scss";
import BalancesRecapFilter from "./BalancesRecapFilter";
import Table from "./Table";
import InProgressBalances from "../../CollaboratorSpace/Planner/InProgressBalances";
import { createEvent, updateEvent } from "../../../api/msal-graph.js";
import {
  getBalancePlannerRecap,
  getCollaborator,
  saveCollaboratorsPlanner,
} from "../../../api";
import { BALANCES_STATUS, colors } from "../../../config";
import _ from "../../../i18n";
import moment from "moment";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/msal-config.js";
import { toast } from "react-toastify";
import { parsePreference } from "../../../utils/common.js";
import GroupFilter from "../GroupFilter.js";

const BalancesRecap = ({ parentFilter, collaborator, preferences }) => {
  const { token, navCommunity, user, loggedAs } = useSelector(
    (state) => state.auth
  );
  const allowViewDataBy =
    parsePreference(preferences).recapBalance.allowViewDataBy;

  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [currentWeek, setCurrentWeek] = useState(null);
  const [balancesPlannerRecap, setBalancesPlannerRecap] = useState([]);
  const [inProgressFilteredData, setInProgressFilteredData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedBalanceId, setSelectedBalanceId] = useState(null);
  const [selectedTypeEvent, setSelectedTypeEvent] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedDurationHours, setSelectedDurationHours] = useState(0);
  const [selectedDurationMin, setSelectedDurationMin] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedDateEvent, setSelectedDateEvent] = useState(moment());
  const [toSaveCollaboratorPlanner, setToSaveCollaboratorPlanner] =
    useState(false);
  const [selectedMeetingInfo, setSelectedMeetingInfo] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedPlaceRDV, setSelectedPlaceRDV] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedRecurrenceDetails, setSelectedRecurrenceDetails] =
    useState(null);
  const year = new Date().getFullYear().toString();
  const selectedTitleEvent = "";
  const selectedParentId = null;
  const isAuthenticatedWithTokenToMsal = true;
  const { instance, accounts } = useMsal();
  const [filter, setFilter] = useState({
    ...parentFilter,
    collaborator: null,
    partnerZoneId: null,
    bu: allowViewDataBy === 100 && collaborator ? [collaborator.bu] : [],
    zone: allowViewDataBy === 101 && collaborator ? collaborator.zoneId : null,
    partner:
      loggedAs === "MANAGER"
        ? user?.id
        : loggedAs == "COLLABORATOR"
        ? collaborator?.partner.value
        : null,
    collaborators:
      [10, 110].includes(allowViewDataBy) && collaborator
        ? [collaborator.id]
        : [],
    year: new Date().getFullYear().toString(),
    keyWord: "",
    status: "",
  });

  useEffect(() => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const daysSinceStart = Math.floor(
      (now - startOfYear) / (24 * 60 * 60 * 1000)
    );
    const currentWeekIndex = Math.floor(daysSinceStart / 7) + 1;

    setCurrentWeek(`Semaine ${currentWeekIndex}`);
    setExpandedWeeks({ [`Semaine ${currentWeekIndex}`]: true });
  }, []);

  const handleStatusBalanceChange = (items) => {
    setFilter({ ...filter, status: items });
  };

  const {
    data: balanceRecapData,
    refetch,
    isLoading,
  } = useQuery(
    ["getBalancePlannerRecap", token, navCommunity.id, filter],
    async () => {
      // if (token && navCommunity) {
      //   try {
      //     const response = await getBalancePlannerRecap(
      //       token,
      //       navCommunity.id,
      //       filter.partner,
      //       filter.year,
      //       filter.collaborators,
      //       filter.zone,
      //       filter.bu,
      //       filter.keyWord,
      //       filter.status
      //     );
      //     setBalancesPlannerRecap(response.data.data);
      //     return response.data.data;
      //   } catch (e) {
      //     toast.error("Erreur lors de la récupération des données");
      //     return null;
      //   }
      // }
    }
  );

  const handleOptionClick = (date, data, option) => {
    setShowOptions(false);
    setSelectedTypeEvent(option);
    let categoryId = 8;
    // selectedTab === "ISOC" ? 8 : selectedTab === "IPM" ? 7 : null;

    setSelectedBalanceId(
      categoryId === 5
        ? "TVA:" + data.tva_id
        : categoryId === 8
        ? "balance:" + data.balance_id
        : null
    );

    setSelectedStatus("TODO");
    setSelectedClient(data.client_id);
    setSelectedCategory(categoryId);
    console.log("option=========", option);
    if (option === "balancePresentation") {
      setSelectedDurationHours(1);
    }
    const momentDate = date ? moment(date) : moment();
    if (option === "balancePresentation" && date) {
      momentDate.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    }
    setSelectedDate(momentDate);
    setSelectedDateEvent(momentDate);
    // setToSaveCollaboratorPlanner(true);
    setToSaveCollaboratorPlanner(true);
    // handleSaveEvent(selectedDateEvent);
  };
  useEffect(() => {
    if (selectedDateEvent && toSaveCollaboratorPlanner) {
      handleSaveEvent(selectedDateEvent);
      setToSaveCollaboratorPlanner(false);
    }
  }, [toSaveCollaboratorPlanner]);

  const handleSaveEvent = (selectedDate) => {
    if (selectedDateEvent) {
      const duration =
        selectedDurationHours && selectedDurationMin
          ? parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin)
          : selectedDurationHours
          ? selectedDurationHours * 60
          : selectedDurationMin
          ? selectedDurationMin
          : 0;

      const updatedEvent = {
        id: null,
        type: selectedTypeEvent,
        start: selectedDateEvent.format("YYYY-MM-DD"),
        date: selectedDateEvent.format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        duration: duration,
        meetingPlace: selectedPlaceRDV,
        client_id: selectedClient ? selectedClient.value : null,
        client_name: selectedClient ? selectedClient.label : null,
        category_name: selectedCategory ? selectedCategory.label : null,
        category_id: selectedCategory ? selectedCategory.value : null,
        description: null,
        color: selectedColor,
        status: selectedStatus,
        recurrenceDetails: selectedRecurrenceDetails,
      };
      // toSaveCollaboratorPlanner(updatedEvent);
      saveCollaboratorPlanner(updatedEvent);
    }
  };
  const saveOutlookEvent = async () => {
    let meetingId = null;
    let meetingUrl = null;

    if (isAuthenticatedWithTokenToMsal) {
      const eventDetails = composeEventDetailsForTeams();

      if (selectedEventId) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const eventData = await updateEvent(
            response.accessToken,
            selectedMeetingInfo.id,
            eventDetails
          );

          meetingId = eventData.id;
          meetingUrl = eventData?.onlineMeeting?.joinUrl;

          setSelectedMeetingInfo({ id: meetingId, url: meetingUrl });
        } catch (error) {
          console.error("Error updating event:", error);
        }
      } else {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const eventData = await createEvent(
            response.accessToken,
            eventDetails
          );

          meetingId = eventData.id;
          meetingUrl = eventData?.onlineMeeting?.joinUrl;

          setSelectedMeetingInfo({ id: meetingId, url: meetingUrl });
        } catch (error) {
          console.error("Error creating event:", error);
        }
      }
    }

    return { meetingId, meetingUrl };
  };
  const saveCollaboratorPlanner = async () => {
    setIsSaving(true);
    const { meetingId, meetingUrl } = await saveOutlookEvent();

    try {
      const response = await saveCollaboratorsPlanner(
        selectedEventId,
        navCommunity.id,
        selectedTypeEvent,
        selectedTitleEvent,
        selectedPlaceRDV,
        filter.collaborator,
        [String(filter.collaborator)],
        selectedClient,
        selectedCategory,
        selectedStatus,
        selectedDateEvent?.format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        selectedDescription,
        selectedColor,
        selectedRecurrenceDetails,
        selectedBalanceId,
        parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin),
        0,
        selectedParentId,
        year,
        token,
        meetingId,
        meetingUrl
      );
      if (response.data.result === "OK") {
        toast.success(_("successfully_added"));
        refetch();
        // if (["balancePresentation"].includes(selectedTypeEvent)) {
        //   sendEmailToResponsable(responsables, null, null);
        // }
      } else {
        toast.error(_("successfully_added"), { autoClose: true });
      }
    } catch (e) {
      toast.error(_("successfully_added"), { autoClose: true });
      return null;
    } finally {
      setIsSaving(false);
    }
  };
  const composeEventDetailsForTeams = () => {
    let startDate = selectedDateEvent.clone();
    let endDate = selectedDateEvent.clone();
    const minutesToAdd =
      parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin);
    const eventDetails = {};

    console.log({ selectedTypeEvent });
    const limitedDurationEvents = [
      "task",
      "meeting",
      "management",
      "balancePresentation",
    ];
    const allDayEvents = ["balanceEcheance", "intermediateSituationBalance"];

    if (limitedDurationEvents.includes(selectedTypeEvent)) {
      let title = "";
      endDate.add(minutesToAdd, "minutes");

      if (selectedTypeEvent === "task") {
        title = `[Tâche] ${selectedClient.label} - ${selectedCategory.label}`;
      } else if (selectedTypeEvent === "meeting") {
        title = `Rendez-vous avec ${selectedClient.label}`;
        // eventDetails.isOnlineMeeting = true;
        // eventDetails.onlineMeetingProvider = "teamsForBusiness";
        // eventDetails.allowNewTimeProposals = true;
        // eventDetails.responseRequested = true;
        eventDetails.allowNewTimeProposals = true;
        eventDetails.isOnlineMeeting = true;
        eventDetails.onlineMeetingProvider = "teamsForBusiness";
      } else if (selectedTypeEvent === "management") {
        title = `[Gestion] ${selectedTitleEvent}`;
      } else if (selectedTypeEvent === "balancePresentation") {
        title = `[Présentation Bilan] ${selectedClient.label}`;
      }

      eventDetails.subject = title;
    } else if (allDayEvents.includes(selectedTypeEvent)) {
      let title = "";

      startDate.hour(0);
      startDate.minute(0);
      startDate.second(0);
      startDate.millisecond(0);

      endDate.add(1, "day");
      endDate.hour(0);
      endDate.minute(0);
      endDate.second(0);
      endDate.millisecond(0);

      if (selectedTypeEvent === "balanceEcheance") {
        title = `[Échéance Bilan] ${selectedClient.label} - ${selectedCategory.label}`;
      } else if (selectedTypeEvent === "intermediateSituationBalance") {
        title = `[Situation Intermédiaire] ${selectedClient.label} - ${selectedCategory.label}`;
      }

      eventDetails.subject = title;
      eventDetails.isReminderOn = true;
      eventDetails.isAllDay = true;
    }

    // eventDetails.start = {
    //   dateTime: startDate.format("Y-MM-DDTHH:mm:ss"),
    //   timeZone:
    //     collaborator.zoneId === 1 ? "Europe/Brussels" : "Africa/Casablanca",
    // };
    // eventDetails.end = {
    //   dateTime: endDate.format("Y-MM-DDTHH:mm:ss"),
    //   timeZone:
    //     collaborator.zoneId === 1 ? "Europe/Brussels" : "Africa/Casablanca",
    // };
    // eventDetails.location = {
    //   displayName: "Microsoft Teams",
    // };

    let color = colors.find((colorData) => {
      return colorData.id === selectedColor;
    });

    if (!color) {
      color = colors[0];
    }
    eventDetails.categories = [color.id];

    // add collaborator e-mail
    // if (selectedCollaboratorEmail) {
    //   eventDetails.attendees = [
    //     {
    //       emailAddress: {
    //         address: selectedCollaboratorEmail,
    //         name: collaborator.name,
    //       },
    //       type: "required",
    //     },
    //   ];
    // }
    // TODO: add client e-mail also

    return eventDetails;
  };
  const toggleWeek = (week) => {
    setExpandedWeeks((prev) => ({
      ...prev,
      [week]: !prev[week],
    }));
  };
  const formatDateToFrench = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;
    return date.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <div className="balances_recap_page">
      <div className="page-content">
        {/* <BalancesRecapFilter
          token={token}
          organization={navCommunity && navCommunity.id}
          filter={{ ...filter }}
          updateFilter={setFilter}
          loggedAs={loggedAs}
        /> */}

        <GroupFilter
          extraFilters={[
            {
              grid: 3,
              type: "SELECT",
              options: {
                isSimple: true,
                isClearable: true,
                placeholder: _("status"),
                options: BALANCES_STATUS,
                action: handleStatusBalanceChange,
                values: filter.status,
              },
            },
          ]}
          filter={filter}
          updateFilter={setFilter}
          allowViewDataBy={
            parsePreference(preferences).recapBalance.allowViewDataBy
          }
          collaborator={collaborator}
          preferences={preferences}
          subPreference="recapBalance"
        />
        <div className="echeance_container">
          <Table filter={filter} />
          {filter.collaborator && (
            <InProgressBalances
              token={token}
              navCommunity={navCommunity}
              collaboratorId={filter.collaborator}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              selectedBalanceId={null}
              setSelectedBalanceId={null}
              inProgressFilteredData={inProgressFilteredData}
              setInProgressFilteredData={setInProgressFilteredData}
              handleOptionClick={handleOptionClick}
              isPlannerPage={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BalancesRecap;
