import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getTTPUser, getSelectedOrganization } from "../api";
import {
  setLocalStorageAuth,
  getUserCurrentNavCommunity,
  getUserLoggedAsValues,
  getCookie,
} from "../utils";
import { APP_ENV } from "../config";

const initialState = {
  fetching: false,
  fetched: false,
  token: "",
  expiresIn: null,
  createdAt: null,
  user: null,
  collaborator: null,
  error: null,
  loggedAs: "GUEST",
  spaceAs: "COLLABORATOR",
  synchronizationList: null,
  managerNotification: null,
  loggedAsPost: null,
  navCommunity: null,
  navCommunityId: null,
  loggedIsAdmin: false,
};

export const fetchNavCommunity = createAsyncThunk(
  "auth/fetchNavCommunity",
  async (tmp, { getState, dispatch }) => {
    const { token, navCommunity, navCommunityId, user } = getState().auth;
    if (!token || !user) {
      return false;
    }

    // if (user.communities && user.communities.length === 1) {
    //   dispatch(setNavCommunity(user.communities[0]));
    // }

    return getSelectedOrganization(token).then((resp) => {
      const help = typeof resp === "string" ? JSON.parse(resp) : { ...resp };
      if (
        help &&
        help.data &&
        help.data.organization &&
        (!navCommunity || navCommunity.id !== help.data.organization.id)
      ) {
        const { organization } = help.data;
        const chosed = user.communities.filter(
          (com) => organization.id === com.id
        );

        if (!chosed || chosed.length === 0) {
          dispatch(setAuthLoggedAs("CLIENT"));
        }

        dispatch(
          setNavCommunity(
            navCommunityId
              ? user.userOrganizations.filter((org) => {
                  return parseInt(org.id) === parseInt(navCommunityId);
                })[0]
              : user && user.communities && chosed && chosed.length > 0
              ? chosed[0]
              : organization
          )
        );
      }
    });
  }
);

export const fetchAuthUser = createAsyncThunk(
  "auth/fetchAuthUser",
  async (user, { getState, dispatch }) => {
    const state = getState();
    const response = await getTTPUser({
      userId: user.id,
      token: user.token ? user.token : state.auth.token,
    });

    return response.data[0];
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      state = { ...state, payload };
    },
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthCollaborator: (state, action) => {
      state.collaborator = action.payload;
    },
    setAuthLoggedAs: (state, action) => {
      state.loggedAs = action.payload;
    },
    setAuthLoggedAsPost: (state, action) => {
      state.loggedAsPost = action.payload;
    },
    setAuthSpaceAs: (state, action) => {
      state.spaceAs = action.payload;
    },
    setAuthLoggedIsAdmin: (state, action) => {
      state.loggedIsAdmin = action.payload;
    },
    setExpiresIn: (state, action) => {
      state.expiresIn = action.payload;
    },
    setTokenCreatedAt: (state, action) => {
      state.createdAt = action.payload;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setNavCommunity: (state, action) => {
      state.navCommunity = action.payload;
      setLocalStorageAuth(state);
    },
    setNavCommunityId: (state, action) => {
      state.navCommunityId = action.payload;
      setLocalStorageAuth(state);
    },
    setSynchronisationNotification: (state, action) => {
      state.synchronizationList = action.payload;
    },
    setManagerNotification: (state, action) => {
      state.managerNotification = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchCommunity.fulfilled, (state, action) => {
    //   const { data } = action.payload;
    //   state = {
    //     ...state,
    //     navCommunity: data[0],
    //   };
    // });
    builder.addCase(fetchAuthUser.fulfilled, (state, action) => {
      state.user = action.payload;
      let ttpCommunityCookieValue = getCookie("ttp_community_" + APP_ENV);
      let currentNavCommunityId =
        ttpCommunityCookieValue && !isNaN(ttpCommunityCookieValue)
          ? parseInt(ttpCommunityCookieValue)
          : null;
      let currentNavCommunity = getUserCurrentNavCommunity(
        action.payload,
        currentNavCommunityId
      );

      let { loggedAs, loggedAsPost } = getUserLoggedAsValues(
        action.payload,
        currentNavCommunity
      );
      state.loggedAs = loggedAs;
      state.loggedAsPost = loggedAsPost;
      if (action.payload.role) {
        if (
          ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(
            action.payload.role.type
          ) &&
          action.payload.role.typeStatus !== "FOLDER"
        ) {
          state.loggedIsAdmin = true;
        }
      }

      setLocalStorageAuth(state);
    });
  },
});

export const {
  setAuth,
  setAuthUser,
  setAuthCollaborator,
  setAuthLoggedAs,
  setAuthLoggedAsPost,
  setAuthSpaceAs,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  setNavCommunity,
  setNavCommunityId,
  setAuthLoggedIsAdmin,
  setManagerNotification,
  updateHiddenFaqsLocalStorage,
  setSynchronisationNotification,
} = authSlice.actions;

export default authSlice.reducer;
