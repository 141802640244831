import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { FaDownload, FaEye, FaFileInvoice, FaPencilAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { BeatLoader } from "react-spinners";
import { getInvoiceFiles, getPrestationAnnexe } from "../../../api";
import _ from "../../../i18n";
import {
  formatAmount,
  formatNumber,
  getMonthShortLabelObject,
  renderDurationDisplay,
} from "../../../utils";
import { IconClose } from "../../common/Icons";
import InvoiceDownloader from "./InvoiceModal";
import Preview from "./Preview";

export default function InvoiceTracking({
  collaboratorOptions,
  organization,
  token,
  lng,
  year,
  onClose,
}) {
  const [invoices, setInvoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [displayPDF, setDisplayPDF] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [filter, setFilter] = useState({
    tabs: getMonthShortLabelObject(lng),
    period: getMonthShortLabelObject(lng)[0],
    start: getMonthShortLabelObject(lng)[0].start,
    end: getMonthShortLabelObject(lng)[0].end,
  });
  const [isLastWeekOfMonth, setIsLastWeekOfMonth] = useState(false);
  const [annexe, setAnnexe] = useState(null);

  useEffect(() => {
    const today = new Date();
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    const lastWeekStart = new Date(lastDayOfMonth);
    lastWeekStart.setDate(lastDayOfMonth.getDate() - 6);

    setIsLastWeekOfMonth(today >= lastWeekStart);
  }, []);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const invoiceYear = parseInt(year, 10);
  const invoiceMonth = parseInt(filter.period.id, 10);

  const shouldShowDownload =
    invoiceYear < currentYear ||
    invoiceMonth < currentMonth ||
    (invoiceMonth === currentMonth && isLastWeekOfMonth);

  const handleMonthChange = (tab) => {
    setFilter({
      ...filter,
      period: tab,
      start: tab.start,
      end: tab.end,
    });
  };

  let getInvoicesQuery = useQuery(
    ["getInvoicesQuery", token, organization, year],
    async () => {
      if ((token, organization, year)) {
        try {
          const response = await getInvoiceFiles(token, organization.id, year);

          const data = response.data.data;
          setInvoices(data.invoices);
          return data;
        } catch (e) {
          console.log(e);
          setInvoices([]);
          return null;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!year,
    }
  );

  let getInvoiceAnnexeQuery = useQuery(
    ["getInvoiceAnnexeQuery", token, organization],
    async () => {
      if (token && organization) {
        const startDate = year + "-" + filter.start + "T00:00:00";
        const endDate = year + "-" + filter.end + "T00:00:00";
        const ids = collaboratorOptions.map((item) => item.value);
        try {
          const response = await getPrestationAnnexe(
            token,
            organization.id,
            startDate,
            endDate,
            ids
          );
          setAnnexe(response.data.data);
          return response.data.data;
        } catch (e) {
          console.log(e);
          setAnnexe(null);
          throw new Error(e.message);
        }
      }
    },
    {
      enabled: !!token && !!organization && !!year,
    }
  );

  useEffect(() => {
    getInvoiceAnnexeQuery.refetch();
  }, [filter.period]);

  const renderFilterFrame = () => {
    return (
      <div className="filter-frame">
        <div
          className="additional-line"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            overflowX: "auto",
          }}
        >
          {filter.tabs.map((tab) => {
            return (
              <div
                className={`tag ${
                  filter.period.id === tab.id ? "active" : ""
                } ${
                  filter.period &&
                  moment().year() == year &&
                  tab.id > moment().month() + 1 &&
                  "disabled"
                }`}
                onClick={() =>
                  filter.period &&
                  moment().year() == year &&
                  tab.id > moment().month() + 1
                    ? null
                    : handleMonthChange(tab)
                }
              >
                {tab.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const {
    period: { id: month },
  } = filter;

  const fileUrl =
    invoices[year] && invoices[year][month] && invoices[year][month][0].url;

  return (
    <Fragment>
      <div
        style={{
          width: 900,
          height: "100%",
          position: "absolute",
          right: 0,
          background: "white",
        }}
      >
        <InvoiceDownloader
          token={token}
          navCommunity={organization}
          data={collaboratorOptions}
          open={open}
          loading={downloading}
          filter={filter}
          year={year}
          close={() => setOpen(false)}
          refresh={() => getInvoicesQuery.refetch()}
        />
        <div className="sidebar_header">
          <div className="sidebar_header_infos">
            <h3>{_("accountingPrestation")}</h3>
          </div>
          <div className="sidebar_header_action" onClick={onClose}>
            <IconClose size={12} />
          </div>
        </div>
        <div className="sidebar_body vertical_content">
          {renderFilterFrame()}
          <div className="sidebar_body_content">
            <div>
              <h3>{`Facture M${filter.period.end.slice(0, 2)}-${year
                .toString()
                .slice(2, 4)}`}</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "15px 0",
                }}
              >
                <div style={{ width: "50%" }}>
                  <span>Période de facture</span>
                  <h4>{`De ${year}-${filter.start} à ${year}-${filter.end}`}</h4>
                </div>
                <div style={{ width: "50%" }}>
                  <span>Monnaie</span>
                  <h4>{`EUR (€)`}</h4>
                </div>
              </div>
              {getInvoiceAnnexeQuery.isFetching ? (
                <div className="ripple_loading">
                  <img src={"/img/Ripple-1s-150px.svg"} alt="" />
                </div>
              ) : (
                annexe && (
                  <div className="invoice-container">
                    <h2 className="invoice-title">Annexe</h2>
                    <table className="invoice-table">
                      <thead>
                        <tr>
                          <th>{_("collaborator")}</th>
                          <th>{_("folders")}</th>
                          <th>{_("volumetry")}</th>
                          <th>{_("timesheet")}</th>
                          <th>{_("offshore")}</th>
                          <th>{_("fees")}</th>
                          <th>{_("adjustment")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {annexe.rows.map((row, index) => (
                          <tr key={index}>
                            <td>{row.name}</td>
                            <td>{row.clients.count}</td>
                            <td>{row.clients.volumetry}</td>
                            <td>{renderDurationDisplay(row.timeSheet * 60)}</td>
                            <td>{renderDurationDisplay(row.offshore * 60)}</td>
                            <td>{formatAmount(row.honoraire)}</td>
                            <td>{formatAmount(row.adjustment)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="invoice-total">
                      <h3
                        style={{
                          textAlign: "left",
                          background: "#6d7f9250",
                          width: "50%",
                          padding: 7,
                        }}
                      >
                        {_("totalSummary")}
                      </h3>
                      <table className="summary-table">
                        <tbody>
                          {Object.entries(annexe.total).map(([key, value]) => (
                            <tr key={key}>
                              <td className="summary-key">{_(key)}</td>
                              <td className="summary-value">
                                {key == "volumetry"
                                  ? value.toFixed(2)
                                  : formatNumber(value)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="sidebar_footer">
            <h3>Attachements</h3>
            <div className="print_invoice">
              <div>
                <FaFileInvoice color="#6d7f92" size={20} />
                <div>
                  <strong>{`Facture M${filter.period.end.slice(0, 2)}-${year
                    .toString()
                    .slice(2, 4)}.pdf`}</strong>
                  <span>{filter.period.label}</span>
                </div>
              </div>

              {invoices[year] &&
              Object.keys(invoices[year]).includes(String(month)) ? (
                <button
                  style={{
                    color: "#06d9b1",
                    cursor: "pointer",
                  }}
                  onClick={() => setDisplayPDF(true)}
                >
                  <div>
                    {_("preview")}
                    <FaEye />
                  </div>
                </button>
              ) : (
                <button
                  onClick={shouldShowDownload ? () => setOpen(true) : null}
                  style={{
                    color: shouldShowDownload ? "#18a0fb" : "#6d7f9280",
                    cursor: shouldShowDownload ? "pointer" : "not-allowed",
                  }}
                >
                  {downloading ? (
                    <BeatLoader color="#3498db" size={10} />
                  ) : (
                    <div>
                      {_("export")}
                      <FaDownload />
                    </div>
                  )}
                </button>
              )}

              {invoices[year] &&
                Object.keys(invoices[year]).includes(String(month)) && (
                  <button
                    className="invoice-edit"
                    onClick={() => setOpen(true)}
                  >
                    <FaPencilAlt style={{ margin: "auto" }} />
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
      {fileUrl && displayPDF && (
        <Preview src={fileUrl} onClose={() => setDisplayPDF(false)} />
      )}
    </Fragment>
  );
}
