import { TiArrowSortedDown } from "react-icons/ti";
import styles from './index.module.scss';
import { useEffect, useState } from "react";

export default function BonusViewFilter({ options, onChange }) {
  const [optionsDivIsVisible, setOptionsDivIsVisible] = useState(false);
  const [selectedView, setSelectedView] = useState(options[0] ? options[0] : null);
  const [viewOptions, setViewOptions] = useState(options);

  useEffect(() => {
    if (onChange && typeof onChange === 'function') {
      onChange(selectedView.value);
    }
  }, [onChange, selectedView]);

  return (
    <div
        className={`${styles.customedSelect} ${optionsDivIsVisible ? styles.customedSelectOpened : ''}`}>
        <div
          className={styles.customedSelectHeader}
          onClick={() => setOptionsDivIsVisible(!optionsDivIsVisible)}
        >
          <span>{selectedView?.label}</span>
          <div className={styles.actions}>
            <div>
              <span className={styles.action}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {optionsDivIsVisible && (
          <div className={styles.customedSelectBody}>
            <div className={styles.customedSelectBodyOptions}>
              {viewOptions.map((item, index) => (
                <div
                  key={index}
                  className={styles.customedSelectBodyOption}
                  onClick={() => {
                    setSelectedView(item);
                    setOptionsDivIsVisible(false);
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
  )
}
