import { React, useState, useEffect } from "react";
import styles from "./Budget.module.scss";
import classNames from "classnames";
import Modal from "react-modal";
import { toast } from "react-toastify";
import _ from "../../i18n";
import { extractNumber, extractFloatNumber } from "../../utils";
import { TableBorderedColumns } from "tamtam-components";
import { saveBudgetOffre } from "../../api";

import { IconPencil } from "../common/Icons";
const GlobalBudget = ({
  clientName,
  data,
  updatedDataOffre,
  setUpdatedDataOffre,
  updatedDataCategoryBudget,
  setUpdatedDataCategoryBudget,
  updatedCategoryRoleList,
  setUpdatedCategoryRoleList,
  updatedCollaboratorsList,
  settotalGD,
  settotalGE,
  settotalSE,
  settotalScanOp,
  settotalsupervisor,
  handleChangeRolesValues,
  organizationId,
  year,
  token,
}) => {
  const bordrerdcolumns = [
    {
      label: parseInt(year - 1),
      haveHeader: true,
      isBordered: true,
      isEditable: false,
    },
    {
      label: year,
      haveHeader: true,
      isBordered: true,
      isEditable: true,
    },
  ];
  const bordrerdcolumnsOffre = [
    { label: "Offre", haveHeader: true, isBordered: true, isEditable: true },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [updatedOffre, setUpdatedOffre] = useState(false);
  const [saveBudget, setSaveBudget] = useState(false);
  const [enableToSave, setEnableToSave] = useState(true);
  const [budgetCategory, setBudgetCategory] = useState([]);

  useEffect(() => {
    if (updatedOffre) {
      handleChangeRolesValues();
      setUpdatedOffre(false);
    }
  }, [updatedOffre, updatedCollaboratorsList]);

  const handleInputChange = (value, label, column, rowIndex, symbole) => {
    let totalPercentage = 0;
    let totalHeure = 0;
    let totalheureCalculated = 0;

    const updatedList = updatedDataOffre.map((element, i) => {
      const numericValue = extractNumber(value);
      const parsedValue = isNaN(numericValue) ? 0 : parseFloat(numericValue);
      if (i === 0) {
        totalHeure = i === rowIndex ? parsedValue : element.total_heure;
      }

      if (i !== 0) {
        totalheureCalculated += parseFloat(
          element.checked
            ? i === rowIndex
              ? parsedValue
              : element.value_heure
            : 0
        );
      }
      if (i === rowIndex) {
        if (column !== "total_heure") {
          totalPercentage += parseFloat(
            column !== "checked" ? parsedValue : value ? element.value : 0
          );
        }
        if (i === 0) {
          return {
            ...element,
            ["total_heure"]:
              column !== "checked" ? extractNumber(value) + " " : 0,
          };
        } else {
          let valueHeureUpdated =
            column !== "checked"
              ? column === "value_heure"
                ? parsedValue
                : (parsedValue / totalHeure) * 100
              : 0;
          let valuePercentageUpdated =
            column !== "checked"
              ? column === "value"
                ? parsedValue
                : parseFloat((parsedValue / totalHeure) * 100).toFixed(2)
              : 0;

          return {
            ...element,
            ["checked"]: column === "checked" ? value : element.checked,
            ["value"]: valuePercentageUpdated,
            ["value_heure"]: valueHeureUpdated,
          };
        }
      }

      return element;
    });
    setUpdatedDataOffre(
      updatedList.map((element, i) => {
        setEnableToSave(
          parseFloat(totalheureCalculated) !== parseFloat(totalHeure)
        );

        if (i === 0 && column !== "total_heure") {
          let totalPercentage = parseFloat(
            (totalheureCalculated / totalHeure) * 100
          );
          return {
            ...element,
            ["value"]:
              totalPercentage % 1 !== 0
                ? totalPercentage.toFixed(2)
                : Math.round(totalPercentage),
          };
        }
        return element;
      })
    );
  };

  const handleSaveBudgetOffre = () => {
    setUpdatedDataCategoryBudget(
      updatedDataCategoryBudget.map((budgetItem, index) => {
        if (budgetItem.id_category !== 17 && budgetItem.id_category !== 11) {
          const newValue =
            index === 0
              ? updatedDataOffre[index]["total_heure"]
              : updatedDataOffre[index].value_heure;
          return {
            ...budgetItem,
            [year]: newValue > 0 ? newValue : 0,
          };
        }
      })
    );

    setBudgetCategory(
      updatedDataCategoryBudget.map((budgetItem, index) => {
        if (budgetItem.id_category !== 17 && budgetItem.id_category !== 11) {
          const newValue = parseFloat(
            index === 0
              ? updatedDataOffre[index]["total_heure"]
              : updatedDataOffre[index].value_heure
          ).toFixed(2);

          return {
            id_category: budgetItem["id_category"],
            label: budgetItem["label"],
            value: newValue > 0 ? newValue : 0,
          };
        }
      })
    );
    setUpdatedCategoryRoleList((prevList) => {
      const totalValues = prevList.reduce(
        (acc, budgetItem, idx) => {
          if (idx > 0) {
            const totalHeureIndex =
              idx === 14
                ? updatedDataOffre[idx].value_heure - 2
                : updatedDataOffre[idx].value_heure;

            acc.totalGD += parseFloat(
              !budgetItem.GD["checked"]
                ? (extractNumber(budgetItem.GD["value_percentage"]) *
                    totalHeureIndex) /
                    100
                : budgetItem.GD["value_heure"]
            );

            acc.totalGE += parseFloat(
              !budgetItem.GE["checked"]
                ? (extractNumber(budgetItem.GE["value_percentage"]) *
                    totalHeureIndex) /
                    100
                : budgetItem.GE["value_heure"]
            );

            acc.totalSE += parseFloat(
              !budgetItem.SE["checked"]
                ? (extractNumber(budgetItem.SE["value_percentage"]) *
                    totalHeureIndex) /
                    100
                : budgetItem.SE["value_heure"]
            );

            acc.totalScanOp += parseFloat(
              !budgetItem.ScanOp["checked"]
                ? (extractNumber(budgetItem.ScanOp["value_percentage"]) *
                    totalHeureIndex) /
                    100
                : budgetItem.ScanOp["value_heure"]
            );

            // Traitement spécial pour le dernier item
            acc.totalSuperviseur +=
              idx === 14
                ? 2
                : parseFloat(
                    !budgetItem.Superviseur["checked"]
                      ? (extractNumber(
                          budgetItem.Superviseur["value_percentage"]
                        ) *
                          totalHeureIndex) /
                          100
                      : budgetItem.Superviseur["value_heure"]
                  );
          }
          return acc;
        },
        {
          totalGD: 0,
          totalGE: 0,
          totalSE: 0,
          totalScanOp: 0,
          totalSuperviseur: 0,
        }
      );

      settotalGD(totalValues.totalGD);
      settotalGE(totalValues.totalGE);
      settotalSE(totalValues.totalSE);
      settotalScanOp(totalValues.totalScanOp);
      settotalsupervisor(totalValues.totalSuperviseur);

      return prevList.map((budgetItem, index) => {
        const totalHeureIndex =
          index === 0
            ? updatedDataOffre[index]["total_heure"]
            : updatedDataOffre[index].value_heure;

        if (index === 14) {
          return {
            ...budgetItem,
            GD: {
              ...budgetItem.GD,
              value_heure: (totalHeureIndex - 2).toFixed(2),
            },
            GE: {
              ...budgetItem.GE,
              value_heure: (totalHeureIndex - 2).toFixed(2),
            },
            SE: {
              ...budgetItem.SE,
              value_heure: (totalHeureIndex - 2).toFixed(2),
            },
            ScanOp: {
              ...budgetItem.ScanOp,
              value_heure: (totalHeureIndex - 2).toFixed(2),
            },
            Superviseur: {
              ...budgetItem.Superviseur,
              value_heure: 2,
            },
          };
        }

        if (index === 0) {
          return {
            ...budgetItem,
            GD: {
              ...budgetItem.GD,
              value_heure: totalValues.totalGD.toFixed(2),
            },
            GE: {
              ...budgetItem.GE,
              value_heure: totalValues.totalGE.toFixed(2),
            },
            SE: {
              ...budgetItem.SE,
              value_heure: totalValues.totalSE.toFixed(2),
            },
            ScanOp: {
              ...budgetItem.ScanOp,
              value_heure: totalValues.totalScanOp.toFixed(2),
            },
            Superviseur: {
              ...budgetItem.Superviseur,
              value_heure: totalValues.totalSuperviseur.toFixed(2),
            },
          };
        }

        const value_GD = !budgetItem.GD["checked"]
          ? (totalHeureIndex *
              extractNumber(budgetItem.GD["value_percentage"])) /
            100
          : budgetItem.GD["value_heure"];

        const percentage_GD = budgetItem.GD["checked"]
          ? totalHeureIndex > 0
            ? parseFloat((value_GD / totalHeureIndex) * 100).toFixed(2)
            : 0
          : budgetItem.GD["value_percentage"];

        const value_GE = !budgetItem.GE["checked"]
          ? (totalHeureIndex *
              extractNumber(budgetItem.GE["value_percentage"])) /
            100
          : budgetItem.GE["value_heure"];

        const percentage_GE = budgetItem.GE["checked"]
          ? totalHeureIndex > 0
            ? parseFloat((value_GE / totalHeureIndex) * 100).toFixed(2)
            : 0
          : budgetItem.GE["value_percentage"];

        const value_SE = !budgetItem.SE["checked"]
          ? (totalHeureIndex *
              extractNumber(budgetItem.SE["value_percentage"])) /
            100
          : budgetItem.SE["value_heure"];

        const percentage_SE = budgetItem.SE["checked"]
          ? totalHeureIndex > 0
            ? parseFloat((value_SE / totalHeureIndex) * 100).toFixed(2)
            : 0
          : budgetItem.SE["value_percentage"];

        const value_ScanOp = !budgetItem.ScanOp["checked"]
          ? (totalHeureIndex *
              extractNumber(budgetItem.ScanOp["value_percentage"])) /
            100
          : budgetItem.ScanOp["value_heure"];

        const percentage_ScanOp = budgetItem.ScanOp["checked"]
          ? totalHeureIndex > 0
            ? parseFloat((value_ScanOp / totalHeureIndex) * 100).toFixed(2)
            : 0
          : budgetItem.ScanOp["value_percentage"];

        const value_Superviseur = !budgetItem.Superviseur["checked"]
          ? (totalHeureIndex *
              extractNumber(budgetItem.Superviseur["value_percentage"])) /
            100
          : budgetItem.Superviseur["value_heure"];

        const percentage_Superviseur = budgetItem.Superviseur["checked"]
          ? totalHeureIndex > 0
            ? parseFloat((value_Superviseur / totalHeureIndex) * 100).toFixed(2)
            : 0
          : budgetItem.Superviseur["value_percentage"];

        return {
          ...budgetItem,
          GD: {
            ...budgetItem.GD,
            value_heure: parseFloat(value_GD).toFixed(2),
            value_percentage: percentage_GD,
          },
          GE: {
            ...budgetItem.GE,
            value_heure: parseFloat(value_GE).toFixed(2),
            value_percentage: percentage_GE,
          },
          SE: {
            ...budgetItem.SE,
            value_heure: parseFloat(value_SE).toFixed(2),
            value_percentage: percentage_SE,
          },
          ScanOp: {
            ...budgetItem.ScanOp,
            value_heure: parseFloat(value_ScanOp).toFixed(2),
            value_percentage: percentage_ScanOp,
          },
          Superviseur: {
            ...budgetItem.Superviseur,
            value_heure: parseFloat(value_Superviseur).toFixed(2),
            value_percentage: percentage_Superviseur,
          },
        };
      });
    });

    setSaveBudget(true);
    setUpdatedOffre(true);
    setIsOpen(false);
  };

  useEffect(() => {
    if (saveBudget) {
      saveBudgetOffre(
        data.client_id,
        organizationId,
        updatedDataOffre,
        budgetCategory,
        updatedCategoryRoleList,
        updatedCollaboratorsList,
        "BUDGET_GLOBAL_COMPLETED",
        year,
        token
      )
        .then((resp) => {
          if (resp.data.result === "OK") {
            toast.success(_("successfully_added"));
          }
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          )
            toast.error(_(e.response.data.errors[0].message));
          else toast.error(_("error"));
        });
    }
    setSaveBudget(false);
  }, [saveBudget]);

  const handleColumns = (column, item, index1) => {
    return (
      <>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <input
            type="text"
            className={classNames(
              styles.input_box,
              styles.label,
              styles.disabled
            )}
            style={{ textAlign: "center", width: "50px" }}
            value={item[column.label]}
            required={true}
          ></input>
        </div>
      </>
    );
  };
  const handleColumnsPopup = (column, item, index1) => {
    return (
      <>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: item.label === "Total" && "-12px",
            // justify-content: center;
            // display: flex;
            // align-items: center;
            // width: 100%;
          }}
        >
          {/* {item.label !== "Total" && (
            <div >
              <input
                type="checkbox"
                checked={item.checked}
                onChange={() =>
                  handleInputChange(
                    !item.checked,
                    item.label,
                    "checked",
                    index1,
                    ""
                  )
                }
              />
            </div>
          )} */}
          {
            // item.label === "Total" && (
            <input
              type="text"
              className={classNames(styles.input_box, styles.label)}
              style={{ width: "71px" }}
              value={
                (item.label === "Total" ? item.total_heure : item.value_heure) +
                " h"
              }
              required={true}
              onChange={(event) =>
                handleInputChange(
                  event.target.value,
                  item.label,
                  item.label === "Total" ? "total_heure" : "value_heure",
                  index1,
                  "%"
                )
              }
            />
          }
          <div className={styles.percentage_label}>
            {item.value + " %"}
            {/* <input
              type="text"
              className={classNames(
                styles.input_box,
                styles.label,
                !item.checked && styles.disabled
                // item.label === "Total" &&
                //   item.value !== 100 &&
                //   item.value !== "100" &&
                //   styles.insucess_total
              )}
              style={{ width: "80px" }}
              value={item.value + " %"}
              required={true}
              onChange={(event) =>
                handleInputChange(
                  event.target.value,
                  item.label,
                  "value",
                  index1,
                  "%"
                )
              }
            /> */}
          </div>
        </div>
      </>
    );
  };
  const handleModalOffrePrix = () => {
    return (
      <Modal
        isOpen={isOpen}
        // onRequestClose={close}
        className={{
          base: styles.modalContent,
          afterOpen: styles.modalContentAfterOpen,
          beforeClose: styles.modalContentBeforeClose,
        }}
        overlayClassName={{
          base: styles.modalOverlay,
          afterOpen: styles.modalOverlayAfterOpen,
          beforeClose: styles.modalOverlayBeforeClose,
        }}
        closeTimeoutMS={300}
        contentLabel="Add media"
      >
        <div className={styles.modal}>
          <div className={styles.body}>
            <div className={styles.title}>
              <span style={{ color: "#18A0FB" }}>
                Modifier l'offre de prix du client{" "}
              </span>
              <span>{clientName} </span>
              <div className={styles.close} onClick={() => setIsOpen(false)}>
                x
              </div>
            </div>

            <div className={styles.table}>
              <TableBorderedColumns
                headerHeight={"58px"}
                height={"55px"}
                bordrerdcolumns={bordrerdcolumnsOffre}
                handleColumns={handleColumnsPopup}
                tableItems={updatedDataOffre}
                isGestionTask={false}
                widthColumns={"35%"}
              />
              <button
                className={classNames(
                  styles.save_button,
                  enableToSave && styles.enabled_button
                )}
                onClick={() => !enableToSave && handleSaveBudgetOffre()}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      {handleModalOffrePrix()}
      <div style={{ display: "flex" }}>
        <div className={styles.title}>BUDGET GLOBAL</div>
        <div className={styles.title} style={{ marginLeft: "auto" }}>
          <button
            className={styles.button_offre}
            onClick={() => setIsOpen(true)}
          >
            <IconPencil />
            <span>Modifier l’offre de prix {year}</span>
          </button>
        </div>
      </div>
      <div className={classNames(styles.all, "grid-x")}>
        <div
          className={classNames(
            styles.list_grid,
            "cell small-6 medium-6 large-6"
          )}
        >
          <div className={styles.volumetrie}>
            <div className={styles.title}>
              <span>Temps budgétés</span>
              <div className={styles.description}>
                Petite description à prévoir ici...
              </div>
            </div>
            <TableBorderedColumns
              headerHeight={"58px"}
              height={"40px"}
              bordrerdcolumns={bordrerdcolumns}
              handleColumns={handleColumns}
              tableItems={updatedDataCategoryBudget}
              isGestionTask={false}
              widthColumns={"40%"}
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.list_grid,
            "cell small-6 medium-6 large-6"
          )}
        >
          <div className={styles.volumetrie}>
            <div
              className={styles.title}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "70%",
              }}
            >
              <span>Réalisé économique {year - 1}</span>
              <div className={styles.description}>
                {data.economic_info.sum_invoice}
                {" €"}
              </div>
            </div>
            <div
              className={styles.title}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "70%",
              }}
            >
              <span>Budget économique {year}</span>
              <div className={styles.description}>
                {data.economic_info.economic_budget}
                {" €"}
              </div>
            </div>

            {/* <TableBorderedColumns
              headerHeight={"58px"}
              height={"40px"}
              bordrerdcolumns={bordrerdcolumns}
              handleColumns={handleColumns}
              tableItems={data.economie_list}
              isGestionTask={false}
              widthColumns={"40%"}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalBudget;
