import styles from './index.module.scss';
import { useOuterClick } from '../../../../../utils/hooks';
import { CheckFilledIcon, UnCheckFilledIcon } from '../../../../common/Icons';
import { minutesToTime } from '../../../../../utils';
import { BorderedCard } from './..';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { CloseOutlined } from '@ant-design/icons';

export default function Sidebar({
  setShowSidebar,
  list,
  setList,
  eligible
}) {
  const innerRef = useOuterClick(() => {
    setShowSidebar(false);
  });

  return (
    <aside className={styles.sidebar} ref={innerRef}>
      <div className={styles.sidebar_header}>
        <h4 className={styles.title}>Éligible {eligible}/5</h4>
        <CloseOutlined
          className={styles.close_icon}
          onClick={() => {
            setShowSidebar(false)
          }}
        />
      </div>

      <div className={styles.checks}>
        {list.map(item => (
          <BorderedCard>
            <div
              className={styles.check_item}
              onClick={() => {
                const newVal = list.map(checkItem => {
                  if (checkItem.key === item.key) {
                    return {...checkItem, isOpened: !checkItem.isOpened};
                  } else {
                    return {...checkItem}
                  }
                })
                setList(newVal)
              }}
            >
              <div className={styles.check_title}>
                {item.isCompleted ?
                  (<CheckFilledIcon size={29} color='#02AF8E'/>) :
                  (<UnCheckFilledIcon size={24} color='#DE4848'/>)
                }
                <p className={styles.check_label}>{item.title}</p>
                {item.isOpened ?
                  (<FiChevronUp
                    size={16}
                    color='#29394D'
                    className={styles.check_info_icon}
                  />) :
                  (<FiChevronDown
                    size={16}
                    color='#29394D'
                    className={styles.check_info_icon}
                  />
                )
                }
              </div>

              <div className={styles.realized_values}>
                <p className={styles.realized_title}>
                  Réalisé:
                </p>
                <p className={styles.realized_per_week}>
                  {minutesToTime(item.weeklyValue)}/semaine
                </p>
                <p className={styles.realized_per_month}>
                  {minutesToTime(item.monthlyValue)}/mois
                </p>
              </div>

              {item.isOpened && (
                <div className={styles.check_description}>
                  {item.description}
                </div>
              )}
            </div>
          </BorderedCard>
        ))}

        <div className={styles.realized_values}>
          <p className={styles.realized_title}>
            Le total réalisé:
          </p>
          <p className={styles.realized_per_week}>
            {minutesToTime(list.reduce((total, item) => total + item.weeklyValue, 0))}/semaine
          </p>
          <p className={styles.realized_per_month}>
            {minutesToTime(list.reduce((total, item) => total + item.monthlyValue, 0))}/mois
          </p>
        </div>

        <div className={styles.total_remark_text}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2689_58551)">
              <path d="M8.00065 10.6666V7.99992M8.00065 5.33325H8.00732M14.6673 7.99992C14.6673 11.6818 11.6825 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6825 1.33325 14.6673 4.31802 14.6673 7.99992Z" stroke="#29394D" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_2689_58551">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>Le total général des heures par mois est <span>d'un maximum 30 heures</span>.</p>
        </div>

      </div>
    </aside>
  )
}
