import React, { useState, useEffect, useRef } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import { Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import classNames from "classnames";
import {
  IconPlus,
  IconTrash,
  IconPencil,
  RefreshIcon,
  SyncSlashIcon,
  SyncIcon,
} from "../../common/Icons";
import { ModalConfirm } from "tamtam-components";
import { renderAvatar } from "../../../utils";
import { colors } from "../../../config";
import { deleteCollaboratorPlanner, saveCollaboratorsPlanner} from "../../../api";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/msal-config";
import { createEvent, deleteEvent } from "../../../api/msal-graph";
import { useIsAuthenticatedWithToken } from "../../../utils/hooks";
import { Popconfirm } from "antd";
import { composeEventForOutlook, handleOutlookLogin } from "../../../utils/outlook";
import { useSelector } from "react-redux";
import AnimatedLoadingIcon2 from "../../common/Icons/AnimatedLoadingIcon2";
import AnimatedLoadingIcon from "../../common/Icons/AnimatedLoadingIcon";

const Item = ({
  events,
  setEvents,
  backupEvents,
  token,
  organizationId,
  setOpenAddEvent,
  setSelectedDateEvent,
  renderDurationDisplay,
  formatDuration,
  timeSheetByDays,
  holidays,
  durationsByDay,
  workingHours,
  dayMapping,
  day,
  dayIndex,
  setSelectedEventId,
  weekIndex,
  collapsedWeeks,
  onClickEvent,
  selectedCollaboratorBackupTabs,
  backupInformations,
  collaborator
}) => {
  const daysOfWeek = [
    { index: 0, name: "Lundi" },
    { index: 1, name: "Mardi" },
    { index: 2, name: "Mercredi" },
    { index: 3, name: "Jeudi" },
    { index: 4, name: "Vendredi" },
  ];
  const [isDeleteEvent, setIsDeleteEvent] = useState(false);
  const [selectedEventToDelete, setSelectedEventToDelete] = useState([]);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [openConfirmDeletionWithoutOutlookAssociationModal, setOpenConfirmDeletionWithoutOutlookAssociationModal] = useState(false);
  const allEvents = [];
  const tooltipRef = useRef(null);
  const isAuthenticatedWithTokenToMsal = useIsAuthenticatedWithToken();
  const { instance, accounts } = useMsal();
  const [isToastAssociateOutlook, setIsToastAssociateOutlook] = useState(false);
  const [synchingEventId, setSynchingEventId] = useState(null);

  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  };
  useOutsideClick(tooltipRef, () => setTooltipVisible(false));

  const deletePlanner = async (event) => {
    if (event && token && organizationId) {
      try {
        setIsDeleteEvent(false);
        setOpenConfirmDeletionWithoutOutlookAssociationModal(false);
        // removing event from Microsoft Outlook Calendar
        if (isAuthenticatedWithTokenToMsal) {
          const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          await deleteEvent(tokenResponse.accessToken, event.meetingId);
        }

        // remove planner from database
        const response = await deleteCollaboratorPlanner(
          event.id,
          organizationId,
          token
        );

        if (response.data.result === "OK") {
          const updatedEvents = events.filter((e) => e.id !== event.id);
          setEvents(updatedEvents);
          toast.success(_("saveSuccess"));
        } else {
          toast.error(_("savingError"), { autoClose: true });
          // toast.error("Configurer votre compte Outlook pour supprimer le planner aussi de votre compte Outlook.");
        }
      } catch (e) {
        toast.error(_("savingError"), { autoClose: true });
        return null;
      }
    }
  };
  // const createRecurringEvent = (event) => {
  //   const recurrenceDetails = {
  //     type: selectedRecurrenceType,
  //     day_of_month: selectedDayOfMonth,
  //     nth_week: selectedNthWeek,
  //     day_of_week: selectedDayOfWeek,
  //     recurrence_end_date: recurrenceEndDate,
  //   };
  //   let updatedEvent = {
  //     ...event,
  //     recurrenceDetails: recurrenceDetails,
  //   };
  //   let updatedEvents = [];
  //   setSelectedRecurrenceDetails(recurrenceDetails);

  //   updatedEvents = events.map((e) => (e.id === event.id ? updatedEvent : e));
  //   setEvents(updatedEvents);

  //   setSelectedDateEvent(moment(event.date.date).toDate());
  //   setSelectedDurationHours(Math.floor(event.duration / 60));
  //   setSelectedDurationMin(event.duration % 60);
  //   setSelectedDurationTravelHours(Math.floor(event.durationTravel / 60));
  //   setSelectedDurationTravelMin(event.durationTravel % 60);
  //   setSelectedStatus(event.status);
  //   setSelectedPlaceRDV(event.meetingPlace);
  //   setSelectedDescription(event.description);
  //   setSelectedTypeEvent(event.type);
  //   setSelectedTitleEvent(event.title);
  //   setSelectedEventId(event.id);
  //   setSelectedDuration(event.duration);
  //   setSelectedEvent(updatedEvent);
  // };
  const convertDecimalTime = (decimalTime) => {
    let hours = Math.floor(decimalTime);
    let decimalPart = decimalTime - hours;

    let minutes = Math.round(decimalPart * 60);

    return hours + "h " + minutes;
  };

  const generateRecurringEvents = (event) => {
    const recurrenceDetails = event.recurrenceDetails;
    if (!recurrenceDetails) return [];

    const occurrences = [];
    const startDate = moment(event.date.date, "YYYY-MM-DD");
    const endDate = moment(recurrenceDetails.recurrence_end_date, "YYYY-MM-DD");
    let nextOccurrence = startDate.clone();

    if (recurrenceDetails.type === "weekly") {
      while (nextOccurrence.isBefore(endDate)) {
        recurrenceDetails.day_of_week.forEach((day) => {
          let occurrence = nextOccurrence.clone().day(day);
          if (
            occurrence.isSameOrAfter(event.date.date) &&
            occurrence.isBefore(endDate) &&
            occurrence.isAfter(event.date.date)
          ) {
            occurrences.push({
              ...event,
              date: { date: occurrence.format("YYYY-MM-DD") },
            });
          }
        });
        nextOccurrence.add(1, "week");
      }
    }

    if (recurrenceDetails.type === "monthly") {
      while (nextOccurrence.isBefore(endDate)) {
        let firstDayOfMonth = nextOccurrence.clone().startOf("month");

        let occurrence = firstDayOfMonth
          .clone()
          .day(recurrenceDetails.day_of_week[0]);

        if (occurrence.isBefore(firstDayOfMonth)) {
          occurrence.add(7, "days");
        }
        occurrence.add((recurrenceDetails.day_of_month - 1) * 7, "days");

        if (
          occurrence.isSameOrAfter(startDate) &&
          occurrence.isBefore(endDate)
        ) {
          occurrences.push({
            ...event,
            date: { date: occurrence.format("YYYY-MM-DD") },
          });
        }

        nextOccurrence.add(1, "month");
      }
    }

    return occurrences;
  };

  events.forEach((event) => {
    const recurringEvents = generateRecurringEvents(event, day);
    allEvents.push(...recurringEvents, event);
  });

  const handleEventDeletion = (eventToDelete) => {
    setSelectedEventToDelete(eventToDelete);

    if (!eventToDelete.meetingId) {
      setIsDeleteEvent(true);
      return;
    }

    if (!isAuthenticatedWithTokenToMsal) {
      setOpenConfirmDeletionWithoutOutlookAssociationModal(true);
    } else {
      setIsDeleteEvent(true);
    }
  }

  const handleSyncEvent = async (eventData) => {
    setSynchingEventId(eventData.id);
    if (isAuthenticatedWithTokenToMsal) {
      let attendees = [];

      // if (collaborator) {
      //   attendees.push({
      //     emailAddress: {
      //       address: collaborator.email,
      //       name: collaborator.name,
      //     },
      //     type: "required",
      //   })
      // }

      let additionalEmailsList = null;
      if (eventData.additionalEmails) {
        additionalEmailsList = eventData.additionalEmails.split("|");
        additionalEmailsList.forEach((email) => {
          attendees.push({
            emailAddress: {
              address: email,
            },
            type: "required",
          })
        });
      }

      const eventDetails = composeEventForOutlook({
        type: eventData.type,
        title: eventData.title,
        meetingPlace: eventData.meetingPlace,
        collaborators: eventData.collaborators,
        clientName: eventData.client_name,
        selectedColor: eventData.color,
        date: moment(eventData.date?.date),
        timezone: collaborator.zoneId === 1 ? "Europe/Brussels" : "Africa/Casablanca",
        duration: eventData.duration,
        categoryName: eventData.category_name,
        description: eventData.description,
        attendees: attendees,
      });

      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const eventResponse = await createEvent(tokenResponse.accessToken, eventDetails);

      // save event meetingId and meetingUrl
      const updatedEvent = {
        ...eventData,
        meetingId: eventResponse.id,
        meetingUrl: eventResponse?.onlineMeeting?.joinUrl
      };

      saveCollaboratorsPlanner(
        updatedEvent.id,
        organizationId,
        updatedEvent.type,
        updatedEvent.title,
        updatedEvent.meetingPlace,
        collaborator.id,
        updatedEvent.collaborators,
        updatedEvent.client_id,
        updatedEvent.category_id,
        updatedEvent.status,
        moment(updatedEvent.date.date).format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        updatedEvent.description,
        updatedEvent.color,
        updatedEvent.recurrenceDetails,
        updatedEvent.balanceInfo,
        updatedEvent.duration,
        updatedEvent.durationTravel,
        updatedEvent.parentId,
        moment(updatedEvent.date.date).year(),
        token,
        updatedEvent.meetingId,
        updatedEvent.meetingUrl,
        additionalEmailsList,
        updatedEvent.autoSendClientMeetingLink
      ).then((response) => {
        if (response.data.result === "OK") {
          toast.success(_("saveSuccess"));
          setEvents(prevState => {
            return prevState.map((ev) => {
              if (ev.id === updatedEvent.id) {
                return updatedEvent;
              }
              return ev;
            });
          })
        } else {
          toast.error(_("savingError"), { autoClose: true });
        }
      }).finally(() => {
        setSynchingEventId(null);
      })
    }
  }

  // const cardEvents = (events, isBackup) => {
  //   const avatar = isBackup
  //     ? backupInformations.backupAvatar
  //     : backupInformations.collaboratorAvatar;
  //   const name = isBackup
  //     ? backupInformations.backupName
  //     : backupInformations.collaboratorName;
  //   return (
  //     <div>
  //       {events
  //         .filter(
  //           (event) =>
  //             event.date &&
  //             moment(moment(event.date.date, "Y-M-D").toDate()).isSame(
  //               day,
  //               "day"
  //             )
  //         )
  //         .map((filteredEvent, eventIndex) => (
  //           <div key={eventIndex} className={`event ${filteredEvent.status}`}>
  //             <div className="event_type">
  //               <div className="line_content">
  //                 {filteredEvent.type === "task" && (
  //                   <div className="circle">
  //                     <div
  //                       className={classNames(
  //                         "circle",
  //                         filteredEvent.status === "done" ||
  //                           filteredEvent.status === "Terminé"
  //                           ? "tag-success"
  //                           : filteredEvent.status === "inprogress" ||
  //                             filteredEvent.status === "En cours"
  //                           ? "tag-warning"
  //                           : "tag-danger"
  //                       )}
  //                     ></div>
  //                   </div>
  //                 )}

  //                 <div className={`type`}>
  //                   {filteredEvent.type !== "meeting"
  //                     ? _(filteredEvent.type)
  //                     : _(filteredEvent.type) +
  //                       " : " +
  //                       _(filteredEvent.meetingPlace)}
  //                 </div>
  //                 {(!filteredEvent.parentId ||
  //                   !filteredEvent.parent_id ||
  //                   (filteredEvent.recurrenceDetails &&
  //                     filteredEvent.recurrenceDetails.type)) && <RefreshIcon />}
  //               </div>
  //               <div className="line_content">
  //                 <div
  //                   className={`type duration ${!isBackup && "item_duration"}`}
  //                 >
  //                   {formatDuration(filteredEvent.duration)}
  //                 </div>
  //                 {!isBackup && (
  //                   <div className="event_actions line_content">
  //                     <div onClick={() => onClickEvent(filteredEvent, true)}>
  //                       <IconPencil size={12} />
  //                     </div>

  //                     <div
  //                       onClick={() => {
  //                         setIsDeleteEvent(true);
  //                         setSelectedEventToDelete(filteredEvent);
  //                       }}
  //                     >
  //                       <IconTrash size={12} />
  //                     </div>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //             <div className="client_name">
  //               {filteredEvent.type !== "management"
  //                 ? filteredEvent.client_name
  //                 : filteredEvent.title}
  //             </div>
  //             {filteredEvent.type !== "meeting" && (
  //               <div className="line_space_content" style={{ width: "100%" }}>
  //                 <div className="category_name">
  //                   {filteredEvent.category_name}
  //                 </div>
  //                 <div>{renderAvatar(avatar, name)}</div>
  //               </div>
  //             )}
  //           </div>
  //         ))}
  //     </div>
  //   );
  // };

  const getColorById = (colorId) => {
    return colors.find((c) => c.id === colorId) || {};
  };
  const cardOneEvent = (filteredEvent, isBackup, eventIndex) => {
    const hasBackups =
      Array.isArray(backupInformations.backupName) &&
      backupInformations.backupName.length > 0;

    let backupTab = {
      id: 2,
      name: hasBackups
        ? backupInformations.backupName.length === 1
          ? backupInformations.backupName[0]
          : "Autres"
        : null,
      avatar: backupInformations.backupAvatar,
    };

    const collaboratorBackupTabs = [
      { id: 0, name: "All" },
      {
        id: 1,
        name: backupInformations.collaboratorName,
        avatar: backupInformations.collaboratorAvatar,
      },
      backupTab,
    ];

    const avatar = isBackup
      ? backupInformations.backupAvatar
      : backupInformations.collaboratorAvatar;
    const name = isBackup
      ? hasBackups
        ? backupInformations.backupName.length === 1
          ? backupInformations.backupName[0]
          : "Autres"
        : null
      : backupInformations.collaboratorName;

    const { backgroundColor, color } = getColorById(filteredEvent.color);

    return (
      <div>
        <div
          key={eventIndex}
          className={classNames(
            "event",
            filteredEvent.type !== "meeting" &&
              filteredEvent.type !== "task" &&
              filteredEvent.type !== "management" &&
              "balance_event",
            filteredEvent.status
          )}
          style={{
            background: backgroundColor,
          }}
        >
          <div className="event_type">
            <div className="line_content">
              {filteredEvent.type === "task" && (
                <div className="circle">
                  <div
                    className={classNames(
                      "circle",
                      filteredEvent.status === "done" ||
                        filteredEvent.status === "Terminé"
                        ? "tag-success"
                        : filteredEvent.status === "inprogress" ||
                          filteredEvent.status === "En cours"
                        ? "tag-warning"
                        : "tag-danger"
                    )}
                  ></div>
                </div>
              )}

              <div className="type">
                {filteredEvent.type !== "meeting"
                  ? _(filteredEvent.type)
                  : _(filteredEvent.type) +
                    " : " +
                    _(filteredEvent.meetingPlace)}
              </div>

              {filteredEvent.parentId ||
              filteredEvent.parent_id ||
              (filteredEvent.recurrenceDetails &&
                filteredEvent.recurrenceDetails.type) ? (
                <RefreshIcon />
              ) : null}
            </div>
            <div className="line_content">
              {filteredEvent.type !== "balanceEcheance" &&
                filteredEvent.type !== "intermediateSituationBalance" && (
                  <div
                    className={`type duration ${!isBackup && "item_duration"}`}
                  >
                    {formatDuration(filteredEvent.duration)}
                  </div>
                )}
              {!isBackup && (
                <div className="event_actions line_content">
                  <div onClick={() => onClickEvent(filteredEvent, true)}>
                    <IconPencil size={12} />
                  </div>

                  <div
                    onClick={() => handleEventDeletion(filteredEvent)}
                  >
                    <IconTrash size={12} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="client_info">
            <div className="client_name">
              <div>
                {filteredEvent.type !== "management"
                  ? filteredEvent.client_name
                  : filteredEvent.title}
              </div>
              {filteredEvent.type === "meeting" && filteredEvent.avatars && (
                <div style={{ width: "100%", display: "flex" }}>
                  {filteredEvent.avatars &&
                    filteredEvent.avatars.map((item) => (
                      <div>{renderAvatar(item.avatar, item.name)}</div>
                    ))}
                </div>
              )}
            </div>
            {!filteredEvent.meetingId && !isBackup && (
              <>
                {!isAuthenticatedWithTokenToMsal ? (
                  <Popconfirm
                    placement="bottomRight"
                    title="Associer votre compte Outlook tout d'abord."
                    onConfirm={() => handleOutlookLogin("popup", instance, loginRequest)}
                    okText="Associer"
                    cancelText="Non"
                    onCancel={() => { setIsToastAssociateOutlook(false); }}
                  >
                    <SyncSlashIcon
                      color="#de4848"
                      className="event_not_synched_icon"
                    />
                  </Popconfirm>
                ) : (
                  synchingEventId === filteredEvent.id ? (
                    <SyncIcon
                      fill="#02af8e"
                      className="event_not_synched_icon"
                      size={14}
                      withAnimation={true}
                    />
                  ) : (
                    <SyncSlashIcon
                      color="#de4848"
                      size={14}
                      className={classNames({
                        "event_not_synched_icon": true,
                        "event_not_synched_icon_not_allowed": synchingEventId !== null && synchingEventId !== filteredEvent.id,
                      })}
                      onClick={() => {
                        if (synchingEventId !== null && synchingEventId !== filteredEvent.id) {
                          return;
                        } else {
                          handleSyncEvent(filteredEvent);
                        }
                      }}
                    />
                  )
                )}
              </>
            )}
          </div>
          {filteredEvent.type !== "balancePresentation" &&
            filteredEvent.type !== "balanceEcheance" &&
            filteredEvent.type !== "intermediateSituationBalance" &&
            filteredEvent.type !== "meeting" && (
              <div className="line_space_content" style={{ width: "100%" }}>
                <div className="category_name">
                  {filteredEvent.category_name}
                </div>
                <div>{renderAvatar(avatar, name)}</div>
              </div>
            )}
        </div>
      </div>
    );
  };

  return (
    <div className="calendar_columns" key={dayIndex}>
      <div className={classNames("day_label")}>{day.format("dddd")}</div>
      <div className={classNames("day_label")}>{day.format("DD")}</div>
      <div
        className={classNames(
          "day_content",
          day.isSame(moment(), "day") ? "selected_day" : ""
        )}
      >
        <div className="day_header line_space_content">
          <div
            onClick={() => {
              setOpenAddEvent(true);
              const today = moment();
              const selectedDateWithTime = moment(day)
                .hours(today.hours())
                .minutes(today.minutes())
                .second(today.seconds())
                .millisecond(0);
              setSelectedDateEvent(selectedDateWithTime);
              setSelectedEventId(null);
            }}
          >
            <IconPlus size={13} />
          </div>

          <div className="realised">
            Realisé:
            {timeSheetByDays.filter(
              (task) =>
                task.date &&
                moment(task.date.replace(" ", "T") + "Z").isSame(day, "day")
            ).length > 0 ? (
              timeSheetByDays
                .filter((filteredEvent) =>
                  moment(filteredEvent.date.replace(" ", "T") + "Z").isSame(
                    day,
                    "day"
                  )
                )
                .map((filteredEvent, eventIndex) => (
                  <>
                    {renderDurationDisplay(
                      filteredEvent.total_task_duration * 60
                    )}
                  </>
                ))
            ) : (
              <>{renderDurationDisplay(0)}</>
            )}
          </div>

          <div
            className="disponibility"
            style={{
              background:
                holidays.filter((event) =>
                  moment(event.date).isSame(day, "day")
                ).length > 0 && "rgb(255, 226, 221)",
              color:
                holidays.filter((event) =>
                  moment(event.date).isSame(day, "day")
                ).length > 0 && "rgb(93, 23, 21)",
            }}
          >
            {holidays.filter((event) => moment(event.date).isSame(day, "day"))
              .length > 0
              ? holidays
                  .filter((event) => moment(event.date).isSame(day, "day"))
                  .map((holiday, holidayIndex) => (
                    <div
                      className="filteredEvent"
                      key={holidayIndex}
                      style={{
                        background: "rgb(255, 226, 221)",
                        backgroundColor: holiday.key,
                        color: "rgb(93, 23, 21)",
                      }}
                    >
                      {holiday.label.slice(0, 14)}
                    </div>
                  ))
              : durationsByDay &&
                durationsByDay.filter((duration) =>
                  moment(duration.date).isSame(day, "day")
                ).length > 0
              ? durationsByDay
                  .filter((duration) =>
                    moment(duration.date).isSame(day, "day")
                  )
                  .map((holiday, holidayIndex) => (
                    <div
                      className="filteredEvent"
                      key={holidayIndex}
                      style={{ background: holiday.key }}
                    >
                      {formatDuration(holiday.duration)}
                    </div>
                  ))
              : formatDuration(0)}
            {holidays.filter((event) => moment(event.date).isSame(day, "day"))
              .length === 0 && (
              <>
                {" "}
                sur{" "}
                {convertDecimalTime(
                  workingHours[dayMapping[moment(day).format("ddd")]]
                )}
              </>
            )}
          </div>
        </div>
        <div className="events">
          <Droppable droppableId={day.format("YYYY-MM-DD")}>
            {(provided) => (
              <div
                className="events"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {events
                  .filter((event) => moment(event.date.date).isSame(day, "day"))
                  .map((event, index) => {
                    return (
                      <Draggable
                        key={`${day.format("YYYY-MM-DD")}-${
                          event.id
                            ? event.id.toString()
                            : event.parentId
                            ? event.parentId.toString()
                            : event.date.date
                        }`}
                        draggableId={
                          event.id
                            ? event.id.toString()
                            : event.parentId
                            ? event.parentId.toString()
                            : event.date.date
                        }
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className={classNames(
                                collapsedWeeks[weekIndex] ? "collapsed" : ""
                              )}
                            >
                              {selectedCollaboratorBackupTabs !== 2 &&
                                cardOneEvent(
                                  {
                                    ...event,
                                    avatars: events[index]["avatars"],
                                  },
                                  false,
                                  index
                                )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {selectedCollaboratorBackupTabs !== 1 &&
                  backupEvents
                    .filter((event) =>
                      moment(event.date.date).isSame(day, "day")
                    )
                    .map((event, index) => (
                      <div
                        key={`backup-${event.id}`}
                        className={classNames(
                          "events",
                          collapsedWeeks[weekIndex] ? "collapsed" : ""
                        )}
                        style={{ minHeight: "10px" }}
                      >
                        {cardOneEvent(event, true, index)}
                      </div>
                    ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={isDeleteEvent}
        onCancel={() => {
          setIsDeleteEvent(false);
          setSelectedEventToDelete([]);
        }}
        onConfirm={() => {
          deletePlanner(selectedEventToDelete);
        }}
        inProcess={false}
        actionFailed={false}
        title={_("deleteConfirm")}
        text={_("confirmEventDelete")}
        labelNo={_("cancel")}
        labelYes={_("delete")}
        labelError={"Error"}
      />

      <ModalConfirm
        type={"delete"}
        isOpen={openConfirmDeletionWithoutOutlookAssociationModal}
        onCancel={(e) => {
          setOpenConfirmDeletionWithoutOutlookAssociationModal(false);
          // we delete the event only if the user clicks over the button and not the icon at the top.
          if (e.target.nodeName === 'BUTTON') {
            deletePlanner(selectedEventToDelete);
          }
        }}
        onConfirm={() => {
          setOpenConfirmDeletionWithoutOutlookAssociationModal(false);
          handleOutlookLogin("popup", instance, loginRequest);
        }}
        inProcess={false}
        actionFailed={false}
        title="Attention: Votre compte Outlook n'est pas associé."
        text="Associez votre compte Outlook pour également supprimer le planner de votre compte."
        labelNo="Continuer"
        labelYes="Associer Outlook"
        labelError={"Error"}
      />
    </div>
  );
};

export default Item;
