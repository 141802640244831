import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import DrawerIframe from "../common/DrawerIframe";
import { TTP_TEAM_URL } from "../../config";
import PageHeader from "../common/PageHeader";
import { Button, Modal, Checkbox } from "antd";
import YearFilter from "../common/Filter/YearFilter/YearFilter";
import ZoneFilter from "../common/Filter/ZoneFilter/ZoneFilter";
import styles from './index.module.scss';
import { getAnnualCalendar, getCollaboratorsByZone, saveAnnualCalendarHolidayOutlookMappings } from "../../api";
import { renderAvatar } from "../../utils";
import moment from "moment";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/msal-config";
import { createEvent } from "../../api/msal-graph";
import { MsalSignInButton } from "../CollaboratorSpace/Planner/MsalSignInButton";
import { useIsAuthenticatedWithToken } from "../../utils/hooks";
import AnimatedLoadingIcon from "../common/Icons/AnimatedLoadingIcon";
import { MsalSignOutButton } from "../CollaboratorSpace/Planner/MsalSignOutButton";
import { toast } from "react-toastify";
import { CheckFilledIcon } from "../common/Icons";

const CalendarSettings = ({ children }) => {
  const { token, navCommunity, user } = useSelector((state) => state.auth);
  const [fiduciaryId, setFiduciaryId] = useState(null);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [collaboratorsByZone, setCollaboratorsByZone] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedZone, setSelectedZone] = useState(null);
  const [isSynchingEvents, setIsSynchingEvents] = useState(false);
  const isAuthenticatedToMsal = useIsAuthenticatedWithToken();
  const { instance, accounts } = useMsal();
  const [annualCalendarData, setAnnualCalendarData] = useState(null);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);

  const handleCollaboratorSelect = (collaboratorId, isSelected) => {
    setSelectedCollaborators(prev => ({
      ...prev,
      [collaboratorId]: isSelected
    }));
  };

  const handleSelectAll = (checked) => {
    const newSelection = collaboratorsByZone.reduce((acc, col) => ({
      ...acc,
      [col.id]: checked
    }), {});
    setSelectedCollaborators(newSelection);
  };

  const isAllSelected = useMemo(() => {
    return collaboratorsByZone.every(col => selectedCollaborators[col.id]);
  }, [selectedCollaborators, collaboratorsByZone]);

  useEffect(() => {
    if (navCommunity) {
      setFiduciaryId(navCommunity.id);
    }
  }, [navCommunity]);

  useEffect(() => {
    getCollaboratorsByZone({
      token,
      organizationId: navCommunity.id,
      zoneId: selectedZone?.value ?? 1
    }).then((response) => {
      if (response.data?.data) {
        setCollaboratorsByZone(response.data.data);
        setSelectedCollaborators(() => {
          // Initialize with all collaborators selected by default
          return response.data.data.reduce((acc, col) => ({
            ...acc,
            [col.id]: true
          }), {});
        })
      }
    });
  }, [selectedZone]);

  useEffect(() => {
    getAnnualCalendar({
      token,
      organizationId:
      navCommunity.id,
      year: selectedYear,
      zone: selectedZone?.value
    }).then((response) => {
      setAnnualCalendarData(response.data.data);
    })

  }, [token, navCommunity, selectedYear, selectedZone]);

  const params = [];
  let powerTeamUrl = TTP_TEAM_URL + "/calendar-settings";

  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id ===navCommunity.id
        ).authorisation.preferences
      : {};

  function groupDateRangesByKey(dates) {
    if (!dates.length) return [];

    // Sort dates in ascending order
    dates.sort((a, b) => new Date(a.date) - new Date(b.date));

    let result = [];
    let currentGroup = {
      start: new Date(dates[0].date),
      end: new Date(dates[0].date),
      label: dates[0].label,
      key: dates[0].key
    };

    for (let i = 1; i < dates.length; i++) {
      let currentDate = new Date(dates[i].date);
      let previousDate = new Date(dates[i - 1].date);
      let currentKey = dates[i].key;
      let previousKey = dates[i - 1].key;

      // Check if current date is consecutive to previous date AND has the same label
      if ((currentDate - previousDate) === 86400000 && currentKey === previousKey) {
        currentGroup.end = currentDate;
      } else {
        // Add the previous group to the result and start a new one
        result.push({
          start: currentGroup.start.toISOString(),
          end: currentGroup.end.toISOString(),
          label: currentGroup.label,
          key: currentGroup.key
        });

        // Start a new group
        currentGroup = {
          start: currentDate,
          end: currentDate,
          key: currentKey,
          label: dates[i].label
        };
      }
    }

    // Push the last range
    result.push({
      start: currentGroup.start.toISOString(),
      end: currentGroup.end.toISOString(),
      label: currentGroup.label,
      key: currentGroup.key
    });

    return result;
  }

  const syncHolidayes = () => {
    setIsSynchingEvents(true);
    getAnnualCalendar({token, organizationId: navCommunity.id, year: selectedYear, zone: selectedZone?.value})
    .then((response) => {
      const annualCalendarId = response.data.data?.id;
      const annualCalendar = response.data.data?.planning;

      if (annualCalendar) {
        const holidays = annualCalendar.holidays;
        const annualHolidays = annualCalendar.annualHolidays;
        const holidaysData = {
          yearlyHolidays: {
            label: 'Congé annuel',
            data: annualHolidays,
            color: "orange",
          },
          holidays: {
            label: 'Jour férié',
            data: holidays,
            color: "red",
          }
        };

        let counter = 0;

        const outlookEventMappings = [];
        async function processHolidays(holidaysData) {
          for (const [_, value] of Object.entries(holidaysData)) {
            const events = value.data;
            const color = value.color;
            const label = value.label;

            for (const [monthAbbr, eventDays] of Object.entries(events)) {
              const consecutiveDatesByKey = groupDateRangesByKey(eventDays);

              for (const consecutiveDate of consecutiveDatesByKey) {
                if (counter % 4 === 0) {
                  // Waiting 1s to avoid rate limits...
                  await new Promise(resolve => setTimeout(resolve, 1000));
                }

                const startDate = moment(consecutiveDate.start).clone().utc().startOf('day');
                const endDate = moment(consecutiveDate.end).clone().utc().add(1, 'day').startOf('day');
                const title = `[${label}] - ${consecutiveDate.label}`;
                const key = consecutiveDate.key;
                const eventDetails = composeOutlookEvent(startDate, endDate, title, color, collaboratorsByZone);

                // console.log({eventDetails});

                try {
                  const response = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                  });

                  counter++;
                  const eventData = await createEvent(response.accessToken, eventDetails);
                  outlookEventMappings.push({
                    holidayKey: key,
                    holidayType: label,
                    outlookEventId: eventData.id,
                  })

                  // console.log("Event Created:", eventData);
                } catch (error) {
                  console.error("Error creating event:", error);
                }
              }
            }
          }
        }

        processHolidays(holidaysData)
          .then(() => {
            // save to db
            saveAnnualCalendarHolidayOutlookMappings({
              token,
              organizationId: navCommunity.id,
              annualCalendarId,
              mappings: outlookEventMappings
            })
          })
          .catch((e) => {
            toast.error("Erreur lors de la sauvegarde des congés.");
          }).finally(() => {
            setIsSynchingEvents(false);
          });
      }
    });
  };

  function composeOutlookEvent(startDate, endDate, label, color, attendees) {
    const eventDetails = {
      subject: label,
      isAllDay: true,
      start: {
        dateTime: startDate.format('YYYY-MM-DDTHH:mm:ss'),
        timeZone: selectedZone?.value === 1 ? "Europe/Brussels" : "Africa/Casablanca",
      },
      end: {
        dateTime: endDate.format('YYYY-MM-DDTHH:mm:ss'),
        timeZone: selectedZone?.value === 1 ? "Europe/Brussels" : "Africa/Casablanca",
      },
      categories: [color],
      showAs: 'busy',
      responseRequested: false,
      allowNewTimeProposals: false,
    };

    eventDetails.attendees = attendees
      .filter(colab => selectedCollaborators[colab.id])
      .filter(colab => colab.email !== null)
      .map(colab => ({
        emailAddress: {
          address: colab.email,
          name: colab.name
        },
        type: "required",
        status: {
          response: 'accepted'
        }
      }));

    return eventDetails;
  }

  if (fiduciaryId) {
    params.push("without_header");
    params.push("without_menu");
    params.push("organization_id=" + fiduciaryId);
    params.push("year=" + selectedYear);
    params.push("zoneId=" + selectedZone?.value);

    return (
      <>
        <PageHeader header="Gestion de calendrier">
          <div className={styles.filters}>
            <ZoneFilter onZoneChange={(value) => setSelectedZone(value)} />
            <YearFilter onYearChange={(value) => setSelectedYear(value)} />
            {isAuthenticatedToMsal ? (
              <>
                {annualCalendarData?.holiday_outlook_mappings?.length === 0 ? (
                  <Button onClick={() => setModalIsVisible(true)}>Synchroniser les congés avec Outlook</Button>
                ) : (
                  <p className="outlook-associated-text">
                    <CheckFilledIcon color="#02af8e" size={14} />
                    <span>Congés synchronisés avec Outlook</span>
                  </p>
                )}
              </>
            ) : (
              <MsalSignInButton />
            )}
          </div>
        </PageHeader>

        <DrawerIframe
          src={powerTeamUrl}
          params={params}
          hasAccess={preferences.allowAccessCalendarSetting}
        />

        <Modal
          className={styles.collaboratorsModal}
          open={modalIsVisible}
          title={
            <div className={styles.modalHeader}>
              <span>La liste des collaborateurs</span>
            </div>
          }
          onCancel={() => setModalIsVisible(false)}
          onOk={() => syncHolidayes()}
          centered
          bodyStyle={{
            height: '400px',
            overflow: 'scroll'
          }}
          confirmLoading={isSynchingEvents}
        >
          <div className={styles.collaboratorsList}>
            <p className={styles.title}>
              <div className={styles.selectAllWrapper}>
                <Checkbox
                  checked={isAllSelected}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                />
                {/* <span>Sélectionner tout</span> */}
              </div>
              Les collaborateurs qui seront synchroniser leurs congés avec Outlook
            </p>
            {collaboratorsByZone.map((col) => (
              <div key={col.id} className={styles.collaboratorItem}>
                <Checkbox
                  checked={selectedCollaborators[col.id] || false}
                  onChange={(e) => handleCollaboratorSelect(col.id, e.target.checked)}
                />
                <div className={styles.collaboratorAvatar}>
                  {renderAvatar(col.avatarUrl, col.name)}
                </div>
                <span className={styles.collaboratorName}>
                  {col.name}
                </span>
                <span className={styles.collaboratorEmail}>
                  ({col.email ?? "N/A"})
                </span>
                {col.isSynching && (
                  <span>
                    <AnimatedLoadingIcon color="#22bb33" />
                  </span>
                )}
              </div>
            ))}
          </div>
        </Modal>
      </>
    );
  }
  return <></>;
};

export default CalendarSettings;
