import styles from './index.module.scss';
import { formatAmount, formatDuration } from '../../../../../utils/index';
import CustomBarChart from "./components/CustomBarChart";
import CustomPieChart from "./components/CustomPieChart";
import { useState } from "react";
import { getTableColumns } from "./services";
import CustomTable from '../CustomTable';
import BonusViewFilter from './components/RatiosViewFilter';
import TableChartFilter, { CHART_VIEW, TABLE_VIEW } from '../TableChartFilter';

const DISPLAY_OPTIONS = [
  {
    value: 'en',
    label: 'Encodage/Nettoyage'
  },
  {
    value: 'nb',
    label: 'Nettoyage/Bilan'
  },
  {
    value: 'ns',
    label: 'Nettoyage/Situation'
  },
];

export default function Ratios({
  collaboratorId,
  period,
  setDeserveBonus,
  data
}) {
  const [dataToDisplay, setDataToDisplay] = useState(DISPLAY_OPTIONS[0].value);
  const [dataView, setDataView] = useState(TABLE_VIEW);

  const renderEncodageNettoyageTable = () => {
    return (
      <CustomTable
        columns={getTableColumns("nettoyage")}
        dataSource={data.items.map(item => ({
          clientName: item.clientName,
          encodage: formatDuration(item.encodageRealized),
          nettoyage: formatDuration(item.nettoyageRealized),
          ratio: formatAmount(item.encodageNettoyageRatio, false),
        }))}
      />
    );
  };

  const renderNettoyageBilanTable = () => {
    return (
      <CustomTable
        columns={getTableColumns("bilan")}
        dataSource={data.items.map(item => ({
          clientName: item.clientName,
          nettoyage: formatDuration(item.nettoyageRealized),
          bilan: formatDuration(item.bilanRealized),
          ratio: formatAmount(item.nettoyageBilanRatio, false),
        }))}
      />
    );
  };

  const renderNettoyageSituationTable = () => {
    return (
      <CustomTable
        columns={getTableColumns("situation")}
        dataSource={data.items.map(item => ({
          clientName: item.clientName,
          nettoyage: formatDuration(item.nettoyageRealized),
          situation: formatDuration(item.situationRealized),
          ratio: formatAmount(item.nettoyageSituationRatio, false),
        }))}
      />
    );
  };

  const renderEncodageNettoyageCharts = () => {
    return (
      <div className={styles.charts}>
        <div className={styles.bar_chart}>
          <CustomBarChart
            data={data.items.map(item => ({ name: item.clientName, ratio: item.encodageNettoyageRatio }))}
            chartName="Encodage/Nettoyage Ratio"
            referenceLineY={2}
          />
        </div>
        <div className={styles.pie_chart}>
          <CustomPieChart data={data.charts.en} />
        </div>
      </div>
    );
  };

  const renderNettoyageBilanCharts = () => {
    return (
      <div className={styles.charts}>
        <div className={styles.bar_chart}>
          <CustomBarChart
            data={data.items.map(item => ({ name: item.clientName, ratio: item.nettoyageBilanRatio }))}
            chartName="Nettoyage/Bilan Ratio"
            referenceLineY={4}
          />
        </div>
        <div className={styles.pie_chart}>
          <CustomPieChart data={data.charts.nb} />
        </div>
      </div>
    );
  };

  const renderNettoyageSituationCharts = () => {
    return (
      <div className={styles.charts}>
        <div className={styles.bar_chart}>
          <CustomBarChart
            data={data.items.map(item => ({ name: item.clientName, ratio: item.nettoyageSituationRatio }))}
            chartName="Nettoyage/Situation Ratio"
            referenceLineY={4}
          />
        </div>
        <div className={styles.pie_chart}>
          <CustomPieChart data={data.charts.ns} />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (dataToDisplay === 'en') {
      switch(dataView) {
        case TABLE_VIEW: return renderEncodageNettoyageTable();
        case CHART_VIEW: return renderEncodageNettoyageCharts();
        default: return null;
      }
    } else if (dataToDisplay === 'nb') {
      switch(dataView) {
        case TABLE_VIEW: return renderNettoyageBilanTable();
        case CHART_VIEW: return renderNettoyageBilanCharts();
        default: return null;
      }
    } else if (dataToDisplay === 'ns') {
      switch(dataView) {
        case TABLE_VIEW: return renderNettoyageSituationTable();
        case CHART_VIEW: return renderNettoyageSituationCharts();
        default: return null;
      }
    }

    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.filters}>
          <TableChartFilter
            onChange={(selectedView) => {
              setDataView(selectedView);
            }}
          />
          <BonusViewFilter
            options={DISPLAY_OPTIONS}
            onChange={(inputValue) => setDataToDisplay(inputValue)}
          />
        </div>

        {renderContent()}
      </div>
    </div>
  )
}
