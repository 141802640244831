import { colors } from "../config";

export function composeEventForOutlook({
  type,
  title,
  date,
  duration,
  timezone,
  clientName,
  categoryName,
  meetingPlace,
  selectedColor,
  attendees,
  absenceStatus,
  absenceType
}) {
  let startDate = date.clone();
  let endDate = date.clone();
  const minutesToAdd = duration;
  const eventDetails = {};

  const limitedDurationEvents = [
    "task",
    "meeting",
    "management",
    "balancePresentation",
  ];
  const allDayEvents = ["balanceEcheance", "intermediateSituationBalance"];

  if (limitedDurationEvents.includes(type)) {
    let composedTitle = "";
    endDate.add(minutesToAdd, "minutes");

    if (type === "task") {
      composedTitle = `[Tâche] ${clientName} - ${categoryName}`;
    } else if (type === "meeting") {
      composedTitle = `Rendez-vous avec ${clientName}`;
    } else if (type === "management") {
      composedTitle = `[Gestion] ${title}`;
    } else if (type === "balancePresentation") {
      composedTitle = `[Présentation Bilan] ${clientName}`;
    }

    eventDetails.subject = composedTitle;

    if (
      (type === "meeting" ||
        type === "balancePresentation") &&
        meetingPlace === "remote"
    ) {
      eventDetails.responseRequested = true;
      eventDetails.allowNewTimeProposals = true;
      eventDetails.isOnlineMeeting = true;
      eventDetails.onlineMeetingProvider = "teamsForBusiness";
    }
  } else if (allDayEvents.includes(type)) {
    let title = "";

    startDate.hour(0);
    startDate.minute(0);
    startDate.second(0);
    startDate.millisecond(0);

    endDate.add(1, "day");
    endDate.hour(0);
    endDate.minute(0);
    endDate.second(0);
    endDate.millisecond(0);

    if (type === "balanceEcheance") {
      title = `[Échéance Bilan] ${clientName} - ${categoryName}`;
    } else if (type === "intermediateSituationBalance") {
      title = `[Situation Intermédiaire] ${clientName} - ${categoryName}`;
    }

    eventDetails.subject = title;
    eventDetails.isReminderOn = true;
    eventDetails.isAllDay = true;
  } else if (type === "absence") {
    eventDetails.subject = title;
    if (!title) {
      if (absenceType === 'absence') {
        eventDetails.subject = 'Vacances annuelles';
      }
    }
    startDate.hour(0);
    startDate.minute(0);
    startDate.second(0);
    startDate.millisecond(0);

    if (absenceType === 'partial') {
      startDate.hour(8);
      startDate.minute(0);
      startDate.second(0);
      startDate.millisecond(0);
      endDate = startDate.clone();
      endDate.add(duration, "minutes");
    } else {
      endDate.add(duration, "minutes");
      endDate.hour(0);
      endDate.minute(0);
      endDate.second(0);
      endDate.millisecond(0);
    }

    if (absenceStatus === 'PENDING') {
      eventDetails.showAs = 'free';
    } else if (absenceStatus === 'APPROVED') {
      eventDetails.showAs = 'busy';
    } else if (absenceStatus === 'REJECTED') {
      eventDetails.showAs = 'tentative';
    }
  }

  eventDetails.start = {
    dateTime: startDate.format("Y-MM-DDTHH:mm:ss"),
    timeZone: timezone,
  };
  eventDetails.end = {
    dateTime: endDate.format("Y-MM-DDTHH:mm:ss"),
    timeZone: timezone
  };
  eventDetails.location = {
    displayName: "Microsoft Teams",
  };

  let color = selectedColor;
  if (!color) {
    color = colors[0].id;
  }

  eventDetails.categories = [color];
  eventDetails.attendees = attendees;

  if(type !== 'absence') {
    if (type === 'balanceEcheance') {
      eventDetails.showAs = 'free';
    } else {
      eventDetails.showAs = 'busy';
    }
  }

  return eventDetails;
}


export const handleOutlookLogin = (loginType, instance, loginRequest) => {
  if (loginType === "popup") {
    instance.loginPopup(loginRequest).catch((e) => {
      console.log(e);
    });
  } else if (loginType === "redirect") {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }
};
