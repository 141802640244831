import axios from "axios";
import { TTP_API_URL } from "../config";

export const getAnnualCalendar = ({ token, organizationId, year, zone }) => {
  const requestUrl = `${TTP_API_URL}/team/annual-calendar`;
  let params = {
    access_token: token,
    organizationId,
    year,
    zone,
  };

  return axios.get(requestUrl, {
    params,
  });
};


export const saveAnnualCalendarHolidayOutlookMappings = ({ token, organizationId, annualCalendarId, mappings }) => {
  const requestUrl = `${TTP_API_URL}/team/annual-calendar/outlook-mappings`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);
  formData.append("annualCalendarId", annualCalendarId);
  formData.append("mappings", JSON.stringify(mappings));

  return axios.post(requestUrl, formData);
};
