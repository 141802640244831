export function CheckMarkIcon({
  size = 14,
  fill = "#06D9B1",
  ...params
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...params}
    >
      <path d="M5.29503 14C4.85983 14 4.44823 13.7964 4.18363 13.4472L0.610026 8.72782C0.145226 8.11402 0.266226 7.24002 0.880026 6.77502C1.49483 6.30982 2.36823 6.43162 2.83323 7.04502L5.18363 10.1486L11.095 0.657024C11.502 0.00402431 12.3614 -0.195776 13.0158 0.210624C13.6694 0.617424 13.869 1.47742 13.4618 2.13102L6.47863 13.343C6.23603 13.7328 5.81723 13.978 5.35863 13.9988C5.33723 13.9996 5.31623 14 5.29503 14Z" fill={fill} />
    </svg>
  )
}
