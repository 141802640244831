import React from 'react';
import classNames from 'classnames';
import styles from './CategoriesHeader.module.scss';

const CategoriesHeader = ({ categories }) => (
  <div className={styles.categories_header}>
    {categories.map((category, index) => (
      <div
        key={index}
        className={classNames({
          [styles.categories_header_item]: true,
          [styles[`${category.className}_item`]]: true
        })}
      >
        <div
          className={classNames({
            [styles.top_bar]: true,
            [styles[`${category.className}_item_top_bar`]]: true
          })}
        />
        <div
          className={classNames({
            [styles.item_count]: true,
            [styles[`${category.className}_item_count`]]: true
          })}
        >
          {category.count}{category?.maxCount ? `/${category.maxCount}` : ''} j.
        </div>
        <div
          className={classNames({
            [styles.item_label]: true,
            [styles[`${category.className}_item_label`]]: true
          })}
        >
          {category.label}
        </div>
      </div>
    ))}
  </div>
);

export default CategoriesHeader;
