import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import _ from "../../../i18n";
import { useOnClickOutside, renderTeamYears } from "../../../utils";
import {
  getCollaborator,
  getCollaboratorSettingSuggestions,
  getCollaboratorsSuggestions,
} from "../../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";

const COLLABORATOR_ROLE_ATTRIBUT = [
  {
    value: "manager",
    label: "GD",
  },
  {
    value: "encodingManager",
    label: "GE",
  },
  {
    value: "supervisor",
    label: "SUP",
  },
  {
    value: "scanOperator",
    label: "SCANOP",
  },
  {
    value: "encodingSupervisor",
    label: "SE",
  },
];

const COLLABORATOR_STATUS = [
  {
    value: "freelancer",
    label: _("freelancer"),
  },
  {
    value: "employer",
    label: _("employer"),
  },
];

const CalendarFilter = ({ filter, nbResult, updateFilter }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));
  const { token, navCommunity, loggedAs, user } = useSelector(
    (state) => state.auth
  );

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);

  let collboratorQuery = useQuery(
    ["getCollaborator", token, navCommunity],
    async () => {
      if (token && navCommunity && loggedAs == "COLLABORATOR") {
        try {
          const response = await getCollaborator(
            null,
            navCommunity.id,
            token,
            user.uid,
            user.adminisId.split(":")[1]
          );
          const data = response.data.data;
          updateFilter({
            ...filter,
            partner: data.partner ? Number(data.partner.value) : null,
          });
        } catch (e) {
          return null;
        }
      }
    }
  );

  let collaboratorsdQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let collaboratorSettingQuery = useQuery(
    ["zonesQuery", navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorSettingSuggestions(
            token,
            navCommunity.id
          );
          let dataZones = response.data.data.zones
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.label,
              };
            });
          setZoneOptions(dataZones);
          let dataBUs = response.data.data.bus
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.label,
              };
            });
          setBuOptions(dataBUs);
        } catch (e) {
          setBuOptions([]);
          setZoneOptions([]);
          return null;
        }
      }
    }
  );

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => updateFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handlePartnerChange = (partner) => {
    updateFilter({ ...filter, partner: Number(partner) });
  };

  const handleCollaboratorsChange = (items) => {
    updateFilter({ ...filter, collaborators: items });
  };

  const handleCollaboratorRoleChange = (item) => {
    updateFilter({ ...filter, role: item });
  };

  const handleCollaboratorStatusChange = (item) => {
    updateFilter({ ...filter, status: item });
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isSearch: loggedAs == "COLLABORATOR" ? false : true,
        isClearable: loggedAs == "COLLABORATOR" ? false : true,
        isDisabled: loggedAs == "COLLABORATOR" ? true : false,
        placeholder: _("partner"),
        options: filter.partnerOptions,
        values: filter.partner,
        action: handlePartnerChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isSearch: true,
        isClearable: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborators,
        action: handleCollaboratorsChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        placeholder: _("bu"),
        options: buOptions,
        values: filter.bu ? filter.bu.value : null,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.bu;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              bu: buOptions.filter((option) => option.value == value)[0],
            });
          }
        },
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        placeholder: _("zone"),
        options: zoneOptions,
        values: filter.zone ? filter.zone.value : null,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.zone;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              zone: zoneOptions.filter((option) => option.value == value)[0],
            });
          }
        },
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("role"),
        options: COLLABORATOR_ROLE_ATTRIBUT,
        values: filter.role,
        action: handleCollaboratorRoleChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("status"),
        options: COLLABORATOR_STATUS,
        values: filter.status,
        action: handleCollaboratorStatusChange,
      },
    },
  ];

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          bg="#FFF"
          subBG="F8F9FA"
          button
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          pageSize={filter.pageSize}
          customAddOn={renderYearSelect()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default CalendarFilter;
