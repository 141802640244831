export const PAGINATION_CURRENT_PAGE = 0;
export const PAGINATION_START = 0;
export const PAGINATION_LIMIT = 5;

export const COLUMNS = [
  {
    field: "clientName",
    header: "Nom",
    resizeable: true,
    align: 'left',
    style: {
      width: "240px"
    },
  },
  {
    field: "rdv",
    header: "RDV",
    alignHeader: 'center',
    style: {
      width: "280px"
    },
  },
  {
    field: "observation",
    header: "Remarque",
    alignHeader: 'center',
    align: 'right',
  },
];
