import { renderAvatar } from '../../../../../utils';
import { FiArrowUpRight } from "react-icons/fi";
import styles from './index.module.scss';

export default function Header({
  collaborator,
  eligible,
  totalBonusAmount,
  setDisplaySidebar
}) {
  return (
    <section className={styles.header_container}>
      <div className={styles.collaborator_info}>
        <div className={styles.avatar}>
          {renderAvatar(collaborator.avatarUrl, collaborator.name, 44)}
        </div>
        <div className={styles.name}>
          {collaborator.name}
        </div>
      </div>

      <div className={styles.box_item}>
        <p className={styles.title}>Éligible :</p>
        <p className={styles.value}>{eligible}/5</p>
        <span className={styles.details} onClick={() => setDisplaySidebar(true)}>
          Détails <FiArrowUpRight size={16} />
        </span>
      </div>

      <div className={styles.box_item}>
        <p className={styles.title}>Montant total de la prime :</p>
        <p className={styles.value}>{totalBonusAmount} DH</p>
      </div>
    </section>
  );
}
