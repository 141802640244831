import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCategoriesSuggestions } from "../../../api";
import "./Nettoyage/Nettoyage.local.scss";
import { Carousel } from "primereact/carousel";
import Nettoyage from "./Nettoyage";
import _ from "../../../i18n";
import "./ProductionCapacity.local.scss";
import Encoding from "./Encoding";
import { parsePreference } from "../../../utils";

const ProductionCapacity = ({ filter = {}, collaborator, preferences }) => {
  const { token, navCommunity, loggedAs, user } = useSelector(
    (state) => state.auth
  );
  const { language } = useSelector((state) => state.params);
  const [activeTab, setActiveTab] = useState({
    category: 4,
  });

  const allowViewDataBy =
    parsePreference(preferences).production.allowViewDataBy;

  const [filters, setFilters] = useState({
    ...filter,
    collaborator: null,
    partnerZoneId: null,
    bu: allowViewDataBy === 100 && collaborator ? [collaborator.bu] : [],
    zone: allowViewDataBy === 101 && collaborator ? collaborator.zoneId : null,
    partner: loggedAs == "MANAGER" ? user?.id : null,
    collaborators:
      [10, 110].includes(allowViewDataBy) && collaborator
        ? [collaborator.id]
        : null,
  });

  const categories = [
    {
      id: 4,
      label: _("04. ENCODAGE COMPTABLE."),
      category: "ENCODAGE COMPTABLE",
    },
    {
      id: 6,
      label: _("06. NETTOYAGE ET VERIFIC."),
      category: "NETTOYAGE ET VERIFICATION COMPTABILITE",
    },
  ];

  const renderPannelFrame = () => {
    return (
      <div style={{ width: "100%" }}>
        {activeTab.category === 6 ? (
          <Nettoyage
            filter={filters}
            setFilter={setFilters}
            collaborator={collaborator}
            preferences={preferences}
          />
        ) : activeTab.category === 4 ? (
          <Encoding
            filter={filters}
            setFilter={setFilters}
            collaborator={collaborator}
            preferences={preferences}
          />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  let categoriesQuery = useQuery(
    ["categoriesQuery", navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getCategoriesSuggestions(
            navCommunity.id,
            token
          );
          let categoriesResponse = response.data.data.map((item) => {
            return { id: item.id, label: item.label[language] };
          });
          // setCategories(categoriesResponse); // Assurez-vous d'avoir useState pour categories si vous voulez mettre à jour l'état
        } catch (e) {
          // setCategories([]);
          return null;
        }
      }
    }
  );

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Carousel
        value={categories}
        numVisible={2}
        numScroll={0}
        showNavigators={false}
        style={{
          width: "100%",
          marginBottom: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        itemTemplate={(category, i) => {
          return (
            <div key={i}>
              <div
                className={`tab_badge ${
                  activeTab.category === category.id ? "tab_badge_active" : ""
                }`}
                onClick={() => setActiveTab({ category: category.id })}
              >
                <div>{category.label}</div>
              </div>
            </div>
          );
        }}
      />
      {renderPannelFrame()}
    </div>
  );
};

export default ProductionCapacity;
