import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCollaboratorsEcheance, getCollaboratorBalance } from "../../../api";
import PageHeader from "../../common/PageHeader/PageHeader";
import { BriefCaseIcon } from "../../common/Icons";
import EcheancesFilter from "./EcheancesFilter";
import "./Echeances.local.scss";
import { Tooltip } from "antd";
// import { CheckIcon } from "../../common/Icons";
import { CheckIcon, ChevronDown, ChevronUp } from "../../common/Icons";
import { ECHEANCES } from "../../../config";
import _ from "../../../i18n";
import moment from "moment";

const Table = ({
  token,
  organization,
  partner,
  partnerZoneId,
  year,
  collaboratorId,
  type,
  balance_types,
  zoneId,
  bu,
  balanceRefresh,
  setBalanceRefresh,
  loggedAs,
  category,
}) => {
  const [selectedBalanceTypes, setSelectedBalanceTypes] = useState(type);
  const [seles, setSeles] = useState(null);
  const [balances, setBalances] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [countBalances, setCountBalances] = useState(null);
  const [loadingBalances, setLoadingBalances] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [percentageByType, setPercentageByType] = useState([]);
  const [selectedEcheanceClosingDate, setSelectedEcheanceClosingDate] =
    useState(null);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showNotCompleted, setShowNotCompleted] = useState(true);

  let balancesQuery = useQuery(
    ["getCollaboratorBalance", token, organization, year],
    async () => {
      if (token && organization) {
        try {
          const response = await getCollaboratorBalance(
            token,
            organization,
            partner,
            year,
            collaboratorId,
            zoneId,
            bu,
            selectedBalanceTypes,
            selectedEcheanceClosingDate
          );
          console.log("dataa.------",response.data.data);
          setBalances(response.data.data.response);
          setHeaders(response.data.data.headers);
          setBalanceRefresh(false);
          setLoadingBalances(false);
          return response.data.data;
        } catch (e) {
          setBalances(null);
          setLoadingBalances(false);
          return null;
        } finally {
          setLoadingBalances(false);
        }
      }
    },
    {
      enabled: !!partner && !!token && !!organization,
    }
  );

  useEffect(() => {
    if (balanceRefresh) {
      setLoadingBalances(true);
      balancesQuery.refetch();
    }
    if (!seles) {
      fetchBalancesData();
      setSeles(true);
    }
  }, [selectedBalanceTypes, selectedEcheanceClosingDate, balanceRefresh]);

  const countDatesForTypes = (balances, headers) => {
    const counts = headers.reduce((acc, header) => {
      acc[header.label] = 0;
      if (header.children) {
        header.children.forEach((child) => {
          acc[child.label] = 0;
        });
      }
      return acc;
    }, {});

    balances.forEach((balance) => {
      headers.forEach((header) => {
        if (balance[header.value] === "1" || balance[header.value] === "Oui") {
          counts[header.label]++;
        }
        if (header.children) {
          header.children.forEach((child) => {
            if (
              balance[child.value] === "1" ||
              balance[child.value] === "Oui"
            ) {
              counts[child.label]++;
            }
          });
        }
      });
    });
    return counts;
  };
  const fetchBalancesData = async () => {
    try {
      if (loggedAs == "MANAGER" && partnerZoneId != null) {
        const responses = await Promise.all(
          balance_types.map((type) =>
            getCollaboratorBalance(
              token,
              organization,
              partner,
              year,
              collaboratorId,
              zoneId,
              bu,
              type,
              selectedEcheanceClosingDate,
            )
          )
        );
        let updatedPercentageByType = { ...percentageByType };
        console.log("bchgdvfcdgvc==================response.data", responses);
        responses.forEach((response) => {
          let chekedValue =
            response.data.data.type === "TVA"
              ? response.data.data.response["global_total"]["intervat_deposit"]
              : response.data.data.response["global_total"][
                  "is_bnb_finished_deg"
                ];
  
          if (response.data.data.type === selectedBalanceTypes) {
            setCountBalances(response.data.data.countData);
            setBalances(response.data.data.response);
            setHeaders(response.data.data.headers);
          }
          updatedPercentageByType[response.data.data.type] = parseFloat(
            (chekedValue / response.data.data.response.length) * 100
          ).toFixed(2);
        });
        setPercentageByType(updatedPercentageByType);
      }
    } catch (error) {
      console.error("Error fetching balances:", error);
    } finally {
      setLoadingBalances(false);
    }
  };

  useEffect(() => {
    fetchBalancesData();
  }, [
    token,
    organization,
    partner,
    year,
    collaboratorId,
    zoneId,
    selectedBalanceTypes,
    selectedEcheanceClosingDate,
  ]);

  const handleChangeBalanceTypes = (type) => {
    setSelectedEcheanceClosingDate(null);
    setSelectedBalanceTypes(type);
    setBalanceRefresh(true);
  };

  const handleChangeBalanceClosingDate = (date) => {
    setSelectedEcheanceClosingDate(date);
    setBalanceRefresh(true);
  };

  return (
    <div className="balances_page">
      <div className="balances">
        Nombre de dossiers : {countBalances}
        <div className="balance_header">
          <div className="types">
            {balance_types.length > 1 &&
              balance_types.map((type) => (
                <>
                  <div
                    className={`item_type ${
                      type === selectedBalanceTypes && "selected_type"
                    } `}
                    onClick={() => handleChangeBalanceTypes(type)}
                  >
                    {type} {"  "}{" "}
                    {(percentageByType[type] && percentageByType[type]) || 0} %
                  </div>
                </>
              ))}
          </div>
        </div>
        <div className="echeanches_filter">
          {ECHEANCES[selectedBalanceTypes].map((type) => (
            <div
              className={`item ${
                type === selectedEcheanceClosingDate && "active"
              } `}
              onClick={() => handleChangeBalanceClosingDate(type)}
            >
              {type ? type : "Tous"}
            </div>
          ))}
        </div>
        {loadingBalances || fetchBalancesData.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : (
          <div className="table">
            {balances && balances.length === 0 && (
              <div className="no_data" style={{ height: 38 }}>
                <div className="title">{_("Oops")}</div>
                <div className="subTitle">{_("noBalanceFound")}</div>
              </div>
            )}
            {balances && balances.length > 0 && (
              <div className="bilan_table header">
                <div className="bilan_item name" style={{ width: "40%" }}>
                  <div className="content_header"></div>
                </div>

                {headers &&
                  headers.map((header) => (
                    <div className="bilan_item name">
                      <div className="content_header">
                        <div className="sub_title">
                          <div>{header.label}</div>
                          <div className="sub_titles">
                            {header.children &&
                              header.children.length > 0 &&
                              header.children.map((child, index) => (
                                <div
                                  className="sub_title"
                                  style={{
                                    margin: index === 0 && "0 64% 0 0%",
                                  }}
                                >
                                  {child.label}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {balances && headers && (
              <>
                <div className="bilan_table header">
                  <div className="bilan_item name" style={{ width: "40%" }}>
                    <div className="content_header">Nom du client</div>
                  </div>
                  {headers.map((header, index) => (
                    <div key={index} className="bilan_item name">
                      <div className="content_header">
                        <div className="sub_title">{header.label}</div>
                        {header.children && header.children.length > 0 && (
                          <div className="sub_titles">
                            {header.children.map((child, idx) => (
                              <div
                                key={idx}
                                className="sub_title"
                                style={{
                                  margin: idx === 0 ? "0 64% 0 0%" : undefined,
                                }}
                              >
                                {child.label}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="bilan_table global_total">
                  <div
                    className="bilan_item name"
                    style={{
                      width: "40%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      className="content first_content"
                      style={{ paddingLeft: "10px" }}
                    >
                      {balances.global_total.client_name || "Total Global"}
                    </div>
                  </div>
                  {headers.map((header, index) => (
                    <div key={`global-${index}`} className="bilan_item name">
                      <div className="content first_content">
                        {balances.global_total[header.value] || ""}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="group_section">
                  {balances.completed &&
                    balances.completed.map(
                      (bilan, index) =>
                        ((showCompleted && index !== 0) || index === 0) && (
                          <div key={`completed-${index}`}>
                            <div
                              className="bilan_table"
                              onClick={() =>
                                setSelectedClientId(bilan.client_id)
                              }
                            >
                              <div
                                className="bilan_item name"
                                style={{
                                  width: "40%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <div
                                  className={`content ${
                                    bilan.id === 0 && "first_content"
                                  }`}
                                  style={{
                                    paddingLeft: "10px",
                                    display: "flex",
                                    justifyContent:
                                      index === 0 && "space-around",
                                    background: index === 0 && "#dee2e6",
                                  }}
                                >
                                  {bilan.client_name}
                                  {index === 0 && (
                                    <div
                                      onClick={() =>
                                        setShowCompleted((prev) => !prev)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {showCompleted ? (
                                        <ChevronUp />
                                      ) : (
                                        <ChevronDown />
                                      )}
                                    </div>
                                  )}

                                  {index !== 0 && (
                                    <div className="vat_periodicity">
                                      {bilan.vat_periodicity || ""}
                                    </div>
                                  )}
                                  {bilan.follow_up_remarks && "++"}
                                </div>
                              </div>
                              {headers.map((header, indexHeader) => (
                                <div
                                  key={`completed-${index}-${indexHeader}`}
                                  className="bilan_item name"
                                >
                                  <div
                                    className={`content ${
                                      bilan.id === 0 && "first_content"
                                    }`}
                                    style={{
                                      background: index === 0 && "#dee2e6",
                                    }}
                                  >
                                    {header.children &&
                                    header.children.length > 0 ? (
                                      header.children.map(
                                        (child, indexChild) => (
                                          <Tooltip
                                            key={`child-${indexChild}`}
                                            title={
                                              bilan[
                                                child.value.substring(3) +
                                                  "_date"
                                              ] && (
                                                <div>
                                                  <ul>
                                                    {bilan[
                                                      child.value.substring(3) +
                                                        "_date"
                                                    ]
                                                      ? moment(
                                                          bilan[
                                                            child.value.substring(
                                                              3
                                                            ) + "_date"
                                                          ].date
                                                        ).format("DD/MM/YYYY")
                                                      : " "}
                                                  </ul>
                                                </div>
                                              )
                                            }
                                          >
                                            <div
                                              className={`checked_item ${
                                                bilan.id === 0 &&
                                                "first_content"
                                              } ${
                                                (!bilan[child.value] ||
                                                  bilan[child.value] === "0") &&
                                                bilan[child.value] !== "1" &&
                                                "unchecked_item"
                                              }`}
                                              style={{
                                                width: "50%",
                                                background:
                                                  index === 0
                                                    ? "#dee2e6"
                                                    : (!bilan[child.value] ||
                                                        bilan[child.value] ===
                                                          "0") &&
                                                      bilan[child.value] !== "1"
                                                    ? ""
                                                    : indexHeader === 0
                                                    ? "yellow"
                                                    : indexChild === 0
                                                    ? "linear-gradient(to right, yellow, #f7e600)"
                                                    : indexChild === 1
                                                    ? "linear-gradient(to right, #f7e600, #acd46f 50%)"
                                                    : indexHeader === 1
                                                    ? "linear-gradient(to right, #f7e600, #acd46f)"
                                                    : indexHeader === 2
                                                    ? "linear-gradient(to right, #acd46f, #43d18b)"
                                                    : indexHeader === 3
                                                    ? "linear-gradient(to right, #43d18b, #06d9b1)"
                                                    : "linear-gradient(to right, #06d9b1, #00b3f0)",
                                              }}
                                            >
                                              {bilan.id !== 0 ? (
                                                bilan[child.value] !== "0" &&
                                                (bilan[child.value] ||
                                                  bilan[child.value] === "1") &&
                                                (index === 0 ? (
                                                  bilan[child.value]
                                                ) : (
                                                  <CheckIcon
                                                    fill="#3C4E64"
                                                    width="12"
                                                    height="12"
                                                  />
                                                ))
                                              ) : (
                                                <Tooltip
                                                  title={
                                                    <div>
                                                      <ul>
                                                        (Réalisé / Budgétisé ) *
                                                        100
                                                      </ul>
                                                    </div>
                                                  }
                                                >
                                                  <div>
                                                    {bilan[child.value]} gftft
                                                  </div>
                                                </Tooltip>
                                              )}
                                            </div>
                                          </Tooltip>
                                        )
                                      )
                                    ) : (
                                      <Tooltip
                                        title={
                                          bilan[
                                            header.value.substring(3) + "_date"
                                          ] && (
                                            <div>
                                              <ul>
                                                {bilan[
                                                  header.value.substring(3) +
                                                    "_date"
                                                ]
                                                  ? moment(
                                                      bilan[
                                                        header.value.substring(
                                                          3
                                                        ) + "_date"
                                                      ].date
                                                    ).format("DD/MM/YYYY")
                                                  : " "}
                                              </ul>
                                            </div>
                                          )
                                        }
                                      >
                                        <div
                                          className={`checked_item ${
                                            bilan.id === 0 && "first_content"
                                          } ${
                                            bilan[header.value] !== "0" &&
                                            (bilan[header.value] ||
                                              bilan[header.value] === "1")
                                              ? ""
                                              : "unchecked_item"
                                          }`}
                                          style={{
                                            background:
                                              index === 0
                                                ? "#dee2e6"
                                                : bilan[header.value] !== "0" &&
                                                  (bilan[header.value] ||
                                                    bilan[header.value] === "1")
                                                ? indexHeader === 0
                                                  ? "linear-gradient(to right, yellow, #f7e600)"
                                                  : indexHeader === 1
                                                  ? "linear-gradient(to right, #f7e600, #acd46f)"
                                                  : indexHeader === 2
                                                  ? "linear-gradient(to right, #acd46f, #43d18b)"
                                                  : "linear-gradient(to right, #43d18b, #06d9b1)"
                                                : "",
                                          }}
                                        >
                                          {bilan.id !== 0 ? (
                                            bilan[header.value] !== "0" &&
                                            (bilan[header.value] ||
                                              bilan[header.value] === "1") &&
                                            (index === 0 ? (
                                              bilan[header.value]
                                            ) : (
                                              <CheckIcon
                                                fill="#3C4E64"
                                                width="12"
                                                height="12"
                                              />
                                            ))
                                          ) : (
                                            <div>{bilan[header.value]}</div>
                                          )}
                                        </div>
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                    )}

                  <div className="group_section">
                    {balances.not_completed &&
                      balances.not_completed.map(
                        (bilan, index) =>
                          ((showNotCompleted && index !== 0) ||
                            index === 0) && (
                            <div key={`notcompleted-${index}`}>
                              <div
                                className="bilan_table"
                                onClick={() =>
                                  setSelectedClientId(bilan.client_id)
                                }
                              >
                                <div
                                  className="bilan_item name"
                                  style={{
                                    width: "40%",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <div
                                    className={`content ${
                                      bilan.id === 0 && "first_content"
                                    }`}
                                    style={{
                                      paddingLeft: "10px",
                                      display: "flex",
                                      justifyContent:
                                        index === 0 && "space-around",
                                      background: index === 0 && "#dee2e6",
                                    }}
                                  >
                                    {bilan.client_name}
                                    {index === 0 && (
                                      <div
                                        onClick={() =>
                                          setShowNotCompleted((prev) => !prev)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {showNotCompleted ? (
                                          <ChevronUp />
                                        ) : (
                                          <ChevronDown />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {headers.map((header, indexHeader) => (
                                  <div
                                    key={`notcompleted-${index}-${indexHeader}`}
                                    className="bilan_item name"
                                  >
                                    <div
                                      className={`content ${
                                        bilan.id === 0 && "first_content"
                                      }`}
                                      style={{
                                        background: index === 0 && "#dee2e6",
                                      }}
                                    >
                                      {header.children &&
                                      header.children.length > 0 ? (
                                        header.children.map(
                                          (child, indexChild) => (
                                            <Tooltip
                                              key={indexChild}
                                              title={
                                                bilan[
                                                  child.value.substring(3) +
                                                    "_date"
                                                ] && (
                                                  <div>
                                                    <ul>
                                                      {bilan[
                                                        child.value.substring(
                                                          3
                                                        ) + "_date"
                                                      ]
                                                        ? moment(
                                                            bilan[
                                                              child.value.substring(
                                                                3
                                                              ) + "_date"
                                                            ].date
                                                          ).format("DD/MM/YYYY")
                                                        : " "}
                                                    </ul>
                                                  </div>
                                                )
                                              }
                                            >
                                              <div
                                                className={`checked_item ${
                                                  bilan.id === 0 &&
                                                  "first_content"
                                                } ${
                                                  (!bilan[child.value] ||
                                                    bilan[child.value] ===
                                                      "0") &&
                                                  bilan[child.value] !== "1" &&
                                                  "unchecked_item"
                                                }`}
                                                style={{
                                                  width: "50%",
                                                  background:
                                                    index === 0
                                                      ? "#dee2e6"
                                                      : (!bilan[child.value] ||
                                                          bilan[child.value] ===
                                                            "0") &&
                                                        bilan[child.value] !==
                                                          "1"
                                                      ? ""
                                                      : indexHeader === 0
                                                      ? "yellow"
                                                      : indexChild === 0
                                                      ? "linear-gradient(to right, yellow, #f7e600)"
                                                      : indexChild === 1
                                                      ? "linear-gradient(to right, #f7e600, #acd46f 50%)"
                                                      : indexHeader === 1
                                                      ? "linear-gradient(to right, #f7e600, #acd46f)"
                                                      : indexHeader === 2
                                                      ? "linear-gradient(to right, #acd46f, #43d18b)"
                                                      : indexHeader === 3
                                                      ? "linear-gradient(to right, #43d18b, #06d9b1)"
                                                      : "linear-gradient(to right, #06d9b1, #00b3f0)",
                                                }}
                                              >
                                                {bilan.id !== 0 ? (
                                                  bilan[child.value] !== "0" &&
                                                  (bilan[child.value] ||
                                                    bilan[child.value] ===
                                                      "1") &&
                                                  (index === 0 ? (
                                                    bilan[child.value]
                                                  ) : (
                                                    <CheckIcon
                                                      fill="#3C4E64"
                                                      width="12"
                                                      height="12"
                                                    />
                                                  ))
                                                ) : (
                                                  <Tooltip
                                                    title={
                                                      <div>
                                                        <ul>
                                                          (Réalisé / Budgétisé )
                                                          * 100
                                                        </ul>
                                                      </div>
                                                    }
                                                  >
                                                    <div>
                                                      {bilan[child.value]} gftft
                                                    </div>
                                                  </Tooltip>
                                                )}
                                              </div>
                                            </Tooltip>
                                          )
                                        )
                                      ) : (
                                        <Tooltip
                                          title={
                                            bilan[
                                              header.value.substring(3) +
                                                "_date"
                                            ] && (
                                              <div>
                                                <ul>
                                                  {bilan[
                                                    header.value.substring(3) +
                                                      "_date"
                                                  ]
                                                    ? moment(
                                                        bilan[
                                                          header.value.substring(
                                                            3
                                                          ) + "_date"
                                                        ].date
                                                      ).format("DD/MM/YYYY")
                                                    : " "}
                                                </ul>
                                              </div>
                                            )
                                          }
                                        >
                                          <div
                                            className={`checked_item ${
                                              bilan.id === 0 && "first_content"
                                            } ${
                                              bilan[header.value] !== "0" &&
                                              (bilan[header.value] ||
                                                bilan[header.value] === "1")
                                                ? ""
                                                : "unchecked_item"
                                            }`}
                                            style={{
                                              background:
                                                index === 0
                                                  ? "#dee2e6"
                                                  : bilan[header.value] !==
                                                      "0" &&
                                                    (bilan[header.value] ||
                                                      bilan[header.value] ===
                                                        "1")
                                                  ? indexHeader === 0
                                                    ? "linear-gradient(to right, yellow, #f7e600)"
                                                    : indexHeader === 1
                                                    ? "linear-gradient(to right, #f7e600, #acd46f)"
                                                    : indexHeader === 2
                                                    ? "linear-gradient(to right, #acd46f, #43d18b)"
                                                    : "linear-gradient(to right, #43d18b, #06d9b1)"
                                                  : "",
                                            }}
                                          >
                                            {bilan.id !== "0" ? (
                                              bilan[header.value] !== "0" &&
                                              (bilan[header.value] ||
                                                bilan[header.value] === "1") &&
                                              (index === 0 ? (
                                                bilan[header.value]
                                              ) : (
                                                <CheckIcon
                                                  fill="#3C4E64"
                                                  width="12"
                                                  height="12"
                                                />
                                              ))
                                            ) : (
                                              <div>{bilan[header.value]}</div>
                                            )}
                                          </div>
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                      )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
