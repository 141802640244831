import React, { useState, Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import {
  getClientsList,
  getCollaboratorsAvatars,
  getCollaborator,
} from "../../api";
import { parsePreference, renderDurationDisplay } from "../../utils";
import _ from "../../i18n";
import PageHeader from "../common/PageHeader/PageHeader";
import TTPPaginator from "../common/TTPPaginator";
import { FolderIcon } from "../common/Icons";
import Item from "./Item";
import ItemEntity from "./ItemEntity";
import ClientsFilter from "./ClientsFilter";
import "./Clients.local.scss";
import CollaboratorCorrectionCard from "../CollaboratorSpace/ProductionTracking/EncodingProgression/CollaboratorCorrectionCard";
import NotAuthorized from "../common/NotAuthorized";

const COLORS = ["#E1E4E8", "#3D98D6", "#8CB7D7", "#C7E8FE"];
const RADIAN = Math.PI / 180;

const Clients = () => {
  const { token, navCommunity, loggedAs, spaceAs, user, collaborator } = useSelector(
    (state) => state.auth
  );
  const [searchParams] = useSearchParams();
  const portfolio = searchParams.get("portfolio");

  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};

  const { language } = useSelector((state) => state.params);
  const [nbrClients, setNbrClients] = useState(0);
  const [activeAttachedClient, setActiveAttachedClient] = useState(0);
  const [correctionCardClient, setCorrectionCardClient] = useState(null);
  // const [collaborator, setCollaborator] = useState(null);
  const [clients, setClients] = useState([]);
  const [collaboratorFetched, setCollaboratorFetched] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [filter, setFilter] = useState({
    keyWord: "",
    year: new Date().getFullYear().toString(),
    pageSize: 10,
    paginationPage: 1,
    collaborators:
      [10, 100, 101, 110].includes(
        parsePreference(preferences.clients).allowViewDataBy
      ) && collaborator
        ? [collaborator.id]
        : portfolio
        ? [parseInt(portfolio)]
        : [],
    deletedCollaborators:
      [10, 100, 101, 110].includes(
        parsePreference(preferences.clients).allowViewDataBy
      ) && collaborator
        ? [collaborator.id]
        : [],
    role: portfolio ? "GD" : "",
    partner: loggedAs == "MANAGER" ? user?.id : null,
    partnerZone: null,
    clients: [],
    isEntity: null,
    deletedStatus: "NOT_DELETED",
    clientCRMGroup: [
      // _("AiSBL"),
      // _("ASBL"),
      // _("ASBL - Consultation"),
      // _("ASBL - Partenaire"),
      // _("ASBL petite entreprise"),
      // _("Contact"),
      // _("Fondation privée"),
      // _("Indépendants"),
      // _("Indépendants - Consultation"),
      // _("Indépendants petite entreprise"),
      // _("Partenaire"),
      // _("Particuliers"),
      // _("Particuliers - Consultation"),
      // _("Sociétés"),
      // _("Sociétés - Partenaire"),
      // _("Sociétés petite entreprise"),
    ],
    clientCRMTypes: [],
    sortBy: [],
    filterIsClientGroupReseted: true,
    budgetStatus: [],
    departStatus: null,
  });
  const [selectedItem, setSlectedItem] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      const col = localStorage.getItem("filterCol");
      if (col) {
        const collaborator = JSON.parse(col);
        setSlectedItem(collaborator);
        localStorage.removeItem("filterCol");
      }
    }, 5000);
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        deletedCollaborators: [selectedItem.value],
      }));
    }
  }, [selectedItem]);

  let collboratorQuery = useQuery(
    ["getCollaborator", token, navCommunity, user, spaceAs],
    async () => {
      if (token && navCommunity) {
        try {
          setCollaboratorFetched(true);
          const response = await getCollaborator(
            collaborator.id,
            navCommunity.id,
            token,
            user.uid,
            user.adminisId.split(":")[1]
          );
          // setCollaborator(response.data.data);
          setFilter((prevFilter) => ({
            ...prevFilter,
            partner:
              loggedAs === "COLLABORATOR" &&
              parsePreference(preferences.clients).allowViewDataBy === 11
                ? response.data.data?.partner.value
                : null,
            collaborators:
              [10, 100, 101, 110].includes(
                parsePreference(preferences.clients).allowViewDataBy
              ) && response.data.data
                ? [response.data.data.id]
                : prevFilter.collaborators,
          }));
        } catch (e) {
          return null;
        } finally {
          setCollaboratorFetched(false);
        }
      }
    }
  );

  let clientsQuery = useQuery(
    ["getClientsList", token, navCommunity, filter, collaborator],
    async () => {
      const allowViewDataBy = parsePreference(
        preferences.clients
      ).allowViewDataBy;
      if (
        [10, 100, 101, 110].includes(allowViewDataBy) &&
        (!filter || filter.collaborators.length === 0)
      ) {
        setClients([]);
        setNbrClients(0);
        return [];
      } else if (
        token &&
        navCommunity &&
        (([10, 100, 101, 110].includes(allowViewDataBy) &&
          filter &&
          (filter.collaborators.length > 0 ||
            filter.deletedCollaborators.length > 0)) ||
          allowViewDataBy === 1 ||
          (allowViewDataBy === 11 && filter.partner))
      ) {
        try {
          let filteredFilters = {
            ...filter,
            budgetStatus: filter.budgetStatus.filter((item) => item !== "ALL"),
          };
          // if ((loggedAs === 'SUPERADMIN' && spaceAs === 'SUPERADMIN') ||
          // (loggedAs === 'ADMIN' && spaceAs === 'ADMIN') ||
          //   (loggedAs === 'MANAGER' && spaceAs === 'MANAGER')) {
          //   filteredFilters = {...filteredFilters, collaborators: []};
          // }
          const response = await getClientsList(
            navCommunity.id,
            token,
            filteredFilters
          );
          setClients(response.data.data);
          setNbrClients(response.data.nbResult ? response.data.nbResult : 0);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let avatarsQuery = useQuery(
    ["getCollaboratorAvatars", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsAvatars(
            navCommunity.id,
            token
          );
          setAvatars(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderFetchingLines = () => {
    let cardFetching = [];
    for (let i = 0; i < 10; i++) {
      cardFetching.push(
        <div key={i} className="line_fetching">
          <div className="infos">
            <div className="infos_header gradiant" />
            <div className="infos_sub_header gradiant" />
            <div className="infos_sub_header gradiant" />
          </div>
          <div className="state">
            <div className="state_icon gradiant" />
            <div className="state_label gradiant" />
          </div>
          <div className="progress">
            <div className="progress_info">
              <div className="gradiant" />
              <div className="gradiant" />
            </div>
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <div className="progress_container">
                <div className="progress_icon gradiant" />
                <div className="progress_index gradiant" />
              </div>
            </div>
          </div>
          <div className="progress">
            <div className="progress_info">
              <div className="gradiant" />
              <div className="gradiant" />
            </div>
            <div
              style={{
                height: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <div className="progress_container">
                <div className="progress_icon gradiant" />
                <div className="progress_index gradiant" />
              </div>
            </div>
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="avatars">
            {[1, 2, 3, 4].map((index) => (
              <div className="avatar gradiant" key={index} />
            ))}
          </div>
        </div>
      );
    }
    return cardFetching;
  };

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={filter.pageSize}
        nbResult={nbrClients}
        paginationPage={filter.paginationPage}
      />
    );
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent > 0) {
      return (
        <text
          x={x}
          y={y}
          fill="#29394D"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    }
  };

  //Chart functions
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="client-custom-tooltip">
          <div className="title">{payload[0].payload.name}</div>
          <div className="details">
            <div>{renderDurationDisplay(60 * payload[0].payload.real)}</div>
            <div>{payload[0].payload.economic} €</div>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${correctionCardClient && "display"}`}>
          <CollaboratorCorrectionCard
            organization={navCommunity ? navCommunity.id : null}
            token={token}
            year={filter.year}
            loggedAs={loggedAs}
            onClose={() => {
              setCorrectionCardClient(null);
            }}
            creator={user}
            client={{
              id: correctionCardClient.id,
              title: {
                label: correctionCardClient.name,
                responsables: correctionCardClient.responsables,
              },
            }}
            lng={language}
          />
        </div>
      </div>
    );
  };

  const handleResetGroupClientFilter = () => {
    setFilter({
      ...filter,
      clientCRMGroup: [
        _("AiSBL"),
        _("ASBL"),
        _("ASBL - Consultation"),
        _("ASBL - Partenaire"),
        _("ASBL petite entreprise"),
        _("Contact"),
        _("Fondation privée"),
        _("Indépendants"),
        _("Indépendants - Consultation"),
        _("Indépendants petite entreprise"),
        _("Partenaire"),
        _("Particuliers"),
        _("Particuliers - Consultation"),
        _("Sociétés"),
        _("Sociétés - Partenaire"),
        _("Sociétés petite entreprise"),
      ],
    });
  };

  return (
    <div className="page-content">
      <PageHeader
        header={_("clientList")}
        subHeader={_("subTitleClientList")}
        icon={<FolderIcon size="36" fill="#29394D" />}
      />
      <div className="container">
       
          <ClientsFilter
            filterFormIsVisible={false}
            filter={filter}
            nbResult={nbrClients}
            updateFilter={setFilter}
            resetFilter={handleResetGroupClientFilter}
            fetching={clientsQuery.isFetching}
            loggedAs={loggedAs}
            spaceAs={spaceAs}
            collaborator={collaborator}
            preferences={preferences}
          />
        
        <div className="result_label">
          {_("folderCount")} : <span>{nbrClients}</span>
        </div>
        <div className="medium-11">
          {clientsQuery.isFetching  ? (
            renderFetchingLines()
          ) : clients.length ? (
            <Fragment>
              {clients.map((item, i) => {
                let responsables = item.responsables.map((responsable) => {
                  return {
                    ...responsable,
                    avatar: avatars[responsable.uid]?.avatarUrl,
                  };
                });
                let chartData = item.attachedList.map((attached) => {
                  return {
                    name: attached.name,
                    realPer:
                      (attached.budget.hours.real * 100) /
                      item.budget.hours.real,
                    real: attached.budget.hours.real,
                    economic: attached.budget.economic.real,
                  };
                });
                return (
                  <div key={`client-${item.id}`}>
                    <Item
                      lng={language}
                      preferences={preferences}
                      item={item}
                      responsables={responsables}
                      year={filter.year}
                      isEntity={true}
                      setActiveAttachedClient={setActiveAttachedClient}
                      setCorrectionCardClient={setCorrectionCardClient}
                      activeAttachedClient={activeAttachedClient}
                      loggedAs={loggedAs}
                      spaceAs={spaceAs}
                    />

                    <div className="list-frame_item_body">
                      {activeAttachedClient === item.id && (
                        // item.attachedList.map((attachedItem, i) => (
                        <div
                          style={{
                            margin: "0px 0px 13px 15px",
                            paddingBottom: "20px",
                          }}
                        >
                          <span className="title_details">
                            Détails des relations liées :
                          </span>
                          <div
                            class="grid-x"
                            style={{
                              minHeight:
                                activeAttachedClient === item.id ? 200 : 0,
                            }}
                          >
                            <div
                              class="cell small-6 medium-6 large-5"
                              style={{ marginLeft: "9%" }}
                            >
                              {item.attachedList.map((attachedItem, i) => (
                                <ItemEntity
                                  lng={language}
                                  entityName={item.name}
                                  item={attachedItem}
                                  responsables={responsables}
                                  year={filter.year}
                                  isEntity={true}
                                  setActiveAttachedClient={
                                    setActiveAttachedClient
                                  }
                                  index={i}
                                  activeAttachedClient={activeAttachedClient}
                                />
                              ))}
                            </div>
                            <div class="cell small-6 medium-6 large-4">
                              <ResponsiveContainer width="100%" height="100%">
                                <PieChart width={400} height={400}>
                                  <Pie
                                    data={chartData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    stroke="none"
                                    dataKey="realPer"
                                  >
                                    {chartData.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                      />
                                    ))}
                                  </Pie>
                                  <Tooltip content={<CustomTooltip />} />
                                </PieChart>
                              </ResponsiveContainer>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              {correctionCardClient && renderSideBarFrame()}
              {renderPaginate()}
            </Fragment>
          ) : (
            <div className="no_data">
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noClientsFound")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Clients;
