import { Tooltip } from "antd";
import classNames from "classnames";
import { ChartIcon, TableIcon } from "../../../../common/Icons";
import styles from './index.module.scss';
import { useState } from "react";

export const TABLE_VIEW = 'TABLE_VIEW';
export const CHART_VIEW = 'CHART_VIEW';

export default function TableChartFilter({
  onChange
}) {
  const [dataView, setDataView] = useState(TABLE_VIEW);

  return (
    <div
      className={styles.container}
    >
      <Tooltip title="Tableau">
        <span
          className={classNames({
            [styles.view_icon]: true,
            [styles.view_icon_active]: dataView === TABLE_VIEW
          })}
          onClick={() => {
            setDataView(TABLE_VIEW);
            onChange(TABLE_VIEW);
          }}
        >
          <TableIcon
            size={18}
            stroke={dataView === TABLE_VIEW ? '#fff' : "#6D7F92"}
          />
        </span>
      </Tooltip>
      <Tooltip title="Graphes">
        <span
          className={classNames({
            [styles.view_icon]: true,
            [styles.view_icon_active]: dataView === CHART_VIEW
          })}
          onClick={() => {
            setDataView(CHART_VIEW);
            onChange(CHART_VIEW);
          }}
        >
          <ChartIcon
            size={18}
            stroke={dataView === CHART_VIEW ? '#fff' : "#6D7F92"}
            style={{ cursor: 'pointer' }}
          />
        </span>
      </Tooltip>
    </div>
  );
}
