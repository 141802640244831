import moment from 'moment';
import styles from './index.module.scss';
import { addToRange } from 'react-day-picker';
import _ from './../../../i18n';
import { useEffect, useState } from 'react';
import { getCollaboratorCalendar, getCollaboratorsSuggestions, saveCollaboratorAbsence } from '../../../api';
import { useQuery } from 'react-query';
import { CalendarsColumn, HolidayListItem, CategoriesHeader, HolidayFilters } from './components';
import { ModalConfirm } from "tamtam-components";
import { useSelector } from 'react-redux';
import { isCollaboratorAccess, prepareCollaboratorAbsencesForSave } from '../../../utils';
import { toast } from 'react-toastify';
import { AttachmentsModal } from './components/AttachmentsViewer';
import { MsalSignInButton } from "../../CollaboratorSpace/Planner/MsalSignInButton";
import { CATEGORIES_DATA, HOLIDAY_FILTERS, MODIFIERS_DEFAULT_DATA, MONTHS } from './constants';
import { CheckFilledIcon } from '../../common/Icons';
import { useIsAuthenticatedWithToken } from '../../../utils/hooks';
import { useMsal } from '@azure/msal-react';
import { composeEventForOutlook, handleOutlookLogin } from '../../../utils/outlook';
import { loginRequest } from '../../../config/msal-config';
import { createEvent, deleteEvent, updateEvent } from '../../../api/msal-graph';

moment.locale('fr');

export default function CalendarCollaborator({
  collaborator,
  year
}) {
  const { user, token, navCommunity, loggedAs, spaceAs } = useSelector(state => state.auth);
  const { language } = useSelector((state) => state.params);
  moment.locale(language);

  const [modifiers, setModifiers] = useState(MODIFIERS_DEFAULT_DATA);
  const [planning, setPlanning] = useState([]);
  const [transformedPlanningData, setTransformedPlanningData] = useState([]);
  const [transformedAbsenceData, setTransformedAbsenceData] = useState([]);
  const [collaboratorPeriodSetting, setCollaboratorPeriodSetting] = useState(null);
  const [notWorkingDays, setNotWorkingDays] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [openedMonthCalendar, setOpenedMonthCalendar] = useState(null);
  const [holidayFilters, setHolidayFilters] = useState(HOLIDAY_FILTERS);
  const [activeHolidayFiler, setActiveHolidayFilter] = useState('ALL');
  const [categoriesData, setCategoriesData] = useState(CATEGORIES_DATA);
  const [selectedDays, setSelectedDays] = useState([]);
  const [otherAbsenceOptions, setOtherAbsenceOptions] = useState([]);
  const [selectedAbsenceType, setSelectedAbsenceType] = useState(null); // absence || other || partial
  const [selectedOtherAbsenceType, setSelectedOtherAbsenceType] = useState(null); // other absence selected type
  const [dayPickerParams, setDayPickerParams] = useState(null);
  const [showEditFormForCalendar, setShowEditFormForCalendar] = useState(null);
  const [collaboratorsOptions, setCollaboratorOptions] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [absenceDescription, setAbsenceDescription] = useState(null);
  const [partialAbsenceHours, setPartialAbsenceHours] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [absenceToEdit, setAbsenceToEdit] = useState(null);
  const [absenceToDelete, setAbsenceToDelete] = useState(null);
  const [isAbsenceDeletionModalOpen, setIsAbsenceDeletionModalOpen] = useState(false);
  const [isAttachementsModalVisible, setIsAttachmentModalVisible] = useState(false);
  const [isSavingAbsence, setIsSavingAbsence] = useState(false);
  const [isApprovingAbsence, setIsApprovingAbsence] = useState(false);
  const [isRejectingAbsence, setIsRejectingAbsence] = useState(false);
  const [isSynchedToOutlook, setIsSynchedToOutlook] = useState(false);
  const [isAssociateOutlookModalVisible, setIsAssociateOutlookModalVisible] = useState(false);
  const isAuthenticatedWithTokenToMsal = useIsAuthenticatedWithToken();
  const { instance, accounts } = useMsal();

  let collaboratorsQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity?.id) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let collaboratorCalendarQuery = useQuery(
    ["getCollaborators", token, navCommunity, collaborator, year, token],
    async () => {
      if (token && navCommunity?.id && year && collaborator) {
        try {
          setIsFetchingData(true);
          getCollaboratorCalendar(navCommunity.id, collaborator.id, year, token)
          .then(response => {
            const modifiersData = response.data.data.modifiers;
            const planningData = response.data.data.planning;
            const notWorkingDaysData = response.data.data.notWorkingDays;
            const holidaysTypes = response.data.data.holidaysTypes;
            const collaboratorPeriodSettingData = response.data.data.collaboratorPeriodSetting;

            setCollaboratorPeriodSetting(collaboratorPeriodSettingData);
            setModifiers(modifiersData);
            setPlanning(planningData);
            // this used to easly loop throw data and display components.
            setTransformedPlanningData(transformPlanningData(planningData));
            setNotWorkingDays(notWorkingDaysData);
            setOtherAbsenceOptions(holidaysTypes);
            if (holidaysTypes.length > 0) {
              setSelectedOtherAbsenceType(holidaysTypes[0]);
            }
            setIsFetchingData(false);
          }).finally(() => {
            setIsFetchingData(false);
          });
        } catch (e) {
          return null;
        }
      }
    }
  );

  function transformPlanningData(planning) {
    return MONTHS.map(({abbr, month, index}) => {
      let allItems = [];

      // Merge holidays and annual holidays
      const holidayTypes = [
          { key: "holidays", type: "holiday" },
          { key: "annualHolidays", type: "annual_holiday" },
          { key: 'absence_1', type: 'absence' },
          { key: 'absence_2', type: 'other' },
          { key: 'absence_3', type: 'partial' }
      ];

      holidayTypes.forEach(({ key, type }) => {
        if (planning[key] && planning[key][abbr]) {
          allItems = allItems.concat(
            planning[key][abbr].map(h => {
              return {
                label: h.label,
                vacationStatus: h.vacationStatus,
                type,
                day: moment(h.date).format('D'),
                dates: [h.date],
                attachments: h.attachments,
                backup: h.backup,
                id: h.id,
                key: h.key,
                rejectReason: h.rejectReason ? h.rejectReason : null,
                numberHours: h.numberHours,
                month: MONTHS.find(item => item.abbr.toLowerCase() === abbr.toLowerCase()),
                outlookEvents: h.outlookEvents
              };
            })
          );
        }
      });

      // Group items by label
      const groupedItems = Object.values(allItems.reduce((acc, item) => {
        if (!acc[item.key]) {
          acc[item.key] = { key: item.key, label: item.label, type: item.type, days: [], count: 0 };
        }

        acc[item.key].days.push(parseInt(item.day, 10));
        acc[item.key].dates = acc[item.key]?.dates ? acc[item.key].dates.concat(item.dates) : item.dates;
        acc[item.key].count += 1;
        acc[item.key].vacationStatus = item.vacationStatus;
        acc[item.key].attachments = item.attachments;
        acc[item.key].backup = item.backup;
        acc[item.key].id = item.id;
        acc[item.key].rejectReason = item.rejectReason;
        acc[item.key].month = item.month;
        acc[item.key].numberHours = item.numberHours;
        acc[item.key].type = acc[item.key].count === 1 && item.numberHours > 0 ? 'partial' : item.type;
        acc[item.key].outlookEvents = item.outlookEvents ? JSON.parse(item.outlookEvents) : null;

        return acc;
      }, {}));

      const sortedGroupedItems = groupedItems.sort((item1, item2) => {
        return item1.days[0] > item2.days[0] ? 1 : -1;
      });

      return {
        index,
        monthAbbr: abbr,
        monthName: month,
        items: sortedGroupedItems
      };
    });
  }

  useEffect(() => {
    if (transformedPlanningData) {
      setHolidayFilters(prevState => {
        return prevState.map(filter => {
          if (filter.key === 'ALL') {
            return {
              ...filter,
              count: transformedPlanningData.reduce((acc, month) => {
                return acc + month.items.filter(i => i.vacationStatus != undefined).length;
              }, 0)
            }
          } else {
            return {
              ...filter,
              count: transformedPlanningData.reduce((acc, month) => {
                return acc + month.items.filter(item => item.vacationStatus === filter.key).length;
              }, 0)
            }
          }
        });
      });
    }
  }, [transformedPlanningData]);

  useEffect(() => {
    if (planning.absence_1 && planning.absence_2 && planning.absence_3) {
      const filteredPlanning = {
        absence_1: planning?.absence_1 || {},
        absence_2: planning?.absence_2 || {},
        absence_3: planning?.absence_3 || {}
      };
      setTransformedAbsenceData(transformPlanningData(filteredPlanning));
    }
  }, [planning])

  useEffect(() => {
    if (modifiers && planning && Object.keys(planning).length > 0 && collaboratorPeriodSetting) {
      setCategoriesData(prevState => {
        return [
          {
            key: 'holiday',
            label: 'Fériés',
            count: modifiers.holidays.length,
            className: 'holiday'
          },
          {
            key: 'annual_holiday',
            label: 'Congé annuel',
            count: modifiers.annualHolidays.length,
            className: 'annual_holiday'
          },
          {
            key: 'absence',
            label: 'Mes congés',
            count: Object.values(planning.absence_1).map(items => items.filter(item => item.numberHours === 0).length).reduce((acc, val) => acc + val, 0),
            maxCount: collaboratorPeriodSetting[1],
            className: 'my_holidays'
          },
          {
            key: 'other',
            label: 'Autres',
            count: modifiers.absence_2.length,
            className: 'other_holiday'
          }
        ]
      });
    }
  }, [modifiers, planning, collaboratorPeriodSetting]);

  useEffect(() => {
    setDayPickerParams({
      disabled: [
        ...modifiers.holidays.map(holiday => new Date(holiday)),
        ...modifiers.annualHolidays.map(holiday => new Date(holiday)),
        ...modifiers.absence_1.map(absence => new Date(absence)),
        ...modifiers.absence_2.map(absence => new Date(absence)),
        ...modifiers.absence_3.map(absence => new Date(absence)),
        { dayOfWeek: [0, 6, ...notWorkingDays] }
      ],
      modifiers: {
        holidays: modifiers.holidays.map(holiday => new Date(holiday)),
        annualHolidays: modifiers.annualHolidays.map(holiday => new Date(holiday)),
        // notWorkingDays: notWorkingDays.map(day => new Date(year, index, day)),
        absence_1: modifiers.absence_1.map(absence => new Date(absence)),
        absence_2: modifiers.absence_2.map(absence => new Date(absence)),
        absence_3: modifiers.absence_3.map(absence => new Date(absence))
      },
      modifiersClassNames: {
        holidays: 'holiday_day',
        annualHolidays: 'annual_holiday_day',
        notWorkingDays: 'not_working_days_day',
        weekend: 'weekend_day',
        absence_1: 'absence_day',
        absence_2: 'other_day',
        absence_3: 'partial_day'
      }
    })
  }, [modifiers, notWorkingDays]);

  const handleOpenCalendar = (index) => {
    setSelectedAbsenceType(null);
    setAttachments([]);
    setSelectedOtherAbsenceType(otherAbsenceOptions[0]);
    setSelectedDays([]);
    setSelectedCollaborator(null);
    setAbsenceDescription(null);
    setPartialAbsenceHours(null);

    if (openedMonthCalendar === index) {
      setOpenedMonthCalendar(null);
    } else {
      setOpenedMonthCalendar(index);
    }
  }

  const handleActiveFilter = (filter) => {
    setActiveHolidayFilter(filter.key);
  }

  const handleDayClick = (selected, triggerDate, modifiers, e) => {
    if (modifiers.outside || modifiers.disabled) {
      return;
    }

    triggerDate.setHours(12, 0, 0, 0);

    const range = addToRange(triggerDate, selectedDays);

    if (range) {
      if (!range.to) {
        range.to = range.from;
      }

      setSelectedDays(range);
    }
  }

  useEffect(() => {
    if (accounts.length === 0) {
      setIsAssociateOutlookModalVisible(true);
    }
  }, []);

  useEffect(() => {
    if (selectedAbsenceType && selectedDays.from && selectedDays.to) {
      setShowEditFormForCalendar(moment(selectedDays.from).month());
    } else {
      setShowEditFormForCalendar(null);
    }
  }, [selectedAbsenceType, selectedDays]);

  useEffect(() => {
    if (absenceToEdit) {
      setOpenedMonthCalendar(absenceToEdit.month);
      setSelectedDays({
        from: absenceToEdit.dates[0],
        to: absenceToEdit.dates[absenceToEdit.dates.length - 1]
      })
      setAbsenceDescription(absenceToEdit.label);
      setSelectedAbsenceType(absenceToEdit.type);
      if (absenceToEdit.backup) {
        setSelectedCollaborator(collaboratorsOptions.find(col => col.label === absenceToEdit.backup));
      }
      if (absenceToEdit.type === 'partial') {
        setPartialAbsenceHours(absenceToEdit.numberHours);
      }
    } else {
      setAbsenceDescription(null);
      setPartialAbsenceHours(null);
    }
  }, [absenceToEdit]);

  useEffect(() => {
    const syncAbsencesToOutlook = async () => {
      if (!transformedAbsenceData?.length || !isAuthenticatedWithTokenToMsal || isSynchedToOutlook) return;

      for (const monthData of transformedAbsenceData) {
        let tempPlanningToSave = null;

        for (const item of monthData.items) {
          if (!item.outlookEvents?.length) continue;

          if (item.vacationStatus === 'APPROVED' && item.outlookEvents[0].outlookEventStatus === 'free') {

            if (!tempPlanningToSave) {
              tempPlanningToSave = prepareCollaboratorAbsencesForSave(
                planning,
                item.month.abbr,
                item.month.index,
                ["absence_1", "absence_2", "absence_3"],
                collaboratorsOptions
              );
            }

            const isMarked = await markOutlookEventsAsBusy(item);
            if (isMarked) {
              tempPlanningToSave = tempPlanningToSave.map(plan =>
                plan.id === item.id
                  ? { ...plan, outlookEvents: item.outlookEvents.map(ev => ({ ...ev, outlookEventStatus: 'busy' })) }
                  : plan
              );

              await saveAbsenceToDB({
                planningToSave: tempPlanningToSave,
                monthIndex: item.month.index,
                showToast: false,
              });
              setIsSavingAbsence(false);
              setIsSynchedToOutlook(true);
            }
          } else if (item.vacationStatus === 'REJECTED' && item.outlookEvents[0].outlookEventStatus === 'free') {
            const tokenResponse = await instance.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
            });

            for (const outlookEvent of item.outlookEvents) {
              console.log("DELETE", item, outlookEvent.outlookEventId);
              await deleteEvent(tokenResponse.accessToken, outlookEvent.outlookEventId);
            }

            let planningToSave = prepareCollaboratorAbsencesForSave(
              planning,
              item.month.abbr,
              item.month.index,
              ["absence_1", "absence_2", "absence_3"],
              collaboratorsOptions
            );

            planningToSave = planningToSave.map(plan =>
              plan.id === item.id
                ? { ...plan, outlookEvents: item.outlookEvents.filter(ev => !ev.outlookEventId) }
                : plan
            );

            await saveAbsenceToDB({ planningToSave, monthIndex: item.month.index, showToast: false });
            setIsSavingAbsence(false);
            setIsSynchedToOutlook(true);
          }
        }
      }
    };

    syncAbsencesToOutlook();
  }, [transformedAbsenceData, accounts]);


  const markOutlookEventsAsBusy = async (item) => {
    let isMarked = true;
    const tokenResponse = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    const timezone = collaborator.zoneId === 1 ? "Europe/Brussels" : "Africa/Casablanca";
    const absenceType = item.type === 'other'? 'other' : item.numberHours > 0 ? 'partial' : 'absence';
    const selectedColor = absenceType === 'other' ? 'purple' : absenceType === 'partial' ? 'red' : 'blue';

    for (let i = 0; i < item.outlookEvents.length; i++) {
      // Add delay every 4 requests
      if (i > 0 && i % 4 === 0) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      const { start, end, outlookEventId } = item.outlookEvents[i];
      const absenceEvent = composeEventForOutlook({
        type: 'absence',
        title: item.label,
        date: moment(start),
        duration: absenceType === 'partial' ?
          item.numberHours * 60 :
          moment(end).diff(moment(start), 'minutes') + (24 * 60),
        timezone: timezone,
        absenceStatus: item.vacationStatus,
        selectedColor: selectedColor,
        absenceType: absenceType,
        showAs: 'busy'
      });
      const result = await updateEvent(tokenResponse.accessToken, outlookEventId, absenceEvent);

      if (!result?.id || result?.showAs !== 'busy') {
        isMarked = false;
      }
    }
    return isMarked;
  }

  const getDatesBetween = (from, to) => {
    let dates = [];
    let currentDate = moment(from).startOf('day'); // Normalize to start of the day
    const endDate = moment(to).startOf('day');

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ")); // Format as needed
      currentDate.add(1, 'day'); // Move to next day
    }

    return dates;
  };

  const handleSaveAbsence = async () => {
    setIsSavingAbsence(true);
    const annualAbsencesData = categoriesData[2];
    const absence_month = absenceToEdit ?
      MONTHS.find(m => m.index === absenceToEdit.month) :
      MONTHS.find(m => m.index === moment(selectedDays.from).month());

    let planningToSave = prepareCollaboratorAbsencesForSave(
      planning,
      absence_month.abbr,
      absence_month.index,
      ["absence_1", "absence_2", "absence_3"],
      collaboratorsOptions,
    );
    let absenceData = null;
    const datesRange = getDatesBetween(selectedDays.from, selectedDays.to);
    const holidays = [...modifiers.holidays, ...modifiers.annualHolidays];
    const listOfDays = [];
    let vacationStatus = 'APPROVED';

    if (isCollaboratorAccess(loggedAs, spaceAs)) {
      vacationStatus = 'PENDING';
    }

    datesRange.forEach((date) => {
      let validDate = true;

      // check if it's a weekend day
      const day = moment(date).day(); // Get the day of the week (0 = Sunday, 6 = Saturday)
      if (day === 0 || day === 6) {
        validDate = false;
      }

      // check if it's a holiday
      holidays.forEach(h => {
        if (moment(date).isSame(h, 'day')) {
          validDate = false;
          return;
        }
      });

      if (validDate) {
        listOfDays.push(date);
      }
    });

    if (!absenceToEdit) {
      // console.log("When saving", {attachments, type: typeof(attachments)});
      const newAbsence = {
        from: moment(selectedDays.from).format('Y-M-D'),
        to: moment(selectedDays.to).format('Y-M-D'),
        month: absence_month.abbr,
        listOfDays: listOfDays,
        numberDays: listOfDays.length,
        numberHours: selectedAbsenceType === 'partial' ? partialAbsenceHours : 0,
        vacationStatus
       };

      if (selectedAbsenceType === 'absence') {
        const newAnnualAbsenceDaysCount = annualAbsencesData.count + listOfDays.length;

        if (newAnnualAbsenceDaysCount > parseInt(annualAbsencesData.maxCount)) {
          toast.error("Attention: Nombre de jours de congé dépassés.");
          setIsSavingAbsence(false);
          return;
        }
        newAbsence.rejectReason = '';
        newAbsence.type = 1;
        newAbsence.reason = absenceDescription;
        newAbsence.backup = selectedCollaborator ? selectedCollaborator.value : null;
       } else if (selectedAbsenceType === 'other') {
        newAbsence.reason = selectedOtherAbsenceType.label;
        newAbsence.type = selectedOtherAbsenceType.value;
        // at this point when we upload a file its type is File
        // with those props {path, name, lastModified,...}
        newAbsence.attachments = attachments?.map(item => item.name);
      } else if (selectedAbsenceType === 'partial') {
        newAbsence.reason = 'Congé partiel';
        newAbsence.type = 1;
      }

      absenceData = newAbsence;
      planningToSave.push({
        ...newAbsence,
        outlookToSave: true
      });
    } else {
      // console.log("When updating", {attachments, type: typeof(attachments)});
      const absence = {
        id: absenceToEdit.id,
        from: moment(selectedDays.from).format('Y-M-D'),
        to: moment(selectedDays.to).format('Y-M-D'),
        month: absence_month.abbr,
        listOfDays: listOfDays,
        numberDays: listOfDays.length,
        numberHours: selectedAbsenceType === 'partial' ? partialAbsenceHours : 0,
        vacationStatus,
      }

      if (selectedAbsenceType === 'absence') {
        const newAnnualAbsenceDaysCount = annualAbsencesData.count - absenceToEdit.dates.length + listOfDays.length;

        if (newAnnualAbsenceDaysCount > parseInt(annualAbsencesData.maxCount)) {
          toast.error("Attention: Nombre de jours de congé dépassés.");
          setIsSavingAbsence(false);
          return;
        }
        absence.rejectReason = '';
        absence.type = 1;
        absence.reason = absenceDescription;
        absence.backup = selectedCollaborator ? selectedCollaborator.value : null;
      } else if (selectedAbsenceType === 'other') {
        absence.reason = selectedOtherAbsenceType.label;
        absence.type = selectedOtherAbsenceType.value;
        // we check if the collab uploads other files or if are reset the same
        // if he uploads again the type will be File
        // otherwise the type is string...
        if (attachments && attachments.length > 0 && attachments[0]?.name) {
          absence.attachments = attachments.map(item => item.name);
        }
      } else if (selectedAbsenceType === 'partial') {
        absence.reason = 'Congé partiel';
        absence.type = 1;
      }

      absenceData = absence;
      planningToSave = planningToSave.map(val => val.id === absence.id ? {
        ...absence,
        outlookToSave: true,
      } : val);
    }

    if (accounts.length > 0) {
      if (absenceToEdit) {
        let outlookEventIdsToRemove = [];

        let oldAbsenceType = 'absence_1';

        if (absenceToEdit.type === 'other') {
          oldAbsenceType = 'absence_2';
        } else if (absenceToEdit.numberHours > 0) {
          oldAbsenceType = 'absence_3';
        }

        const oldAbsenceData = planning[oldAbsenceType][MONTHS.find(m => m.index === absenceToEdit.month).abbr].filter(it => it.id === absenceToEdit.id);
        if (oldAbsenceData?.length > 0 && oldAbsenceData[0].outlookEvents) {
          outlookEventIdsToRemove = JSON.parse(oldAbsenceData[0].outlookEvents);
        }

        const tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        outlookEventIdsToRemove.forEach((outlookEventData) => {
          deleteEvent(tokenResponse.accessToken, outlookEventData.outlookEventId);
        })
      }

      const outlookEventIds = await saveAbsenceEventToOutlook(absenceData);

      saveAbsenceToDB({ planningToSave, monthIndex: absence_month.index, outlookEventIds })
      .finally(() => {
        setIsSavingAbsence(false);
      });
    } else {
      saveAbsenceToDB({ planningToSave, monthIndex: absence_month.index })
      .finally(() => {
        setIsSavingAbsence(false);
      });
    }
  }

  const saveAbsenceToDB = async ({
    planningToSave,
    monthIndex,
    outlookEventIds = null,
    showToast = true
  }) => {
    console.log('Saving to DB', {planningToSave, monthIndex, outlookEventIds, showToast});
    saveCollaboratorAbsence(
      token,
      navCommunity.id,
      planningToSave,
      collaborator.id,
      year,
      monthIndex,
      `${user.firstName} ${user.lastName}`,
      false,
      attachments,
      outlookEventIds,
    ).then(() => {
      collaboratorCalendarQuery.refetch();
      setAbsenceToEdit(null);
      if (showToast) {
        toast.success(_("saveSuccess"));
      }
    });
  }

  const handleDeleteAbsence = () => {
    if (accounts.length === 0) {
      deleteAbsenceFromDB();
      return;
    }

    if (absenceToDelete) {
      removeEventsFromOutlook(absenceToDelete)
      .finally(() => {
        deleteAbsenceFromDB();
      })
    }
  }

  const deleteAbsenceFromDB = () => {
    const absence_month = absenceToDelete.month;

    let oldPlanning = prepareCollaboratorAbsencesForSave(
      planning,
      absence_month.abbr,
      absence_month.index,
      ["absence_1", "absence_2", "absence_3"]
    );
    const planningToSave = oldPlanning.filter(plan => plan.id !== absenceToDelete.id);

    saveCollaboratorAbsence(
      token,
      navCommunity.id,
      planningToSave,
      collaborator.id,
      year,
      absence_month.index,
      `${user.firstName} ${user.lastName}`,
      false,
    ).then((response) => {
      collaboratorCalendarQuery.refetch();
      setAbsenceToDelete(null);
      toast.success(_("saveSuccess"));
    });
  }

  const removeEventsFromOutlook = async (absence) => {
    let outlookEventIdsToRemove = [];
    let oldAbsenceType = 'absence_1';

    if (absence.type === 'other') {
      oldAbsenceType = 'absence_2';
    } else if (absence.numberHours > 0) {
      oldAbsenceType = 'absence_3';
    }

    const oldAbsenceData = planning[oldAbsenceType][absence.month.abbr].filter(it => it.id === absence.id);
    if (oldAbsenceData?.length > 0 && oldAbsenceData[0].outlookEvents) {
      outlookEventIdsToRemove = JSON.parse(oldAbsenceData[0].outlookEvents);
    }

    const tokenResponse = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    outlookEventIdsToRemove.forEach((outlookEventData) => {
      deleteEvent(tokenResponse.accessToken, outlookEventData.outlookEventId);
    })
  }

  const handleApproveAbsence = (absence) => {
    setIsApprovingAbsence(true);
    const absence_month = absence.month;
    let oldPlanning = prepareCollaboratorAbsencesForSave(
      planning,
      absence_month.abbr,
      absence_month.index,
      ["absence_1", "absence_2", "absence_3"]
    );

    const absenceToApprove = oldPlanning.find(val => val.id === absence.id);

    absenceToApprove.vacationStatus = "APPROVED";
    if (absence.type === 'absence') {
      absenceToApprove.type = 'absence_1';
    } else if (absence.type === 'other') {
      absenceToApprove.type = 'absence_2';
    } else if (absence.type === 'partial') {
      absenceToApprove.type = 'absence_3';
    }

    saveCollaboratorAbsence(
      token,
      navCommunity.id,
      [absenceToApprove],
      collaborator.id,
      year,
      absence_month.index,
      `${user.firstName} ${user.lastName}`,
      true
    ).then((response) => {
      collaboratorCalendarQuery.refetch();
      toast.success(_("saveSuccess"));
    }).finally(() => {
      setIsApprovingAbsence(false);
    });
  }

  const handleRejectAbsence = (absence, rejectReason) => {
    setIsRejectingAbsence(true);
    const absence_month = absence.month;
    let oldPlanning = prepareCollaboratorAbsencesForSave(
      planning,
      absence_month.abbr,
      absence_month.index,
      ["absence_1", "absence_2", "absence_3"]
    );

    const absenceToReject = oldPlanning.find(val => val.id === absence.id);

    absenceToReject.vacationStatus = "REJECTED";
    if (absence.type === 'absence') {
      absenceToReject.type = 'absence_1';
    } else if (absence.type === 'other') {
      absenceToReject.type = 'absence_2';
    } else if (absence.type === 'partial') {
      absenceToReject.type = 'absence_3';
    }

    if (rejectReason) {
      absenceToReject.rejectReason = rejectReason;
    }

    saveCollaboratorAbsence(
      token,
      navCommunity.id,
      [absenceToReject],
      collaborator.id,
      year,
      absence_month.index,
      `${user.firstName} ${user.lastName}`,
      true
    ).then((response) => {
      collaboratorCalendarQuery.refetch();
      toast.success(_("saveSuccess"));
    }).finally(() => {
      setIsRejectingAbsence(false);
    });
  }

  const saveAbsenceEventToOutlook = async (absence) => {
    const timezone = collaborator.zoneId === 1 ? "Europe/Brussels" : "Africa/Casablanca";
    const selectedColor = absence.type === 2 ? 'purple' : absence.numberHours > 0 ? 'red' : 'blue';
    const absenceType = absence.type === 2 ? 'other' : absence.numberHours > 0 ? 'partial' : 'absence';

    const tokenResponse = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    const groupedDates = groupConsecutiveDates(absence.listOfDays);
    const savedOutlookEvents = [];

    for (const { start, end } of groupedDates) {
      const absenceEvent = composeEventForOutlook({
        type: 'absence',
        title: absence.reason,
        date: moment(start),
        duration: absenceType === 'partial' ?
          absence.numberHours * 60 :
          moment(end).diff(moment(start), 'minutes') + (24 * 60),
        timezone: timezone,
        absenceStatus: absence.vacationStatus,
        selectedColor: selectedColor,
        absenceType: absenceType,
      });

      const eventResponse = await createEvent(tokenResponse.accessToken, absenceEvent);
      // console.log(`Event created from ${start} to ${end}:`, eventResponse);
      savedOutlookEvents.push({
        absenceId: absence.id,
        start,
        end,
        outlookEventId: eventResponse.id,
        outlookEventStatus: eventResponse.showAs
      });
    }

    return savedOutlookEvents;
  };

  const groupConsecutiveDates = (dates) => {
    if (!dates || dates.length === 0) return [];

    const sortedDates = dates.map(d => moment(d)).sort((a, b) => a.diff(b, 'days'));
    const grouped = [];

    let start = sortedDates[0];
    let end = start;

    for (let i = 1; i < sortedDates.length; i++) {
      if (sortedDates[i].diff(end, 'days') === 1) {
        // Continue the range
        end = sortedDates[i];
      } else {
        // Save the previous range and start a new one
        grouped.push({ start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") });
        start = sortedDates[i];
        end = start;
      }
    }

    // Push the last range
    grouped.push({ start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") });

    return grouped;
  };

  if (isFetchingData) {
    return (
      <div className="ripple_loading">
        <img src={"/img/Ripple-1s-150px.svg"} alt="Loader SVG" />
      </div>
    )
  }

  return (
    <div>
      <section className={styles.page_header}>
        <div className={styles.title_section}>
          <h2 className={styles.page_title}>
            Gestion de calendrier
          </h2>
          <h4 className={styles.page_subtitle}>
            Gérer le calendrier du collaborateur.
          </h4>
        </div>

        <div className={styles.actions_section}>
          {isAuthenticatedWithTokenToMsal ? (
            <>
              <p className="outlook-associated-text">
                <CheckFilledIcon color="#02af8e" size={14} />
                <span>Outlook associé</span>
              </p>
              {/* <MsalSignOutButton /> */}
            </>
          ) : (
            <MsalSignInButton />
          )}
        </div>
      </section>

      <div className={styles.main_container}>
        <section className={styles.months_container}>
          {/* I separate this to two columns for appearance purpose */}
          {/* If I put these at whole and use flex-box the styles not properly work */}

          <CalendarsColumn
            year={year}
            months={MONTHS.filter((month, index) => index % 2 === 0)}
            showEditFormForCalendar={showEditFormForCalendar}
            setShowEditFormForCalendar={setShowEditFormForCalendar}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            setSelectedHolidayOption={setSelectedAbsenceType}
            selectedHolidayOption={selectedAbsenceType}
            handleOpenCalendar={handleOpenCalendar}
            openedMonthCalendar={openedMonthCalendar}
            dayPickerParams={dayPickerParams}
            handleDayClick={handleDayClick}
            editDescription={absenceDescription}
            setEditDescription={setAbsenceDescription}
            collaboratorsOptions={collaboratorsOptions}
            selectedCollaborator={selectedCollaborator}
            setSelectedCollaborator={setSelectedCollaborator}
            holidaysTypesOptions={otherAbsenceOptions}
            selectedHolidayTypeOption={selectedOtherAbsenceType}
            setSelectedHolidayTypeOption={setSelectedOtherAbsenceType}
            attachments={attachments}
            setAttachments={setAttachments}
            handleSaveAbsence={handleSaveAbsence}
            isSavingAbsence={isSavingAbsence}
            partialAbsenceHours={partialAbsenceHours}
            setPartialAbsenceHours={setPartialAbsenceHours}
          />

          <CalendarsColumn
            year={year}
            months={MONTHS.filter((month, index) => index % 2 !== 0)}
            showEditFormForCalendar={showEditFormForCalendar}
            setShowEditFormForCalendar={setShowEditFormForCalendar}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            setSelectedHolidayOption={setSelectedAbsenceType}
            selectedHolidayOption={selectedAbsenceType}
            handleOpenCalendar={handleOpenCalendar}
            openedMonthCalendar={openedMonthCalendar}
            dayPickerParams={dayPickerParams}
            handleDayClick={handleDayClick}
            editDescription={absenceDescription}
            setEditDescription={setAbsenceDescription}
            collaboratorsOptions={collaboratorsOptions}
            selectedCollaborator={selectedCollaborator}
            setSelectedCollaborator={setSelectedCollaborator}
            holidaysTypesOptions={otherAbsenceOptions}
            selectedHolidayTypeOption={selectedOtherAbsenceType}
            setSelectedHolidayTypeOption={setSelectedOtherAbsenceType}
            attachments={attachments}
            setAttachments={setAttachments}
            handleSaveAbsence={handleSaveAbsence}
            isSavingAbsence={isSavingAbsence}
            partialAbsenceHours={partialAbsenceHours}
            setPartialAbsenceHours={setPartialAbsenceHours}
          />
        </section>

        <section className={styles.categories_container}>
          <CategoriesHeader categories={categoriesData} />

          <HolidayFilters
            filters={holidayFilters}
            activeFilter={activeHolidayFiler}
            onFilterChange={handleActiveFilter}
          />

          <div className={styles.holidays_list}>
            {transformedPlanningData?.map((item, index) => (
              item.items.length > 0 && (
                <div key={index} className={styles.holidays_list_month}>
                  {/* if there is no holiday for the month we ignore it */}
                  {(activeHolidayFiler === 'ALL' || item.items.find(i => i.vacationStatus === activeHolidayFiler)) && (
                    <span className={styles.month_label}>{item.monthName} :</span>
                  )}

                  {item.items?.map((holiday, index) => (
                    (activeHolidayFiler === 'ALL' || holiday.vacationStatus === activeHolidayFiler) && (
                      <HolidayListItem
                        holiday={holiday}
                        activeHolidayFiler={activeHolidayFiler}
                        collaborator={collaborator}
                        onEditAbsence={(value) => {
                          setAbsenceToEdit({ ...value, month: item.index });
                          if (value.type === 'other') {
                            setSelectedOtherAbsenceType(
                              otherAbsenceOptions.find(hto => hto.label === value.label)
                            )
                            setAttachments(value.attachments);
                          }
                        }}
                        onDeleteAbsence={() => {
                          setAbsenceToDelete(holiday);
                          setIsAbsenceDeletionModalOpen(true);
                        }}
                        onApproveAbsence={(absence) => {
                          handleApproveAbsence(absence);
                        }}
                        onRejectAbsence={(absence, rejectReason) => {
                          handleRejectAbsence(absence, rejectReason);
                        }}
                        handleShowAttachments={(attachments) => {
                          setAttachments(attachments);
                          setIsAttachmentModalVisible(true);
                        }}
                        isApprovingAbsence={isApprovingAbsence}
                        isRejectingAbsence={isRejectingAbsence}
                      />
                    )
                  ))}
                </div>
              )
            ))}
          </div>
        </section>
      </div>

      <ModalConfirm
        type={"delete"}
        isOpen={isAbsenceDeletionModalOpen}
        onCancel={() => {
          setIsAbsenceDeletionModalOpen(false);
          setAbsenceToDelete(null);
        }}
        onConfirm={() => {
          handleDeleteAbsence();
          setIsAbsenceDeletionModalOpen(false);
        }}
        inProcess={false}
        actionFailed={false}
        title={_("confirmationDeleteCategoryTitle")}
        text={_("confirmationDeleteCalendarConfigMessage")}
        labelNo={_("cancel")}
        labelYes={_("delete")}
        labelError={"Error"}
      />

      <AttachmentsModal
        isVisible={isAttachementsModalVisible}
        onClose={() => setIsAttachmentModalVisible(false)}
        attachments={attachments}
        collaboratorId={collaborator.id}
        token={token}
      />

      <ModalConfirm
        type={"privatize"}
        isOpen={isAssociateOutlookModalVisible}
        onCancel={() => {
          setIsAssociateOutlookModalVisible(false);
        }}
        onConfirm={() => {
          handleOutlookLogin("popup", instance, loginRequest);
          setIsAssociateOutlookModalVisible(false);
        }}
        inProcess={false}
        actionFailed={false}
        title="Votre compte Outlook n'est pas associé"
        text="Voulez-vous associer votre compte pour synchroniser vos congés ?"
        labelNo={_("cancel")}
        labelYes="Associer Outlook"
        labelError={"Error"}
      />
    </div>
  )
}
