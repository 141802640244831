import { IconClose } from "../../common/Icons";

export default function Preview({ src, onClose }) {
  return (
    <div className="invoice-pdf-iframe">
      <iframe
        src={src}
        width="90%"
        height="90%"
        style={{ position: "relative", border: "none", margin: "auto" }}
      />
      <div onClick={onClose} className="icon-close-iframe">
        <IconClose />
      </div>
    </div>
  );
}
