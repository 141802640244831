import axios from "axios";

import { TTP_API_URL } from "../config";

export const getClientsList = (id, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/clients/list`;
  let filter = [];

  if (data.keyWord && data.keyWord.length > 0) {
    filter.push({
      property: "name",
      value: data.keyWord,
      operator: "like",
    });
  }

  let params = {
    access_token: token,
    organization_id: id,
    organizationId: id,
    collaborators: data.collaborators,
    deletedCollaborators: data.deletedCollaborators,
    role: data.role,
    entityClientId: data.clients,
    budgetStatus: JSON.stringify(data.budgetStatus),
    sortBy: data.sortBy,
    filter,
    year: data.year,
    isEntity: data.isEntity,
    deletedStatus: data.deletedStatus,
    clientCRMGroup: JSON.stringify(data.clientCRMGroup),
    clientCRMTypes: JSON.stringify(data.clientCRMTypes),
    departStatus: data.departStatus,
    partner: data.partner,
    partnerZone: data.partnerZone,
  };

  params.limit = data.pageSize || 10;
  params.start = !data.paginationPage
    ? 0
    : (data.paginationPage - 1) * data.pageSize;

  return axios.get(requestUrl, {
    params,
  });
};

export const getClientsSuggestions = (token, groups, id, typeClients, year) => {
  const requestUrl = `${TTP_API_URL}/team/clients`;
  let filter = [
    {
      property: "isDeleted",
      value: 0,
      operator: "eq",
    },
  ];
  let params = {
    organization_id: id,
    access_token: token,
    organizationId: id,
    groups: groups,
    nolimit: true,
    filter: JSON.stringify(filter),
    typeClients: typeClients,
    year: year,
  };
  return axios.get(requestUrl, {
    params,
  });
};

export const getCollaboratorsSuggestions = (token, id, isDeleted, year) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators`;

  let params = {
    organization_id: id,
    access_token: token,
    organization: id
  };

  if (isDeleted !== undefined) {
    params.isDeleted = isDeleted;
  }

  if (year !== undefined) {
    params.year = year;
  }

  return axios.get(requestUrl, {
    params,
  });
};

export const getZonesSuggestions = (token, id) => {
  const requestUrl = `${TTP_API_URL}/team/zones`;

  let params = {
    organization_id: id,
    access_token: token,
    organization: id,
  };
  return axios.get(requestUrl, {
    params,
  });
};

export const getCollaboratorSettingSuggestions = (token, id) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators_setting/suggestions`;
  let params = {
    organization_id: id,
    access_token: token,
    organization: id,
  };
  return axios.get(requestUrl, {
    params,
  });
};

export const getCategoriesSuggestions = (id, token) => {
  const requestUrl = `${TTP_API_URL}/team/task-categories`;

  let params = {
    organization_id: id,
    access_token: token,
    organization: id,
  };
  return axios.get(requestUrl, {
    params,
  });
};

export const getGroupsDescription = (id, token) => {
  const requestUrl = `${TTP_API_URL}/team/client-groups`;

  let params = {
    organization_id: id,
    access_token: token,
    organizationId: id,
  };
  return axios.get(requestUrl, {
    params,
  });
};

export const getCollaboratorsAvatars = (id, token) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/avatars`;

  let params = {
    organization_id: id,
    access_token: token,
    organization: id,
  };
  return axios.get(requestUrl, {
    params,
  });
};
export const getOrganisationPeriodSetting = (organizationId, year, token) => {
  const requestUrl = `${TTP_API_URL}/team/organization_setting/get`;

  let params = {
    access_token: token,
    organization: organizationId,
    year: year,
  };
  return axios.get(requestUrl, {
    params,
  });
};
