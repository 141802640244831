export function SyncSlashIcon({size = 14, color = '#000', ...params}) {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      {...params}
    >
      <path d="M18.3,7.1C18.3,7.1,18.3,7.1,18.3,7.1l1.4-1.4c0,0,0,0,0,0l2-2c0,0,0,0,0,0c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-2,2c-3.6-3-8.9-3.1-12.6,0C5.5,4.4,5.3,4.6,5.1,4.8V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v4.5c0,0.6,0.4,1,1,1h4.5c0.6,0,1-0.4,1-1s-0.4-1-1-1H6.2c2.5-2.6,6.5-3.3,9.8-1.4c0.3,0.2,0.6,0.4,0.9,0.6L5.7,16.9C4.6,15.5,4,13.8,4,12c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,2.3,0.8,4.6,2.3,6.3l-2,2C2.1,20.5,2,20.7,2,21c0,0.6,0.4,1,1,1c0.3,0,0.5-0.1,0.7-0.3l2-2c0,0,0,0,0,0l1.4-1.4c0,0,0,0,0,0L18.3,7.1z M19.9,15.5h-4.5c-0.6,0-1,0.4-1,1s0.4,1,1,1h2.4c-2.2,2.4-5.8,3.2-8.9,1.9l-1.5,1.5c1.4,0.7,3,1.1,4.6,1.1c2.6,0,5-1,6.9-2.8V21c0,0.6,0.4,1,1,1s1-0.4,1-1v-4.5C20.9,15.9,20.4,15.5,19.9,15.5z M19.4,8.9c0.4,1,0.6,2,0.6,3.1c0,0.6,0.4,1,1,1s1-0.4,1-1c0-1.6-0.4-3.2-1.1-4.6L19.4,8.9z"/>
    </svg>
  )
}
