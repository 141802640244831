import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import _ from './../../../../../i18n';
import classNames from 'classnames';
import moment from 'moment';

export default function Filters({
  filters,
  setFilters,
  year,
  setSelectedFilter
}) {
  // const [filter, setFilter] = useState(defaultFiltersValue);

  // useEffect(() => {
  //   setFilter((prevFilter) => {
  //     const updatedFilterTab = prevFilter.filterTab.map((tab) => {
  //       if (tab.id === "TERMLY") {
  //         return {
  //           ...tab,
  //           subTab: tab.subTab.map((subTab) => {
  //             const disabled = subTab.id > 1 ? moment().quarter() < subTab.id && moment().year() === year : false;
  //             console.log(disabled, moment().quarter() < subTab.id, moment().year() === year, subTab.id, moment().quarter(), moment().year(), year);
  //             return { ...subTab, disabled };
  //           }),
  //         };
  //       }
  //       return tab;
  //     });

  //     return {
  //       ...prevFilter,
  //       year,
  //       filterTab: updatedFilterTab,  // Ensure we set the updated filterTab
  //     };
  //   });
  // }, [year]);

  useEffect(() => {
    console.log(filters.period, filters.filterTab);
    if (filters.period.id === 'ANNUAL') {
      setSelectedFilter(filters.period);
    } else if (filters.period.id === 'TERMLY') {
      setSelectedFilter(filters.subPeriod);
    }
  }, [filters]);

  return (
    <div className={styles.filter_container}>
      <div className={styles.period_selector}>
        {filters.filterTab.map((item) => (
          <div
            key={item.id}
            className={classNames({
              [styles.period_selector_item]: true,
              [styles.period_selector_item_active]: filters.period.id === item.id,
            })}
            onClick={() => {
              setFilters({
                ...filters,
                period: item,
                subPeriod: "ANNUAL" !== item.id ? item.subTab[0] : null,
              });
            }}
          >
            {_(item.label)}
          </div>
        ))}
      </div>
      <div className={styles.sub_period_selector}>
        {"ANNUAL" !== filters.period.id && (
          <div className={styles.sub_period_selector_items}>
            {filters.period.subTab.map((tab) => {
              return (
                <div
                  key={tab.id}
                  className={classNames({
                    [styles.item_tag]: true,
                    [styles.item_active]: filters.subPeriod.id === tab.id,
                    [styles.item_disabled]: (tab.disabled && filters.period.id === "TERMLY")
                  })}
                  onClick={() =>
                    !tab.disabled
                      ? setFilters({ ...filters, subPeriod: tab })
                      : null
                  }
                >
                  {tab.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
