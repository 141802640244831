import { useEffect, useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import { useQuery } from "react-query";
import {
  getCollaboratorStatistics,
  getLastCollaboratorTimesheet,
} from "../../../api/dashboard/collaborator";
import _ from "../../../i18n";
import { formatNumber } from "../../../utils";
import { AnimatedBar } from "../components/AnimatedBar";
import { CustomCard, CustomPieChart } from "../Partner/Stats";
import styles from "./Dashboard.module.scss";

export default function CollaboratorStats({
  token,
  organization,
  collaborator,
  year,
}) {
  const [timeRegistration, setTimeRegistration] = useState([]);
  const [stats, setStats] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [arrivalActive, setArrivalActive] = useState(false);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const lastCollaboratorTimesheetQuery = useQuery(
    ["lastCollaboratorTimesheetQuery", token, organization?.id, collaborator?.id],
    async () => {
      if (token && collaborator && organization) {
        try {
          const response = await getLastCollaboratorTimesheet(
            token,
            organization.id,
            collaborator.id,
            year
          );
          setTimeRegistration(response.data.data);
        } catch (e) {
          setTimeRegistration(null);
          return e;
        }
      }
    }
  );

  const collaboratorStats = useQuery(
    ["getCollaboratorStatistics", token, organization?.id, collaborator?.id],
    async () => {
      if (token && collaborator && organization) {
        try {
          const response = await getCollaboratorStatistics(
            token,
            organization.id,
            collaborator.id,
            collaborator.role.type,
            year
          );
          const data = response.data.data;
          setStats(data);
        } catch (e) {
          setStats(null);
          return e;
        }
      }
    }
  );

  useEffect(() => {
    lastCollaboratorTimesheetQuery.refetch();
    collaboratorStats.refetch();
  }, [year]);

  const hours = stats?.hours;
  const economic = stats?.economic;
  const client = stats?.client;
  const departure = client?.departures;
  const arrival = client?.arrivals;

  return (
    <div className={styles.main_stats}>
      <AnimatedBar data={timeRegistration} />
      {/* Other stats */}
      {collaboratorStats.isFetching ? (
        <div style={{ margin: "10px auto" }}>Loading...</div>
      ) : (
        collaboratorStats.isFetched && stats && (
          <div className={styles.main_stats_stats}>
            <div className={styles.main_stats_stats_pie_chart}>
              {stats && (
                <CustomPieChart
                  data={stats}
                  index={activeIndex}
                  action={onPieEnter}
                />
              )}
              <label>
                <strong>{`${stats?.client?.total}`}</strong>
                {_("clientFolders")}
              </label>
            </div>
            <CustomCard
              budget={hours?.total}
              real={hours?.real}
              unit="H"
              title="hourBudget"
              color={hours?.real > hours?.total ? "red" : "#06D9B1"}
              styles={styles}
            />
            <CustomCard
              budget={economic?.total}
              real={economic?.real}
              unit="€"
              title="economicBudget"
              color={economic?.real > economic?.total ? "red" : "#EA8124"}
              styles={styles}
            />
            <div className={styles.main_stats_stats_card}>
              <div className={styles.title}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setArrivalActive(!arrivalActive)}
                >
                  {arrivalActive
                    ? _(`${arrival?.title}`)
                    : _(`${departure?.title}`)}
                  <TiArrowUnsorted />
                </div>
                <div>
                  {arrivalActive
                    ? parseInt(arrival?.total) < 10
                      ? `0${arrival?.total} `
                      : arrival?.total
                    : parseInt(departure?.total) < 10
                    ? `0${departure?.total} `
                    : departure?.total}
                </div>
              </div>
              <div className={styles.departure_info}>
                <div>
                  {arrivalActive
                    ? `${formatNumber(arrival?.hours)} h`
                    : `${formatNumber(departure?.hours)} h`}
                </div>
                <div>
                  {arrivalActive
                    ? `${formatNumber(arrival?.economic)} €`
                    : `${formatNumber(departure?.economic)} €`}
                </div>
              </div>
            </div>
            <div className={styles.main_stats_stats_card}>
              <div className={styles.offshoring_stats}>
                <div>
                  {_("Offshoring")}{" "}
                  <span>{`${client?.offshoring.toFixed(1)}%`}</span>
                </div>
                <div className={styles.bar_container}>
                  <div
                    className={styles.bar}
                    style={{ width: `${client?.offshoring}%` }}
                  />
                </div>
              </div>

              <div className={styles.offshoring_stats}>
                <div>
                  {_("horusFolders")}{" "}
                  <span>{`${client?.horus.toFixed(1)}%`}</span>
                </div>
                <div className={styles.bar_container}>
                  <div
                    className={styles.bar}
                    style={{ width: `${client?.horus}%` }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
