import classNames from 'classnames'
import styles from './index.module.scss'
import moment from 'moment';

export const renderFunctions = {
  depositDocsTableRenderStatusColumn: (_, rowData) => {
    const depositDate = moment(rowData.depositDate);
    const processingLimitDate = moment(rowData.processingLimitDate);
    const lastModifiedDate = moment(rowData.lastModifiedDate);
    const documentStatus = rowData.docStatus;
    const isBeforeBy7Days = lastModifiedDate.clone().add(7, 'days').isSameOrBefore(processingLimitDate);
    const isAfterLimitDate = lastModifiedDate.isAfter(processingLimitDate);
    let isGreen = false;
    let isOrange = false;
    let isRed = false;

    // before 7 days
    // -- status = CONFIRMED      (green)
    // -- status != CONFIRMED     (orange)

    // after limit date
    // -- status = CONFIRMED      (red)
    // -- status != CONFIRMED     (red)

    // within 7 days before limit date
    // -- status = CONFIRMED      (orange)
    // -- status != CONFIRMED     (orange)

    if (isBeforeBy7Days && documentStatus === 'CONFIRMED') {
      isGreen = true;
    }

    if (isAfterLimitDate) {
      isRed = true;
    }


    const classes = classNames({
      [styles.status_cercle]: true,
      [styles.status_cercle_green]: isGreen,
      [styles.status_cercle_orange]: !isGreen && !isRed,
      [styles.status_cercle_red]: isRed,
    });

    return (
      <span className={classes} />
    )
  },
  depositDocsTableRenderPercentageColumn: (value, rowData, more, a) => {
    console.log({rowData, more, a, value})
    // const val = rowData.total
    // return <span>{value}%</span>
  },
  depositDocsTableRenderActionColumn: (value, rowData) => {
    const depositDate = moment(rowData.depositDate);
    const processingLimitDate = moment(rowData.processingLimitDate);
    const lastModifiedDate = moment(rowData.lastModifiedDate);
    const documentStatus = rowData.docStatus;
    const isBeforeBy7Days = lastModifiedDate.clone().add(7, 'days').isSameOrBefore(processingLimitDate);
    const isAfterLimitDate = lastModifiedDate.isAfter(processingLimitDate);
    let text = 'Rappel envoyé au responsable';

    if (isBeforeBy7Days && documentStatus === 'CONFIRMED') {
      text = 'Aucune';
    }

    if (isAfterLimitDate) {
      text = 'Priorité urgente: alerte au manager'
    }
    return (
      <p>{text}</p>
    )
  },
  dateColumn: (value, rowData) => {
    return <span>{moment(value).format("DD/MM/YY")}</span>
  }
}
