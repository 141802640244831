import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { saveAs } from "file-saver";
import moment from "moment";
import _ from "../../../i18n";
import * as XLSX from "xlsx";

import { getCollaboratorFinancial } from "../../../api";

import { formatAmount, renderDurationDisplay } from "../../../utils";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import FinancialCollaboratorFilter from "./FinancialCollaboratorFilter";
import { AlertCircleIcon } from "../../common/Icons";

import "../CollaboratorSpace.local.scss";
import "./FinancialCollaborator.local.scss";
import { Tooltip } from "antd";

const FinancialCollaborator = ({ id, name, year, token, organization }) => {
  //Header
  const [filtersCount, setFiltersCount] = useState(0);
  const [filter, setFilter] = useState({
    clientCRMTypes: ["CLIENT"],
    deletedStatus: "NOT_DELETED",
  });
  const [sortApplication, setSortApplication] = useState(null);

  //Body
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      id: "client",
      label: _("client"),
      widthPercentage: 15,
    },
    {
      id: "budget",
      label: _("budget"),
      widthPercentage: 10,
    },
    {
      id: "real",
      label: _("réalisé"),
      widthPercentage: 10,
    },
    {
      id: "caAdjusted",
      label: _("collaboratorCA"),
      widthPercentage: 25,
    },
    {
      id: "delta",
      label: _("delta"),
      widthPercentage: 13,
    },
    {
      id: "benchmarkEffectif",
      label: _("effectifBenshmark"),
      widthPercentage: 14,
    },
    {
      id: "benchmarkBudgetaire",
      label: _("budgetaireBenshmark"),
      widthPercentage: 14,
    },
  ]);

  let collaboratorFinancialQuery = useQuery(
    ["collaboratorFinancialQuery", filter],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorFinancial(
            id,
            organization,
            token,
            year,
            filter
          );
          setData(response.data.data);
        } catch (e) {
          setData([]);
          return null;
        }
      }
    }
  );

  const sortClientBy = (type) => {
    let sortedData = [...data].filter((a) => a.client !== "TOTAL");
    let totalData = data.filter((a) => a.client === "TOTAL")[0];
    switch (type) {
      case "delta_asc":
        sortedData.sort((a, b) => {
          let aDelta =
            a.collabCaReal > 0
              ? parseInt(
                  parseFloat(
                    (a.collabCaAdjusted - a.collabCaReal) / a.collabCaReal
                  ).toFixed(2) * 100
                )
              : 0;
          let bDelta =
            b.collabCaReal > 0
              ? parseInt(
                  parseFloat(
                    (b.collabCaAdjusted - b.collabCaReal) / b.collabCaReal
                  ).toFixed(2) * 100
                )
              : 0;
          return aDelta > bDelta ? -1 : 1;
        });
        sortedData.unshift(totalData);
        setData(sortedData);
        return;
      case "delta_desc":
        sortedData.sort((a, b) => {
          let aDelta =
            a.collabCaReal > 0
              ? parseInt(
                  parseFloat(
                    (a.collabCaAdjusted - a.collabCaReal) / a.collabCaReal
                  ).toFixed(2) * 100
                )
              : 0;
          let bDelta =
            b.collabCaReal > 0
              ? parseInt(
                  parseFloat(
                    (b.collabCaAdjusted - b.collabCaReal) / b.collabCaReal
                  ).toFixed(2) * 100
                )
              : 0;
          return aDelta < bDelta ? -1 : 1;
        });
        sortedData.unshift(totalData);
        setData(sortedData);
        return;
      default:
        return;
    }
  };

  const renderCelluleContent = (key, item) => {
    switch (key) {
      case "client":
        return item.client;
      case "budget":
        return item.clientBudget ? (
          <div className="composed_cellule">
            <div>{renderDurationDisplay(item.collabBudget * 60)}</div>
            <div>
              <span>{_("total")}: </span>
              <span>{renderDurationDisplay(item.clientBudget * 60)}</span>
            </div>
          </div>
        ) : (
          "0h"
        );
      case "real":
        return item.collabReal ? (
          <div className="composed_cellule">
            <div>{renderDurationDisplay(item.collabReal * 60)}</div>
            <div>
              <span>{_("total")}: </span>
              <span>{renderDurationDisplay(item.clientReal * 60)}</span>
            </div>
          </div>
        ) : (
          "0h"
        );
      case "caAdjusted":
        return (
          <div className="composed_cellule">
            {/* <div>{Math.round(item.collabCaAdjusted)}€</div> */}
            <div>{formatAmount(item.collabCaAdjusted)}</div>
            <div>
              <div>
                <span>{_("tauxEffectif")}: </span>
                <span>{item.tauxEffectif}€/heure</span>
              </div>
              <div>
                <span>{_("tauxBudgetaire")}: </span>
                <span>{item.tauxBudgetaire}€/heure</span>
              </div>
            </div>
          </div>
        );
      case "delta":
        let delta =
          item.collabCaReal > 0
            ? parseInt(
                parseFloat(
                  (item.collabCaAdjusted - item.collabCaReal) /
                    item.collabCaReal
                ).toFixed(2) * 100
              )
            : 0;
        return `${delta}%`;
      case "benchmarkEffectif":
        return (
          <div>
            <div>{formatAmount(item.benchmarkEffectif, false)}</div>
            <div></div>
          </div>
        );
      case "benchmarkBudgetaire":
        return (
          <div>
            <div>{formatAmount(item.benchmarkBudgetaire, false)}</div>
            <div></div>
          </div>
        );
      default:
        return;
    }
  };

  const renderFinancialTable = () => {
    let headerCellules = columns.map((column) => (
      <div
        style={{
          width: `${column.widthPercentage}%`,
          display: column.label === "delta" ? "flex" : "inline-block",
        }}>
        {column.label}{" "}
        {column.label === "delta" && (
          <Tooltip
            key="tool-1"
            placement="top"
            title={`(${_("Chiffre d'affaire ajusté du collab")} - ${_(
              "Chiffre d'affaire reel du collab"
            )}) /
                    ${_("Chiffre d'affaire ajusté du collab")}`}
            mouseLeaveDelay={0}>
            <div
              style={{
                padding: "0px",
                height: 0,
              }}>
              <AlertCircleIcon />
            </div>
          </Tooltip>
        )}
        {column.label === "delta" && (
          <div
            style={{
              padding: "0px",
              height: 0,
              cursor: "pointer",
            }}
            onClick={() => {
              if (!sortApplication) {
                sortClientBy("delta_asc");
                setSortApplication("delta_asc");
              } else {
                sortClientBy(
                  sortApplication === "delta_desc" ? "delta_asc" : "delta_desc"
                );
                setSortApplication(
                  sortApplication === "delta_desc" ? "delta_asc" : "delta_desc"
                );
              }
            }}>
            {sortApplication === "delta_desc" ? (
              <TiArrowSortedUp />
            ) : (
              <TiArrowSortedDown />
            )}
          </div>
        )}
      </div>
    ));
    let bodyCellules =
      data.length > 0 &&
      data.map((item) => {
        return (
          <div
            className={`${item.client === "TOTAL" ? "total_line" : ""} line`}>
            {columns.map((column) => {
              return (
                <div
                  className="cellule"
                  style={{ width: `${column.widthPercentage}%` }}>
                  {renderCelluleContent(column.id, item)}
                </div>
              );
            })}
          </div>
        );
      });
    return (
      <div className="financial_table">
        <div className="table_header">{headerCellules}</div>
        <div className="table_body">{bodyCellules}</div>
      </div>
    );
  };

  const exportToExcel = () => {
    let exportedData = data.map((item) => {
      let delta =
        item.collabCaReal > 0
          ? parseInt(
              parseFloat(
                (item.collabCaAdjusted - item.collabCaReal) / item.collabCaReal
              ).toFixed(2) * 100
            )
          : 0;
      return {
        client: item.client,
        budget_client: item.clientBudget,
        presta_ts_client: item.clientReal,
        ca_client: item.clientInvoice,
        budget_collaborator: item.collabBudget,
        presta_ts_collaborator: item.collabReal,
        ca_adjusted_collaborator: item.collabCaAdjusted,
        delta: `${delta}%`,
        benchmark_effectif: item.benchmarkEffectif,
        benchmark_udgetaire: item.benchmarkBudgetaire,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(exportedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `${name.replace(" ", "_")}_financial_${moment().valueOf()}.xlsx`
    );
  };

  return (
    <Fragment>
      <div className="production-frame">
        <FinancialCollaboratorFilter
          organization={organization}
          token={token}
          filter={filter}
          nbResult={data.length}
          updateFilter={setFilter}
          updateFiltersCount={setFiltersCount}
          onDownloadData={exportToExcel}
          fetching={collaboratorFinancialQuery.isFetching}
        />
        {collaboratorFinancialQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : data.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("noDataFound")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          renderFinancialTable()
        )}
      </div>
    </Fragment>
  );
};

export default FinancialCollaborator;
