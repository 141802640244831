import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";

import _ from "../../i18n";
import {
  useOnClickOutside,
  renderTeamYears,
  viewDataBy,
  parsePreference,
} from "../../utils";
import {
  getBU,
  getCollaboratorsSuggestions,
  getZonesSuggestions,
} from "../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../common/Filter/TTPFilterHorizontalBar";
import { getPartners } from "../../api/partners";

const CollaboratorsFilter = ({
  token,
  filter,
  collaborator,
  nbResult,
  organization,
  updateFilter,
  preferences,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);
  const [partnersOptions, setPartnersOptions] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    updateFilter({ ...filter, year: item });
                    setYearDivIsVisible(false);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderCustomAddOn = () => {
    return (
      <div className="filter_custom">
        {renderYearSelect()}
        <div className="mini-tabs-frame">
          {[
            {
              id: "all",
              label: "all",
              value: "",
            },
            {
              id: "actives",
              label: "actifs",
              value: "NOT_DELETED",
            },
            {
              id: "deleted",
              label: "inactifs",
              value: "DELETED",
            },
          ].map((item) => (
            <div
              className={
                filter.deletedStatus === item.value && "mini-tabs-frame_active"
              }
              onClick={() => {
                updateFilter({
                  ...filter,
                  deletedStatus: item.value,
                });
              }}
            >
              {_(item.label)}
              <div className="span">{filter.counters[item.id]}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  let collaboratorOptionsQuery = useQuery(
    ["getCollaboratorOptions", token, organization],
    async () => {
      if (token && organization) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            organization,
            null,
            filter.year
          );
          let data = response.data.data;
          let list = viewDataBy(preferences.collaborators, data, collaborator);
          data = list
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
                partner: t?.partner?.value,
                isActif: t.isActif,
              };
            });
          setCollaborators(
            list.map((c) => ({
              value: c.id,
              label: c.name,
              partner: c?.partner?.value,
              isActif: c.isActif,
            }))
          );
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let zonesQuery = useQuery(["zonesQuery", organization], async () => {
    if (token) {
      try {
        const response = await getZonesSuggestions(token, organization);
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setZoneOptions(data);
      } catch (e) {
        setZoneOptions([]);
        return null;
      }
    }
  });

  let buQuery = useQuery(["businessUnitsQuery", organization], async () => {
    if (token) {
      try {
        const response = await getBU({ token, organizationId: organization });
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setBuOptions(data);
      } catch (e) {
        setBuOptions([]);
        return null;
      }
    }
  });

  let partnersQuery = useQuery(["partnersQuery", organization], async () => {
    if (token) {
      try {
        const response = await getPartners({
          token,
          organizationId: organization,
        });
        let data = response.data.data.map((partner) => ({
          value: partner.id,
          label: partner.name,
          zone: partner.zone,
        }));
        setPartnersOptions(data);
      } catch (e) {
        setPartnersOptions([]);
        return null;
      }
    }
  });

  useEffect(() => {
    if (filter.partner) {
      const options = collaborators.filter((c) => c.partner == filter.partner);
      setCollaboratorOptions(options);
    } else {
      setCollaboratorOptions(collaborators);
    }
  }, [filter.partner, collaborators]);

  const handleCollaboratorsChange = (items) => {
    const actifs = collaborators
      .filter((item) => item.isActif)
      .map((item) => item.value);

    const updatedSelection = [...actifs, ...items];

    updateFilter({ ...filter, collaborators: [...new Set(updatedSelection)] });
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        isDisabled:
          parsePreference(preferences.collaborators).allowViewDataBy === 101,
        placeholder: _("zone"),
        options: zoneOptions,
        values: filter.zone ? filter.zone : null,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.zone;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              zone: zoneOptions.filter((option) => option.value == value)[0]
                .value,
            });
          }
        },
      },
    },
    {
      type: "SELECT",
      options: {
        isMulti: true,
        isClearable: true,
        isDisabled:
          parsePreference(preferences.collaborators).allowViewDataBy === 100,
        placeholder: _("bu"),
        options: buOptions,
        values: filter.bu,
        action: (value) => {
          if (value.length === 0) {
            let cleanFilter = { ...filter };
            cleanFilter.bu = [];
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              bu: [...value],
            });
          }
        },
      },
    },
  ];

  if (
    [1, 10, 11, 110].includes(
      parsePreference(preferences.collaborators).allowViewDataBy
    )
  ) {
    filters.unshift({
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: false,
        isSearch: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborators,
        action: handleCollaboratorsChange,
      },
    });
  }

  if (
    [1, 11].includes(parsePreference(preferences.collaborators).allowViewDataBy)
  ) {
    filters.unshift({
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        isDisabled:
          parsePreference(preferences.collaborators).allowViewDataBy === 11,
        placeholder: _("Partner"),
        options: partnersOptions,
        values: filter.partner,
        action: (value) => {
          if (value === null) {
            let cleanFilter = { ...filter };
            cleanFilter.partner = null;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              partner: Number(value),
            });
          }
        },
      },
    });
  }

  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={filter.pageSize}
          customAddOn={renderCustomAddOn()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
          fetching={zoneOptions.isFetching}
        />
      </div>
    </div>
  );
};

export default CollaboratorsFilter;
