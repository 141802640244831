import React, { Fragment, useEffect, useState } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import { renderAvatar, validateEmail } from "../../../utils";
import { colors } from "../../../config";
import { ClipBoardIcon, IconClose, IconTrash } from "../../common/Icons";
import { TiArrowSortedDown } from "react-icons/ti";
import moment from "moment";
import { Button, DatePicker, Input, Space, Switch, Tooltip } from "antd";
import { Checkbox } from "antd";
import { addLocale } from "primereact/api";
import { useSelector } from "react-redux";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";
moment.locale("fr");

const SideBarPlanner = ({
  collaboratorName,
  selectedEventId,
  selectedColor,
  setSelectedColor,
  selectedClient,
  setSelectedClient,
  selectedCategory,
  setSelectedCategory,
  selectedDateEvent,
  setSelectedDateEvent,
  selectedDurationHours,
  setSelectedBalanceId,
  selectedDurationTravelHours,
  setSelectedDurationHours,
  setSelectedDurationTravelHours,
  selectedDurationMin,
  selectedDurationTravelMin,
  setSelectedDurationMin,
  setSelectedDurationTravelMin,
  selectedStatus,
  setSelectedStatus,
  selectedPlaceRDV,
  setSelectedPlaceRDV,
  selectedDescription,
  clientOptions,
  taskCategoriesOptions,
  statusOptions,
  events,
  setEvents,
  setOpenAddEvent,
  setSelectedTypeEvent,
  selectedTypeEvent,
  selectedTitleEvent,
  setSelectedTitleEvent,
  setSelecteddescription,
  budgetProgression,
  responsables,
  avatar,
  saveCollaboratorPlanner,
  selectedEvent,
  lng,
  selectedMeetingUrl,
  setSelectedMeetingInfo,
  setSelectedRecurrenceDetails,
  selectedRecurrenceDetails,
  isSaving,
  generateRecurringEvents,
  applyToChildren,
  setApplyToChildren,
  setFisrtDateOccurence,
  collaboratorOptions,
  selectedCollaborators,
  setSelectedCollaborators,
  collaboratorId,
  additionalEmails,
  setAdditionalEmails,
  isAutoSendClientMeetingLink,
  setIsAutoSendClientMeetingLink
}) => {
  const hasAleadyRecurrenceDetails =
    selectedEvent && selectedEvent.recurrenceDetails
      ? selectedEvent.recurrenceDetails.type
        ? true
        : false
      : false;
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [typesEventDivIsVisible, setTypesEventDivIsVisible] = useState(null);
  const [clientsDivIsVisible, setClientsDivIsVisible] = useState(null);
  const [collaboratorsDivIsVisible, setCollaboratorsDivIsVisible] =
    useState(null);
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorsSelected, setCollaboratorsSelected] = useState(
    selectedCollaborators
  );
  const [categoriesDivIsVisible, setCategoriesDivIsVisible] = useState(null);
  const [visibleDropdowns, setVisibleDropdowns] = useState({});
  const [statusDivIsVisible, setStatusDivIsVisible] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [isMeetinglinkCopied, setIsMeetingLinkCopied] = useState(false);
  const [clientEmailAddress, setClientEmailAddress] = useState(null);
  const [showAdditionalEmailsSection, setShowAdditionalEmailsSection] = useState(false);
  const [additionalEmailInput, setAdditionalEmailInput] = useState({
    value: null,
    isValid: false,
  });

  const [selectedRecurrenceType, setSelectedRecurrenceType] = useState(
    selectedEvent && selectedEvent.recurrenceDetails
      ? selectedEvent.recurrenceDetails.type
      : null
  );
  const [selectedNthWeek, setSelectedNthWeek] = useState(
    selectedEvent && selectedEvent.recurrenceDetails
      ? selectedEvent.recurrenceDetails.nth_week
      : null
  );
  const [selectedRecurrenceEndDate, setSelectedRecurrenceEndDate] = useState(
    selectedEvent &&
      selectedEvent.recurrenceDetails &&
      selectedEvent.recurrenceDetails.recurrence_end_date
      ? new Date(selectedEvent.recurrenceDetails.recurrence_end_date)
      : null
  );
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(
    selectedEvent && selectedEvent.recurrenceDetails
      ? selectedEvent.recurrenceDetails.day_of_week
      : []
  );
  const [isSwitchChecked, setIsSwitchChecked] = useState(
    !!(
      selectedEvent &&
      selectedEvent.recurrenceDetails &&
      selectedEvent.recurrenceDetails.type
    )
  );
  const ECART_MAP = {
    NEGATIVE: "tag-warning",
    POSITIVE: "tag-danger",
    EQUAL: "tag-success",
  };
  const typeEvent = [
    {
      id: 1,
      label: "task",
      title: "task",
    },
    {
      id: 2,
      label: "meeting",
      title: "meeting",
    },
    {
      id: 3,
      label: "management",
      title: "management",
    },
    {
      id: 5,
      label: "intermediateSituationBalance",
      title: "intermediateSituationBalance ",
    },
    {
      id: 5,
      label: "balanceEcheance",
      title: "balanceEcheance ",
    },
    {
      id: 4,
      label: "balancePresentation",
      title: "balancePresentation ",
    },
  ];
  const daysOfWeek = [
    { index: 1, name: "Lundi" },
    { index: 2, name: "Mardi" },
    { index: 3, name: "Mercredi" },
    { index: 4, name: "Jeudi" },
    { index: 5, name: "Vendredi" },
  ];

  useEffect(() => {
    try {
      setSelectedCollaborators([selectedCollaborators.toString()]);
    } catch (error) {
      console.error("Erreur de parsing JSON:", error);
      // }
    }
  }, []);

  useEffect(() => {
    if (selectedTypeEvent === 'balancePresentation' && selectedDurationHours == 0) {
      setSelectedDurationHours(1);
    }
  }, [selectedTypeEvent]);

  addLocale("fr", {
    firstDayOfWeek: 1,

    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
  });

  const handleSetIsSwitchChecked = () => {
    if (!isSwitchChecked) {
      setSelectedRecurrenceType(null);
      setSelectedNthWeek(null);
      setSelectedRecurrenceEndDate(null);
      setSelectedDayOfWeek(null);
    }
    setIsSwitchChecked(!isSwitchChecked);
  };

  const addCollaborator = () => {
    const newCollaborator = { value: null, label: _("Select") };

    setCollaborators((prevCollaborators) => [
      ...prevCollaborators,
      newCollaborator,
    ]);

    setSelectedCollaborators((prevSelected) => [...prevSelected, null]);
  };

  const handleSelectCollaborator = (index, selectedItem) => {
    // console.log("selectedItem========. value", selectedItem);
    const updatedCollaborators =
      collaborators &&
      collaborators.map((collab, idx) =>
        idx === index ? { ...collab, ...selectedItem } : collab
      );

    setCollaborators(updatedCollaborators);
    const updatedSelectedCollaborators = [...selectedCollaborators];

    if (selectedItem.value) {
      updatedSelectedCollaborators[index] = selectedItem.value.toString();
    }
    // console.log(
    //   "updatedSelectedCollaborators",
    //   updatedSelectedCollaborators,
    //   "selectedCollaborators",
    //   selectedCollaborators
    // );
    setSelectedCollaborators(updatedSelectedCollaborators);
    setVisibleDropdowns((prev) => ({ ...prev, [index]: false }));
  };

  const toggleDropdownVisibility = (index) => {
    setVisibleDropdowns((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const removeCollaborator = (index) => {
    const updatedCollaborators = collaborators.filter(
      (_, idx) => idx !== index
    );

    const updatedSelectedCollaborators = selectedCollaborators.filter(
      (_, idx) => idx !== index
    );

    setCollaborators(updatedCollaborators);
    setSelectedCollaborators(updatedSelectedCollaborators);
  };
  useEffect(() => {
    const recurrenceDetails =
      isSwitchChecked && selectedRecurrenceType
        ? {
            type: selectedRecurrenceType,
            nth_week: selectedNthWeek,
            day_of_week: selectedDayOfWeek,
            recurrence_end_date: selectedRecurrenceEndDate,
          }
        : {};
    setSelectedRecurrenceDetails(recurrenceDetails);
  }, [
    selectedRecurrenceType,
    selectedNthWeek,
    selectedDayOfWeek,
    selectedRecurrenceEndDate,
  ]);

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const filteredOptions = clientOptions.filter((item) =>
    item.label.toLowerCase().includes(filterValue.toLowerCase())
  );

  const handleAddAdditionalEmail = () => {
    const { value, isValid } = additionalEmailInput;
    if (additionalEmails.find(e => e === value)) {
      return;
    }
    if (isValid) {
      setAdditionalEmails([...additionalEmails, value]);
      setAdditionalEmailInput({
        value: null,
        isValid: false,
      })
    }
  }

  const handleRemoveAdditionalEmail = (value) => {
    if (additionalEmails.find(e => e === value)) {
      setAdditionalEmails(additionalEmails.filter(e => e !== value));
    }
  };

  const handleClearAdditionalEmailInput = () => {
    if (!additionalEmailInput.value) {
      setShowAdditionalEmailsSection(false);
    }

    setAdditionalEmailInput({
      value: null,
      isValid: false,
    });
  }

  const renderMeetingLinkInput = () => {
    if (!selectedMeetingUrl) return null;

    return (
      <div style={{ width: "100%" }}>
        <div className="title">Lien de meet</div>
        <div className="meeting_link">
          <div className="meeting_link_input_container">
            <input
              type="text"
              value={selectedMeetingUrl}
              readOnly
              className="meeting_link_input"
            />
            <Tooltip
              title={isMeetinglinkCopied ? "Le lien est copié" : "Copier dans le presse-papiers"}
            >
              <button
                className="copy_button"
                onClick={() => {
                  navigator.clipboard.writeText(selectedMeetingUrl);
                  setIsMeetingLinkCopied(true);
                  setTimeout(() => {
                    setIsMeetingLinkCopied(false);
                  }, 1500);
                }}
              >
                {isMeetinglinkCopied ? (
                  <ClipBoardIcon className="copy_icon" fill="#b2bcc6" />
                ) : (
                  <ClipBoardIcon className="copy_icon" fill="#29394d" />
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  const renderAddTaskEevent = (selectedId, items, type) => {
    return (
      <div>
        <div className="color-picker-container">
          {colors
            .filter((color) => color.id !== "default")
            .map((color, index) => (
              <div
                key={index}
                className={`color-circle ${
                  selectedColor === color.id ? "selected" : ""
                }`}
                style={{ backgroundColor: color.backgroundColor }}
                onClick={() => setSelectedColor(color.id)}
              >
                {selectedColor === color.id && (
                  <div className="outer-ring"></div>
                )}
              </div>
            ))}
        </div>
        <div className="title">Dossier client</div>
        <div
          className={"rectangular_select"}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            className={"rectangular_select_header"}
            onClick={() => setClientsDivIsVisible(!clientsDivIsVisible)}
          >
            <span>{selectedClient ? selectedClient.label : "Select"}</span>
            <TiArrowSortedDown />
          </div>
          {clientsDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <input
                type="text"
                value={filterValue}
                onChange={handleFilterChange}
                placeholder="Filter options..."
                className={"rectangular_select_filter"}
              />
              <div className={"rectangular_select_body_options text_tranform"}>
                {filteredOptions.map((item, index) => (
                  <div
                    key={index}
                    className={"rectangular_select_body_option"}
                    onClick={() => {
                      setSelectedClient(item);
                      setClientsDivIsVisible(false);
                    }}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="title">Catégorie</div>
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setCategoriesDivIsVisible(!categoriesDivIsVisible)}
          >
            <span>
              {selectedCategory ? selectedCategory.label : _("Select")}
            </span>
            {selectedTypeEvent !== "balanceEcheance" &&
              selectedTypeEvent !== "intermediateSituationBalance" && (
                <TiArrowSortedDown />
              )}
          </div>
          {selectedTypeEvent !== "balanceEcheance" &&
            selectedTypeEvent !== "intermediateSituationBalance" &&
            categoriesDivIsVisible && (
              <div className={"rectangular_select_body"}>
                <div className={"rectangular_select_body_options"}>
                  {taskCategoriesOptions.map((item, index) => {
                    if (!item) {
                      return;
                    }
                    return (
                      <div
                        key={index}
                        className={"rectangular_select_body_option"}
                        onClick={() => {
                          setSelectedCategory(item);
                          setCategoriesDivIsVisible(false);
                        }}
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
        </div>
        {selectedCategory && selectedClient && (
          <div className="">
            <div className="upper-frame">
              <div className="left-panel" style={{ width: "100%" }}>
                <div className="list-frame">{renderTaskListII()}</div>
              </div>
            </div>
          </div>
        )}
        <div className="line_space_content">
          <div>
            <div className="title">Date</div>
            <div className={"calendar_select"}>
              <DatePicker
                className="datepicker_input"
                format="DD/MM/YYYY HH:mm"
                value={selectedDateEvent}
                locale="fr"
                allowClear={false}
                placeholder="Selectionner une date"
                popupClassName="datepicker_popup"
                showTime={{
                  defaultValue: moment("now").format("HH:mm"),
                }}
                onSelect={(date) => {
                  if (moment(date).isValid()) {
                    setSelectedDateEvent(date);
                  } else {
                    setSelectedDateEvent(moment());
                  }
                }}
              />
            </div>
          </div>
          {selectedTypeEvent !== "balanceEcheance" && (
            <div>
              <div className="title">Durée de la tache</div>

              <div className="line_space_content">
                <div className="input-group">
                  <input
                    id="hours"
                    className="duration_input"
                    type="number"
                    min="0"
                    max="12"
                    value={selectedDurationHours}
                    onChange={(event) =>
                      setSelectedDurationHours(event.target.value)
                    }
                  />
                  <span className="label_input">Heure(s)</span>
                </div>
                <div className="input-group no-margin-right">
                  <input
                    id="minutes"
                    className="duration_input"
                    type="number"
                    min="0"
                    max="59"
                    value={selectedDurationMin}
                    onChange={(event) =>
                      setSelectedDurationMin(event.target.value)
                    }
                  />
                  <span className="label_input">Minute(s)</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="title">Statut de progression</div>
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setStatusDivIsVisible(!statusDivIsVisible)}
          >
            {selectedStatus ? selectedStatus : _("Select")}
            <TiArrowSortedDown />
          </div>
          {statusDivIsVisible && (
            <div className={"rectangular_select_body"}>
              <div className={"rectangular_select_body_options"}>
                {statusOptions.map((item, index) => {
                  if (!item) {
                    return;
                  }
                  return (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        setSelectedStatus(item.label);
                        setStatusDivIsVisible(false);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="title">Description</div>

        <textarea
          className="description"
          value={selectedDescription}
          rows="3"
          placeholder="Ajoutez votre description ici ..."
          onChange={(event) => {
            setSelecteddescription(event.target.value);
          }}
        />

        {renderMeetingLinkInput()}
      </div>
    );
  };

  const renderAddMeetingEvent = (selectedId, items, type) => {
    return (
      <div>
        <div className="color-picker-container">
          {colors
            .filter((color) => color.id !== "default")
            .map((color, index) => (
              <div
                key={index}
                className={`color-circle ${
                  selectedColor === color.id ? "selected" : ""
                }`}
                style={{ backgroundColor: color.backgroundColor }}
                onClick={() => setSelectedColor(color.id)}
              >
                {selectedColor === color.id && (
                  <div className="outer-ring"></div>
                )}
              </div>
            ))}
        </div>
        {(selectedTypeEvent === "meeting" || selectedTypeEvent === "balancePresentation") && (
          <div className="radio-container">
            <input
              type="radio"
              id="option1"
              name="options"
              value="option1"
              style={{ border: "2px solid red" }}
              checked={selectedPlaceRDV === "remote"}
              onChange={() => setSelectedPlaceRDV("remote")}
            />
            <div className="place"> {_("remote")}</div>
            <input
              type="radio"
              id="option2"
              name="options"
              value="option1"
              checked={selectedPlaceRDV === "onSite"}
              onChange={() => setSelectedPlaceRDV("onSite")}
            />
            <div className="place"> {_("onSite")}</div>
            <input
              type="radio"
              id="option2"
              name="options"
              value="option1"
              checked={selectedPlaceRDV === "visite"}
              onChange={() => setSelectedPlaceRDV("visite")}
            />
            <div className="place"> {_("visite")}</div>
          </div>
        )}
        <div className="title">
          {selectedTypeEvent === "meeting" ||
          selectedTypeEvent === "balancePresentation"
            ? "Dossier client"
            : "Title"}
        </div>

        {selectedTypeEvent === "meeting" ||
        selectedTypeEvent === "balancePresentation" ? (
          <div
            className={"rectangular_select"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "self-start",
            }}
          >
            <div
              className={"rectangular_select_header"}
              onClick={() => setClientsDivIsVisible(!clientsDivIsVisible)}
            >
              <span>{selectedClient ? selectedClient.label : _("Select")}</span>
              <TiArrowSortedDown />
            </div>
            {clientsDivIsVisible && (
              <div className={"rectangular_select_body"}>
                <input
                  type="text"
                  value={filterValue}
                  onChange={handleFilterChange}
                  placeholder="Filter options..."
                  className={"rectangular_select_filter"}
                />
                <div
                  className={"rectangular_select_body_options text_tranform"}
                >
                  {filteredOptions.map((item, index) => (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        setSelectedClient(item);
                        setClientsDivIsVisible(false);
                      }}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={"rectangular_select"}>
            <input
              type="text"
              className="rectangular_select_header"
              value={selectedTitleEvent}
              onChange={(event) => setSelectedTitleEvent(event.target.value)}
            ></input>
          </div>
        )}
        {selectedTypeEvent !== "meeting" && (
          <>
            <div className="title">Statut de progression</div>
            <div className={"rectangular_select"}>
              <div
                className={"rectangular_select_header"}
                onClick={() => setStatusDivIsVisible(!statusDivIsVisible)}
              >
                {selectedStatus ? selectedStatus : _("Select")}
                <TiArrowSortedDown />
              </div>
              {statusDivIsVisible && (
                <div className={"rectangular_select_body"}>
                  <div className={"rectangular_select_body_options"}>
                    {statusOptions.map((item, index) => {
                      if (!item) {
                        return;
                      }
                      return (
                        <div
                          key={index}
                          className={"rectangular_select_body_option"}
                          onClick={() => {
                            setSelectedStatus(item.label);
                            setStatusDivIsVisible(false);
                          }}
                        >
                          {item.label}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div className="line_space_content">
          <div>
            <div className="title">Date</div>
            <div className={"calendar_select"}>
              <DatePicker
                className="datepicker_input"
                format="DD/MM/YYYY HH:mm"
                value={selectedDateEvent}
                locale="fr"
                allowClear={false}
                placeholder="Selectionner une date"
                popupClassName="datepicker_popup"
                showTime={{
                  defaultValue: moment("now").format("HH:mm"),
                }}
                onSelect={(date) => {
                  if (moment(date).isValid()) {
                    setSelectedDateEvent(date);
                  } else {
                    setSelectedDateEvent(moment());
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div className="title">Durée de la tache</div>

            <div className="line_space_content">
              <div className="input-group">
                <input
                  id="hours"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="12"
                  value={selectedDurationHours}
                  onChange={(event) =>
                    setSelectedDurationHours(event.target.value)
                  }
                />
                <span className="label_input">Heure(s)</span>
              </div>
              <div className="input-group no-margin-right">
                <input
                  id="minutes"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="59"
                  value={selectedDurationMin}
                  onChange={(event) =>
                    setSelectedDurationMin(event.target.value)
                  }
                />
                <span className="label_input">Minute(s)</span>
              </div>
            </div>
          </div>
        </div>

        {(selectedTypeEvent === "meeting" || selectedTypeEvent === "balancePresentation") && selectedPlaceRDV !== "remote" && (
          <div>
            <div className="title">Durée du trajet</div>

            <div className="line_space_content">
              <div className="input-group">
                <input
                  id="hours"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="12"
                  value={selectedDurationTravelHours}
                  onChange={(event) =>
                    setSelectedDurationTravelHours(event.target.value)
                  }
                />
                <span className="label_input">Heure(s)</span>
              </div>
              <div className="input-group no-margin-right">
                <input
                  id="minutes"
                  className="duration_input"
                  type="number"
                  min="0"
                  max="59"
                  value={selectedDurationTravelMin}
                  onChange={(event) =>
                    setSelectedDurationTravelMin(event.target.value)
                  }
                />
                <span className="label_input">Minute(s)</span>
              </div>
            </div>
          </div>
        )}
        {(selectedTypeEvent === "meeting" || selectedTypeEvent === "balancePresentation") && (
          <div>
            {selectedClient != null && selectedClient.email != null ? (
              <div className="client_email_container">
                <p className="client_email_notice client_email_notice_ok">
                  L'address e-mail du client est :{' '}
                  <a
                    href={`mailto: ${selectedClient.email}`}
                    className="client_email_notice_link"
                  >
                    {selectedClient.email}
                  </a>
                </p>
                <div className="client_email_switch_container">
                  <Switch
                    className="client_email_switch"
                    size="small"
                    value={isAutoSendClientMeetingLink}
                    onChange={() => setIsAutoSendClientMeetingLink(!isAutoSendClientMeetingLink)}
                  />
                  <p className="client_email_notice">
                    Envoyer le lien Teams à l'adresse e-mail du client.
                  </p>
                </div>
              </div>
              ) : (
              selectedClient && (
                <p className="client_email_notice client_email_notice_nok">
                  Ce client n'a pas d'adresse e-mail.
                </p>
              )
            )}

            {/* add another email address */}
            <div className="additional_emails_section">
              {additionalEmails.length > 0 && (
                <div>
                  <h5 className="additional_emails_title title">
                    E-mails additionnels
                  </h5>
                  <ol className="additional_emails_list">
                    {additionalEmails.map((email, index) => (
                      <li key={index} className="additional_emails_item">
                        {email}
                        <IconTrash
                          size={12}
                          className="additional_emails_item_remove_icon"
                          onClick={() => handleRemoveAdditionalEmail(email)}
                        />
                      </li>
                    ))}
                  </ol>
                </div>
              )}
              {showAdditionalEmailsSection && (
                <Input
                  className="additional_emails_input"
                  placeholder="Tapper une adresse e-mail."
                  value={additionalEmailInput.value}
                  onChange={(e) => setAdditionalEmailInput({
                    value: e.target.value,
                    isValid: validateEmail(e.target.value)
                  })}
                  type="email"
                  status={
                    !additionalEmailInput.isValid && (additionalEmailInput?.value?.length > 0) ?
                    "error" : "valid"
                  }
                  onPressEnter={handleAddAdditionalEmail}
                  suffix={
                    <Space>
                      <Tooltip title="Ajouter">
                        <Button
                          className={
                            classNames({
                              "additional_emails_input_add_button": true,
                              "additional_emails_input_add_button_disabled": !additionalEmailInput.isValid
                            })
                          }
                          type="text"
                          size="small"
                          icon={
                            <CheckOutlined
                              style={{ color: "#02af8e" }}
                            />
                          }
                          onClick={handleAddAdditionalEmail}
                        />
                      </Tooltip>
                      <Tooltip title="Annuler">
                        <Button
                          className={
                            classNames({
                              "additional_emails_input_remove_button": true,
                              // "additional_emails_input_remove_button_disabled": !additionalEmailInput.isValid
                            })
                          }
                          type="text"
                          size="small"
                          icon={
                            <CloseOutlined
                              style={{ color: "#de4848" }}
                            />
                          }
                          onClick={handleClearAdditionalEmailInput}
                        />
                      </Tooltip>
                    </Space>
                  }
                />
              )}
              {!showAdditionalEmailsSection && (
                <Button
                  className="additional_emails_add_button"
                  onClick={() => setShowAdditionalEmailsSection(true)}
                >
                  Ajouter une adresse e-mail
                </Button>
              )}
            </div>
          </div>
        )}

        <div className="add_collaborators_section">
          <h5 className="title">Collaborateurs</h5>
          {selectedTypeEvent === "meeting" &&
            selectedCollaborators &&
            selectedCollaborators.map((idCollaborator, index) => {
              const collaborator = collaboratorOptions.find(
                (option) => option.value === Number(idCollaborator)
              );

              return (
                <div key={index} className="collaborator-select">
                  <div className="rectangular_select">
                    <div
                      className="rectangular_select_header"
                      // style={{ width: "400px", marginRight: "20px" }}
                      onClick={() => toggleDropdownVisibility(index)}
                    >
                      <span>
                        {collaborator ? collaborator.label : _("Select")}
                      </span>
                      <TiArrowSortedDown />
                    </div>
                    {visibleDropdowns[index] && (
                      <div className="rectangular_select_body">
                        <input
                          type="text"
                          value={filterValue}
                          onChange={handleFilterChange}
                          placeholder="Filter options..."
                          className="rectangular_select_filter"
                        />
                        <div className="rectangular_select_body_options text_transform">
                          {(!collaborator ||
                            collaborator.value !== collaboratorId) &&
                            collaboratorOptions.map((item, idx) => (
                              <div
                                key={idx}
                                className="rectangular_select_body_option"
                                onClick={() =>
                                  handleSelectCollaborator(index, item)
                                }
                              >
                                {item.label}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    {collaborator && collaborator.value !== collaboratorId && (
                      <div onClick={() => removeCollaborator(index)}>
                        <IconTrash
                          className="collaborator_remove_icon"
                          size={14}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          <button className="add_collaborator_button" onClick={addCollaborator}>
            Ajouter Collaborateur
          </button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {renderMeetingLinkInput()}
        </div>

        <div className="title">Description</div>
        <textarea
          className="description"
          value={selectedDescription}
          rows="3"
          placeholder="Ajoutez votre description ici ..."
          onChange={(event) => {
            setSelecteddescription(event.target.value);
          }}
        />
      </div>
    );
  };

  const renderOcurenceEvent = (selectedId, items, type) => {
    return (
      <div>
        {true && (
          <div className="radio-container">
            <input
              type="radio"
              id="optionRecurrence1"
              name="recurrenceOptions"
              value="optionRecurrence1"
              style={{ border: "2px solid red" }}
              checked={selectedRecurrenceType === "weekly"}
              onChange={() => setSelectedRecurrenceType("weekly")}
            />
            <div className="place"> Hebdomadaire</div>
            <input
              type="radio"
              id="optionRecurrence2"
              name="recurrenceOptions"
              value="optionRecurrence1"
              checked={selectedRecurrenceType === "monthly"}
              onChange={() => setSelectedRecurrenceType("monthly")}
            />
            <div className="place"> Mensuelle</div>
          </div>
        )}
        {selectedRecurrenceType === "monthly" && (
          <div className="radio-container">
            <div className="place">Semaine du mois ( 1 - 5 )</div>
            <input
              className="number_of_week"
              type="number"
              min="1"
              max="5"
              value={selectedNthWeek}
              onChange={(e) => setSelectedNthWeek(parseInt(e.target.value))}
            />
          </div>
        )}
        <div className="radio-container" style={{ display: "block" }}>
          <div className="place">Choisiser les jours de la semaine</div>

          <Checkbox.Group
            className="line_content"
            value={selectedDayOfWeek}
            onChange={(selectedOptions) => {
              setSelectedDayOfWeek(selectedOptions);
            }}
          >
            {daysOfWeek.map((day) => (
              <Checkbox
                key={day.index}
                value={day.index}
                style={{
                  paddingRight: "0px",
                  paddingLeft: "0px",
                }}
                className="place"
              >
                {day.name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>
        <div className="radio-container">
          <div className="place">Jusqu'au</div>
          <div className={"calendar_select"}>
            {/* <Calendar
              showIcon
              dateFormat="dd/mm/yy"
              locale={lng}
              value={selectedRecurrenceEndDate}
              className="p-datepicker-sidebar"
              panelClassName="large_calendar"
              onChange={(e) => {
                setSelectedRecurrenceEndDate(e.value);
              }}
              icon={<CalendarIcon fill="#6D7F92" />}
            /> */}

            <DatePicker
              className="datepicker_input"
              format="DD/MM/YYYY HH:mm"
              value={
                selectedRecurrenceEndDate
                  ? moment(selectedRecurrenceEndDate, "DD/MM/YYYY HH:mm")
                  : null
              }
              locale="fr"
              allowClear={false}
              placeholder="Selectionner une date"
              popupClassName="datepicker_popup"
              showTime={{
                defaultValue: moment(),
              }}
              onSelect={(date) => {
                if (moment(date).isValid()) {
                  setSelectedRecurrenceEndDate(date);
                } else {
                  setSelectedRecurrenceEndDate(moment());
                }
              }}
            />
          </div>
        </div>
        {hasAleadyRecurrenceDetails && (
          <div className="radio-container">
            <input
              type="radio"
              id="optionApplyToChildren"
              name="ApplyToChildrenOptions"
              value="optionApplyToChildren"
              style={{ border: "2px solid red" }}
              checked={applyToChildren}
              onChange={() => setApplyToChildren(true)}
            />
            <div className="place"> Modifier toutes les récurrences </div>
            <input
              type="radio"
              id="optionApplyToChildren"
              name="ApplyToChildrenOptions"
              value="optionApplyToChildren"
              checked={!applyToChildren}
              onChange={() => setApplyToChildren(false)}
            />
            <div className="place"> Modifier uniquement ici</div>
          </div>
        )}
      </div>
    );
  };

  const handleSaveEvent = () => {
    let date;
    if (
      typeof selectedDateEvent === "string" ||
      selectedDateEvent instanceof String
    ) {
      date = moment();
    } else {
      date = selectedDateEvent;
    }

    let formattedDate = null;
    let formattedDateAA, formattedDateLast;
    if (!isNaN(date)) {
      // formattedDate = {
      //   date: date.toISOString().replace("T", " ").replace("Z", ".000000"),
      //   timezone_type: 3,
      //   timezone: "UTC",
      // };
      // const localOffset = date.getTimezoneOffset();
      // const adjustedDate = new Date(date.getTime() + localOffset * 60000);

      // formattedDateAA = adjustedDate
      //   .toISOString()
      //   .replace("T", " ")
      //   .replace("Z", ".000000");

      // const year = date.getFullYear();
      // const month = ("0" + (date.getMonth() + 1)).slice(-2);
      // const day = ("0" + date.getDate()).slice(-2);
      // const hours = ("0" + date.getHours()).slice(-2);
      // const minutes = ("0" + date.getMinutes()).slice(-2);
      // const seconds = ("0" + date.getSeconds()).slice(-2);

      // formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000000`;
      formattedDate = date.format("YYYY-MM-DD HH:mm:ss.SSSSSS");
      formattedDateLast = {
        date: formattedDate,
        timezone_type: 3,
        timezone: "UTC",
      };
    }
    const duration =
      selectedDurationHours && selectedDurationMin
        ? parseInt(selectedDurationHours) * 60 + parseInt(selectedDurationMin)
        : selectedDurationHours
        ? selectedDurationHours * 60
        : selectedDurationMin
        ? selectedDurationMin
        : 0;

    const updatedEvent = {
      id: selectedEventId,
      type: selectedTypeEvent,
      start: selectedDateEvent.format("YYYY-MM-DD"),
      date: formattedDateLast,
      duration: duration,
      meetingPlace: selectedPlaceRDV,
      client_id: selectedClient ? selectedClient.value : null,
      client_name: selectedClient ? selectedClient.label : null,
      category_name: selectedCategory ? selectedCategory.label : null,
      category_id: selectedCategory ? selectedCategory.value : null,
      description: selectedDescription,
      status: selectedStatus,
      recurrenceDetails: selectedRecurrenceDetails,
      color: selectedColor,
      additionalEmails: additionalEmails,
      autoSendClientMeetingLink: false,
    };
    saveCollaboratorPlanner(updatedEvent);
  };

  const renderTaskListII = () => {
    if (!budgetProgression || !responsables) {
      return <div>Loading...</div>;
    }

    const selectedCategoryDetails =
      budgetProgression &&
      budgetProgression.categories &&
      budgetProgression.categories[selectedCategory.label];

    return (
      <Fragment>
        <div className="list-frame_item_body">
          <div className="header">
            <div className="infos">
              <div className="avatar"></div>
              <div></div>
              <div></div>
            </div>

            <div className="tag" style={{ marginLeft: "47%" }}>
              <span className="progression_header">{_("Budgétés")}</span>
              <div className="tag"></div>
            </div>
            <div className="tag tag-success">
              <span className="progression_header">{_("Réalisés")}</span>{" "}
            </div>
            <div>
              <span className="progression_header">{_("Écart")}</span>{" "}
              <div className="tag"></div>
            </div>
          </div>

          {selectedCategoryDetails &&
            Object.keys(
              selectedCategoryDetails && selectedCategoryDetails.details
            ).map((index) => {
              let detail = selectedCategoryDetails.details[index];
              let responsable = responsables[index];

              if (!responsable) {
                return null;
              }

              return (
                <div className="line" key={index}>
                  <div className="infos">
                    <div
                      className="avatar"
                      style={{
                        backgroundImage: `url(${responsable.avatarUrl})`,
                      }}
                    ></div>
                    <div>{responsable.name}</div>
                    <div>{_(responsable.roles.join("-"))}</div>
                  </div>
                  <div className="tag">
                    <div>{parseFloat(detail.budget)}</div>
                  </div>
                  <div className="tag tag-success">
                    <div>{parseFloat(detail.real)}</div>
                  </div>
                  <div className={`tag ${ECART_MAP[detail.ecart]}`}>
                    <div>
                      {parseFloat(
                        parseFloat(detail.real - detail.budget).toFixed(2)
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Fragment>
    );
  };

  const resetSidebarInputs = () => {
    setSelectedColor("default");
    setSelectedPlaceRDV("remote");
    setFisrtDateOccurence(null);
    setSelectedClient(null);
    setSelectedCategory(null);
    setSelectedTypeEvent(null);
    setSelectedTitleEvent(null);
    setSelectedMeetingInfo(null);
    setClientEmailAddress(null);
    setAdditionalEmails([]);
    setIsAutoSendClientMeetingLink(false);
    setSelectedDateEvent(moment());
    setSelectedDurationHours(0);
    setSelectedDurationMin(0);
    setSelectedDurationTravelHours(0);
    setSelectedDurationTravelMin(0);
    setSelectedBalanceId(null);
    setSelecteddescription(null);
  }

  return (
    <div className="fadePage">
      <div className={`sideBar display`}>
        {isSaving && (
          <div className="overlay">
            <div className="ripple_loading">
              <img
                src={"/img/Ripple-1s-150px.svg"}
                alt=""
                width={100}
                height={100}
              />
            </div>
          </div>
        )}
        <div
          className="header"
          style={{
            height: isSwitchChecked
              ? selectedRecurrenceType === "monthly"
                ? "320px"
                : "275px"
              : "140px",
          }}
        >
          <div>
            <div>
              <span className="header_title">
                {selectedEventId
                  ? "Modifier l'événement"
                  : "Ajouter un événement"}
              </span>
            </div>
            <div className="occurrence">
              <Switch
                style={{ width: "30px" }}
                checked={isSwitchChecked}
                onChange={() => handleSetIsSwitchChecked()}
              />
              <div className="title">
                {isSwitchChecked
                  ? "Désactiver la récurrence"
                  : "Activer la récurrence"}
              </div>
            </div>
            {isSwitchChecked && renderOcurenceEvent()}
          </div>

          <div
            className="sidebar_header_action"
            onClick={() => {
              setOpenAddEvent(false);
              resetSidebarInputs();
            }}
          >
            <IconClose size={18} />
          </div>
        </div>
        <div className="sidebar_content">
          <div className="title">Collaborateur assigné</div>
          <div className="sub_title">
            <div className="avatar">{renderAvatar(avatar)}</div>
            <div className="name">{collaboratorName}</div>
          </div>
          <div className="title">Type d’événement</div>
          <div className={"rectangular_select"}>
            <div
              className={"rectangular_select_header"}
              onClick={() => {
                setTypesEventDivIsVisible(!typesEventDivIsVisible);
                resetSidebarInputs();
              }}
            >
              <span>
                {selectedTypeEvent ? _(selectedTypeEvent) : _("select")}
              </span>
              <TiArrowSortedDown />
            </div>
            {typesEventDivIsVisible && (
              <div className={"rectangular_select_body"}>
                <div className={"rectangular_select_body_options"}>
                  {typeEvent.map((item, index) => {
                    if (!item) {
                      return;
                    }
                    return (
                      <div
                        key={index}
                        className={"rectangular_select_body_option"}
                        onClick={() => {
                          setSelectedTypeEvent(item.label);
                          if (
                            item.label === "balanceEcheance" ||
                            item.label === "intermediateSituationBalance"
                          ) {
                            const category = taskCategoriesOptions.find(
                              (cat) =>
                                cat.value ===
                                (item.label === "balanceEcheance" ? 8 : 7)
                            );
                            setSelectedColor("orange");
                            setSelectedCategory(category);
                          }
                          if (
                            item.label === "task" ||
                            item.label === "management"
                          ) {
                            setSelectedColor("blue");
                          } else if (item.label === "meeting") {
                            setSelectedColor("green");
                          }
                          setTypesEventDivIsVisible(false);
                        }}
                      >
                        {_(item.label)}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="diviser" />
          {selectedTypeEvent &&
            (selectedTypeEvent === "task" ||
            selectedTypeEvent === "balanceEcheance" ||
            selectedTypeEvent === "intermediateSituationBalance"
              ? renderAddTaskEevent()
              : renderAddMeetingEvent())}
        </div>
        {selectedTypeEvent !== null && (
          <div className="sidebar_footer">
            <button
              onClick={() => {
                setOpenAddEvent(false);
                resetSidebarInputs();
              }}
            >
              Annuler
            </button>
            <button
              className={
                "add " +
                (!selectedTypeEvent ||
                (selectedTypeEvent !== "management" && !selectedClient) ||
                (selectedTypeEvent === "task" && !selectedCategory) ||
                !selectedDateEvent
                  ? "disabled"
                  : "")
              }
              onClick={() => handleSaveEvent()}
            >
              {selectedEventId ? "Modifier" : "Ajouter"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBarPlanner;
