import axios from "axios";

import { TTP_API_URL } from "../config";

export const getCapacityPlaningProduction = (
  organizationId,
  token,
  analyseDate,
  filter
) => {
  const requestUrl = `${TTP_API_URL}/team/capacity/production`;
  let params = {
    access_token: token,
    organization_id: organizationId,
    organization: organizationId,
    date: analyseDate,
    searchWord: filter.keyWord,
    collaboratorState: JSON.stringify(filter.collaboratorState),
    collaborators: JSON.stringify(filter.collaborators),
    deletedStatus: filter.deletedStatus,
    zone: filter.zone,
  };

  if (filter.bu) {
    params.businessUnits = JSON.stringify(filter.bu);
  }

  if (filter.partner) {
    params.partner = filter.partner;
  }

  params.limit = filter.pageSize || 10;
  params.start = !filter.paginationPage
    ? 0
    : (filter.paginationPage - 1) * filter.pageSize;

  return axios.get(requestUrl, {
    params,
  });
};

export const getCollaboratorContribution = (
  id,
  organizationId,
  year,
  token,
  categories,
  period,
  start,
  end,
  keyWord,
  code
) => {



  const requestUrl = `${TTP_API_URL}/team/collaborator/production`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      id,
      year,
      organization: organizationId,
      categories: JSON.stringify(categories),
      period,
      start,
      end,
      keyWord,
      code,
    },
  });
};

export const getCollaboratorNonFactorableContribution = (
  id,
  organizationId,
  year,
  token,
  categories,
  period,
  start,
  end,
  keyWord,
  code
) => {

  const requestUrl = `${TTP_API_URL}/team/collaborator/production/non-factorable`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      id,
      year,
      organization: organizationId,
      categories: JSON.stringify(categories),
      period,
      start,
      end,
      keyWord,
      code,
    },
  });
};

export const getCollaboratorContributionByCategory = (
  id,
  organizationId,
  year,
  token,
  start,
  end
) => {


  const requestUrl = `${TTP_API_URL}/team/collaborator/production-category`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      id,
      year,
      organization_id: organizationId,
      organization: organizationId,
      start,
      end,
    },
  });
};

export const getOrganizationCollaborators = (
  organizationId,
  year,
  token,
  zones,
  searchWord
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators`;

  let filter = [];
  if (searchWord && searchWord.length) {
    filter = [
      {
        property: "name",
        value: `%` + searchWord + `%`,
        operator: "like",
      },
    ];
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      years: [year],
      zones,
      filter: JSON.stringify(filter),
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const getCollaboratorAvatars = (organizationId, token) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/avatars`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const getCollaboratorsAbsence = (organizationId, token, filter) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators-absences`;

  return axios.get(requestUrl, {
    params: {
      year: filter.year,
      bu: filter.bu,
      zone: filter.zone,
      access_token: token,
      collaborators: JSON.stringify(filter.collaborators),
      months: JSON.stringify(filter.months),
      organization_id: organizationId,
      organization: organizationId,
      partner: filter.partner
    },
  });
};

export const getCollaboratorsGlobalReporting = (
  organizationId,
  token,
  fliter
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/globalReporting`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      searchWord: fliter.keyWord,
      year: fliter.year,
      isEntity: fliter.isEntity,
      deletedStatus: fliter.deletedStatus,
      clientCRMGroup: JSON.stringify(fliter.clientCRMGroup),
      clientCRMTypes: JSON.stringify(fliter.clientCRMTypes),
      collaborators: fliter.collaborators,
    },
  });
};
export const getRecapEncodingReporting = (
  organization,
  token,
  filter
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators/encodage`;
  let params = {
    access_token: token,
    year: filter.year,
    organization: organization,
    zone: filter.zone,
    category: filter.category,
    keyword: filter.keyword,
    partner: filter.partner,
    collaborators: filter.collaborators,
    zone: filter.zone,
    bu: filter.bu
  };
  return axios.get(requestUrl, {
    params,
  });
};

export const getCollaboratorsGlobalNettoyage = (
  token,
  organization,
  filter
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators/nettoyage`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization,
      zone: filter.zone,
      year: filter.year,
      keyword: filter.keyWord,
      partner: filter.partner,
      collaborators: filter.collaborators,
      zone: filter.zone,
      bu: filter.bu,
    },
  });
};

export const getCollaboratorNettoyageReport = (
  token,
  collaborator,
  organization,
  filter
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/nettoyage`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      collaborator,
      organization,
      year: filter.year,
      zone: filter.zone,
      keyword: filter.keyWord,
    },
  });
};

export const exportProductionCapacity = async (
  token,
  organization,
  filter
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/suivi-production/export`;

  const response = await axios
    .get(requestUrl, {
      params: {
        access_token: token,
        organization,
        year: filter.year,
        zone: filter.zone,
        category: filter.category,
        keyword: filter.keyword,
      },
      responseType: "blob",
      headers: { Accept: "application/vnd.ms-excel" }
    });
  const url_2 = window.URL.createObjectURL(
    new Blob([response.data], { type: response.data.type })
  );
  const link = document.createElement("a");
  link.href = url_2;
  link.setAttribute(
    "download",
    "PowerTeam Production Capacity " + filter.year + ".xlsx"
  );
  document.body.appendChild(link);
  link.click();
}

export const getCollaboratorProcessingTime = ({
  token,
  organizationId,
  collaboratorId,
  startDate,
  endDate,
  categories,
  zoneId = null
}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/processing-time`;
  const params = {
    access_token: token,
    organizationId,
    collaboratorId,
    startDate,
    endDate,
    categories
  };
  if (zoneId) {
    params.zoneId = zoneId;
  }
  return axios.get(requestUrl, { params });
};

export const getCollaboratorRatios = ({
  token,
  organizationId,
  collaboratorId,
  startDate,
  endDate
}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/ratios`;
  const params = {
    access_token: token,
    organizationId,
    collaboratorId,
    startDate,
    endDate
  };
  return axios.get(requestUrl, { params });
}

export const getCollaboratorFoldersQuality = ({
  token,
  organizationId,
  collaboratorId,
  startDate,
  endDate
}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/folders-quality-data`;
  const params = {
    access_token: token,
    organizationId,
    collaboratorId,
    startDate,
    endDate
  };
  return axios.get(requestUrl, { params });
}

export const getCollaboratorDocsProcessingData = ({
  token,
  organizationId,
  collaboratorId,
  startDate,
  endDate
}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/docs-processing-data`;
  const params = {
    access_token: token,
    organizationId,
    collaboratorId,
    startDate,
    endDate
  };
  return axios.get(requestUrl, { params });
}

export const getCollaboratorNFHoursData = ({
  token,
  organizationId,
  collaboratorId,
  period
}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/nf-hours-data`;
  const params = {
    access_token: token,
    organizationId,
    collaboratorId,
    period
  };
  return axios.get(requestUrl, { params });
}

export const getCollaboratorsByRole = ({
  token,
  organizationId,
  role
}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators/by-role`;
  const params = {
    access_token: token,
    organizationId,
    role
  };
  return axios.get(requestUrl, { params });
}

export const getCollaboratorsByZone = ({
  token,
  organizationId,
  zoneId,
  isDeleted
}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators/by-zone`;
  const params = {
    access_token: token,
    organizationId,
    zoneId
  };

  if (isDeleted !== null && isDeleted !== undefined) {
    params.isDeleted = isDeleted;
  }

  return axios.get(requestUrl, { params });
};
