import axios from "axios";
import { TTP_API_URL } from "../config";

export const getDynamicTableData = (
  token,
  id,
  clients,
  clientGroups,
  collaborators,
  collaboratorRole,
  year,
  squads,
  clusters,
  businessUnits,
  sortBy,
  clientTypes,
  clientCRMTypes,
  searchWord,
  budgetStatus,
  clientCRMGroup,
  deletedStatus,
  offshoreStatus,
  facturableStatus,
  pageSize,
  startPage,
  partner,
  partnerZone
) => {
  const requestUrl = `${TTP_API_URL}/team/dynamic-table`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: id,
      // clients: JSON.stringify(clients),
      clientTypes: JSON.stringify(clientTypes),
      clientCRMTypes: JSON.stringify(clientCRMTypes),
      clientGroups,
      collaborators: collaborators,
      collaboratorRole,
      period: year,
      squads: JSON.stringify(squads),
      clusters: JSON.stringify(clusters),
      // businessUnits: JSON.stringify(businessUnits),
      sortBy: sortBy,
      pageSize: pageSize,
      startPage: startPage * pageSize,
      facturableStatus: facturableStatus,
      searchWord,
      budgetStatus,
      withBudgetaryBenchmark: true,
      groupDescriptions: JSON.stringify(clientCRMGroup),
      deletedStatus,
      offshoreStatus,
      partner,
      partnerZone
    },
  });
};

export const updateBudgetParts = ({ organization, budget }) => {
  const requestUrl = `${TTP_API_URL}/team/budget/parts/update`;
  let formData = new FormData();
  formData.append("organization", organization);
  formData.append("budget", JSON.stringify(budget));

  return axios.post(requestUrl, formData);
};
