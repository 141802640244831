import { Modal } from 'antd';
import styles from './index.module.scss';
import { getTemporaryAnnexeFileLink } from '../../../../../utils';

const AttachmentViewer = ({ file, index, collaboratorId, token }) => (
  <div className={styles.file_view}>
    <iframe
      id={`annexe-${index}`}
      title={`Attachment ${index + 1}`}
      style={{ width: "100%" }}
      allow="camera;microphone;autoplay;fullscreen"
      allowFullScreen
      src={getTemporaryAnnexeFileLink(collaboratorId, file, token) || ""}
    />
  </div>
);

const AttachmentsModal = ({
  isVisible,
  onClose,
  attachments,
  collaboratorId,
  token
}) => (
  <Modal
    open={isVisible}
    footer={null}
    closable
    width={700}
    height={600}
    bodyStyle={{ padding: '20px' }}
    onCancel={onClose}
  >
    <div className="tamtam-modal upload-modal upload-modal__body">
      {attachments && (typeof attachments === 'string'
        ? JSON.parse(attachments)
        : attachments
      )?.map((file, index) => (
        <AttachmentViewer
          key={`${file}-${index}`}
          file={file}
          index={index}
          collaboratorId={collaboratorId}
          token={token}
        />
      ))}
    </div>
  </Modal>
);

export { AttachmentViewer, AttachmentsModal };