import { useState } from "react";
import { FaAngleDown, FaSync } from "react-icons/fa";
import { formatNumber } from "../../../../utils";
import { CheckIcon, IconClose } from "../../../common/Icons";
import styles from "./CollapsibleCard.module.scss";

export default function CollapsibleCard({
  key,
  name,
  badge,
  label,
  signed = false,
  inReview = false,
  doneReview = false,
  attributed = false,
  prestationEndDate = "",
  prestationRest = [],
  clientGroup = "",
  clientHourBudget = 0,
  clientEconomicBudget = 0,
  attribution = null,
}) {
  const [expanded, setExpanded] = useState("");

  const handleToggle = () => {
    setExpanded(expanded === "" ? key : "");
  };
  return (
    <div className={styles.card} key={key}>
      <div className={styles.card_header}>
        <span>{name}</span>
        <div>
          <span className={`${styles["badge"]} ${styles[`badge_${badge}`]}`}>
            {badge == "op" && !signed && <IconClose size={10} fill="#DE4848" />}
            {badge == "op" && signed && (
              <CheckIcon width="14px" height="14px" fill="#02AF8E" />
            )}
            {badge == "forfait" && inReview && (
              <FaSync size={12} fill="#FFAC3A" />
            )}
            {badge == "forfait" && doneReview && (
              <CheckIcon width="14px" height="14px" fill="#02AF8E" />
            )}
            {label}
          </span>
          <div
            className={styles.toggle_btn}
            style={{ "--angle": expanded == key ? "180deg" : 0 }}
            onClick={handleToggle}
          >
            <FaAngleDown width={16} height={16} fill="#29394D" />
          </div>
        </div>
      </div>
      <div
        className={styles.card_content}
        style={{
          display: expanded == key ? "flex" : "none",
          maxHeight: expanded == key ? "200px" : 0,
          opacity: expanded == key ? 1 : 0,
        }}
      >
        {badge == "out" ? (
          <div className={styles.column}>
            <div style={{ display: "flex", gap: 5 }}>
              <span>Fin de prestation le: </span>
              {prestationEndDate}
            </div>
            {prestationRest.length > 0 && (
              <>
                <span>prestations restantes:</span>
                <div
                  style={{
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: 7,
                  }}
                >
                  {prestationRest.map((p) => (
                    <div className={styles.column_card}>
                      <span className={styles.column_card_prestation}>
                        {p.name}
                      </span>
                      <span>
                        {p.value && p.budget
                          ? `${Math.floor(p.value / 60)} h/${p.budget.toFixed(
                              0
                            )}`
                          : "N/A"}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={styles.row}>
            <div className={styles.row_grp_client}>
              <span>Groupe client: </span>
              <strong>{clientGroup}</strong>
            </div>
            <div className={styles.row_card}>
              <span>Budget temps</span>
              <strong>{`${clientHourBudget} h`}</strong>
            </div>
            {badge == "in" ? (
              <div className={styles.row_card}>
                <span>Attribution</span>
                {attributed ? (
                  <div className={styles.row_card_attribution}>
                    <CheckIcon width="10px" height="14px" fill="#02AF8E" />{" "}
                    {`${attribution && attribution.fname} ${
                      attribution &&
                      attribution?.lname &&
                      attribution?.lname[0] + "."
                    }`}
                  </div>
                ) : (
                  <IconClose size={10} fill="#DE4848" />
                )}
              </div>
            ) : (
              <div className={styles.row_card}>
                <span>Budget économique</span>
                <strong>{`${formatNumber(clientEconomicBudget)} €`}</strong>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
