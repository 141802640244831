import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import _ from "../../../i18n";
import { printPrestationInvoice } from "../../../api";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

const InvoiceDownloader = ({
  token,
  navCommunity,
  open,
  loading,
  close,
  data,
  filter,
  year,
  refresh,
}) => {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [downloading, setDownloading] = useState(false);

  const handlePrintPrestationInvoice = async () => {
    setDownloading(true);
    if (token && navCommunity) {
      const startDate = year + "-" + filter.start + "T00:00:00";
      const endDate = year + "-" + filter.end + "T00:00:00";
      const ids = data.map((item) => item.value);
      try {
        await printPrestationInvoice(
          token,
          navCommunity.id,
          startDate,
          endDate,
          invoiceNumber,
          ids
        ).then((resp) => {
          if (resp.data.result === "OK") {
            toast.success(_("successfully_added"));
          }
        });
        refresh();
        setDownloading(false);
        setInvoiceNumber("");
        close();
      } catch (e) {
        setDownloading(false);
        setInvoiceNumber("");
        throw new Error(e.message);
      }
    }
  };
  return (
    <Modal
      title={<p>{_("exportInvoice")}</p>}
      footer={
        <Button
          disabled={!invoiceNumber}
          className="download-button"
          onClick={handlePrintPrestationInvoice}
        >
          {downloading ? <BeatLoader color="#3498db" size={10} /> : _("export")}
        </Button>
      }
      width={700}
      loading={loading}
      open={open}
      onCancel={close}
    >
      <div className="form-control">
        <div className="field-control">
          <label htmlFor="invoice">{_("invoiceNumber")}</label>
          <Input
            type="text"
            id="invoice"
            name="incoive"
            placeholder={_("invoiceNumber")}
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
            className="field"
          />
        </div>
      </div>
    </Modal>
  );
};
export default InvoiceDownloader;
