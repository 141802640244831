import PageHeader from "../common/PageHeader/PageHeader";
import TTPTab2 from "../common/Tab/TTPTab2";
// import { TABS } from "./constants";
import _ from "../../i18n";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { ChartIcon, SyncIcon } from "../common/Icons";
import CroissanceClientsInPage from "./ClientsIn";
import CroissanceClientsOutPage from "./ClientsOut";
import CroissanceReviewPage from "./Review";
import CroissanceClientsInRiskPage from "./ClientsInRisk";
import { Progress, Tooltip } from "antd";
import {
  getCollaborator,
  getOrganisationPeriodSetting,
  runAdminIsClientParametersSynchronisation,
} from "../../api";
import { useSelector } from "react-redux";
import moment from "moment";
import { parsePreference } from "../../utils";
import { useQuery } from "react-query";

export default function CroissancePage() {
  const { token, navCommunity, user, collaborator } = useSelector((state) => state.auth);
  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};
  const TABS = [
    {
      id: "CLIENTS_IN",
      label: "Clients Entrants",
      subTitle: "clientsEntrants",
      hasAccess: parsePreference(preferences.financial).croissance.allowAccessIN,
    },
    {
      id: "CLIENTS_IN_RISK",
      label: "Clients à risque",
      subTitle: "clientsInRisk",
      hasAccess: parsePreference(preferences.financial).croissance.allowAccessOUT,
    },
    {
      id: "CLIENTS_OUT",
      label: "Clients Sortants",
      subTitle: "clientsSortants",
      hasAccess: parsePreference(preferences.financial).croissance.allowAccessRisky,
    },
    {
      id: "REVIEW",
      label: "Review",
      subTitle: "review",
      hasAccess: parsePreference(preferences.financial).croissance.allowAccessReview,
    },
  ];
  const accessibleTabs = TABS.filter((tab) => tab.hasAccess);
  const [filter, setFilter] = useState({
    tab: accessibleTabs[0].id,
    subTitle: accessibleTabs[0].subTitle,
  });
  const [clientParamsSynchro, setClientParamsSynchro] = useState({
    is_running: false,
    progress: 0,
    last_update: null,
  });
  const [anotherSynchoIsRunning, setAnotherSynchoIsRunning] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchData = () => {
      getOrganisationPeriodSetting(navCommunity.id, selectedYear, token)
        .then((response) => {
          if (response.data?.data) {
            const data = response.data.data;
            if (
              data?.synchroDetails?.synchronization?.is_running
                ?.client_params !== null
            ) {
              // check if there is another synchro running
              setAnotherSynchoIsRunning(
                Object.entries(
                  data.synchroDetails.synchronization.is_running
                ).some((it) => {
                  return it[0] !== "client_params" && it[1] === true;
                })
              );
              setClientParamsSynchro({
                is_running:
                  data.synchroDetails.synchronization.is_running.client_params,
                progress:
                  data.synchroDetails.synchronization.progress.client_params,
                last_update:
                  data.synchroDetails.synchronization.last_update.client_params,
              });
            }
          }
        })
        .catch(() => {
          console.error("Error fetching organisation period setting");
          setClientParamsSynchro({
            is_running: false,
            progress: 0,
            last_update: null,
          });
        });
    };

    // Call immediately
    fetchData();

    // Set interval
    const intervalId = setInterval(fetchData, 30000); // fetch every 30 seconds

    // Cleanup function to clear interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const renderSelectedTabContent = () => {
    switch (filter.tab) {
      case "CLIENTS_OUT":
        return (
          <CroissanceClientsOutPage
            changeSelectedYear={setSelectedYear}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "CLIENTS_IN_RISK":
        return (
          <CroissanceClientsInRiskPage
            changeSelectedYear={setSelectedYear}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "REVIEW":
        return (
          <CroissanceReviewPage
            changeSelectedYear={setSelectedYear}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      default:
        return (
          <CroissanceClientsInPage
            changeSelectedYear={setSelectedYear}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
    }
  };

  const startClientsParamsSynchro = async () => {
    const response = await runAdminIsClientParametersSynchronisation({
      year: selectedYear,
      organizationId: navCommunity.id,
      token,
    });

    if (response.data?.data && response.data.data?.status === "success") {
      setClientParamsSynchro({
        is_running: true,
        progress: 0,
        last_update: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    } else {
      setClientParamsSynchro({
        is_running: false,
        progress: 0,
        last_update: null,
      });
    }
  };

  return (
    <div className={styles.page_container}>
      <PageHeader
        header={_("clientsCroissance")}
        subHeader={_(`${filter.subTitle}`)}
        icon={<ChartIcon stroke="#29394d" size="36" />}
        children={preferences != null &&
          parsePreference(preferences.settings).allowAccessSynchronization && (
          <>
            {!clientParamsSynchro.is_running && (
              <>
                {anotherSynchoIsRunning ? (
                  <Tooltip title='Une autre synchronisation est en cours...'>
                    <SyncIcon
                      fill="#29394d"
                      size={22}
                      className={styles.sync_icon_not_allowed}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={`Synchroniser les données. (Dérnière synchronisation: ${clientParamsSynchro.last_update})`}>
                    <SyncIcon
                      fill="#29394d"
                      size={22}
                      className={styles.sync_icon}
                      onClick={startClientsParamsSynchro}
                    />
                  </Tooltip>
                )}
              </>
            )}
            {clientParamsSynchro.is_running && (
              <Tooltip title='Synchronisation en cours...'>
                <Progress
                  type="circle"
                  percent={parseInt(clientParamsSynchro.progress)}
                  width={26}
                  className={styles.syncing_icon}
                  strokeWidth={8}
                />
              </Tooltip>
            )}
          </>
        )}
      />

      <div className={styles.tab_container}>
        <TTPTab2
          tabs={accessibleTabs}
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id, subTitle: item.subTitle });
          }}
        />
      </div>

      {renderSelectedTabContent()}
    </div>
  );
}
