import { formatAmount } from "../../../../../utils";
import { renderFunctions } from "./services";

export const DEPOSIT_TABLE_VIEW_OPTIONS = [
  {
    value: 'BY_DOCUMENT',
    label: 'Par Document'
  },
  {
    value: 'BY_CLIENT',
    label: 'Par Client'
  }
];

export const DEPOT_TABLE_BY_DEPOT_COLUMNS =  [
  {
    title: 'ID',
    dataIndex: 'depositId',
    key: 'depositId',
    align: 'left',
  },
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    align: 'left',
    width: '100px',
    className: 'table-cell-bold'
  },
  {
    title: 'Dépôt',
    dataIndex: 'docCreationDate',
    key: 'docCreationDate',
    align: 'center',
    render: renderFunctions.dateColumn
  },
  {
    title: 'Date limite',
    dataIndex: 'processingLimitDate',
    key: 'processingLimitDate',
    align: 'center',
    render: renderFunctions.dateColumn
  },
  // {
  //   title: 'Date X',
  //   dataIndex: 'lastModifiedDate',
  //   key: 'lastModifiedDate',
  //   align: 'center',
  //   render: renderFunctions.dateColumn
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: renderFunctions.depositDocsTableRenderStatusColumn
  },
  {
    title: 'Actions',
    dataIndex: 'nextActions',
    key: 'nextActions',
    align: 'center',
    render: renderFunctions.depositDocsTableRenderActionColumn
  },
];

export const DEPOT_TABLE_BY_CLIENT_COLUMNS =  [
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    align: 'left',
    className: 'table-cell-bold'
  },
  {
    title: 'Total dépôts',
    dataIndex: 'totalDeposits',
    key: 'totalDeposits',
  },
  {
    title: 'Total dépôts respectés',
    dataIndex: 'totalRespectedDeposits',
    key: 'totalRespectedDeposits',
    align: 'center',
  },
  {
    title: 'Total dépôts non respectés',
    dataIndex: 'totalNonRespectedDeposits',
    key: 'totalNonRespectedDeposits',
  },
  {
    title: '% Dépôts respectés',
    dataIndex: 'percentageRespectedDeposits',
    key: 'percentageRespectedDeposits',
    render: (_, rowData) => {
      const val = rowData.totalRespectedDeposits * 100 / rowData.totalDeposits;
      return (<span>{val === 0 || val === 100 ? val :formatAmount(val, false)}%</span>);
    },
  },
  {
    title: '% Dépôts non respectés',
    dataIndex: 'percentageNonRespectedDeposits',
    key: 'percentageNonRespectedDeposits',
    render: (_, rowData) => {
      const val = rowData.totalNonRespectedDeposits * 100 / rowData.totalDeposits;
      return (<span>{val === 0 || val === 100 ? val : formatAmount(val, false)}%</span>);
    },
  },
];
