import { Carousel } from "primereact/carousel";
import React, { useState } from "react";
import _ from "../../../i18n";
import BonusBE from "./BonusBE";
import { parsePreference } from "../../../utils";
import BonusPage from "../../CollaboratorSpace/BonusPage";
import { useSelector } from "react-redux";

const Bonuses = ({ parentFilter = {}, collaborator, preferences }) => {
  const { user } = useSelector((state) => state.auth);
  const zones = [
    {
      id: 1,
      label: _("belgium"),
      hasAccess: parsePreference(preferences).individualDevBe.allowAccess,
    },
    {
      id: 2,
      label: _("morocco"),
      // hasAccess: parsePreference(preferences).individualDevMa.allowAccess,
      hasAccess: user.id == 471421 || user.id == 473472
    },
  ];
  const [activeTab, setActiveTab] = useState(
    zones.filter((z) => z.hasAccess)[0].id
  );

  const [filters, setFilters] = useState({
    ...parentFilter,
    collaborator: null,
    partnerZoneId: null,
    nbResult: 100,
    pageSize: 10,
    paginationPage: 1,
    keyWord: "",
  });

  const renderPannelFrame = () => {
    return (
      <div style={{ width: "100%" }}>
        {activeTab === 1 ? (
          <BonusBE
            filter={filters}
            collaborator={collaborator}
            preferences={preferences}
          />
        ) : activeTab === 2 ? (
          <BonusPage
            userSpace={null}
            filter={filters}
            collaborator={collaborator}
            preferences={preferences}
            year={filters.year}
          />
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  return (
    <div style={{ position: "relative", width: "100%", paddingTop: 30 }}>
      <Carousel
        value={zones.filter((z) => z.hasAccess)}
        numVisible={2}
        numScroll={0}
        showNavigators={false}
        style={{
          width: "100%",
          marginBottom: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        itemTemplate={(category, i) => {
          return (
            <div key={i}>
              <div
                className={`tab_badge ${
                  activeTab === category.id ? "tab_badge_active" : ""
                }`}
                onClick={() => setActiveTab(category.id)}
              >
                <div style={{ textTransform: "capitalize" }}>
                  {category.label}
                </div>
              </div>
            </div>
          );
        }}
      />
      {renderPannelFrame()}
    </div>
  );
};

export default Bonuses;
