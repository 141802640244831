import styles from './index.module.scss';
import { DEPOT_TABLE_BY_CLIENT_COLUMNS, DEPOT_TABLE_BY_DEPOT_COLUMNS } from "./constants";
import { Table, Tabs } from "antd";
import { formatAmount } from "../../../../../utils";
import CustomTable from '../CustomTable';
import './tabs.scss';

export default function RespectProcessingDeadlines({
  collaboratorId,
  period,
  setDeserveBonus,
  data,
  dataByClients,
  totals
}) {
  const renderByDocumentTable = () => {
    return (
      <CustomTable
        columns={DEPOT_TABLE_BY_DEPOT_COLUMNS}
        dataSource={data}
      />
    );
  };

  const renderByClientTable = () => {
    return (
      <CustomTable
        columns={DEPOT_TABLE_BY_CLIENT_COLUMNS}
        dataSource={dataByClients}
        summary={(pageData) => {
          let totalDeposits = 0;
          let totalRespectedDeposits = 0;
          let totalNonRespectedDeposits = 0;

          dataByClients.forEach((item) => {
            totalDeposits += item.totalDeposits;
            totalRespectedDeposits += item.totalRespectedDeposits;
            totalNonRespectedDeposits += item.totalNonRespectedDeposits;
          });

          const percentageRespectedDeposits = totalDeposits === 0 ? 0 : totalRespectedDeposits * 100 / totalDeposits;
          const percentageNonRespectedDeposits = totalDeposits === 0 ? 0 : totalNonRespectedDeposits * 100 / totalDeposits;

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} align="left" className="table-cell-bold">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align='center' className="table-cell-bold">
                  {totalDeposits}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align='center' className="table-cell-bold">
                  {totalRespectedDeposits}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align='center' className="table-cell-bold">
                  {totalNonRespectedDeposits}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align='center' className="table-cell-bold">
                  {percentageRespectedDeposits === 0 || percentageRespectedDeposits === 100 ? percentageRespectedDeposits : formatAmount(percentageRespectedDeposits, false)}%
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align='center' className="table-cell-bold">
                  {percentageNonRespectedDeposits === 0 || percentageNonRespectedDeposits === 100 ? percentageNonRespectedDeposits : formatAmount(percentageNonRespectedDeposits, false)}%
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Tabs
          size='small'
          className='bonus_custom_tabs'
          centered
          animated
          items={[
            {
              key: '1',
              label: 'Par document',
              children: renderByDocumentTable(),
            },
            {
              key: '2',
              active: true,
              label: 'Par client',
              children: renderByClientTable(),
            },
          ]}
        />
      </div>
    </div>
  )
}
