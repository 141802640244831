export function ReloadIcon({
  size = 14,
  fill = "#B2BCC6",
  ...params
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...params}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.71835 0.0639417C8.09634 -0.134169 9.4986 0.131158 10.7128 0.817021C11.9264 1.5026 12.8839 2.56955 13.4462 3.85263C14.0084 5.13538 14.1468 6.56823 13.842 7.93667C13.5372 9.30529 12.8044 10.5403 11.7486 11.4533C10.6923 12.3666 9.37018 12.9074 7.98074 12.9892C6.5912 13.0709 5.21575 12.6886 4.06207 11.9039C2.9089 11.1195 2.04126 9.97696 1.58444 8.65202C1.42826 8.19905 1.67239 7.70646 2.12971 7.55177C2.58702 7.39709 3.08436 7.63889 3.24053 8.09185C3.57805 9.07078 4.21597 9.90589 5.05273 10.475C5.88898 11.0438 6.88004 11.3175 7.87701 11.2589C8.87407 11.2002 9.8292 10.812 10.5977 10.1475C11.3667 9.48257 11.9075 8.57648 12.1331 7.56331C12.3588 6.54996 12.2557 5.48939 11.8409 4.54277C11.4262 3.59648 10.7242 2.8189 9.84579 2.32269C8.96788 1.82677 7.95888 1.63709 6.96973 1.7793C5.98036 1.92154 5.05858 2.38904 4.34497 3.117C4.33882 3.12327 4.33258 3.12946 4.32624 3.13554L3.03471 4.37564H4.55026C5.03351 4.37564 5.42526 4.76366 5.42526 5.24231C5.42526 5.72095 5.03351 6.10897 4.55026 6.10897H0.875C0.391751 6.10897 0 5.72095 0 5.24231V1.48676C0 1.00811 0.391751 0.620091 0.875 0.620091C1.35825 0.620091 1.75 1.00811 1.75 1.48676V3.19509L3.099 1.89981C4.07586 0.907507 5.34506 0.261376 6.71835 0.0639417Z" fill={fill}/>
    </svg>
  )
}
