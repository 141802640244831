import React from "react";

export const PlannedIcon = ({
  size = "14",
  stroke = "#6D7F92",
  fill = "none",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g clip-path="url(#clip0_2587_31245)">
      <path
        d="M3.48828 2.25L1.73828 4M13.4049 4L11.6549 2.25M4.29328 11.4083L2.90495 12.75M10.8616 11.3908L12.2383 12.75M5.82161 8.08333L6.98828 9.25L9.32162 6.91667M12.2383 8.08333C12.2383 10.6607 10.1489 12.75 7.57161 12.75C4.99429 12.75 2.90495 10.6607 2.90495 8.08333C2.90495 5.506 4.99429 3.41667 7.57161 3.41667C10.1489 3.41667 12.2383 5.506 12.2383 8.08333Z"
        stroke="#02AF8E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2587_31245">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0.571289 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
