/**
 * Validates an email address based on RFC 5322 standards
 * @param {string} email - The email address to validate
 * @returns {boolean} - Returns true if email is valid, false otherwise
 */
export function validateEmail(email) {
    if (!email || typeof email !== 'string') {
      return false;
    }

    // Trim any whitespace
    email = email.trim();

    // Check if empty after trimming
    if (email.length === 0) {
      return false;
    }

    // RFC 5322 compliant regex for email validation
    const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

    // Test the email against the regex
    if (!emailRegex.test(email)) {
      return false;
    }

    // Additional validation for common mistakes

    // Check for consecutive dots in the local part
    const [localPart] = email.split('@');
    if (localPart.includes('..')) {
      return false;
    }

    // Check domain has at least one dot and TLD is at least 2 characters
    const [, domain] = email.split('@');
    if (!domain.includes('.')) {
      return false;
    }

    const tld = domain.split('.').pop();
    if (tld.length < 2) {
      return false;
    }

    return true;
  }
