export default function CroissanceReviewPage({
  changeSelectedYear,
  collaborator,
  preferences
}) {
    return (
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <h3>En cours de réalisation</h3>
      </div>
    )
  }
