import { Tooltip } from "antd";
import {
  CardContainer,
  CardHeader,
  Filters,
  Header,
  CardBody,
  ProcessingDeadline,
  ProcessingTime,
  Ratios,
  FoldersQuality,
  Sidebar,
} from "./components";
import styles from "./index.module.scss";
import { InfoCircleFilledIcon } from "../../common/Icons";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import {
  getCollaboratorDocsProcessingData,
  getCollaboratorFoldersQuality,
  getCollaboratorNFHoursData,
  getCollaboratorProcessingTime,
  getCollaboratorRatios,
  getCollaboratorsByRole,
} from "../../../api";
import { Fragment, useEffect, useState } from "react";
import { formatAmount, parsePreference } from "../../../utils";
import classNames from "classnames";
import moment from "moment";
import GroupFilter from "../../Reporting/GroupFilter";
import _ from "../../../i18n";

const SIDEBAR_CHECK_LIST = [
  {
    key: 'nf5_1',
    title: 'Formation : Nettoyage hebdomadaire',
    description: '(NF5) : 3 heures par semaine, soit 12 heures par mois.',
    isCompleted: true,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
  {
    key: 'nf5_2',
    title: 'Formation : Bilan mensuel',
    description: '(NF5) : 4 heures par mois.',
    isCompleted: false,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
  {
    key: 'nf6',
    title: 'Réunions hebdomadaires',
    description: '(NF6) : 0,75 heure par semaine, soit 3 heures par mois.',
    isCompleted: false,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
  {
    key: 'nf4',
    title: "Besoin d'assistance",
    description: '(NF4) : 1,5 heure par semaine pour les juniors, soit 6 heures par mois.',
    isCompleted: true,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
  {
    key: 'ma5',
    title: 'Production et suivi de production',
    description: '(MA5) : 1 heure par semaine pour le TP, 4 heures par semaine pour la production, soit 5 heures par mois en total.',
    isCompleted: false,
    isOpened: false,
    weeklyValue: 0,
    monthlyValue: 0
  },
];

export default function BonusPage({
  filter,
  userSpace,
  collaborator,
  year,
  preferences,
}) {
  const allowViewDataBy =
    parsePreference(preferences).individualDevMa.allowViewDataBy;
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [processingDeadlineData, setProcessingDeadlineData] = useState([]);
  const [processingDeadlineDataByClients, setProcessingDeadlineDataByClients] = useState([]);
  const [processingDeadlineTotals, setProcessingDeadlineTotals] = useState({
    respected: 0,
    notProcessedYet: 0
  });
  const [processingTimeData, setProcessingTimeData] = useState([]);
  const [processingTimeExcessData, setProcessingTimeExcessData] = useState([]);
  const [processingTimeDataToDisplay, setProcessingTimeDataToDisplay] = useState([]);
  const [processingTimeNumberOfExcess, setProcessingTimeNumberOfExcess] = useState(0);
  const [ratiosData, setRatiosData] = useState({
    items: [],
    percentages: {
      en: 0,
      nb: 0,
      ns: 0
    },
    charts: [],
    totalPercentage: 0
  });
  const [foldersQualityData, setFoldersQualityData] = useState({
    items: [],
    chartsData: [],
    totals: {
      totalClients: 0,
      totalClientsWithErrors: 0,
      totalErrorsCount: 0,
      totalSeriousErrorsCount: 0
    },
    nbrOfSeriousErrors: 0,
    nbrOfNormalErrors: 0
  });
  const [sectionStatus, setStatusSection] = useState({
    respectProcessingDeadlines: {
      percentage: 0,
      isPositif: false,
      amount: 250
    },
    respectProcessingTime: {
      percentage: 0,
      isPositif: false,
      amount: 250
    },
    ratios: {
      percentage: 0,
      isPositif: false,
      amount: 500
    },
    foldersQuality: {
      percentage: 0,
      isPositif: false,
      amount: 500
    }
  });
  const [collaboratorNFHoursData, setCollaboratorNFHoursData] = useState([]);
  const [sidebarChecksList, setSidebarChecksList] = useState(SIDEBAR_CHECK_LIST);
  const [isSidebarVisible, setDisplaySidebar] = useState(false);
  const [eligible, setEligible] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState({ id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" });
  const [filters, setFilters] = useState({
    ...filter,
    filterTab: [
      { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
      {
        id: "TERMLY",
        label: "termly",
        subTab: [
          {
            id: 1,
            label: "1er trimestre (du 01/01 au 31/03)",
            start: "01-01",
            end: "03-31",
            disabled: false,
          },
          {
            id: 2,
            label: "2ème trimestre (du 01/04 au 30/06)",
            start: "04-01",
            end: "06-30",
            disabled: moment().quarter() < 2 && moment().year() == year,
          },
          {
            id: 3,
            label: "3ème trimestre (du 01/07 au 30/09)",
            start: "07-01",
            end: "09-30",
            disabled: moment().quarter() < 3 && moment().year() == year,
          },
          {
            id: 4,
            label: "4ème trimestre (du 01/10 au 31/12)",
            start: "10-01",
            end: "12-31",
            disabled: moment().quarter() < 4 && moment().year() == year,
          },
        ],
      },
    ],
    period: { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
    subPeriod: null,
    countFilter: 0,
    encodingManagers: [],
    geOptions: [],
    encodingManager: userSpace ? userSpace : null,
  });

  let geQuery = useQuery(["getGeByRole", token, navCommunity], async () => {
    if (token && navCommunity) {
      try {
        const response = await getCollaboratorsByRole({
          token,
          organizationId: navCommunity.id,
          role: "encodingManager",
        });
        let data = response.data.data;
        let list = data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.name,
            };
          });

        setFilters((prevFilters) => ({
          ...prevFilters,
          encodingManagers: data,
          encodingManager: userSpace ? userSpace : list[0]?.value,
          geOptions: list,
        }));
      } catch (e) {
        return null;
      }
    }
  });

  const getCollaboratorDocsProcessingDataQuery = useQuery(
    [
      "getCollaboratorDocsProcessingData",
      token,
      navCommunity,
      filters.encodingManager,
      year,
      selectedFilter,
    ],
    async () => {
      if (token && navCommunity && filters.encodingManager) {
        try {
          const response = await getCollaboratorDocsProcessingData({
            token,
            organizationId: navCommunity.id,
            collaboratorId: filters.encodingManager,
            startDate: `${year}-${selectedFilter.start}`,
            endDate: `${year}-${selectedFilter.end}`
          });
          const result = response.data.data.result;
          const resultByClient = response.data.data.resultByClient;
          const docsProcessingRespectedCount = response.data.data.docsProcessingRespectedCount;
          const docsDontProcessedYetCount = response.data.data.docsDontProcessedYetCount;

          setProcessingDeadlineData(result);
          setProcessingDeadlineDataByClients(resultByClient)
          setProcessingDeadlineTotals({
            respected: docsProcessingRespectedCount,
            notProcessedYet: docsDontProcessedYetCount
          });
          setStatusSection((prevState) => ({
            ...prevState,
            respectProcessingDeadlines: {
              ...prevState.respectProcessingDeadlines,
              isPositif: docsDontProcessedYetCount === 0 && result.length === docsProcessingRespectedCount,
              percentage: result.length === 0 ? 100 : (docsProcessingRespectedCount * 100) / result.length
            }
          }))
        } catch (e) {
          return null;
        }
      }
    }
  );

  const getCollaboratorProcessingTimeQuery = useQuery(
    [
      "getCollaboratorProcessingTime",
      token,
      navCommunity,
      year,
      filters.encodingManager,
      selectedFilter,
    ],
    async () => {
      if (token && navCommunity && filters.encodingManager) {
        try {
          const response = await getCollaboratorProcessingTime({
            token,
            organizationId: navCommunity.id,
            collaboratorId: filters.encodingManager,
            categories: [4],
            startDate: `${year}-${selectedFilter.start}`,
            endDate: `${year}-${selectedFilter.end}`
          });
          const data = response.data.data.processingTimeData;
          const excessData = data?.filter(item => item.timesheet.ecart > 0);
          const processingTimeNumberOfExcess = response.data.data.numberOfExcess;
          setProcessingTimeData(data);
          setProcessingTimeExcessData(excessData);
          setProcessingTimeDataToDisplay(excessData.slice(0, 6));
          setProcessingTimeNumberOfExcess(processingTimeNumberOfExcess);
          setStatusSection((prevState) => ({
            ...prevState,
            respectProcessingTime: {
              ...prevState.respectProcessingTime,
              isPositif: processingTimeNumberOfExcess === 0 || (100 - (processingTimeNumberOfExcess * 100 / data.length)) === 100,
              percentage: processingTimeNumberOfExcess === 0 ? 100 : (100 - (processingTimeNumberOfExcess * 100 / data.length))
            }
          }));
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!filters.encodingManager,
    }
  );

  const getCollaboratorRatiosQuery = useQuery(
    [
      "getCollaboratorRatios",
      token,
      navCommunity,
      filters.encodingManager,
      year,
      selectedFilter,
    ],
    async () => {
      if (token && navCommunity && filters.encodingManager) {
        try {
          const response = await getCollaboratorRatios({
            token,
            organizationId: navCommunity.id,
            collaboratorId: filters.encodingManager,
            startDate: `${year}-${selectedFilter.start}`,
            endDate: `${year}-${selectedFilter.end}`
          });
          let data = response.data.data;
          setRatiosData(prevState => ({
            ...prevState,
            items: data
          }));

          const allItems = data.length;
          const clientsRespectingENRatio = data.filter(item => item.isRespectingEncodageNettoyageRatio).length;
          const clientsRespectingNBRatio = data.filter(item => item.isRespectingNettoyageBilanRatio).length;
          const clientsRespectingNSRatio = data.filter(item => item.isRespectingNettoyageSituationRatio).length;
          const enPercentage = allItems > 0 ? clientsRespectingENRatio * 100 / allItems : 0;
          const nbPercentage = allItems > 0 ? clientsRespectingNBRatio * 100 / allItems : 0;
          const nsPercentage = allItems > 0 ? clientsRespectingNSRatio * 100 / allItems : 0;
          const totalPercentage = (0.8 * enPercentage / 100) + (0.1 * nbPercentage / 100) + (0.1 * nsPercentage / 100) * 100;

          setRatiosData((prevState) => ({
            ...prevState,
            percentages: {
              en: enPercentage,
              nb: nbPercentage,
              ns: nsPercentage
            },
            totalPercentage
          }));

          const enPieChartData = [
            {
              name: 'Les dossiers respectant le ratio 2:1',
              value: Math.round(allItems > 0 ? clientsRespectingENRatio * 100 / allItems : 0)
            },
            {
              name: 'les dossiers ne respectant pas le ratio 2:1',
              value: Math.round(allItems > 0 ?  (allItems - clientsRespectingENRatio) * 100 / allItems : 0)
            }
          ];
          const nbPieChartData = [
            {
              name: 'Les dossiers respectant le ratio 4:1',
              value: Math.round(allItems > 0 ?  clientsRespectingNBRatio * 100 / allItems : 0)
            },
            {
              name: 'les dossiers ne respectant pas le ratio 4:1',
              value: Math.round(allItems > 0 ?  (allItems - clientsRespectingNBRatio) * 100 / allItems : 0)
            }
          ];
          const nsPieChartData = [
            {
              name: 'Les dossiers respectant le ratio 4:1',
              value: Math.round(allItems > 0 ?  clientsRespectingNSRatio * 100 / allItems : 0)
            },
            {
              name: 'les dossiers ne respectant pas le ratio 4:1',
              value: Math.round(allItems > 0 ?  (allItems - clientsRespectingNSRatio) * 100 / allItems : 0)
            }
          ];

          setRatiosData((prevState) => ({
            ...prevState,
            charts: {
              en: enPieChartData,
              nb: nbPieChartData,
              ns: nsPieChartData
            }
          }));

          setStatusSection((prevState) => ({
            ...prevState,
            ratios: {
              ...prevState.ratios,
              percentage: totalPercentage,
              isPositif: enPercentage >= 80 && nbPercentage === 100 && nsPercentage === 100
            }
          }));
        } catch (e) {
          return null;
        }
      }
    }
  );

  const getCollaboratorFoldersQualityQuery = useQuery(
    [
      "getCollaboratorFoldersQuality",
      token,
      navCommunity,
      filters.encodingManager,
      year,
      selectedFilter,
    ],
    async () => {
      if (token && navCommunity && filters.encodingManager) {
        try {
          const response = await getCollaboratorFoldersQuality({
            token,
            organizationId: navCommunity.id,
            collaboratorId: filters.encodingManager,
            startDate: `${year}-${selectedFilter.start}`,
            endDate: `${year}-${selectedFilter.end}`,
          });
          const {
            result,
            resultByMonths,
            totalClients,
            totalClientsWithErrors,
            totalErrorsCount,
            totalSeriousErrorsCount,
          } = response.data.data;
          const numberOfNormalErrors =
            totalErrorsCount - totalSeriousErrorsCount;

          setFoldersQualityData({
            items: result,
            chartsData: resultByMonths,
            totals: {
              totalClients: totalClients,
              totalClientsWithErrors: totalClientsWithErrors,
              totalErrorsCount: totalErrorsCount,
              totalSeriousErrorsCount: totalSeriousErrorsCount
            },
            nbrOfSeriousErrors: totalSeriousErrorsCount,
            nbrOfNormalErrors: numberOfNormalErrors
          });

          setStatusSection((prevState) => ({
            ...prevState,
            foldersQuality: {
              ...prevState.foldersQuality,
              percentage: totalClients === 0 ? 100 : (100 - (totalClientsWithErrors * 100 / totalClients)),
              isPositif: totalSeriousErrorsCount === 0 && numberOfNormalErrors < 4
            }
          }));
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    }
  );

  const getCollaboratorNFHoursDataQuery = useQuery(
    ["getCollaboratorNFHoursData", token, navCommunity, filters.encodingManager, year],
    async () => {
      if (token && navCommunity && filters.encodingManager) {
        try {
          const response = await getCollaboratorNFHoursData({
            token,
            organizationId: navCommunity.id,
            collaboratorId: filters.encodingManager,
            period: year,
          });

          setCollaboratorNFHoursData(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  useEffect(() => {
    if (Object.keys(collaboratorNFHoursData).length === 0) {
      return;
    }
    setSidebarChecksList(prevState => {
      const currentYear = (new Date()).getFullYear();
      let currentMonth = (new Date()).getMonth() + 1;
      if (currentYear != year) {
        currentMonth = 12;
      }
      const nf5_1 = prevState.find(item => item.key === 'nf5_1');
      const nf5_2 = prevState.find(item => item.key === 'nf5_2');
      const nf6 = prevState.find(item => item.key === 'nf6');
      const nf4 = prevState.find(item => item.key === 'nf4');
      const ma5 = prevState.find(item => item.key === 'ma5');

      nf5_1.monthlyValue = 0;
      nf5_2.monthlyValue = 0;
      nf6.monthlyValue = 0;
      nf4.monthlyValue = 0;
      ma5.monthlyValue = 0;

      let numberOfMonths = 0;
      for (let i = 1; i <= currentMonth; i++) {
        const monthData = collaboratorNFHoursData[`${year}/${i.toString().padStart(2, "0")}`];
        // TODO: fix this two values...
        nf5_1.monthlyValue += monthData.nf5;
        nf5_2.monthlyValue += monthData.nf5;
        nf4.monthlyValue += monthData.nf4;
        nf6.monthlyValue += monthData.nf6;
        ma5.monthlyValue += monthData.ma5;
        numberOfMonths++;
      }

      const nf5_2_val = nf5_1.monthlyValue - (12 * 60 * numberOfMonths);
      nf5_1.monthlyValue = (nf5_2_val > 0 ? nf5_1.monthlyValue - nf5_2_val : nf5_1.monthlyValue) / 12;
      nf5_2.monthlyValue = nf5_2_val > 0 ?  nf5_2_val : 0;
      nf6.monthlyValue /= numberOfMonths;
      nf4.monthlyValue /= numberOfMonths;
      ma5.monthlyValue /= numberOfMonths;

      nf5_1.weeklyValue = nf5_1.monthlyValue / 4;
      nf5_2.weeklyValue = nf5_2.monthlyValue / 4;
      nf6.weeklyValue = nf6.monthlyValue / 4;
      nf4.weeklyValue = nf4.monthlyValue / 4;
      ma5.weeklyValue = ma5.monthlyValue / 4;

      nf5_1.isCompleted = nf5_1.monthlyValue === 12 && nf5_1.weeklyValue === 3;
      nf5_2.isCompleted = nf5_2.monthlyValue === 4;
      nf6.isCompleted = nf6.monthlyValue === 3 && nf6.weeklyValue === 0.75;
      nf4.isCompleted = nf4.monthlyValue === 6 && nf4.weeklyValue === 1.5;
      ma5.isCompleted = ma5.monthlyValue === 5 && ma5.weeklyValue === 1.25;


      return [
        nf5_1,
        nf5_2,
        nf6,
        nf4,
        ma5
      ];
    })
  }, [collaboratorNFHoursData]);

  useEffect(() => {
    const eligibleList = sidebarChecksList.filter(item => item.isCompleted);
    setEligible(eligibleList.length);
  }, [sidebarChecksList]);

  useEffect(() => {
    setFilters((prevFilter) => {
      const updatedFilterTab = prevFilter.filterTab.map((tab) => {
        if (tab.id === "TERMLY") {
          return {
            ...tab,
            subTab: tab.subTab.map((subTab) => {
              const disabled = subTab.id > 1 ? moment().quarter() < subTab.id && moment().year() == year : false;

              return { ...subTab, disabled };
            }),
          };
        }
        return tab;
      });

      return {
        ...prevFilter,
        period: { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
        filterTab: updatedFilterTab,
      };
    });
    setSelectedFilter(filters.filterTab.find((item) => item.id === "ANNUAL"));
  }, [year]);

  const handleGeChange = (ge) => {
    setFilters({ ...filters, encodingManager: ge });
  };

  const { encodingManagers, geOptions, encodingManager } = filters;

  const selectedCollaborator =
    encodingManager && encodingManagers && encodingManagers.length > 0
      ? encodingManagers.find((ge) => ge.id == encodingManager)
      : null;

  return (
    <div>
      {selectedCollaborator ? (
        <Fragment>
          <div
            className={classNames({
              [styles.overlay]: isSidebarVisible,
            })}
          />

          {!userSpace && (
            <GroupFilter
              extraFilters={[
                {
                  type: "SELECT",
                  options: {
                    isSimple: true,
                    isClearable: allowViewDataBy !== 10,
                    isSearch: true,
                    placeholder: _("collaborator"),
                    options: geOptions,
                    values: encodingManager,
                    action: handleGeChange,
                  },
                },
              ]}
              filter={filters}
              nbResult={filters.nbResult}
              updateFilter={setFilters}
              allowViewDataBy={
                parsePreference(preferences).individualDevMa.allowViewDataBy
              }
              collaborator={collaborator}
              preferences={preferences}
              subPreference="individualDevMa"
            />
          )}

          <Filters
            year={year}
            filters={filters}
            setFilters={setFilters}
            setSelectedFilter={setSelectedFilter}
          />

          {getCollaboratorDocsProcessingDataQuery.isFetching ||
          getCollaboratorProcessingTimeQuery.isFetching ||
          getCollaboratorRatiosQuery.isFetching ||
          getCollaboratorFoldersQualityQuery.isFetching ||
          getCollaboratorNFHoursDataQuery.isFetching ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : (
            <>
              <Header
                collaborator={selectedCollaborator}
                eligible={eligible}
                totalBonusAmount={Object.values(sectionStatus).reduce(
                  (total, section) => {
                    return total + (section.isPositif ? section.amount : 0);
                  },
                  0
                )}
                setDisplaySidebar={setDisplaySidebar}
              />

              <section className={styles.grid}>
                <CardContainer className={styles.card}>
                  <CardHeader
                    percentage={formatAmount(
                      sectionStatus.respectProcessingDeadlines.percentage,
                      false
                    )}
                    amount={sectionStatus.respectProcessingDeadlines.amount}
                    title="Respect des délais de traitement"
                    isPositif={
                      sectionStatus.respectProcessingDeadlines.isPositif
                    }
                    detailsBox={{
                      value:
                        processingDeadlineData.length === 0 ||
                        (processingDeadlineTotals.respected * 100) /
                          processingDeadlineData.length ===
                          100
                          ? 0
                          : processingDeadlineTotals.notProcessedYet,
                      label: "Nbr. de factures restantes",
                      isPositif: processingDeadlineTotals.notProcessedYet === 0,
                    }}
                  />
                  <CardBody
                    title={
                      <div className={styles.card_body_title}>
                        <p>Délais de traitement des documents comptables</p>
                        <Tooltip
                          title="Traitement des documents dans un délai maximal de quatre semaines après leur date de
                      dépôt"
                        >
                          <InfoCircleFilledIcon />
                        </Tooltip>
                      </div>
                    }
                  >
                    <ProcessingDeadline
                      data={processingDeadlineData}
                      dataByClients={processingDeadlineDataByClients}
                      totals={processingDeadlineTotals}
                      collaboratorId={selectedCollaborator.id}
                      period={2025}
                    />
                  </CardBody>
                </CardContainer>

                <CardContainer className={styles.card}>
                  <CardHeader
                    percentage={formatAmount(
                      sectionStatus.respectProcessingTime.percentage,
                      false
                    )}
                    amount={sectionStatus.respectProcessingTime.amount}
                    isPositif={sectionStatus.respectProcessingTime.isPositif}
                    title="Respect du temps de traitement"
                    detailsBox={{
                      value: processingTimeNumberOfExcess,
                      label: "Nbr. de dépassement",
                      isPositif: processingTimeNumberOfExcess === 0,
                    }}
                  />
                  <CardBody title="Temps de traitement par type de documents comptables">
                    <ProcessingTime
                      data={processingTimeExcessData}
                      dataToDisplay={processingTimeDataToDisplay}
                      onPaginationChange={(page, pageSize) => {
                        setProcessingTimeDataToDisplay(
                          processingTimeExcessData.slice(
                            (page - 1) * pageSize,
                            page * pageSize
                          )
                        );
                      }}
                      collaboratorId={selectedCollaborator.id}
                      period={2025}
                    />
                  </CardBody>
                </CardContainer>

                <CardContainer className={styles.card}>
                  <CardHeader
                    percentage={formatAmount(
                      sectionStatus.ratios.percentage,
                      false
                    )}
                    amount={sectionStatus.ratios.amount}
                    title="Ratio"
                    isPositif={sectionStatus.ratios.isPositif}
                    detailsBoxes={[
                      {
                        value:
                          (ratiosData.percentages.en === 0
                            ? 0
                            : formatAmount(ratiosData.percentages.en, false)) +
                          "%",
                        label: "Encodage/Nettoyage",
                        description: "2:1",
                        isPositif: ratiosData.percentages.en >= 80,
                      },
                      {
                        value:
                          (ratiosData.percentages.nb === 0
                            ? 0
                            : formatAmount(ratiosData.percentages.nb, false)) +
                          "%",
                        label: "Nettoyage/Bilan",
                        description: "4:1",
                        isPositif: ratiosData.percentages.nb === 100,
                      },
                      {
                        value:
                          (ratiosData.percentages.ns === 0
                            ? 0
                            : formatAmount(ratiosData.percentages.ns, false)) +
                          "%",
                        label: "Nettoyage/Situation",
                        description: "4:1",
                        isPositif: ratiosData.percentages.ns === 100,
                      },
                    ]}
                  />
                  <CardBody title="Temps de traitement par type de documents comptables">
                    <Ratios
                      collaboratorId={selectedCollaborator.id}
                      period={2025}
                      data={ratiosData}
                    />
                  </CardBody>
                </CardContainer>

                <CardContainer className={styles.card}>
                  <CardHeader
                    percentage={formatAmount(
                      sectionStatus.foldersQuality.percentage,
                      false
                    )}
                    isPositif={sectionStatus.foldersQuality.isPositif}
                    amount={sectionStatus.foldersQuality.amount}
                    title="Qualité des dossiers"
                    detailsBox={{
                      value: foldersQualityData.totals.totalErrorsCount,
                      label: "Nbr. d'erreur",
                      isPositif:
                        foldersQualityData.nbrOfSeriousErrors === 0 &&
                        foldersQualityData.nbrOfNormalErrors < 4,
                    }}
                  />
                  <CardBody title="Temps de traitement par type de documents comptables">
                    <FoldersQuality
                      collaboratorId={selectedCollaborator.id}
                      period={year}
                      data={foldersQualityData}
                    />
                  </CardBody>
                </CardContainer>
              </section>

              {isSidebarVisible &&
                getCollaboratorNFHoursDataQuery.isFetched && (
                  <Sidebar
                    setShowSidebar={setDisplaySidebar}
                    list={sidebarChecksList}
                    setList={setSidebarChecksList}
                    eligible={eligible}
                  />
                )}
            </>
          )}
        </Fragment>
      ) : (
        <div className="no_data">
          <div className="title">{_("noDataFound")}</div>
          <div className="subTitle">{_("noCollaboratorSelected")}</div>
        </div>
      )}
    </div>
  );
}
