import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { getClientsSuggestions, getCollaboratorsSuggestions } from "../../api";
import {
  BUDGET_STATUS,
  CLIENT_CRM_TYPE_FILTER,
  CLIENT_GROUP_CRM_FILTER,
  COLLABORATOR_ROLE_FILTER,
  DELETED_STATUS,
  IS_ENTITY_STATES,
  SORT_TYPES,
  DEPART_STATUS,
} from "../../config";
import _ from "../../i18n";
import {
  parsePreference,
  renderTeamYears,
  useOnClickOutside,
  viewDataBy,
} from "../../utils";

import { TiArrowSortedDown } from "react-icons/ti";
import { getPartners } from "../../api/partners";
import TTPFilterHorizontalBar from "../common/Filter/TTPFilterHorizontalBar";

const ClientsFilter = ({
  filter,
  collaborator,
  nbResult,
  updateFilter,
  resetFilter,
  loggedAs,
  spaceAs,
  preferences,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));
  const { token, navCommunity, user } = useSelector((state) => state.auth);
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [data, setData] = useState({
    collaborators: [],
    deletedCollaborators: [],
  });
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [deletedCollaboratorOptions, setDeletedCollaboratorOptions] = useState(
    []
  );

  let partnersQuery = useQuery(
    ["partnersQuery", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const response = await getPartners({
          token,
          organizationId: navCommunity?.id,
        });
        const data = response.data.data.map((partner) => ({
          value: Number(partner.id),
          label: partner.name,
          zone: partner.zone,
        }));
        setPartnerOptions(data);
        if (parsePreference(preferences.clients).allowViewDataBy === 11) {
          const zone = data.find((p) => p.value === Number(user?.id)).zone;
          updateFilter({ ...filter, partnerZone: zone });
        }
        return data;
      }
    },
    {
      enabled: !!token && !!navCommunity,
    }
  );

  let clientsdQuery = useQuery(
    ["getClients", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getClientsSuggestions(
            token,
            filter.clientCRMGroup,
            navCommunity.id,
            "isEntity",
            filter.year
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setClientOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let collaboratorsdQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id,
            null,
            filter.year
          );
          let data = response.data.data;
          let list = viewDataBy(preferences.clients, data, collaborator);
          data = list
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
                partner: t?.partner?.value,
                isActif: t.isActif,
              };
            });
          setData((prevData) => ({
            ...prevData,
            collaborators: list.map((c) => ({
              value: c.id,
              label: c.name,
              partner: c?.partner?.value,
              isActif: c.isActif,
            })),
          }));
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let deletedCollaboratorsdQuery = useQuery(
    ["getDeletedCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id,
            true,
            filter.year
          );
          let data = response.data.data;
          let list = viewDataBy(preferences.clients, data, collaborator);
          data = list
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
                partner: t?.partner?.value,
                isActif: t.isActif,
              };
            });
          setData((prevData) => ({
            ...prevData,
            deletedCollaborators: list.map((c) => ({
              value: c.id,
              label: c.name,
              partner: c?.partner?.value,
              isActif: c.isActif,
            })),
          }));
          setDeletedCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => updateFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (filter.partner) {
      const options = data.collaborators.filter(
        (c) => c.partner == filter.partner
      );
      const deletedOptions = data.deletedCollaborators.filter(
        (p) => p.partner == filter.partner
      );
      setCollaboratorOptions(options);
      setDeletedCollaboratorOptions(deletedOptions);
    } else {
      setCollaboratorOptions(data.collaborators);
      setDeletedCollaboratorOptions(data.deletedCollaborators);
    }
  }, [filter.partner, data]);

  const handlePartnerChange = (partner) => {
    const zone = partnerOptions.find((p) => p.value === Number(partner))?.zone;
    updateFilter({ ...filter, partner: Number(partner), partnerZone: zone });
  };

  const handleEntityChange = (items) => {
    updateFilter({ ...filter, clients: items });
  };

  const handleSetIsEntityState = (state) => {
    updateFilter({ ...filter, isEntity: state });
  };

  const handleSetDeletedStatus = (status) => {
    updateFilter({ ...filter, deletedStatus: status });
  };

  const handleTypeCRMClientChange = (items) => {
    updateFilter({ ...filter, clientCRMTypes: items });
  };

  const handleGroupCRMClientChange = (items) => {
    updateFilter({
      ...filter,
      clientCRMGroup: items,
    });
  };

  const handleSortByClientChange = (items) => {
    updateFilter({ ...filter, sortBy: items });
  };

  const handleCollaboratorsChange = (items) => {
    const actifs = data.collaborators
      .filter((item) => item.isActif)
      .map((item) => item.value);

    const updatedSelection = [...actifs, ...items];

    updateFilter({ ...filter, collaborators: [...new Set(updatedSelection)] });
  };

  const handleCollaboratorRoleChange = (item) => {
    updateFilter({ ...filter, role: item });
  };

  const handleBudgetStatusChange = (selectedValues) => {
    updateFilter({ ...filter, budgetStatus: selectedValues });
  };

  const handleDepartStatusChange = (selectedValue) => {
    updateFilter({ ...filter, departStatus: selectedValue });
  };

  const handleDeletedCollaboratorsChange = (items) => {
    updateFilter({ ...filter, deletedCollaborators: items });
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isSearch: true,
        isClearable: true,
        values: filter.clients,
        placeholder: _("clientsAttachedTo"),
        action: handleEntityChange,
        options: clientOptions,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("clientType"),
        options: IS_ENTITY_STATES,
        values: filter.isEntity,
        action: handleSetIsEntityState,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("clientState"),
        options: DELETED_STATUS,
        values: filter.deletedStatus,
        action: handleSetDeletedStatus,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("clientsType"),
        options: CLIENT_CRM_TYPE_FILTER,
        values: filter.clientCRMTypes,
        action: handleTypeCRMClientChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("Trier par"),
        options: SORT_TYPES,
        values: filter.sortBy,
        action: handleSortByClientChange,
      },
    },
    // {
    //   type: "SELECT",
    //   options: {
    //     isSimple: true,
    //     isClearable: true,
    //     placeholder: _("budgetStatus"),
    //     options: BUDGET_STATUS_FILTER,
    //     values: filter.budgetStatus,
    //     action: handleBudgetStatusChange,
    //   },
    // },

    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: false,
        isSearch: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborators,
        action: handleCollaboratorsChange,
      },
    },
    filter.collaborators.length > 0
      ? {
          type: "SELECT",
          options: {
            isSimple: true,
            isClearable: true,
            placeholder: _("folderAs"),
            options: COLLABORATOR_ROLE_FILTER,
            values: filter.role,
            action: handleCollaboratorRoleChange,
          },
        }
      : {
          type: "SELECT",
          options: {
            isSimple: false,
            isMulti: true,
            isClearable: true,
            placeholder: _("deletedCollab"),
            options: deletedCollaboratorOptions,
            values: filter.deletedCollaborators,
            action: handleDeletedCollaboratorsChange,
          },
        },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("budgetStatus"),
        options: BUDGET_STATUS,
        values: filter.budgetStatus,
        action: handleBudgetStatusChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("clientsGroup"),
        options: CLIENT_GROUP_CRM_FILTER,
        values: filter.clientCRMGroup,
        defaultValues: [
          _("AiSBL"),
          _("ASBL"),
          _("ASBL - Consultation"),
          _("ASBL - Partenaire"),
          _("ASBL petite entreprise"),
          _("Contact"),
          _("Fondation privée"),
          _("Indépendants"),
          _("Indépendants - Consultation"),
          _("Indépendants petite entreprise"),
          _("Partenaire"),
          _("Particuliers"),
          _("Particuliers - Consultation"),
          _("Sociétés"),
          _("Sociétés - Partenaire"),
          _("Sociétés petite entreprise"),
        ],
        action: handleGroupCRMClientChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        placeholder: _("departStatus"),
        options: DEPART_STATUS,
        values: filter.departStatus,
        action: handleDepartStatusChange,
      },
    },
  ];

  if ([1, 11].includes(parsePreference(preferences.clients).allowViewDataBy)) {
    filters.unshift({
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        isSearch: true,
        isDisabled: parsePreference(preferences.clients).allowViewDataBy === 11,
        placeholder: _("partner"),
        options: partnerOptions,
        values:
          loggedAs === "COLLABORATOR" &&
          parsePreference(preferences.clients).allowViewDataBy === 11
            ? collaborator?.partner?.value
            : filter.partner,
        action: handlePartnerChange,
      },
    });
  }
  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          bg="#FFF"
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={filter.pageSize}
          customAddOn={renderYearSelect()}
          updateFilterContent={updateFilter}
          resetFilterContent={resetFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default ClientsFilter;
