import { useState, useMemo } from "react";
import styles from './index.module.scss';
import { Bar, CartesianGrid, ComposedChart, LabelList, Legend, XAxis, YAxis } from "recharts";
import CustomTable from '../CustomTable';
import TableChartFilter, { CHART_VIEW, TABLE_VIEW } from "../TableChartFilter";

export default function FoldersQuality({
  collaboratorId,
  period,
  data
}) {
  const [dataView, setDataView] = useState(TABLE_VIEW);

  const processedChartData = useMemo(() => {
    const currentMonth = new Date().getMonth() + 1;

    const allMonths = [
      { monthId: 1, monthAbbr: 'Jan' },
      { monthId: 2, monthAbbr: 'Fév' },
      { monthId: 3, monthAbbr: 'Mar' },
      { monthId: 4, monthAbbr: 'Avr' },
      { monthId: 5, monthAbbr: 'Mai' },
      { monthId: 6, monthAbbr: 'Jun' },
      { monthId: 7, monthAbbr: 'Jul' },
      { monthId: 8, monthAbbr: 'Aoû' },
      { monthId: 9, monthAbbr: 'Sep' },
      { monthId: 10, monthAbbr: 'Oct' },
      { monthId: 11, monthAbbr: 'Nov' },
      { monthId: 12, monthAbbr: 'Déc' }
    ];

    return allMonths.map(month => {
      const existingData = data.chartsData.find(item => parseInt(item.month) === month.monthId) || {};

      return {
        monthAbbr: month.monthAbbr,
        numberTotalOfErrors: existingData.numberTotalOfErrors || 0,
        numberTotalOfSeriousErrors: existingData.numberTotalOfSeriousErrors || 0,
        monthColor: month.monthId === currentMonth ? '#29394D' : '#B2BCC6'
      };
    });
  }, [data.chartsData]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.filters}>
          <TableChartFilter
            onChange={(selectedView) => {
              setDataView(selectedView);
            }}
          />
        </div>

        {dataView === TABLE_VIEW && (
          <CustomTable
            columns={[
              {
                title: 'Client',
                dataIndex: 'clientName',
                key: 'clientName',
                align: 'left',
                className: 'table-cell-bold'
              },
              {
                title: 'Nbr. de rapport',
                dataIndex: 'numberOfReports',
                key: 'numberOfReports',
                className: 'table-cell-bold'
              },
              {
                title: 'Nbr. erreurs graves',
                dataIndex: 'numberOfSeriousErrors',
                key: 'numberOfSeriousErrors',
                align: 'center',
                className: 'table-cell-bold',
                render: (_, rowData) => {
                  if (rowData.numberOfSeriousErrors) {
                    return (
                      <span className={styles.grave_error_cell}>
                        <span className={styles.status_circle} />
                        {rowData.numberOfSeriousErrors}
                      </span>
                    );
                  } else {
                    return rowData.numberOfSeriousErrors;
                  }
                }
              },
            ]}
            dataSource={data.items}
          />
        )}

        {dataView === CHART_VIEW && (
          <>
            <div className={styles.legend_container}>
              <Legend
                align="left"
                verticalAlign="top"
                iconType="circle"
                iconSize={8}
                payload={[
                  { value: 'Total des erreurs', color: '#FBA834', type: 'circle' },
                  { value: 'Total des erreurs graves', color: '#EB5757', type: 'circle' }
                ]}
                wrapperStyle={{
                  marginTop: '70px',
                }}
                formatter={(value) => (
                  <span style={{
                    color: '#29394D',
                    fontSize: '12px',
                    fontWeight: '500',
                    marginLeft: '4px'
                  }}>
                    {value}
                  </span>
                )}
              />
            </div>
            <ComposedChart
              width={550}
              height={280}
              data={processedChartData}
              margin={{
                top: 0,
                right: 0,
                left: -40,
                bottom: 0,
              }}
              barSize={20}
              maxBarSize={20}
            >
              <CartesianGrid strokeDasharray="1 3" vertical={false} />
              <XAxis
                dataKey="monthAbbr"
                tickLine={false}
                tick={({ x, y, payload }) => (
                  <text
                    x={x}
                    y={y + 10}
                    textAnchor="middle"
                    fill={processedChartData.find(item => item.monthAbbr === payload.value)?.monthColor}
                  >
                    {payload.value}
                  </text>
                )}
              />
              <YAxis tickLine={false} axisLine={false} />
              <Bar
                name="Total des erreurs"
                dataKey="numberTotalOfErrors"
                stackId="a"
                fill="#FBA834"
                label="Nombre total des erreurs"
                width={20}
              >
                <LabelList dataKey="numberTotalOfErrors" fontSize={8} fill="white" />
              </Bar>
              <Bar
                name="Total des erreurs graves "
                dataKey="numberTotalOfSeriousErrors"
                stackId="a"
                fill="#EB5757"
                width={20}
                radius={[4, 4, 0, 0]}
              >
                <LabelList dataKey="numberTotalOfSeriousErrors" fontSize={8} fill="white" />
              </Bar>
            </ComposedChart>
          </>
        )}
      </div>
    </div>
  );
}
