import { Table } from "antd";
import classNames from "classnames";
import { useState } from "react";
import './index.scss';

export default function CustomTable({
  columns,
  dataSource,
  summary,
}) {
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Table
      className='bonus_custom_table'
      columns={columns}
      dataSource={dataSource}
      size='small'
      style={{ width: '100%' }}
      pagination={{
        className: 'bonus_custom_table_pagination',
        pageSize: pageSize,
        current: currentPage,
        onChange: (page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        },
        itemRender: (page, type) => {
          if (type === 'prev') {
            return (
              <button
                className={classNames({
                  'pagination_button': true,
                  'pagination_button_disabled': currentPage === 1
                })}
                disabled={currentPage === 1}
              >
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none">
                  <path d="M5 9L1 5L5 1" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            );
          }
          if (type === 'next') {
            return (
              <button
                className={classNames({
                  'pagination_button': true,
                  'pagination_button_disabled': currentPage === dataSource.length / pageSize
                })}
                disabled={currentPage * pageSize >= dataSource.length}
              >
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none">
                  <path d="M1 1L5 5L1 9" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            );
          }
          return null;
        },
        showTotal: (total, range) => (
          <p className='pagination_total_text'>
            {Math.ceil(range[0] / 5)} sur {Math.ceil(total / 5)}
          </p>
        ),
      }}
      summary={summary}
    />
  )
}
