import { useEffect, useRef } from "react";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import _ from "../i18n";
import { TTP_API_URL } from "../config";

import { addLocale } from "primereact/api";

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const getDefaultLanguage = () => {
  let lng = navigator.language || navigator.userLanguage;
  lng = lng.split("-")[0];
  return ["fr", "en", "nl"].includes(lng) ? lng : "en";
};

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export const generateFullName = (firstName, lastName) => {
  firstName = firstName || "";
  lastName = lastName || "";
  const fullName = `${lastName} ${firstName}`.trim();
  return fullName !== "" ? fullName : _("unknown");
};

export const getUserNameForAvatar = (firstName, lastName) => {
  let fName = firstName ? firstName.split(" ") : "";
  if (fName.length >= 3) {
    return extractFirstLettre(fName, 3);
  } else {
    let lName = lastName ? lastName.split(" ") : "";
    return extractFirstLettre(fName.concat(lName), 3);
  }
};

function extractFirstLettre(arrayStr, length) {
  let result = "";
  for (let i = 0; i < length; i++) {
    if (arrayStr[i]) {
      result += arrayStr[i].substring(0, 1);
    }
  }
  return result.toUpperCase();
}

export const getMonthShortLabelObject = (lng) => {
  moment.locale(lng);
  let list = [];
  moment.monthsShort().forEach((element, index) => {
    const monthMoment = moment().month(index);
    const endOfMonth = monthMoment.endOf("month").format("DD");

    list.push({
      id: index + 1,
      label: element,
      start: `${(index < 9 ? "0" : "") + (index + 1)}-01`,
      end: `${(index < 9 ? "0" : "") + (index + 1)}-${endOfMonth}`,
    });
  });

  return list;
};

export const getMonthLongLabelObject = (lng) => {
  moment.locale(lng);
  let list = [];

  moment.monthsShort().forEach((element, index) => {
    const monthMoment = moment().month(index);
    const year = moment().year();
    const endOfMonth = monthMoment.endOf("month").format("DD");

    list.push({
      id: index + 1,
      label: element,
      start: `${year}-${(index < 9 ? "0" : "") + (index + 1)}-01/`,
      end: `${year}-${(index < 9 ? "0" : "") + (index + 1)}-${endOfMonth}/`,
    });
  });

  return list;
};

export const renderDurationDisplay = (item) => {
  let duration = Math.abs(parseFloat(item).toFixed(2));
  return Math.abs(duration) >= 60
    ? Math.floor(duration / 60) +
        "h " +
        ((duration / 60 - Math.floor(duration / 60)) * 60 > 0
          ? parseInt((duration / 60 - Math.floor(duration / 60)) * 60, 10) + "m"
          : "")
    : Math.floor(duration) +
        "m " +
        (duration - Math.floor(duration) > 0
          ? parseInt((duration - Math.floor(duration)) * 60, 10) + "s"
          : "");
};

export const renderDurationMinutesDisplay = (item) => {
  if (!item) return "0";
  let duration = Math.abs(parseFloat(item).toFixed(2));

  let hours = Math.floor(duration / 60);
  let minutes = Math.floor(duration % 60);

  let display = "";
  if (hours > 0) display += `${hours}h `;
  if (minutes > 0 || hours > 0) display += `${minutes}m `;

  return display.trim();
};

export const parseDurationDisplay = (formattedValue) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  const match = formattedValue.match(/(\d+)h|(\d+)m|(\d+)s/g);

  if (match) {
    match.forEach((part) => {
      if (part.includes("h")) {
        hours = parseInt(part.replace("h", ""), 10);
      } else if (part.includes("m")) {
        minutes = parseInt(part.replace("m", ""), 10);
      } else if (part.includes("s")) {
        seconds = parseInt(part.replace("s", ""), 10);
      }
    });
  }

  const totalDuration = hours * 60 + minutes + seconds / 60;
  return parseFloat(totalDuration.toFixed(2));
};

export const setLocaleCalendarData = (lng) => {
  switch (lng) {
    case "fr":
      addLocale("fr", {
        firstDayOfWeek: 0,
        dayNames: [
          "lundi",
          "mardi",
          "mercredi",
          "jeudi",
          "vendredi",
          "samedi",
          "dimanche",
        ],
        dayNamesShort: ["lun", "mar", "mer", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["L", "M", "M", "J", "V", "S", "D"],
        monthNames: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        monthNamesShort: [
          "Janv",
          "Févr",
          "Mars",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Août",
          "Sept",
          "Oct",
          "Nov",
          "Déc",
        ],
        today: "Aujourd'hui",
        clear: "effacer",
      });

    case "nl":
      addLocale("nl", {
        firstDayOfWeek: 0,
        dayNames: [
          "maandag",
          "dinsdag",
          "woensdag",
          "donderdag",
          "vrijdag",
          "zaterdag",
          "zondag",
        ],
        dayNamesShort: ["maan", "din", "woe", "don", "vri", "zat", "zon"],
        dayNamesMin: ["M", "D", "W", "D", "V", "Z", "Z"],
        monthNames: [
          "januari",
          "februari",
          "maart",
          "april",
          "mei",
          "juni",
          "juli",
          "augustus",
          "september",
          "oktober",
          "november",
          "december",
        ],
        monthNamesShort: [
          "jan",
          "feb",
          "maa",
          "apr",
          "mei",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ],
        today: "Vandaag",
        clear: "duidelijk",
      });

    case "en":
      addLocale("en", {
        firstDayOfWeek: 0,
        dayNames: [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ],
        dayNamesShort: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
        dayNamesMin: ["M", "T", "W", "T", "F", "S", "S"],
        monthNames: [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ],
        monthNamesShort: [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ],
        today: "Today",
        clear: "Clear",
      });
    default:
      return;
  }
};

export const getMonths = (lng) => {
  switch (lng) {
    case "fr":
      return [
        {
          value: 0,
          label: "Janvier",
        },
        {
          value: 1,
          label: "Février",
        },
        {
          value: 2,
          label: "Mars",
        },
        {
          value: 3,
          label: "Avril",
        },
        {
          value: 4,
          label: "Mai",
        },
        {
          value: 5,
          label: "Juin",
        },
        {
          value: 6,
          label: "Juillet",
        },
        {
          value: 7,
          label: "Août",
        },
        {
          value: 8,
          label: "Septembre",
        },
        {
          value: 9,
          label: "Octobre",
        },
        {
          value: 10,
          label: "Novembre",
        },
        {
          value: 11,
          label: "Décembre",
        },
      ];
    case "nl":
      return [
        {
          value: 0,
          label: "januari",
        },
        {
          value: 1,
          label: "februari",
        },
        {
          value: 2,
          label: "maart",
        },
        {
          value: 3,
          label: "april",
        },
        {
          value: 4,
          label: "mei",
        },
        {
          value: 5,
          label: "juni",
        },
        {
          value: 6,
          label: "juli",
        },
        {
          value: 7,
          label: "augustus",
        },
        {
          value: 8,
          label: "september",
        },
        {
          value: 9,
          label: "oktober",
        },
        {
          value: 10,
          label: "november",
        },
        {
          value: 11,
          label: "december",
        },
      ];
    case "en":
      return [
        {
          value: 0,
          label: "january",
        },
        {
          value: 1,
          label: "february",
        },
        {
          value: 2,
          label: "march",
        },
        {
          value: 3,
          label: "april",
        },
        {
          value: 4,
          label: "may",
        },
        {
          value: 5,
          label: "june",
        },
        {
          value: 6,
          label: "july",
        },
        {
          value: 7,
          label: "august",
        },
        {
          value: 8,
          label: "september",
        },
        {
          value: 9,
          label: "october",
        },
        {
          value: 10,
          label: "november",
        },
        {
          value: 11,
          label: "december",
        },
      ];
    default:
      return;
  }
};

export const renderAvatar = (avatarUrl, name, size = 34) => {
  const styles = {
    width: size,
    height: size
  };

  if (!avatarUrl && !name) {
    return (
      <div className="avatar_not_exist" style={styles}>
        <p style={{ margin: "auto" }}>-</p>
      </div>
    );
  }
  let avatarDiv;
  if (!avatarUrl) {
    avatarDiv = (
      <div className="avatar_not_exist" style={styles}>
        <span>{getUserNameForAvatar(name)}</span>
      </div>
    );
  } else {
    avatarDiv = (
      <div
        className="avatar_exist"
        style={{
          ...styles,
          backgroundImage: `url(${avatarUrl}`,
        }}
      />
    );
  }

  return avatarDiv;
};
export const extractNumber = (value) => {
  return parseInt(
    typeof value === "string" ? value.replace(/[^0-9.]/g, "") : value
  );
};
export const extractFloatNumber = (value) => {
  return parseFloat(
    typeof value === "string" ? value.replace(/[^0-9,.]/g, "") : value
  );
};

export const renderNotificationManagerMessage = (action, data) => {
  switch (action) {
    case "absence_request":
      return (
        <div>
          <span className="highlighted">{data.data.applicant}</span>{" "}
          {`${_("requestedAnAbscence")} ${_("from")}`}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "absence_approved":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {_("approvedAbscenceOf")}{" "}
          <span className="highlighted">{data.data.applicant}</span> {_("from")}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "absence_sick":
      return (
        <div>
          <span className="highlighted">{data.data.beneficiary}</span>{" "}
          {`${_("indicatedAnSickAbscence")} ${_("from")}`}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "absence_affected":
      return (
        <div>
          <span className="highlighted">{data.user}</span> {_("affected")}{" "}
          {_("anAbscence")} {_("for")}
          <span className="highlighted">{data.data.beneficiary}</span>{" "}
          {_("from")} <span className="highlighted">{data.data.from}</span>{" "}
          {_("to")} <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "absence_rejected":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {_("rejectededAbscenceOf")}{" "}
          <span className="highlighted">{data.data.applicant}</span> {_("from")}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "correction_card_affected":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("hasAffectedYou")} ${_("to")}`} {_("correctionPlan")}{" "}
          <span className="highlighted">{data.data.title}</span>
        </div>
      );
    case "correction_card_returned":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("returnedCorrectionCard")}`}{" "}
          <span className="highlighted">{data.data.title}</span>{" "}
          {`${_("from")}`} <span className="highlighted">{data.data.from}</span>{" "}
          {_("to")} <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "correction_card_removed":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("hasRemovedYou")} ${_("from")}`} {_("correctionPlan")}{" "}
          <span className="highlighted">{data.data.title}</span>
        </div>
      );
    default:
      return;
  }
};

export const renderNotificationCollaboratorMessage = (action, data) => {
  switch (action) {
    case "correction_card_request_validat":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("requestCorrectionCardValidation")}`}{" "}
          <span className="highlighted">{data.data.title}</span>
        </div>
      );
    case "correction_card_validated":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("validatedCorrectionCard")}`}{" "}
          <span className="highlighted">{data.data.title}</span>
        </div>
      );
    case "correction_card_validated_partner":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("validatedCorrectionCard")}`}{" "}
          <span className="highlighted">{data.data.title}</span>
          {`${_("of")}`} <span>{data.data.responsable}</span>
        </div>
      );
    case "absence_sick":
      return (
        <div>
          <span className="highlighted">{data.data.beneficiary}</span>{" "}
          {`${_("indicatedAnSickAbscence")} ${_("from")}`}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "absence_backup":
      return (
        <div>
          <span>{data.data.beneficiary}</span>{" "}
          {`${_("setYouAsBackup")} ${_("from")}`} <span>{data.data.from}</span>{" "}
          {_("to")} <span>{data.data.to}</span>
        </div>
      );
    case "correction_card_returned":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("returnedCorrectionCard")}`} <span>{data.data.title}</span>{" "}
          {`${_("from")}`} <span className="highlighted">{data.data.from}</span>{" "}
          {_("to")} <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "correction_card_returned_partner":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("returnedCorrectionCard")}`} <span>{data.data.title}</span>{" "}
          {`${_("of")}`} <span>{data.data.responsable}</span> {`${_("from")}`}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "correction_card_removed":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("hasRemovedYou")} ${_("from")}`} {_("correctionPlan")}{" "}
          <span className="highlighted">{data.data.title}</span>
        </div>
      );
    case "correction_card_removed_partner":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("hasRemoved")} ${_("correctionPlan")}`}
          <span className="highlighted">{data.data.title}</span>
          {_("of")} <span className="highlighted">{data.data.responsable}</span>
        </div>
      );
    case "correction_card_affected":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("hasAffectedYou")} ${_("to")}`} {_("correctionPlan")}{" "}
          <span className="highlighted">{data.data.title}</span>
        </div>
      );
    case "correction_card_affected_partner":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("hasAffected")} ${_("a")} ${_("correctionPlan")} ${_("to")}`}{" "}
          <span className="highlighted">{data.data.responsable}</span>{" "}
          {_("for")} <span className="highlighted">{data.data.title}</span>
        </div>
      );
    case "absence_affected":
      return (
        <div>
          <span className="highlighted">{data.user}</span> {_("affectedYou")}{" "}
          {_("anAbscence")} {_("from")}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "certificat_required":
      return (
        <div>
          {_("certificatRequieredMessage")} {_("from")}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "absence_request":
      return (
        <div>
          <span>{_("you")}</span>{" "}
          {`${_("youRequestedAnAbscence")} ${_("from")}`}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "absence_approved":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {_("approvedAbscenceOf")} {_("yourAbscenceRequest")} {_("from")}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "absence_rejected":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {_("rejectededAbscenceOf")} {_("yourAbscenceRequest")} {_("from")}{" "}
          <span className="highlighted">{data.data.from}</span> {_("to")}{" "}
          <span className="highlighted">{data.data.to}</span>
        </div>
      );
    case "error_report_affected_partner":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("hasAffected")} ${_("a")} ${_("rapportError")} ${_("to")}`}{" "}
          <span className="highlighted">{data.data.responsable}</span>{" "}
          {_("for")} <span className="highlighted">{data.data.title}</span>
        </div>
      );
    case "error_report_affected":
      return (
        <div>
          <span className="highlighted">{data.user}</span>{" "}
          {`${_("hasAffectedYou")} ${_("to")}`} {_("rapportError")} {_("for")}{" "}
          <span className="highlighted">{data.data.title}</span>
        </div>
      );
    default:
      return;
  }
};

export const renderTeamYears = () => {
  let years = [];
  let year = 2020;

  while (year <= moment().year()) {
    years.push(year);
    year++;
  }
  return years;
};

export const getTemporaryScreenShotFileLink = (id, filePath, token) => {
  let url = `${TTP_API_URL}/data/annexe/file?access_token=${token}&id=${id}&filePath=${encodeURIComponent(
    filePath
  )}`;

  return url;
};

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const getTemporaryAnnexeFileLink = (id, filePath, token) => {
  let url = `${TTP_API_URL}/data/annexe/file?access_token=${token}&id=${id}&filePath=${encodeURIComponent(
    filePath
  )}`;

  return url;
};

export const NumberDisplay = (number) => {
  const formattedNumber = parseFloat(number)
    .toLocaleString("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(/\s/g, ".");
  return <div>{formattedNumber} €</div>;
};

export const getNotificationManagerCollaboratorName = (notification) => {
  switch (notification.action) {
    case "absence_sick":
      return notification.data.beneficiary;
    case "absence_request":
      return notification.data.applicant;
    default:
      return notification?.user ? notification.user : notification?.data?.user;
  }
};

export const calculatePeriodDates = (period) => {
  const now = moment();
  let start, end;

  switch (period) {
    case "_week":
      start = now.clone().startOf("week");
      start = start.format("MM-DD");

      end = now.clone().endOf("week");
      end = end.format("MM-DD");
      break;

    case "_month":
      start = now.clone().startOf("month").format("MM-DD");
      end = now.clone().endOf("month").format("MM-DD");
      break;

    case "_quarter":
      start = now.clone().startOf("quarter").format("MM-DD");
      end = now.clone().endOf("quarter").format("MM-DD");
      break;

    default:
      throw new Error("Invalid period");
  }

  return { start, end };
};

export const formatNumber = (num) => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "K";
  } else {
    return num > 0 ? num.toFixed(0) : 0;
  }
};

export const convertDecimalToHoursMinutes = (decimalHours) => {
  const hours = Math.floor(decimalHours); // Partie entière = heures
  const minutes = Math.round((decimalHours - hours) * 60); // Convertir la partie décimale en minutes
  return `${hours}h${minutes}m`;
};

export const parsePreference = (preference) => {
  return preference ? JSON.parse(preference) : {};
};

/**
 * allowViewDataBy options:
 * - No access: 0
 * - All: 1
 * - Personal: 10
 * - Partnership: 11
 * - BU: 100
 * - Zone: 101
 * - Backup: 110
 */
export const viewDataBy = (
  preferences,
  list = [],
  collaborator,
  subPreference = null
) => {
  let data;
  const parsed = subPreference
    ? parsePreference(preferences)[subPreference]
    : parsePreference(preferences);
  const viewBy = parsed.allowViewDataBy || 1;

  if (viewBy === 10) {
    data = list
      .filter((item) => item.id == collaborator.id)
      .map((item) =>
        item.id == collaborator.id
          ? { ...item, isActif: true }
          : { ...item, isActif: false }
      );
  } else if (viewBy === 11) {
    data = list.filter(
      (item) => item?.partner?.value == collaborator?.partner?.value
    );
  } else if (viewBy === 100) {
    data = list
      .filter((item) => item.bu == collaborator?.bu)
      .map((item) =>
        item.id == collaborator.id
          ? { ...item, isActif: true }
          : { ...item, isActif: false }
      );
  } else if (viewBy === 101) {
    data = list
      .filter((item) => item.zone == collaborator?.zoneId)
      .map((item) =>
        item.id == collaborator.id
          ? { ...item, isActif: true }
          : { ...item, isActif: false }
      );
  } else if (viewBy === 110) {
    data = list
      .filter(
        (item) =>
          collaborator?.backupIds.includes(item.id) || item.id == collaborator.id
      )
      .map((item) =>
        item.id == collaborator.id
          ? { ...item, isActif: true }
          : { ...item, isActif: false }
      );
  } else {
    data = list.map((item) =>
      item.id == collaborator.id
        ? { ...item, isActif: true }
        : { ...item, isActif: false }
    );
  }

  return data;
};
