export function TableIcon({ size = 14, fill = 'none', stroke = '#000', ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 1.75V12.25M1.75 5.25H12.25M1.75 8.75H12.25M2.91667 1.75H11.0833C11.7277 1.75 12.25 2.27233 12.25 2.91667V11.0833C12.25 11.7277 11.7277 12.25 11.0833 12.25H2.91667C2.27233 12.25 1.75 11.7277 1.75 11.0833V2.91667C1.75 2.27233 2.27233 1.75 2.91667 1.75Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
