/***
 * This file contains functions to interact with Microsoft Graph API,
 * specifically interacting with Microsoft Graph Calendar API,
 * which allows to manage Oulook calendars, events, and meetings programmatically.
 */

import axios from "axios";
import { MICROSOFT_GRAPH_API_CLIENT_ID, MICROSOFT_GRAPH_API_REDIRECT_URI, MICROSOFT_GRAPH_API_TENANT_ID } from "../config";

export const CLIENT_ID = MICROSOFT_GRAPH_API_CLIENT_ID; // client ID
export const TENANT_ID = MICROSOFT_GRAPH_API_TENANT_ID; // directory ID
export const REDIRECT_URI = MICROSOFT_GRAPH_API_REDIRECT_URI;


/**
 * This function exchanges an authorization code obtained from the OAuth 2.0 authorization flow for access and refresh
 * tokens. It sends a POST request to the Microsoft identity platform token endpoint.
 *
 * @param string authorizationCode The authorization code returned after the user successfully logs in and grants permissions to the application. This code is short-lived and is used to obtain tokens.
 * @param {string} codeVerifier A code verifier used in the PKCE (Proof Key for Code Exchange) flow. This is a random string that is hashed and sent as the code_challenge during the initial authorization request and verified in this token exchange request.
 * @returns Returns the token response from the Microsoft OAuth token endpoint, which contains:
 *  - access_token: A token to access APIs like Microsoft Graph.
 * - refresh_token: A token to obtain a new access token without user interaction.
 * - id_token: A token that contains information about the user.
 */
export const exchangeCodeForTokens = async (authorizationCode, codeVerifier) => {
  const tokenEndpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/token`;

  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');  // Important: grant_type must be included
  params.append('code', authorizationCode);                        // The authorization code
  params.append('redirect_uri', REDIRECT_URI);         // Redirect URI from the auth request
  params.append('client_id', CLIENT_ID);               // Your client ID
  params.append('scope', 'openid profile OnlineMeetings.ReadWrite Calendars.ReadWrite')
  // params.append('client_secret', 'uKe8Q~gU~Vvea_gSFGY30OABCus6P.-eQRd4way2');
  params.append('code_verifier', codeVerifier);

  try {
    const response = await axios.post(tokenEndpoint, params.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    });

    if (response.status !== 200) {
      const errorText = await response.data;
      throw new Error(`Token request failed: ${response.statusText} - ${errorText}`);
    }

    // await createTokenLifetimePolicy(response.data.access_token);

    return response.data;
  } catch (error) {
    console.error('Error exchanging code for tokens:', error);
    throw error;
  }
}

export const refreshAccessToken = async (refreshToken) => {
  const tokenEndpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/token`;

  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);      // The refresh token you received earlier
  params.append('client_id', CLIENT_ID);             // Your client ID
  params.append('scope', 'openid profile OnlineMeetings.ReadWrite Calendars.ReadWrite');
  // params.append('client_secret', CLIENT_SECRET);  // If using confidential client (backend)

  try {
    const response = await axios.post(tokenEndpoint, params.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (response.status === 200) {
      // await createTokenLifetimePolicy(response.data.access_token);

      return response.data;
    } else {
      console.error(`Failed to refresh access token: ${response.statusText}`);
    }

  } catch (error) {
    console.error('Error refreshing access token:', error);
  }
}

export const createTokenLifetimePolicy = async (accessToken) => {

  const policyData = {
    displayName: "Custom Token Lifetime Policy",
    isOrganizationDefault: false,
    description: "This policy sets access token lifetime to 2 hours and refresh token lifetime to 90 days",
    definition: [
      "{\"AccessTokenLifetime\":\"PT2H\",\"RefreshTokenLifetime\":\"P90D\",\"MaxInactiveTime\":\"P90D\"}"
    ]
  };

  try {

  //   const listResponse = await axios.get('https://graph.microsoft.com/v1.0/policies/tokenLifetimePolicies', {
  //     headers: {
  //       'Authorization': `Bearer ${accessToken}`
  //     }
  //  });
  //  console.log({listResponse})

    const response = await axios.post('https://graph.microsoft.com/v1.0/policies/tokenLifetimePolicies', policyData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    return response.data;

  } catch (error) {
    console.error('Error creating token lifetime policy:', error.response ? error.response.data : error.message);
  }
}

// async function assignTokenLifetimePolicy(applicationId, policyId) {
//   const assignEndpoint = `https://graph.microsoft.com/v1.0/applications/${applicationId}/tokenLifetimePolicies/$ref`;

//   const requestData = {
//     "@odata.id": `https://graph.microsoft.com/v1.0/policies/tokenLifetimePolicies/${policyId}`
//   };

//   try {
//     const response = await axios.post(assignEndpoint, requestData, {
//       headers: {
//         'Authorization': `Bearer ${adminAccessToken}`,
//         'Content-Type': 'application/json'
//       }
//     });

//     console.log("Policy assigned to app:", response.data);
//   } catch (error) {
//     console.error('Error assigning token lifetime policy:', error);
//   }
// }

export async function createSecondaryCalendar(accessToken, calendarName) {
  const response = await axios.post(
    "https://graph.microsoft.com/v1.0/me/calendars",
    JSON.stringify({ name: calendarName }),
    {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    }
  );

  if (response.status !== 201) {
    throw new Error("Error creating calendar: " + response.statusText);
  }
  return response.data;
}

export async function getCalendars(accessToken) {
  const response = await axios.get("https://graph.microsoft.com/v1.0/me/calendars", {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
    }
  });

  const calendars = response.data;
  return calendars;
}

export const createTeamsMeetingEvent = async (accessToken, eventDetails) => {
  const response = await axios.post(`https://graph.microsoft.com/v1.0/me/events`,
    JSON.stringify(eventDetails),
    {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    }
  );

  if (response.status !== 201) {
    console.error("Error creating event: " + response.statusText);
  }

  const event = response.data;
  return event;
}

export const updateTeamsMeetingEvent = async (accessToken, eventId, eventDetails) => {
  const requestUrl = `https://graph.microsoft.com/v1.0/me/events/${eventId}`;

  try {
    const response = await axios.patch(requestUrl,
      JSON.stringify(eventDetails),
      {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      }
    );
    if (response.status !== 200) {
      console.error("Error updating event: " + response.statusText);
    }

    const event = response.data;
    return event;

  } catch (error) {
    console.error("Error:", error);
  }
}

export const removeTeamsMeetingEvent = async (accessToken, eventId) => {
  const requestUrl = `https://graph.microsoft.com/v1.0/me/events/${eventId}`;
  try {
    const response = await axios.delete(requestUrl, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      }
    );

    if (response.status !== 204) { // No Content status code
      console.error("Error removing event: " + response.statusText);
    }

    return response;
  } catch (error) {
    console.error("Error:", error);
  }
}

export async function sendEmailNotification(accessToken, recipientEmail, subject, body) {
  const url = "https://graph.microsoft.com/v1.0/users/me/sendMail";

  const emailData = {
    "message": {
      "subject": subject,
      "body": {
        "contentType": "Text",
        "content": body
      },
      "toRecipients": [
        {
          "emailAddress": {
            "address": recipientEmail
          }
        }
      ]
    }
  };

  const options = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(emailData)
  };

  try {
    const response = await fetch(url, options);
    if (response.ok) {
      console.log("Email notification sent successfully.");
    } else {
      console.error("Error sending email:", await response.json());
    }
  } catch (error) {
    console.error("Error:", error);
  }
}

export const getEventCategories = async (accessToken) => {
  const response = await axios.get("https://graph.microsoft.com/v1.0/me/outlook/masterCategories", {
    headers: {
      "Authorization": `Bearer ${accessToken}`,
    }
  });

  if (response.status !== 200) {
    console.error("Error getting categories: " + response.statusText);
    return null;
  }
  return response.data;
}

export const createEventCategory = async ({accessToken, category}) => {
  const response = await axios.post("https://graph.microsoft.com/v1.0/me/outlook/masterCategories",
    JSON.stringify(category),
    {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    }
  );

  if (response.status !== 201) {
    console.error("Error creating categories: " + response.statusText);
  }
  return response.data;
}
