import { useState } from "react";
import { Modal, Popconfirm, Tag, Tooltip } from "antd";
import { FiInfo, FiPaperclip, FiUser } from "react-icons/fi";
import { useSelector } from "react-redux";
import classNames from "classnames";
import TextArea from "antd/lib/input/TextArea";
import { IconPencil, IconTrash } from "../../../../common/Icons";
import ValidationIcons from "../../../../common/Icons/ValidationIcons";
import AnimatedLoadingIcon from "../../../../common/Icons/AnimatedLoadingIcon";
import { isAdminAccess, isManagerAccess, isSuperAdminAccess } from "../../../../../utils";
import _ from "../../../../../i18n";
import styles from './HolidayListItem.module.scss';

const HolidayListItem = ({
  holiday,
  onEditAbsence,
  onDeleteAbsence,
  onApproveAbsence,
  onRejectAbsence,
  isApprovingAbsence,
  isRejectingAbsence,
  handleShowAttachments,
  activeHolidayFiler,
  collaborator
}) => {
  const { loggedAs, spaceAs, user } = useSelector(state => state.auth);
  const [displayRejectModal, setDisplayRejectModal] = useState(false);
  const [rejectReasonText, setRejectReasonText] = useState(null);

  const isAbsenceItem = ['absence', 'other', 'partial'].includes(holiday.type);
  const isCurrentUser = collaborator.uid === user.uid;
  const hasManagerAccess = isSuperAdminAccess(loggedAs, spaceAs) ||
                          isAdminAccess(loggedAs, spaceAs) ||
                          isManagerAccess(loggedAs, spaceAs);

  const getStatusColor = (status) => ({
    'APPROVED': 'success',
    'PENDING': 'warning',
    'REJECTED': 'error'
  }[status]);

  const renderRejectModal = () => (
    <Modal
      title="Voulez-vous vraiment rejeter ce congé ?"
      open={displayRejectModal}
      closeIcon={null}
      closable={false}
      bodyStyle={{ margin: '12px 16px' }}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.03)' }}
      centered
      onOk={() => onRejectAbsence(holiday, rejectReasonText)}
      onCancel={() => setDisplayRejectModal(false)}
      okText={
        <div className={styles.reject_modal_ok_button}>
          {isRejectingAbsence && <AnimatedLoadingIcon size={14} color="#ffffff" />}
          <span>Rejeter</span>
        </div>
      }
      cancelText="Annuler"
      okButtonProps={{
        style: { backgroundColor: '#EB5757', borderColor: '#EB5757' }
      }}
    >
      <TextArea
        placeholder="Raison de rejet..."
        value={rejectReasonText}
        onChange={(e) => setRejectReasonText(e.target.value)}
        className={styles.reject_reason_textarea}
      />
    </Modal>
  );

  const renderAttachments = () => {
    if (holiday.type !== 'other') return null;

    const absencesNeedJustification = [
      'Congé Maladie',
      'Congé éducation, vacances jeunes…',
      'Congé pour raison impérieuse',
      'Congé de naissance (papa)',
    ];

    if (!absencesNeedJustification.includes(holiday.label)) return null;

    if (holiday.attachments?.length > 0) {
      return (
        <Tag
          className={styles.holidays_list_item_attachments_tag}
          onClick={() => handleShowAttachments(holiday.attachments)}
        >
          <FiPaperclip />
        </Tag>
      );
    }

    return (
      <Tooltip title="Besoin de fournir les pièces justifiantes.">
        <Tag color='red' className={styles.holidays_list_item_attachments_tag}>
          <FiInfo />
        </Tag>
      </Tooltip>
    );
  };

  const renderBackup = () => (
    holiday.backup && (
      <Tooltip title={`Binôme : ${holiday.backup}`}>
        <Tag className={styles.holidays_list_item_binome_tag}>
          <FiUser />
        </Tag>
      </Tooltip>
    )
  );

  const renderEditActions = () => {
    // const showEditActions = (
    //   (activeHolidayFiler !== 'PENDING' && isAbsenceItem && holiday.vacationStatus === 'PENDING') ||
    //   isCurrentUser
    // );
    const showEditActions = isAbsenceItem;

    if (!showEditActions) return null;

    return (
      <div className={styles.holidays_list_item_actions}>
        <span
          className={styles.holidays_list_item_action}
          onClick={() => onEditAbsence(holiday)}
        >
          <IconPencil size={12} />
        </span>
        <span className={styles.holidays_list_item_action_divider} />
        <span
          className={styles.holidays_list_item_action}
          onClick={() => onDeleteAbsence(holiday)}
        >
          <IconTrash size={12} />
        </span>
      </div>
    );
  };

  const renderApproveActions = () => {
    const showApproveActions = (
      isAbsenceItem &&
      activeHolidayFiler === 'PENDING' &&
      !isCurrentUser &&
      hasManagerAccess
    );

    if (!showApproveActions) return null;

    return (
      <div className={styles.holidays_list_item_approve_actions}>
        <span
          className={styles.holidays_list_item_approve_action}
          onClick={() => setDisplayRejectModal(true)}
        >
          <ValidationIcons.UnCheckIcon
            size={12}
            color="#808996"
            className={styles.holidays_list_item_approve_action_icon}
          />
        </span>

        <Popconfirm
          title="Voulez-vous vraiment approuver ce congé ?"
          placement="topRight"
          icon={null}
          onConfirm={() => onApproveAbsence(holiday)}
          okText={
            <div className={styles.approve_popup_ok_button}>
              <span>Approuver</span>
              {isApprovingAbsence && <AnimatedLoadingIcon size={16} color="#ffffff" />}
            </div>
          }
          cancelText="Annuler"
          okButtonProps={{
            style: { backgroundColor: '#1adcb7', borderColor: '#1adcb7' }
          }}
        >
          <span className={styles.holidays_list_item_approve_action}>
            <ValidationIcons.CheckIcon
              size={12}
              color="#1adcb7"
              className={styles.holidays_list_item_approve_action_icon}
            />
          </span>
        </Popconfirm>
      </div>
    );
  };

  return (
    <div className={classNames(styles.holidays_list_item, styles[`holidays_list_item_${holiday.type}`])}>
      <span className={styles.holidays_list_item_type_circle} />

      <div className={styles.holidays_list_item_label}>
        {holiday.label}

        <div className={styles.holidays_list_item_label_day_count}>
          {holiday.days?.join(' - ')}
        </div>

        {holiday.type === 'partial' && (
          <span>[{holiday.numberHours}h]</span>
        )}

        {holiday.vacationStatus != null && (
          <Tag
            color={getStatusColor(holiday.vacationStatus)}
            className={styles.holidays_list_item_status_tag}
          >
            <div>{_(holiday.vacationStatus)}</div>
          </Tag>
        )}
      </div>

      {renderAttachments()}
      {renderBackup()}
      {renderEditActions()}
      {renderApproveActions()}
      {renderRejectModal()}
    </div>
  );
};

export default HolidayListItem;
