import React, { useState, Fragment } from "react";
import { useQuery } from "react-query";

import { getCapacityPlaning } from "../../../api";
import _ from "../../../i18n";

import { Table } from "antd";
import CapacityPlaningMatrixFilter from "./CapacityPlaningMatrixFilter";
import "./CapacityPlaningMatrix.local.scss";
import { parsePreference } from "../../../utils";
import { useSelector } from "react-redux";

const columns = [
  {
    title: "TYPE",
    dataIndex: "TYPE",
    key: "TYPE",
  },
  {
    title: "JAN",
    dataIndex: "JAN",
    key: "JAN",
  },
  {
    title: "FEV",
    dataIndex: "FEV",
    key: "FEV",
  },
  {
    title: "MAR",
    dataIndex: "MAR",
    key: "MAR",
  },
  {
    title: "AVR",
    dataIndex: "AVR",
    key: "AVR",
  },
  {
    title: "MAI",
    dataIndex: "MAI",
    key: "MAI",
  },
  {
    title: "JUN",
    dataIndex: "JUN",
    key: "JUN",
  },
  {
    title: "JUL",
    dataIndex: "JUL",
    key: "JUL",
  },
  {
    title: "AOU",
    dataIndex: "AOU",
    key: "AOU",
  },
  {
    title: "SEP",
    dataIndex: "SEP",
    key: "SEP",
  },
  {
    title: "OCT",
    dataIndex: "OCT",
    key: "OCT",
  },
  {
    title: "NOV",
    dataIndex: "NOV",
    key: "NOV",
  },
  {
    title: "DEC",
    dataIndex: "DEC",
    key: "DEC",
  },
  {
    title: "TOTAL",
    dataIndex: "TOTAL",
    key: "TOTAL",
  },
  {
    title: "SOLDE",
    dataIndex: "SOLDE",
    key: "SOLDE",
  },
];

const TimeSheetProgression = ({
  token,
  navCommunity,
  language,
  collaborator,
  preferences,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [progression, setProgression] = useState([]);
  const [nbResult, setNbResult] = useState([]);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    bu:
      parsePreference(preferences.capacityPlanning).allowViewDataBy === 100 &&
      collaborator
        ? [collaborator.bu]
        : [],
    zone:
      parsePreference(preferences.capacityPlanning).allowViewDataBy === 101 &&
      collaborator
        ? collaborator.zoneId
        : null,
    collaborators:
      [10, 110].includes(
        parsePreference(preferences.capacityPlanning).allowViewDataBy
      ) && collaborator
        ? [collaborator.id]
        : [],
    partner:
      parsePreference(preferences.capacityPlanning).allowViewDataBy === 11
        ? user?.id
        : null,
    clients: [],
    squads: [],
    paginationPage: 1,
    pageSize: 10,
  });

  let timesheetQuery = useQuery(
    [("getTimeSheetProgression", token, navCommunity, filter)],
    async () => {
      const allowViewDataBy = parsePreference(
        preferences.capacityPlanning
      ).allowViewDataBy;
      if (
        [10, 110].includes(allowViewDataBy) &&
        (!filter || !filter.collaborators.length > 0)
      ) {
        setProgression([]);
        setNbResult(0);
        return [];
      } else if (token && navCommunity) {
        try {
          const response = await getCapacityPlaning(
            navCommunity.id,
            token,
            filter
          );
          setProgression(response.data.data[filter.year]);
          setNbResult(response.data.data.nbrResult);
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    }
  );

  const formatDurationToHoursAndMinutes = (duration, type) => {
    if (type.toLowerCase().includes("balance")) {
      const roundedHours = Math.round(duration);
      return `${roundedHours}h`;
    } else {
      const hours = Math.floor(duration);
      const minutes = Math.round((duration - hours) * 60);
      return `${hours}h ${minutes}`;
    }
  };

  const renderCapacityPlaningTable = () => {
    let columnsTest = [];

    columnsTest = [
      {
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div style={{ height: 43, textAlign: "right" }}>
                <span
                  class="ant-table-column-title"
                  style={{ width: "94%", margin: "3px 5px 0 22px" }}
                >
                  {_("type")}
                </span>
                <span class="ant-table-column-sorter"></span>
              </div>
            </span>
          );
        },
        dataIndex: "type",
        key: "type",
        render: (text, record) => (
          <span
            className={
              "balance-sheet-key-indicator-treasury-bold balance-sheet-key-indicator-title"
            }
          >
            <span
              className={
                text && text.length >= 46 ? "balance-sheet-label-wrap" : ""
              }
            >
              <div
                style={{
                  margin: "0px 20px",
                  marginLeft:
                    record["TYPE"] === "Jours ouvrables" ||
                    record["TYPE"] === "Jours fériés ouvrables" ||
                    record["TYPE"] === "Congés (Differents type)"
                      ? "48px"
                      : "20px",
                }}
              >
                {record.TYPE}
              </div>
            </span>
          </span>
        ),
        width: 350,
      },
    ];
    for (let i = 1; i < columns.length; i++) {
      columnsTest.push({
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div style={{ height: 43 }}>
                <span class="ant-table-column-title">
                  {_(columns[i].title)}
                </span>
                <span class="ant-table-column-sorter"></span>
              </div>
            </span>
          );
        },
        dataIndex: columns[i].dataIndex,
        key: columns[i].key,
        render: (text, record) => {
          return (
            <span
              className={`balance-sheet-td-component small-components
              ${
                columns[i].key === "TOTAL"
                  ? "end-components"
                  : columns[i].key === "SOLDE"
                  ? "last-components"
                  : 0
              }`}
            >
              <span
                className={
                  text && text.length >= 46 ? "balance-sheet-label-wrap" : ""
                }
              >
                <div>
                  {record["TYPE"] === "Jours ouvrables" ||
                  record["TYPE"] === "Jours fériés ouvrables" ||
                  record["TYPE"] === "Congés (Differents type)"
                    ? record[columns[i].dataIndex]
                    : formatDurationToHoursAndMinutes(
                        record[columns[i].dataIndex],
                        record["TYPE"]
                      )}
                </div>
              </span>
            </span>
          );
        },
        width: 60,
      });
    }

    return (
      <div style={{ paddingTop: "0rem" }}>
        <div className={"rmodal__center"}>
          <Table
            columns={columnsTest}
            dataSource={progression}
            pagination={false}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="portfolio_container">
      <div className="container">
        <CapacityPlaningMatrixFilter
          filterFormIsVisible={false}
          filter={filter}
          nbResult={nbResult}
          updateFilter={setFilter}
          fetching={timesheetQuery.isFetching}
          collaborator={collaborator}
          preferences={preferences}
        />

        <div className="medium-11">
          {timesheetQuery.isFetching ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : Object.keys(progression).length ? (
            <Fragment>{renderCapacityPlaningTable()}</Fragment>
          ) : (
            <div className="no_data">
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noClientsFound")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeSheetProgression;
