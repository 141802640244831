import axios from "axios";
import { TTP_API_URL } from "../../config";

export const getLastCollaboratorTimesheet = (
  token,
  organization,
  collaborator,
  year
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/timesheet`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization,
      collaborator,
      year,
    },
  });
};

export const getCollaboratorStatistics = (
  token,
  organization,
  collaborator,
  role,
  year
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/statistics`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization,
      collaborator,
      role,
      year,
    },
  });
};

export const getCollaboratorCPProgression = (token, organization, collaborator, year, zone) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/production-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      collaborator: collaborator,
      year: year,
      zone: zone,
    },
  });
};

export const getCollaboratorScheduleProgression = (
  token,
  organization,
  collaborator,
  year,
  type,
  role
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/schedule-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      collaborator: collaborator,
      year: year,
      type: type,
      role: role
    },
  });
};

export const getCollaboratorProfitProgression = (
  token,
  organization,
  collaborator,
  year,
  type,
  role
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/profit-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      collaborator: collaborator,
      year: year,
      type: type,
      role: role
    },
  });
};

export const getCollaboratorClientsIN = (token, organization, collaborator, year, role) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/client-in`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborator: collaborator,
      year: year,
      role: role,
    },
  });
};

export const getCollaboratorClientsOut = (token, organization, collaborator, year, role) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/client-out`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborator: collaborator,
      year: year,
      role: role,
    },
  });
};

export const getCollaboratorDevelopmentPlans = (token, organization, collaborator, year, role) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/dev-plan`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborator: collaborator,
      year: year,
      role: role,
    },
  });
};

export const getCollaboratorBonusCount = (token, organization, collaborator, year, role) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/bonus`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborator: collaborator,
      year: year,
      role: role,
    },
  });
};

export const getCollaboratorClientsReview = (token, organization, collaborator, year, role) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/client-review`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborator: collaborator,
      year: year,
      role: role,
    },
  });
};

export const getCollaboratorClientOrderSignature = (token, organization, collaborator, year, role) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/collaborator/order-signature`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborator: collaborator,
      year: year,
      role: role,
    },
  });
};
