import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";
import { MICROSOFT_GRAPH_API_CLIENT_ID, MICROSOFT_GRAPH_API_REDIRECT_URI, MICROSOFT_GRAPH_API_TENANT_ID } from ".";

export const msalConfig = {
  auth: {
    clientId: MICROSOFT_GRAPH_API_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: MICROSOFT_GRAPH_API_REDIRECT_URI,
    // grantType: "client_credentials",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level, message, containsPii) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //         default:
  //           return;
  //       }
  //     }
  //   }
  // }
};
/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [
    "User.Read",
    // "User.ReadWrite.All",
    "openid",
    "profile",
    "OnlineMeetings.ReadWrite",
    "Calendars.Read",
    "Calendars.ReadWrite",
    "Calendars.ReadWrite.Shared",
  ]
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  meEndpoint: "https://graph.microsoft.com/v1.0/me",
  getUserDetailsEndpoint: "https://graph.microsoft.com/v1.0/users/@@USER_ID@@",
  getMyCalendarsEndpoint: "https://graph.microsoft.com/v1.0/me/calendars",
  createCalendarEndpoint: "https://graph.microsoft.com/v1.0/me/calendars",
  createEventEndpoint: "https://graph.microsoft.com/v1.0/me/events",
  updateEventEndpoint: "https://graph.microsoft.com/v1.0/me/events/@@EVENT_ID@@",
  acceptEventEndpoint: "https://graph.microsoft.com/v1.0/me/events/@@EVENT_ID@@/accept",
  deleteEventEndpoint: "https://graph.microsoft.com/v1.0/me/events/@@EVENT_ID@@",
  getEventCategoriesEndpoint: "https://graph.microsoft.com/v1.0/me/outlook/masterCategories",
  createEventCategoryEndpoint: "https://graph.microsoft.com/v1.0/me/outlook/masterCategories",
  createEventForUserEndpoint: "https://graph.microsoft.com/v1.0/users/@@USER_ID@@/calendars/@@CALENDAR_ID@@/events",
  createCalendarForUserEndpoint: "https://graph.microsoft.com/v1.0/users/@@USER_ID@@/calendars",
  getCalendarGroupsForUserEndpoint: "https://graph.microsoft.com/v1.0/users/@@USER_ID@@/calendarGroups",
  createCalendarGroupsForUserEndpoint: "https://graph.microsoft.com/v1.0/users/@@USER_ID@@/calendarGroups",
  getUserCalendarsEndpoint: "https://graph.microsoft.com/v1.0/users/@@USER_ID@@/calendars",
};
