import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getCollaborator } from "../../api";
import CollaboratorProductionTracking from "./ProductionTracking/CollaboratorProductionTracking";
import CollaboratorPlansBoard from "./PlansBoard/CollaboratorPlansBoard";
import Planner from "./Planner";
import "./CollaboratorSpace.local.scss";
// import CalendarCollaborator from "./CalendarIframe";
import CapacityDossier from "./CapacityDossierIframe";
import CollaboratorDashboardIframe from "./CollaboratorDashboardIframe";
import FinancialCollaborator from "./FinancialCollaborator/FinancialCollaborator";
import CollaboratorSpaceHeader from "./CollaboratorSpaceHeader";
import TTPTab2 from "../common/Tab/TTPTab2";
import BonusPage from "./BonusPage";
import CalendarCollaborator from "./CalendarCollaborator";
import CalendarCollaboratorIframe from "./CalendarIframe";
import { parsePreference } from "../../utils";

const CollaboratorSpace = ({ userSpace = null, collaboratorSpace = null }) => {
  const { token, navCommunity, user, loggedAs, collaborator: authCollaborator } = useSelector(
    (state) => state.auth
  );
  const preferences =
    user &&
    navCommunity &&
    user.userOrganizations &&
    user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences?.collaborators
      : {};
  const [searchParams] = useSearchParams();
  const { language } = useSelector((state) => state.params);
  const activeTab = searchParams.get("tab");
  let { id } = useParams();
  let tabs = [
    {
      id: "PRODUCTON_TRACKING",
      label: "productionTracking",
      hasAccess: parsePreference(preferences).allowAccessCPr,
    },
    {
      id: "BONUS",
      label: "Bonus",
      hasAccess: user.id == 471421 || user.id == 473472
      // hasAccess: true
    },
    // {
    //   id: "CALENDAR_OLD",
    //   label: "calendar [OLD]",
    // },
    {
      id: "PLANS_BOARD",
      label: "plansBoard",
      hasAccess: parsePreference(preferences).allowAccessPlans,
    },
    {
      id: "CALENDAR",
      label: "calendar",
      hasAccess: parsePreference(preferences).allowAccessCalendar,
    },
    {
      id: "PLANNER",
      label: "planning",
      hasAccess: parsePreference(preferences).allowAccessPlanification,
    },
    {
      id: "FOLDER_CAPACITY",
      label: "folderCapacity",
      hasAccess: parsePreference(preferences).allowAccessFolderCapacity,
    },
    {
      id: "FINANCIAL",
      label: "etat financier personnel",
      hasAccess: parsePreference(preferences).allowAccessFinancialState,
    },
  ];

  //Header
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    tab:
      tabs.filter((tab) => tab.hasAccess).filter((tab) => tab.id === activeTab)
        .length > 0
        ? tabs.filter((tab) => tab.id === activeTab)[0].id
        : tabs[0].id,
  });

  const [collaborator, setCollaborator] = useState(null);

  useEffect(() => {
    if (collaboratorSpace && userSpace) {
      setCollaborator(userSpace);
    }
  }, [collaborator]);

  let collaboratorQuery = useQuery(
    ["collaboratorQuery", token, id],
    async () => {
      if ((token, user)) {
        try {
          const response = await getCollaborator(id, navCommunity.id, token);
          setCollaborator(response.data.data);
        } catch (e) {
          setCollaborator(null);
          return null;
        }
      }
    }
  );

  const renderTabContent = () => {
    switch (filter.tab) {
      case "DASHBOARD":
        return ["SUPERADMIN", "ADMIN", "MANAGER"].includes(loggedAs) ? (
          <CollaboratorDashboardIframe
            id={collaborator.id}
            name={collaborator.name}
            year={filter.year}
          />
        ) : (
          <div>DASHBOARD</div>
        );
      case "BONUS":
        return (
          <BonusPage
            userSpace={id}
            filter={filter}
            collaborator={authCollaborator}
            preferences={
              user &&
              navCommunity &&
              user.userOrganizations &&
              user.userOrganizations.length > 0
                ? user.userOrganizations.find(
                    (organization) => organization.id === navCommunity.id
                  ).authorisation.preferences?.reporting
                : {}
            }
            year={filter.year}
          />
        );
      case "PRODUCTON_TRACKING":
        return (
          <CollaboratorProductionTracking
            id={collaborator.id}
            name={collaborator.name}
            collaborator={collaborator}
            token={token}
            lng={language}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            year={filter.year}
            user={user}
          />
        );

      case "PLANS_BOARD":
        return (
          <CollaboratorPlansBoard
            id={collaborator.id}
            token={token}
            lng={language}
            parentFilter={{
              partner: null,
              partnerOptions: [],
            }}
            year={filter.year}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            user={user ? user.firstName + " " + user.lastName : ""}
            reportingPreferences={
              user &&
              navCommunity &&
              user.userOrganizations &&
              user.userOrganizations.length > 0
                ? user.userOrganizations.find(
                    (organization) => organization.id === navCommunity.id
                  ).authorisation.preferences?.reporting
                : {}
            }
          />
        );

      case "CALENDAR":
        return (
          <CalendarCollaborator
            collaborator={collaborator}
            year={filter.year}
          />
        );

      case "PLANNER":
        return (
          <Planner
            collaborator={collaborator}
            organizationId={navCommunity && navCommunity.id}
            navCommunity={navCommunity}
            lng={language}
            token={token}
            year={filter.year}
          />
        );

      case "FOLDER_CAPACITY":
        return (
          <CapacityDossier
            id={collaborator.id}
            name={collaborator.name}
            year={filter.year}
          />
        );
      case "FINANCIAL":
        return (
          <FinancialCollaborator
            token={token}
            year={filter.year}
            id={collaborator.id}
            name={collaborator.name}
            organization={navCommunity && navCommunity.id}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="page-content">
      <CollaboratorSpaceHeader
        collaboratorSpace={collaboratorSpace}
        onChangeYear={(item) => {
          setFilter({ ...filter, year: item });
        }}
        collaborator={collaborator}
        year={filter.year}
      />
      <div className="tab-container">
        <TTPTab2
          tabs={tabs.filter((tab) => tab.hasAccess)}
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id });
          }}
        />
      </div>

      <div className="content-body">{collaborator && renderTabContent()}</div>
    </div>
  );
};

export default CollaboratorSpace;
