import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { CustumedMatrix } from "tamtam-components";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import {
  getCollaboratorContribution,
  saveClientsIntermediateSituations,
} from "../../../../api";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { renderDurationDisplay } from "../../../../utils";
import _ from "../../../../i18n";

import {
  ArrowLeftIcon,
  FlagIcon,
  SettingsIcon,
  ShortcutIcon,
} from "../../../common/Icons";
import CollaboratorCorrectionCard from "./CollaboratorCorrectionCard";
import VolumetryConfigContent from "./VolumetryConfigContent";
import "./EncodingProgressionContent.local.scss";

const Progression6Content = ({
  id,
  year,
  lng,
  token,
  user,
  filter,
  loggedAs,
  collaborator,
  organization,
}) => {
  const [expandedClient, setExpandedClient] = useState(null);
  const [showSideBar, setShowSideBar] = useState(null);
  const [showConfigSideBar, setShowConfigSideBar] = useState(null);
  const [contribution, setContribution] = useState([]);
  const [filtredContribution, setFiltredContribution] = useState([]);
  const [intermediateSituations, setIntermediateSituations] = useState([]);
  const [intermediateSituationsToSave, setIntermediateSituationsToSave] =
    useState([]);
  const [updated, setUpdated] = useState(false);
  const [columns, setColumns] = useState([
    {
      id: "volumetry",
      label: _("volumetry"),
      isEditable: true,
      widthPercentage: 8,
    },
    {
      id: "budget",
      label: _("budget"),
      isEditable: true,
      widthPercentage: 14,
    },
    {
      id: "timesheet",
      label: _("timesheet"),
      isEditable: true,
      widthPercentage: 34,
    },
    {
      id: "echeance",
      label: _("echeanceAnnual"),
      isEditable: true,
      widthPercentage: 14,
    },
  ]);
  const [sortApplication, setSortApplication] = useState({
    annualBudget: null,
    periodicBudget: null,
    rapport: null,
    real: null,
  });

  let collaboratorContributionQuery = useQuery(
    ["collaboratorContributionQuery", token, year, filter],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorContribution(
            id,
            organization,
            year,
            token,
            // [4],
            [filter.category.id],
            filter.period && filter.period.id,
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.start
                : year + "-" + filter.subPeriod.start
              : filter.start
              ? moment(filter.start).format("YYYY-MM-DD")
              : year + "-01-01",

            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.end
                : year + "-" + filter.subPeriod.end
              : filter.end
              ? moment(filter.end).format("YYYY-MM-DD")
              : year + "-12-31",
            filter.keyWord
          );
          setContribution(response.data.data.clientsData);
          setFiltredContribution(response.data.data.clientsData);
          if (response.data.data.clientsData) {
            let intermediateSituationTable = [];
            response.data.data.clientsData.forEach((item) => {
              intermediateSituationTable.push({
                clientId: item.id,
                intermediateSituation: item.echeance.intermediateSituations,
              });
            });
            setIntermediateSituations(intermediateSituationTable);
          }
        } catch (e) {
          setContribution([]);
          return null;
        }
      }
    }
  );

  const handleIntermediateSituationChange = (clientId, value) => {
    const updatedIntermediateSituations = [...intermediateSituations];
    const clientIndex = updatedIntermediateSituations.findIndex(
      (c) => c.clientId === clientId
    );
    let intermediateList = [];

    if (clientIndex !== -1) {
      updatedIntermediateSituations[clientIndex].intermediateSituation = value;
      intermediateList.push(updatedIntermediateSituations[clientIndex]);
    }

    setUpdated(true);
    setIntermediateSituations(updatedIntermediateSituations);
    setIntermediateSituationsToSave(intermediateList);
  };

  const sortClientBy = (type) => {
    let sortedContribution = [...filtredContribution].filter(
      (a) => a.id !== "TOTAL"
    );
    let totalContribution = filtredContribution.filter(
      (a) => a.id === "TOTAL"
    )[0];
    switch (type) {
      case "annual_budget_asc":
        sortedContribution.sort((a, b) =>
          a.budget.budgetZoneCollaborator - a.budget.realZoneCollaborator >
          b.budget.budgetZoneCollaborator - b.budget.realZoneCollaborator
            ? -1
            : 1
        );
        sortedContribution.unshift(totalContribution);
        setFiltredContribution(sortedContribution);
        return;
      case "annual_budget_desc":
        sortedContribution.sort((a, b) =>
          b.budget.budgetZoneCollaborator - b.budget.realZoneCollaborator >
          a.budget.budgetZoneCollaborator - a.budget.realZoneCollaborator
            ? -1
            : 1
        );
        sortedContribution.unshift(totalContribution);
        setFiltredContribution(sortedContribution);
        return;
      case "periodic_budget_asc":
        sortedContribution.sort((a, b) =>
          a.budget.budgetTVA < b.budget.budgetTVA ? -1 : 1
        );
        sortedContribution.unshift(totalContribution);
        setFiltredContribution(sortedContribution);
        return;
      case "periodic_budget_desc":
        sortedContribution.sort((a, b) =>
          b.budget.budgetTVA < a.budget.budgetTVA ? -1 : 1
        );
        sortedContribution.unshift(totalContribution);
        setFiltredContribution(sortedContribution);
        return;
      case "rapport_asc":
        sortedContribution.sort((a, b) =>
          a.timesheet.rapport < b.timesheet.rapport ? -1 : 1
        );
        sortedContribution.unshift(totalContribution);
        setFiltredContribution(sortedContribution);
        return;
      case "rapport_desc":
        sortedContribution.sort((a, b) =>
          b.timesheet.rapport < a.timesheet.rapport ? -1 : 1
        );
        sortedContribution.unshift(totalContribution);
        setFiltredContribution(sortedContribution);
        return;
      case "real_nettoyage_asc":
        sortedContribution.sort((a, b) =>
          a.timesheet.realizedNetoyage < b.timesheet.realizedNetoyage ? -1 : 1
        );
        sortedContribution.unshift(totalContribution);
        setFiltredContribution(sortedContribution);
        return;
      case "real_nettoyage_desc":
        sortedContribution.sort((a, b) =>
          b.timesheet.realizedNetoyage < a.timesheet.realizedNetoyage ? -1 : 1
        );
        sortedContribution.unshift(totalContribution);
        setFiltredContribution(sortedContribution);
        return;
      default:
        return;
    }
  };

  const prepareExpandedClient = (id) => {
    if (typeof id === "string" && id.split("-").length > 1) {
      return;
    }
    if (id === "TOTAL") {
      return;
    }
    if (id === expandedClient) {
      const boxes = document.querySelectorAll(
        ".CustumedMatrix_matrix_body_line__11lbb"
      );

      boxes.forEach((box) => {
        box.classList.remove("sub_line");
        box.classList.remove("last_sub_line");
        box.classList.remove("expanded_line");
      });
      setFiltredContribution(contribution);
      setExpandedClient(null);
    } else {
      let result = [];
      contribution.forEach((item) => {
        result.push(item);
        if (item.id === id) {
          Object.keys(item.children).forEach((child) => {
            result.push(item.children[child]);
          });
        }
      });
      setExpandedClient(id === expandedClient ? null : id);
      setFiltredContribution(result);
      if (document.getElementById("expanded")) {
        setTimeout(() => {
          document
            .getElementById("expanded")
            .parentElement.parentElement.classList.add("expanded_line");
          document
            .getElementsByClassName("sub-line-child")
            .parentElement.parentElement.classList.add("sub_line");
        }, "100");
      }
    }
  };

  const renderPannelFrame = () => {
    return (
      <div className="production-frame">
        {renderAddBubble()}
        {collaboratorContributionQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : !collaboratorContributionQuery.isFetching &&
          filtredContribution &&
          filtredContribution.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          collaboratorContributionQuery.isFetched && (
            <div className="production-frame_scroll" style={{ width: "105%" }}>
              <div className="total">
                {_("folderCount")} : {contribution.length - 1}
              </div>
              <CustumedMatrix
                columns={columns}
                items={filtredContribution}
                handleCellule={handleColumns}
                handleTitle={handleTitle}
                headerTagBG={"#FFFFFF"}
              />
            </div>
          )
        )}
      </div>
    );
  };

  const handleColumns = (column, line) => {
    if (line.title.label === "total") {
      if (column.id === "volumetry") {
        return (
          <div className="tags" style={{ margin: "20px 0px 2px 2px" }}>
            <div className="labled-tag no-border">
              <span>{_("realized")}</span>
              <div className="tag">
                <div>{line.volumetry.progression.real} p</div>
              </div>
            </div>
          </div>
        );
      } else if (column.id === "budget") {
        return (
          <div className="tags" style={{ margin: "20px 0px 2px 2px" }}>
            <div
              className="labled-tag no-border"
              style={{ marginBottom: "23px" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "85%",
                  color: sortApplication.annualBudget && "#18a0fb",
                }}
              >
                <span>{_("annual")}</span>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!sortApplication.annualBudget) {
                      sortClientBy("annual_budget_desc");
                      setSortApplication({
                        rapport: null,
                        real: null,
                        periodicBudget: null,
                        annualBudget: "annual_budget_desc",
                      });
                    } else {
                      sortClientBy(
                        sortApplication.annualBudget === "annual_budget_desc"
                          ? "annual_budget_asc"
                          : "annual_budget_desc"
                      );
                      setSortApplication({
                        rapport: null,
                        real: null,
                        periodicBudget: null,
                        annualBudget:
                          sortApplication.annualBudget === "annual_budget_desc"
                            ? "annual_budget_asc"
                            : "annual_budget_desc",
                      });
                    }
                  }}
                >
                  {sortApplication.annualBudget === "annual_budget_asc" ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </div>
              </div>

              <div className="tag" style={{ padding: "0", borderLeft: "none" }}>
                <div
                  className={`progress_item_progress ${
                    line.budget.budgetZoneCollaborator >
                    line.budget.realZoneCollaborator
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                  style={{ background: "none", marginLeft: -8 }}
                >
                  {renderDurationDisplay(line.budget.budgetZoneCollaborator)}
                  <div
                    style={{
                      height: "38px",
                      display: "flex",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div className="progress_item_progress_container">
                      <div
                        className="progress_item_progress_bar"
                        style={{
                          width:
                            parseInt(line.budget.realZoneCollaborator) >
                            parseInt(line.budget.budgetZoneCollaborator)
                              ? "100%"
                              : parseInt(line.budget.budgetZoneCollaborator) >
                                  0 &&
                                parseInt(line.budget.realZoneCollaborator) > 0
                              ? `${
                                  (parseInt(line.budget.realZoneCollaborator) *
                                    100) /
                                  parseInt(line.budget.budgetZoneCollaborator)
                                }%`
                              : 0,

                          background:
                            parseFloat(line.budget.realZoneCollaborator) >
                            parseFloat(line.budget.budgetZoneCollaborator)
                              ? "#EB5757"
                              : "#06D9B1",
                        }}
                      />
                      <div
                        className="progress_item_progress_index"
                        style={{
                          width:
                            parseInt(line.budget.realZoneCollaborator) >
                            parseInt(line.budget.budgetZoneCollaborator)
                              ? "200%"
                              : parseInt(line.budget.budgetZoneCollaborator) >
                                  0 &&
                                parseInt(line.budget.realZoneCollaborator) > 0
                              ? `${
                                  (parseInt(line.budget.realZoneCollaborator) *
                                    200) /
                                  parseInt(line.budget.budgetZoneCollaborator)
                                }%`
                              : 0,
                          color:
                            parseFloat(line.budget.realZoneCollaborator) >
                            parseFloat(line.budget.budgetZoneCollaborator)
                              ? "#DE4848"
                              : "#02AF8E",
                        }}
                      >
                        <TiArrowSortedDown />
                        <div className="realized_progress">
                          {renderDurationDisplay(
                            line.budget.realZoneCollaborator
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="labled-tag no-border">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  color: sortApplication.periodicBudget && "#18a0fb",
                }}
              >
                <span>{_("periodic")}</span>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!sortApplication.periodicBudget) {
                      sortClientBy("periodic_budget_desc");
                      setSortApplication({
                        rapport: null,
                        real: null,
                        annualBudget: null,
                        periodicBudget: "periodic_budget_desc",
                      });
                    } else {
                      sortClientBy(
                        sortApplication.periodicBudget ===
                          "periodic_budget_desc"
                          ? "periodic_budget_asc"
                          : "periodic_budget_desc"
                      );
                      setSortApplication({
                        rapport: null,
                        real: null,
                        annualBudget: null,
                        periodicBudget:
                          sortApplication.periodicBudget ===
                          "periodic_budget_desc"
                            ? "periodic_budget_asc"
                            : "periodic_budget_desc",
                      });
                    }
                  }}
                >
                  {sortApplication.periodicBudget === "periodic_budget_asc" ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </div>
              </div>

              <div className="tag">
                <div>
                  {line.budget.budgetTVA
                    ? renderDurationDisplay(line.budget.budgetTVA)
                    : "----"}
                </div>
              </div>
            </div>
          </div>
        );
      } else if (column.id === "timesheet") {
        return (
          <div className="tags" style={{ margin: "20px 10px 2px 2px" }}>
            <div className="labled-tag no-border">
              <span>{_("Encodage Réalisé")}</span>
              <div className="tag">
                <div>
                  {renderDurationDisplay(line.timesheet.realizedEncodage)}
                </div>
              </div>
            </div>

            <div className="labled-tag no-border">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "85%",
                  color: sortApplication.real && "#18a0fb",
                }}
              >
                <span>{_("Nettoyage Réalisé")}</span>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!sortApplication.real) {
                      sortClientBy("real_nettoyage_desc");
                      setSortApplication({
                        annualBudget: null,
                        periodicBudget: null,
                        rapport: null,
                        real: "real_nettoyage_desc",
                      });
                    } else {
                      sortClientBy(
                        sortApplication.real === "real_nettoyage_desc"
                          ? "real_nettoyage_asc"
                          : "real_nettoyage_desc"
                      );
                      setSortApplication({
                        annualBudget: null,
                        periodicBudget: null,
                        rapport: null,
                        real:
                          sortApplication.real === "real_nettoyage_desc"
                            ? "real_nettoyage_asc"
                            : "real_nettoyage_desc",
                      });
                    }
                  }}
                >
                  {sortApplication.real === "real_nettoyage_asc" ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </div>
              </div>
              <div className="tag">
                <div>
                  {renderDurationDisplay(line.timesheet.realizedNetoyage)}
                </div>
              </div>
            </div>
            <div className="labled-tag no-border">
              <span>{_("Bilan Réalisé")}</span>
              <div className="tag">
                <div>
                  {renderDurationDisplay(line.timesheet.realizedBalance)}
                </div>
              </div>
            </div>
            <div className="labled-tag no-border">
              <div
                style={{
                  display: "flex",
                  width: "85%",
                  color: sortApplication.rapport && "#18a0fb",
                }}
              >
                <Tooltip
                  placement="top"
                  title={`Encodage Réalisé / ( Netoyage Réalisé + Bilan Réalisé)`}
                >
                  <span>{_("Rapport")}</span>
                </Tooltip>

                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!sortApplication.rapport) {
                      sortClientBy("rapport_desc");
                      setSortApplication({
                        annualBudget: null,
                        periodicBudget: null,
                        real: null,
                        rapport: "rapport_desc",
                      });
                    } else {
                      sortClientBy(
                        sortApplication.rapport === "rapport_desc"
                          ? "rapport_asc"
                          : "rapport_desc"
                      );
                      setSortApplication({
                        annualBudget: null,
                        periodicBudget: null,
                        real: null,
                        rapport:
                          sortApplication.rapport === "rapport_desc"
                            ? "rapport_asc"
                            : "rapport_desc",
                      });
                    }
                  }}
                >
                  {sortApplication.rapport === "rapport_asc" ? (
                    <TiArrowSortedUp />
                  ) : (
                    <TiArrowSortedDown />
                  )}
                </div>
              </div>
              <div className="tag">
                <div
                  style={{
                    color:
                      line.timesheet.rapport > 2
                        ? " #06D9B1"
                        : line.timesheet.rapport > 1.5
                        ? "#EA8124"
                        : "#eb5757",
                    backgroundColor:
                      line.timesheet.rapport > 2
                        ? " rgba(6, 217, 177, 0.1)"
                        : line.timesheet.rapport > 1.5
                        ? "rgb(235 152 87 / 17%)"
                        : "rgba(235, 87, 87, 0.1)",
                  }}
                >
                  {line.timesheet.rapport}
                </div>
              </div>
            </div>
            <div className="labled-tag no-border">
              <span>{_("séquences")}</span>
              <div className="tag">
                <div>{line.echeance.sequences}</div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="tags" style={{ margin: "20px 10px 2px 2px" }}>
            <div className="labled-tag no-border">
              <span>{_("Situations Intermédiaires")}</span>
              <div className="tag">
                <div>{line.echeance.intermediateSituations}</div>
              </div>
            </div>
            <div className="labled-tag no-border">
              <span>{_("tvaRegime")}</span>
              <div className="tag">
                <div className="tva">{line.echeance.TVA}</div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (column.id === "volumetry") {
        if (line.key === "CHILD") {
          return (
            <Fragment>
              <div className="tags">
                <div className="tag no-border">
                  <div>{renderDurationDisplay(line.timesheet.duration)}</div>
                </div>
              </div>
            </Fragment>
          );
        }
        return (
          <Fragment>
            <div
              className="tags"
              onClick={() => prepareExpandedClient(line.id)}
            >
              <div className="tag no-border">
                <div>{line.volumetry.progression.real} p</div>
              </div>
            </div>
          </Fragment>
        );
      } else if (column.id === "budget") {
        if (line.key === "CHILD") {
          return (
            <Fragment>
              <div className="tags">
                <div className="tag no-border xl-tag">
                  <div>{line.timesheet.date}</div>
                </div>
              </div>
            </Fragment>
          );
        }
        return (
          <div
            className="tags"
            style={{ margin: "0px 2px" }}
            onClick={() => prepareExpandedClient(line.id)}
          >
            <div className="tag" style={{ padding: "0", borderLeft: "none" }}>
              <div
                className={`progress_item_progress ${
                  line.budget.realZoneCollaborator >
                  line.budget.budgetZoneCollaborator
                    ? "bg-danger"
                    : "bg-success"
                }`}
                style={{ background: "none" }}
              >
                {renderDurationDisplay(line.budget.budgetZoneCollaborator)}
                <div
                  style={{
                    height: "38px",
                    display: "flex",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="progress_item_progress_container">
                    <div
                      className="progress_item_progress_bar"
                      style={{
                        width:
                          parseInt(line.budget.realZoneCollaborator) >
                          parseInt(line.budget.budgetZoneCollaborator)
                            ? "100%"
                            : parseInt(line.budget.budgetZoneCollaborator) >
                                0 &&
                              parseInt(line.budget.realZoneCollaborator) > 0
                            ? `${
                                (parseInt(line.budget.realZoneCollaborator) *
                                  100) /
                                parseInt(line.budget.budgetZoneCollaborator)
                              }%`
                            : 0,

                        background:
                          parseFloat(line.budget.realZoneCollaborator) >
                          parseFloat(line.budget.budgetZoneCollaborator)
                            ? "#EB5757"
                            : "#06D9B1",
                      }}
                    />
                    <div
                      className="progress_item_progress_index"
                      style={{
                        width:
                          parseInt(line.budget.realZoneCollaborator) >
                          parseInt(line.budget.budgetZoneCollaborator)
                            ? "200%"
                            : parseInt(line.budget.budgetZoneCollaborator) >
                                0 &&
                              parseInt(line.budget.realZoneCollaborator) > 0
                            ? `${
                                (parseInt(line.budget.realZoneCollaborator) *
                                  200) /
                                parseInt(line.budget.budgetZoneCollaborator)
                              }%`
                            : 0,
                        color:
                          parseFloat(line.budget.realZoneCollaborator) >
                          parseFloat(line.budget.budgetZoneCollaborator)
                            ? "#DE4848"
                            : "#02AF8E",
                      }}
                    >
                      <TiArrowSortedDown />
                      <div
                        style={{
                          textAlign: "center",
                          width:
                            parseInt(line.budget.budgetZoneCollaborator) > 0
                              ? parseInt(line.budget.realZoneCollaborator) /
                                  parseInt(line.budget.budgetZoneCollaborator) <
                                  40 && "89px"
                              : "",
                        }}
                      >
                        {renderDurationDisplay(
                          line.budget.realZoneCollaborator
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tag" style={{ borderLeft: "none" }}>
              <div>
                {line.budget.budgetTVA
                  ? renderDurationDisplay(line.budget.budgetTVA)
                  : "----"}
              </div>
            </div>
          </div>
        );
      } else if (column.id === "timesheet") {
        if (line.key === "CHILD") {
          return (
            <Fragment>
              <div className="text-tag" style={{ marginTop: 5 }}>
                <div>{line.timesheet.remark}</div>
              </div>
            </Fragment>
          );
        }
        return (
          <div
            className="tags"
            style={{ padding: "0", margin: "0px 2px" }}
            onClick={() => prepareExpandedClient(line.id)}
          >
            <div className="tag no-border">
              <div>
                {renderDurationDisplay(line.timesheet.realizedEncodage)}
              </div>
            </div>

            <div className="tag no-border" style={{ padding: "0" }}>
              <div
                style={{
                  color:
                    line.timesheet.realizedNetoyage >
                    parseFloat(line.timesheet.budgetNetoyage) * 60
                      ? "#eb5757"
                      : "#6d7f92",
                }}
              >
                {renderDurationDisplay(line.timesheet.realizedNetoyage)}
              </div>
            </div>
            <div className="tag no-border">
              <div>{renderDurationDisplay(line.timesheet.realizedBalance)}</div>
            </div>
            <div className="tag no-border" style={{ padding: "none" }}>
              <div
                style={{
                  color:
                    line.timesheet.rapport > 2
                      ? " #06D9B1"
                      : line.timesheet.rapport > 1.5
                      ? "#EA8124"
                      : "#eb5757",
                  backgroundColor:
                    line.timesheet.rapport > 2
                      ? " rgba(6, 217, 177, 0.1)"
                      : line.timesheet.rapport > 1.5
                      ? "rgb(235 152 87 / 17%)"
                      : "rgba(235, 87, 87, 0.1)",
                }}
              >
                {line.timesheet.rapport}
              </div>
            </div>
            <div className="tag no-border" style={{ padding: "0" }}>
              <div>{line.echeance.sequences}</div>
            </div>
          </div>
        );
      } else {
        if (line.key === "CHILD") {
          return;
        }
        return (
          <div
            className="tags"
            style={{ margin: "0px 2px" }}
            onClick={() => prepareExpandedClient(line.id)}
          >
            <div className="tag no-border" style={{ padding: "none" }}>
              {["SUPERADMIN", "ADMIN", "MANAGER"].includes(loggedAs) ? (
                <input
                  type="text"
                  value={
                    intermediateSituations.find(
                      (client) => client.clientId === line.id
                    ).intermediateSituation
                  }
                  required={true}
                  onChange={(event) =>
                    handleIntermediateSituationChange(
                      line.id,
                      event.target.value
                    )
                  }
                />
              ) : (
                <div>
                  {
                    intermediateSituations.find(
                      (client) => client.clientId === line.id
                    ).intermediateSituation
                  }
                </div>
              )}
            </div>
            <div className="tag no-border" style={{ padding: "0" }}>
              <div className="tva">{line.echeance.TVA}</div>
            </div>
          </div>
        );
      }
    }
  };

  const handleTitle = (title) => {
    return (
      <Fragment>
        <div
          className={`${
            title.title.label === "total"
              ? "matrix_title_header"
              : title.key === "CHILD"
              ? "matrix_sub_title"
              : "matrix_title"
          }`}
          id={title.title.label === "total" ? "total-line" : ""}
          onClick={() => {
            if (title.key === "CHILD") {
              return;
            }
            prepareExpandedClient(title.id);
          }}
        >
          <div className="title_infos">
            {title.title.label !== "total" && (
              <div className={title.title.state}>
                <ArrowLeftIcon width={9} height={7} fill="#FFFFFF" />
              </div>
            )}
            <div>{title.title.label}</div>
          </div>

          {title.title.label !== "total" &&
            title.key !== "CHILD" &&
            collaborator &&
            !collaborator.isDeleted && (
              <div className="actions">
                <Link to={`/${lng}/fiche-client/${title.id}`}>
                  <div className={`title_action action_doh`}>
                    <ShortcutIcon size="16" />
                  </div>
                </Link>
                <div
                  className={`title_action ${
                    title.title.state !== "danger" && title.title.count === 0
                      ? "action_doh"
                      : ""
                  }`}
                  style={{ width: title.title.count > 0 ? "" : "51%" }}
                  onClick={() => setShowSideBar(title)}
                >
                  <FlagIcon />
                  <div>{title.title.count > 0 ? title.title.count : "+"}</div>
                </div>
              </div>
            )}
        </div>
      </Fragment>
    );
  };

  //Sidebar functions
  const renderAddBubble = () => {
    return (
      <div className="setting-bubbles">
        <div
          className={`circleSvg 
            ${showConfigSideBar ? "active opened" : ""}`}
          onClick={() => setShowConfigSideBar(true)}
        >
          <SettingsIcon size="20" fill="#6D7F92" />
          <span style={{ paddingLeft: "2.5rem" }}>{_("coefficients")}</span>
        </div>
      </div>
    );
  };

  const renderSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${showSideBar && "display"}`}>
          <CollaboratorCorrectionCard
            organization={organization}
            category={filter.category}
            token={token}
            year={year}
            loggedAs={loggedAs}
            creator={user}
            onClose={() => {
              document
                .getElementsByClassName("off-canvas-wrapper")[0]
                .classList.remove("off-canvas-wrapper-fixed");
              setShowSideBar(null);
            }}
            client={showSideBar}
            lng={lng}
          />
        </div>
      </div>
    );
  };

  const renderConfigSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar config-sid ${showConfigSideBar && "display"}`}>
          <VolumetryConfigContent
            collaborator={collaborator}
            organization={organization}
            category={filter.category}
            token={token}
            onClose={() => setShowConfigSideBar(null)}
          />
        </div>
      </div>
    );
  };
  const handleSaveClientsIntermediateSituations = () => {
    saveClientsIntermediateSituations(
      intermediateSituationsToSave,
      organization,
      year,
      token
    )
      .then((resp) => {
        if (resp.data.result === "OK") {
          toast.success(_("successfully_added"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
    setUpdated(false);
    setIntermediateSituationsToSave([]);
  };
  return (
    <>
      <Fragment>
        {renderPannelFrame()}
        {showSideBar && renderSideBarFrame()}
        {showConfigSideBar && renderConfigSideBarFrame()}
      </Fragment>
      <div className="button_actions">
        <button
          className={`save ${!updated && "disabled"}`}
          onClick={() => handleSaveClientsIntermediateSituations()}
        >
          Enregistrer
        </button>
      </div>
    </>
  );
};

export default Progression6Content;
