import React from "react";

export const ChartIcon = ({ size = "14", stroke = "#6D7F92", fill = "none", ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.75 1.75V11.0833C1.75 11.3928 1.87292 11.6895 2.09171 11.9083C2.3105 12.1271 2.60725 12.25 2.91667 12.25H12.25M9.33333 2.91667H10.5C10.8222 2.91667 11.0833 3.17783 11.0833 3.5V9.33333C11.0833 9.6555 10.8222 9.91667 10.5 9.91667H9.33333C9.01117 9.91667 8.75 9.6555 8.75 9.33333V3.5C8.75 3.17783 9.01117 2.91667 9.33333 2.91667ZM4.66667 4.66667H5.83333C6.1555 4.66667 6.41667 4.92783 6.41667 5.25V9.33333C6.41667 9.6555 6.1555 9.91667 5.83333 9.91667H4.66667C4.3445 9.91667 4.08333 9.6555 4.08333 9.33333V5.25C4.08333 4.92783 4.3445 4.66667 4.66667 4.66667Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);
