import axios from "axios";
import moment from "moment";

import { TTP_API_URL } from "../config";
import { formatDateToISO } from "../utils";

export const getClientDetails = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/client/details/${id}`;
  //TODO

  let params = {
    access_token: token,
    organization_id: organization,
    organizationId: organization,
    year: data.year,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getClientProgressionBudget = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/budget/client/progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organization,
      client: id,
      year: data.year,
      category: data.category.id
    },
  });
};

export const getClientActivities = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/client/activities`;

  let params = {
    access_token: token,
    organization_id: organization,
    organizationId: organization,
    year: data.year,
    client: id,
    lng: data.language,
    after: moment().subtract(7, "d").format("YYYY-MM-DD"),
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getVolumetryCliets = ( token, organization, date, filter) => {
  const requestUrl = `${TTP_API_URL}/team/clients/suivi-prestation`;

  let params = {
    access_token: token,
    organizationId: organization,
    collaborators: JSON.stringify([filter.collaborator]),
    date: date,
    keyWord: filter.keyWord,
    period: filter.period.id
  };

  params.startDate = filter.start ? formatDateToISO(filter.start) : "";
  params.endDate = filter.end ? formatDateToISO(filter.end) : "";

  params.limit = filter.pageSize || 10;
  params.start = !filter.paginationPage
    ? 0
    : (filter.paginationPage - 1) * filter.pageSize;

  return axios.get(requestUrl, {
    params,
  });
};

export const printPrestationInvoice = async (token, organizationId, startDate, endDate, number, collaborators) => {
  const requestUrl = `${TTP_API_URL}/team/clients/suivi-prestation/print`;

  return await axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      startDate,
      endDate,
      invoiceNumber: number,
      collaborators: JSON.stringify(collaborators),
    },
    // responseType: "blob",
    // headers: { Accept: "application/pdf" }
  });

  // const url = window.URL.createObjectURL(
  //   new Blob([response.data], { type: "application/pdf" })
  // );
  // const link = document.createElement("a");
  // link.href = url;
  // link.setAttribute(
  //   "download",
  //   `Facture M${moment(endDate).format("MM-YY")}.pdf`
  // );
  // document.body.appendChild(link);
  // link.click();
};

export const getPrestationAnnexe = async (token, organizationId, startDate, endDate, collaborators) => {
  const requestUrl = `${TTP_API_URL}/team/clients/prestation-annexe`;

  return await axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      startDate,
      endDate,
      collaborators: JSON.stringify(collaborators),
    },
  });
}

export const getInvoiceFiles = async (token, organizationId, year) => {
  const requestUrl = `${TTP_API_URL}/team/oam/invoice`;

  return await axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      year,
    }
  });
}

export const exportClientsVolumetry = async (
  token,
  organizationId,
) => {
  const requestUrl = `${TTP_API_URL}/team/export/clients-volumetry`;

  const response = await axios
    .get(requestUrl, {
      params: {
        access_token: token,
        organizationId,
      },
      responseType: "blob",
      headers: { Accept: "application/vnd.ms-excel" }
    });
  const url_2 = window.URL.createObjectURL(
    new Blob([response.data], { type: response.data.type })
  );
  const link = document.createElement("a");
  link.href = url_2;
  link.setAttribute(
    "download",
    "PowerTeam_Clients_Volumetry.xlsx"
  );
  document.body.appendChild(link);
  link.click();
}

export const getClientContactInfo = ({token, organizationId, clientId}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/${clientId}/contact-info`;

  let params = {
    access_token: token,
    organizationId,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getNewClients = ({token, organizationId, start, filters}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/new-clients`;
  let params = {
    access_token: token,
    organizationId,
    year: filters.year,
    start,
    limit: filters.pageSize,
    bu: filters.bu,
    zone: filters.zone,
    partner: filters.partner,
    collaborator: filters.collaborator,
    collaboratorRole: filters.role,
    clientGroup: filters.clientGroup,
    searchWord: filters.keyWord,
  };

  return axios.get(requestUrl, { params });
}

export const saveNewClients = ({token, organizationId, items}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/new-clients`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);
  formData.append("items", JSON.stringify(items));

  return axios.post(requestUrl, formData);
}

export const getOldClients = ({token, organizationId, start, filters}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/old-clients`;
  let params = {
    access_token: token,
    organizationId,
    year: filters.year,
    start,
    limit: filters.pageSize,
    bu: filters.bu,
    zone: filters.zone,
    partner: filters.partner,
    collaborator: filters.collaborator,
    collaboratorRole: filters.role,
    clientGroup: filters.clientGroup,
    searchWord: filters.keyWord,
  };

  return axios.get(requestUrl, { params });
}

export const saveOldClients = ({token, organizationId, items}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/old-clients`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);
  formData.append("items", JSON.stringify(items));

  return axios.post(requestUrl, formData);
}

export const getRiskyClients = ({token, organizationId, start, filters}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/risky-clients`;
  let params = {
    access_token: token,
    organizationId,
    year: filters.year,
    start,
    limit: filters.pageSize,
    bu: filters.bu,
    zone: filters.zone,
    partner: filters.partner,
    collaborator: filters.collaborator,
    collaboratorRole: filters.role,
    clientGroup: filters.clientGroup,
    searchWord: filters.keyWord,
  };

  return axios.get(requestUrl, { params });
}

export const saveRiskyClients = ({token, organizationId, items}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/risky-clients`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);
  formData.append("items", JSON.stringify(items));

  return axios.post(requestUrl, formData);
}

export const getClientForfaitReviewStatus = (
  token,
  organization,
  client
) => {
  const requestUrl = `${TTP_API_URL}/team/client/forfait/review`;
  let params = {
    access_token: token,
    organization,
    client
  };

  return axios.get(requestUrl, { params });
}

export const updateClientForfaitReviewStatus = ({
  token,
  organization,
  client,
  status
}) => {
  const requestUrl = `${TTP_API_URL}/team/client/forfait/review`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization", organization);
  formData.append("client", client);
  formData.append("status", status);

  return axios.post(requestUrl, formData);
}

export const getBillingTypes = ({
  token,
  organizationId
}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/billing-types`;

  const params = {
    access_token: token,
    organizationId: organizationId
  };

  return axios.get(requestUrl, { params });
}

export const updateNeutralClient = ({
  token,
  organization,
  year,
  month,
  neutral
}) => {
  const requestUrl = `${TTP_API_URL}/team/client/neutral`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organization);
  formData.append("year", year);
  formData.append("month", month);
  formData.append("neutral", neutral);

  return axios.post(requestUrl, formData);
}

export const getCollaboratorOamCode = async (token, organizationId, year) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/oam-code`;

  return await axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      year,
    }
  });
}

export const updateCollaboratorOamCode = async ({token, organizationId, year, data}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/oam-code/update`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);
  formData.append("year", year);
  formData.append("data", JSON.stringify(data));

  return axios.post(requestUrl, formData);
}
