import Dropzone from 'react-dropzone';
import { CloudUploadIcon } from '../../../../common/Icons';
import styles from './DocumentUploader.module.scss';
import _ from "../../../../../i18n";
import { FaPaperclip } from 'react-icons/fa';

export default function DocumentUploader({
  attachments,
  onDropItems,
  allowMultipleSelect,
  acceptedFormats
}) {

  const renderAttachementsList = () => {
    if (!attachments || attachments.length === 0) return (
      <p>
        <span
          className={styles.drag_message}
          dangerouslySetInnerHTML={{
            __html: _("dragMessage")
          }}
        />
      </p>
    )

    let attachmentsToRender = attachments;
    if (typeof(attachments) === 'string') {
      attachmentsToRender = JSON.parse(attachments);
    }
    return (
      <div className={styles.attachments_list}>
        {attachmentsToRender?.map(file => {
          let fileName = null;
          if (file.name) {
            fileName = file.name;
          } else {
            let pathParts = file.split('/');
            fileName = pathParts[pathParts.length - 1];
          }

          return (
            <p className={styles.attachments_item}>
              <FaPaperclip />
              {fileName}
            </p>
          );
        })}
      </div>
    )
  }

  return (
    <div className={styles.attachments_container}>
      <Dropzone
        onDrop={acceptedFiles => onDropItems(acceptedFiles)}
        className={styles.attachments_dropzone}
        multiple={allowMultipleSelect}
        accept={acceptedFormats}
      >
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} accept={acceptedFormats} />
              <div className={styles.attachments_dropzone_inner}>
                <span className={styles.icon_upload}>
                  <CloudUploadIcon />
                </span>
                {renderAttachementsList()}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}
