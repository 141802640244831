import React from "react";

import _ from "../../../i18n";
import "./PageHeader.local.scss";

const PageHeader = ({ header, subHeader, icon, children, customAddOn }) => {
  return (
    <div className="page_header">
      <div className="page_header__left_side">
        <div className="page_header__icon">{icon}</div>
        <div className="page_header__info">
          <div>
            <h3>{_(header)}</h3>
            <p>{_(subHeader)}</p>
          </div>
        </div>
      </div>
      <div className="page_header__children">{children}</div>
      {customAddOn}
    </div>
  );
};

export default PageHeader;
