import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCollaboratorsEcheance } from "../../../api";
import EcheancesFilter from "./EcheancesFilter";
import "./Echeances.local.scss";
import { parsePreference, renderCollaboratorAvatar } from "../../../utils";
import Table from "./Table";
import { Tooltip } from "antd";
import { COLLABORATOR_ROLE_ATTRIBUT } from "../../../config";
import _ from "../../../i18n";
import GroupFilter from "../GroupFilter";

const Echeances = ({ parentFilter, collaborator, preferences }) => {
  const { token, navCommunity, loggedAs, user } = useSelector((state) => state.auth);

  const allowViewDataBy =
      parsePreference(preferences).echeance.allowViewDataBy;

  const [selectedCollaboratorId, setSelectedCollaboratorId] = useState(null);
  const [selectedEcheanceClosingDate, setSelectedEcheanceClosingDate] =
    useState(null);

  const [filter, setFilter] = useState({
    ...parentFilter,
    keyWord: "",
    year: new Date().getFullYear().toString(),
    collaborator: null,
    partnerZoneId: null,
    bu: allowViewDataBy === 100 && collaborator ? [collaborator.bu] : [],
    zone: allowViewDataBy === 101 && collaborator ? collaborator.zoneId : null,
    partner: loggedAs == "MANAGER" ? user?.id : null,
    collaborators:
      [10, 110].includes(allowViewDataBy) && collaborator
        ? [collaborator.id]
        : [],
    // pageSize: 10,
    // paginationPage: 1,
    // collaborators: [],
    // deletedStatus: null,
    // clientCRMGroup: [],
    // clientCRMTypes: [],
    // counters: [],
  });
  const balance_types = ["IPM", "ISOC", "TVA"];
  const [selectedBalanceTypes, setSelectedBalanceTypes] = useState("IPM");
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [counters, setCounters] = useState(0);
  const [collaborators, setCollaborators] = useState(null);
  const [balances, setBalances] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [balanceRefresh, setBalanceRefresh] = useState(false);
  const [loadingCollaborators, setLoadingCollaborators] = useState(true);
  const [loadingBalances, setLoadingBalances] = useState(true);

  useEffect(() => {
    handleChangeSelectedCollaborator(null, null);
  }, [filter.partner]);

  let reportingsQuery = useQuery(
    ["getCollaboratorsEcheance", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsEcheance(
            token,
            navCommunity.id,
            filter.partner,
            filter.collaborators,
            filter.year,
            filter.zone,
            filter.bu
          );
          const defaultCollaborator = {
            id: null,
            name: "Tous les collaborateurs",
          };
          setCollaborators([
            defaultCollaborator,
            ...response.data.data.collaborators,
          ]);
          setLoadingCollaborators(false);
        } catch (e) {
          setLoadingCollaborators(false);
          return null;
        }
      }
    }
  );

  const handleChangeSelectedCollaborator = (collaboratorId, zoneId) => {
    setSelectedCollaboratorId(collaboratorId);
    setFilter({...filter, zone: zoneId});
    setBalanceRefresh(true);
  };

  const handleChangeSelectedZone = () => {
    setLoadingCollaborators(true);
  };

  return (
    <div className="page-content">
      <div className="echeance_container">
        {/* <EcheancesFilter
          token={token}
          organization={navCommunity && navCommunity.id}
          filter={{ ...filter, counters }}
          // nbResult={nbrCollaborators}
          updateFilter={setFilter}
          setSelectedCollaboratorId={setSelectedCollaboratorId}
          handleChangeSelectedZone={handleChangeSelectedZone}
          // fetching={collaboratorsQuery.isFetching}
        /> */}
      <GroupFilter
          extraFilters={[]}
          filter={filter}
          updateFilter={setFilter}
          allowViewDataBy={
            parsePreference(preferences).echeance.allowViewDataBy
          }
          collaborator={collaborator}
          preferences={preferences}
          subPreference="echeance"
        />
        {loadingCollaborators ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : (
          <div className="content grid-x">
            <div className="columns medium-3 small-4 collaboators_list">
              {collaborators &&
                collaborators.map((item) => (
                  <div
                    className={`collaborator ${
                      item.id === selectedCollaboratorId &&
                      "selected_collaborator"
                    } `}
                    onClick={() => {
                      handleChangeSelectedCollaborator(item.id, item.zone_id);
                    }}
                  >
                    {item.id && (
                      <div className="avatar">
                        {renderCollaboratorAvatar(
                          item.avatar,
                          item.firstName,
                          item.lastName
                        )}
                      </div>
                    )}
                    <div className="name">
                      {_(item.name)}
                      {item.id && (
                        <div className="role">
                          {_(
                            COLLABORATOR_ROLE_ATTRIBUT.find(
                              (role) => role.value === item.role
                            ).label
                          )}
                        </div>
                      )}
                    </div>
                    {/* {item.id && (
                      <Tooltip
                        placement="top"
                        title={"Bilan fini Deg " + "/" + " Bilan fini OAM "}
                      >
                        <div className="percentage">
                          {parseFloat(item.fini_be_deg ?? 0)} /
                           {parseFloat(item.fini_ma_oam ?? 0)}
                        </div>
                      </Tooltip>
                    )} */}
                  </div>
                ))}
            </div>
            <div className="columns medium-9 small-8 balances">
              <div className="balance_header">
                {loadingCollaborators ? (
                  <div className="collaborator_name gradiant name" />
                ) : (
                  collaborators && (
                    <div className="name">
                      {
                        collaborators.filter(
                          (collaborator) =>
                            collaborator.id == selectedCollaboratorId
                        )[0]?.name
                      }
                    </div>
                  )
                )}
              </div>
              <Table
                token={token}
                organization={navCommunity.id}
                partner={filter.partner}
                partnerZoneId={filter.partnerZoneId}
                year={filter.year}
                collaboratorId={selectedCollaboratorId}
                type={"IPM"}
                balance_types={balance_types}
                zoneId={filter.zone}
                balanceRefresh={balanceRefresh}
                setBalanceRefresh={setBalanceRefresh}
                loggedAs={loggedAs}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Echeances;
