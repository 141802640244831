export const CalendarSeptember = ({ size = 16, fill = "none", stroke = "#000000", strokeWidth = "1", ...params }) => (
<svg
  width={size}
  height={size}
  viewBox="0 0 48 48"
  xmlns="http://www.w3.org/2000/svg"
  { ...params }
>
  <g id="a">
    <g>
      <circle
        style={{
          fill:fill,
          stroke:stroke,
          strokeWidth: strokeWidth,
          strokeLinecap:"round",
          strokeLinejoin:"round"
        }} cx="24" cy="21.6667" r="4.6667"
      />
      <path
        style={{
          fill:fill,
          stroke:stroke,
          strokeWidth: strokeWidth,
          strokeLinecap:"round",
          strokeLinejoin:"round"
        }}
        d="m19.8519,29.2716c.6913,1.037,1.9012,1.7284,3.8025,1.7284h.3457c2.5926,0,4.6667-2.0741,4.6667-4.6667v-4.6667"
      />
    </g>
  </g>
  <g id="b">
    <path
      style={{
        fill:fill,
        stroke:stroke,
        strokeWidth: strokeWidth,
        strokeLinecap:"round",
        strokeLinejoin:"round"
      }}
      d="m38.5,5.5h-4v1c0,1.933-1.567,3.5-3.5,3.5s-3.5-1.567-3.5-3.5v-1h-8v1c0,1.933-1.567,3.5-3.5,3.5s-3.5-1.567-3.5-3.5v-1h-3c-2.2091,0-4,1.7908-4,4v29c0,2.2091,1.7909,4,4,4h29c2.2092,0,4-1.7909,4-4V9.5c0-2.2092-1.7908-4-4-4Z"
    />
  </g>
</svg>
);
