import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "../../i18n";

import { getCapacityPlaningProduction, getCollaborator } from "../../api";

import PageHeader from "../common/PageHeader/PageHeader";
import TTPPaginator from "../common/TTPPaginator";
import { UsersIcon } from "../common/Icons";
import CollaboratorsFilter from "./CollaboratorsFilter";
import Item from "./Item";

import "./Collaborators.local.scss";
import CollaboratorDevelopmentCard from "./CollaboratorDevelopmentCard";
import NotAuthorized from "../common/NotAuthorized";
import { parsePreference } from "../../utils";

const Collaborators = () => {
  const { token, navCommunity, user, loggedAs, spaceAs, collaborator } = useSelector(
    (state) => state.auth
  );
  const { language } = useSelector((state) => state.params);
  const preferences =
    user &&
    navCommunity &&
    user.userOrganizations &&
    user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};

  const [collaborators, setCollaborators] = useState([]);
  const [showSideBar, setShowSideBar] = useState(null);
  const [filter, setFilter] = useState({
    keyWord: "",
    pageSize: 10,
    paginationPage: 1,
    year: new Date().getFullYear().toString(),
    collaboratorStatus: [],
    deletedStatus: "NOT_DELETED",
    counters: [],
    bu:
      parsePreference(preferences.collaborators).allowViewDataBy === 100 &&
      collaborator
        ? [collaborator.bu]
        : [],
    zone:
      parsePreference(preferences.collaborators).allowViewDataBy === 101 &&
      collaborator
        ? collaborator.zoneId
        : null,
    collaborators:
      [10, 110].includes(
        parsePreference(preferences.collaborators).allowViewDataBy
      ) && collaborator
        ? [collaborator.id]
        : [],
    partner:
      parsePreference(preferences.collaborators).allowViewDataBy === 11
        ? user?.id
        : null,
  });
  const [nbrCollaborators, setNbrCollaborators] = useState(0);
  const [counters, setCounters] = useState(0);
  const organizationId = navCommunity && navCommunity.id;

  let collboratorQuery = useQuery(
    ["getCollaborator", token, navCommunity, user, spaceAs],
    async () => {
      if (token && navCommunity && user) {
        try {
          const response = await getCollaborator(
            collaborator.id,
            navCommunity.id,
            token,
            user.uid,
            user.adminisId.split(":")[1]
          );
          // setCollaborator(response.data.data);
          setFilter((prevFilter) => ({
            ...prevFilter,
            bu:
              parsePreference(preferences.collaborators).allowViewDataBy ===
                100 && response.data.data
                ? [response.data.data.bu]
                : prevFilter.bu,
            zone:
              parsePreference(preferences.collaborators).allowViewDataBy ===
                101 && response.data.data
                ? response.data.data.zoneId
                : prevFilter.zone,
            partner:
              loggedAs === "COLLABORATOR"
                ? response.data.data?.partner.value
                : prevFilter.partner,
            collaborators:
              [10, 110].includes(
                parsePreference(preferences.collaborators).allowViewDataBy
              ) && response.data.data
                ? [response.data.data.id]
                : prevFilter.collaborators,
          }));
        } catch (e) {
          return null;
        }
      }
    }
  );

  let collaboratorsQuery = useQuery(
    ["getCapacityPlaningProduction", token, navCommunity, filter, collaborator],
    async () => {
      if (
        token &&
        navCommunity &&
        (([10, 110].includes(
          parsePreference(preferences.collaborators).allowViewDataBy
        ) &&
          filter.collaborators.length > 0) ||
          (parsePreference(preferences.collaborators).allowViewDataBy === 100 &&
            filter.bu.length > 0) ||
          (parsePreference(preferences.collaborators).allowViewDataBy === 101 &&
            filter.zone) ||
          [1, 11].includes(
            parsePreference(preferences.collaborators).allowViewDataBy
          ))
      ) {
        try {
          const response = await getCapacityPlaningProduction(
            organizationId,
            token,
            moment().year() === filter.year
              ? moment().toDate()
              : new Date(filter.year + "-12-31T12:00:00Z"),
            {
              ...filter,
              zone: filter.zone ? filter.zone : null,
              businessUnits: filter.bu,
            }
          );
          setCollaborators(response.data.data.data.pagination);
          setNbrCollaborators(
            response.data.data.data ? response.data.data.data.totalCount : 0
          );
          let countersResponse = response.data.data;
          delete countersResponse.data;
          setCounters(countersResponse);
        } catch (e) {
          setCollaborators([]);
          setNbrCollaborators(0);
        }
      }
    }
  );

  if (!parsePreference(preferences.collaborators).allowAccess) {
    return <NotAuthorized />;
  }

  const renderFetchingCards = () => {
    let cardFetching = [];
    for (let i = 0; i < 10; i++) {
      cardFetching.push(
        <div key={i} className="card_fetching">
          <div className="avatar gradiant" />
          <div className="title gradiant" />
          <div className="progress gradiant" />
          <div className="infos">
            <span className="gradiant" />
            <span className="gradiant" />
          </div>
          <div className="counters">
            <div className="counter">
              <span className="gradiant" />
              <span className="gradiant" />
            </div>
            <div className="counter">
              <span className="gradiant" />
              <span className="gradiant" />
            </div>
          </div>
        </div>
      );
    }
    return cardFetching;
  };

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={filter.pageSize}
        nbResult={nbrCollaborators}
        paginationPage={filter.paginationPage}
      />
    );
  };

  const renderDevelopementCardSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${showSideBar !== null && "display"}`}>
          <CollaboratorDevelopmentCard
            lng={language}
            year={filter.year}
            organization={navCommunity.id}
            collaborator={showSideBar}
            token={token}
            onClose={() => setShowSideBar(null)}
            user={user ? user.firstName + " " + user.lastName : ""}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="page-content">
      <PageHeader
        header={_("collaborators")}
        subHeader={_("subTitleCollaborators")}
        icon={<UsersIcon size="36" fill="#29394D" />}
      />
      <div className="container">
        <CollaboratorsFilter
          token={token}
          organization={navCommunity && navCommunity.id}
          filter={{ ...filter, counters }}
          collaborator={collaborator}
          nbResult={nbrCollaborators}
          updateFilter={setFilter}
          fetching={collaboratorsQuery.isFetching}
          preferences={preferences}
        />
        <div className="medium-11 content content-cards">
          {collaboratorsQuery.isFetching ? (
            <div>{renderFetchingCards()}</div>
          ) : collaborators.length ? (
            <Fragment>
              <div>
                {collaborators.map((item, i) => {
                  return (
                    <Item
                      preferences={preferences}
                      lng={language}
                      item={item}
                      key={`client-${item.id}`}
                      onShowDevCard={(item) => setShowSideBar(item)}
                    />
                  );
                })}
              </div>
              {renderPaginate()}
              {showSideBar && renderDevelopementCardSideBarFrame()}
            </Fragment>
          ) : (
            <div className="no_data">
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noCollaboratorsFound")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Collaborators;
