import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import _ from "../../../i18n";
import {
  useOnClickOutside,
  renderTeamYears,
  parsePreference,
  viewDataBy,
} from "../../../utils";
import { CLIENT_CRM_TYPE_FILTER, DELETED_STATUS } from "../../../config";
import {
  getCollaboratorsSuggestions,
  getClientsSuggestions,
  getGroupsDescription,
  getZonesSuggestions,
  getBU,
} from "../../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";
import { getPartners } from "../../../api/partners";

const CapacityPlaningMatrixFilter = ({
  filter,
  nbResult,
  updateFilter,
  collaborator,
  preferences,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));
  const { token, navCommunity } = useSelector((state) => state.auth);

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

  useEffect(() => {
    if (filter.partner) {
      const options = collaborators.filter((c) => c.partner == filter.partner);
      setCollaboratorOptions(options);
    } else {
      setCollaboratorOptions(collaborators);
    }
  }, [filter.partner, collaborators]);

  let partnersQuery = useQuery(["partnersQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getPartners({
          token,
          organizationId: navCommunity.id,
        });
        let data = response.data.data.map((partner) => ({
          value: partner.id,
          label: partner.name,
          zone: partner.zone,
        }));
        setPartnerOptions(data);
      } catch (e) {
        setPartnerOptions([]);
        return null;
      }
    }
  });

  let collaboratorsdQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data;
          let list = viewDataBy(
            preferences.capacityPlanning,
            data,
            collaborator
          );
          data = list
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
                partner: t?.partner?.value,
                isActif: t.isActif,
              };
            });
          setCollaborators(
            list.map((c) => ({
              value: c.id,
              label: c.name,
              partner: c?.partner?.value,
              isActif: c.isActif,
            }))
          );
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let clientsdQuery = useQuery(
    ["getClients", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getClientsSuggestions(
            token,
            filter.clientCRMGroup,
            navCommunity.id,
            "isEntity",
            filter.year
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setClientOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let clientGroupsDescriptionQuery = useQuery(
    ["clientGroupsDescriptionQuery", navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getGroupsDescription(navCommunity.id, token);
          let data = response.data.data.map((t) => {
            return {
              value: t.group,
              label: t.group,
            };
          });
          setGroups(data);
        } catch (e) {
          setGroups([]);
          return null;
        }
      }
    }
  );

  let zonesQuery = useQuery(["zonesQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getZonesSuggestions(token, navCommunity.id);
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setZoneOptions(data);
      } catch (e) {
        setZoneOptions([]);
        return null;
      }
    }
  });

  let buQuery = useQuery(["businessUnitsQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getBU({
          token,
          organizationId: navCommunity.id,
        });
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setBuOptions(data);
      } catch (e) {
        setBuOptions([]);
        return null;
      }
    }
  });

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => updateFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handlePartnerChange = (item) => {
    updateFilter({ ...filter, partner: Number(item) });
  };

  const handleBuChange = (items) => {
    updateFilter({ ...filter, bu: items });
  };

  const handleZoneChange = (item) => {
    updateFilter({ ...filter, zone: item });
  };

  const handleCollaboratorsChange = (items) => {
    const actifs = collaborators
      .filter((item) => item.isActif)
      .map((item) => item.value);

    const updatedSelection = [...actifs, ...items];

    const updated = [10, 110].includes(
      parsePreference(preferences.capacityPlanning).allowViewDataBy
    )
      ? [...new Set(updatedSelection)]
      : items;

    updateFilter({ ...filter, collaborators: updated });
  };

  const handleClientsChange = (items) => {
    updateFilter({ ...filter, clients: items });
  };

  const handleSetDeletedStatus = (status) => {
    updateFilter({ ...filter, deletedStatus: status });
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isSearch: true,
        isClearable:
          parsePreference(preferences.capacityPlanning).allowViewDataBy !== 10,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborators,
        action: handleCollaboratorsChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        isSearch: true,
        isDisabled:
          parsePreference(preferences.capacityPlanning).allowViewDataBy === 101,
        placeholder: _("zone"),
        options: zoneOptions,
        values: filter.zone,
        action: handleZoneChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        isSearch: true,
        isDisabled:
          parsePreference(preferences.capacityPlanning).allowViewDataBy === 100,
        placeholder: _("bu"),
        options: buOptions,
        values: filter.bu,
        action: handleBuChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isSearch: true,
        placeholder: _("client"),
        options: clientOptions,
        values: filter.clients,
        action: handleClientsChange,
      },
    },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("Type Client (CRM)"),
        options: CLIENT_CRM_TYPE_FILTER,
        values: filter.clientCRMTypes,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.clientCRMTypes;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, clientCRMTypes: value });
          }
        },
      },
    },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("Groupe Client (CRM)"),
        options: groups,
        values: filter.clientCRMGroup,
        action: (value) => {
          if (value.length == 0) {
            let cleanFilter = { ...filter };
            delete cleanFilter.clientCRMGroup;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, clientCRMGroup: value });
          }
        },
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("clientState"),
        options: DELETED_STATUS,
        values: filter.deletedStatus,
        action: handleSetDeletedStatus,
      },
    },
  ];

  if (
    [1, 11].includes(
      parsePreference(preferences.capacityPlanning).allowViewDataBy
    )
  ) {
    filters.unshift({
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        isDisabled:
          parsePreference(preferences.capacityPlanning).allowViewDataBy === 11,
        placeholder: _("Partner"),
        options: partnerOptions,
        values: filter.partner,
        action: handlePartnerChange,
      },
    });
  }

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          bg="#FFF"
          subBG="F8F9FA"
          button
          filter={filter}
          filters={filters}
          customAddOn={renderYearSelect()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default CapacityPlaningMatrixFilter;
