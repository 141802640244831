import styles from './index.module.scss';

export default function CardHeader({
  percentage,
  amount,
  title,
  isPositif,
  detailsBox,
  detailsBoxes,
}) {
  return (
    <div className={`${styles.card_header} ${isPositif ? styles.positif : styles.negatif}`}>
      <div className={styles.row_1}>
        <div className={styles.percentage}>{percentage}%</div>
        <div className={styles.amount}>
          {amount} DH
          <span className={styles.status_circle} />
        </div>
      </div>
      <div className={styles.row_2}>
        <p className={styles.title}>
          {title}
        </p>
      </div>
      <div className={styles.row_3}>
        {detailsBox != null && (
          <div className={`${styles.details_box} ${detailsBox?.isPositif ? styles.positif : styles.negatif}`}>
            <span className={styles.value}>{detailsBox.value}</span>
            <span className={styles.label}>{detailsBox.label}</span>
            <span className={styles.description}>{detailsBox.description}</span>
          </div>
        )}
        {detailsBoxes != null && (
          <div className={styles.details_boxes}>
            {detailsBoxes.map((box) => (
              <div className={`${styles.details_box} ${detailsBox?.isPositif ? styles.positif : styles.negatif}`}>
                <span className={styles.value}>{box.value}</span>
                <span className={styles.label}>{box.label}</span>
                <span className={styles.description}>{box.description}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
