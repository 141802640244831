import React from "react";
import _ from "../../../../i18n";
import "./ReportingCard.local.scss";
import { ExplorerIcon } from "../../Icons/ExplorerIcon";

const ReportingCard = ({ tab, onClick }) => {
  return (
      <div className="content-card" onClick={() => onClick(tab)}>
        <div className="content-card__header">
          <span className="content-card__label">{_(tab.label)}</span>

            <button 
              className="content-card__action-btn" 
              aria-label={`View ${_(tab.label)}`}
            >
              <ExplorerIcon className="content-card__action-btn__svg"/>
            </button>
        </div>
        {/* <div className="content-card__text-content">
          At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias   
        </div>. */}
      </div>
  );
};

export default ReportingCard;