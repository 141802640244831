import { Tooltip, Legend, Pie, PieChart, Cell } from 'recharts';
import styles from './index.module.scss';

const COLORS = ['#02AF8E', '#DE4848'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function CustomPieChart({data}) {
  return (
    <div className={styles.chart_container}>
      <div className={styles.legend_container}>
        <Legend
          align="left"
          verticalAlign="top"
          iconType="circle"
          iconSize={8}
          payload={[
            { value: "Dossiers respectant le ratio", color: '#02AF8E', type: 'circle' },
            { value: "Dossiers ne respectant pas le ratio", color: '#DE4848', type: 'circle' },
          ]}
          wrapperStyle={{
            marginTop: '-25px',
          }}
          className={styles.legend}
          formatter={(value) => (
            <span style={{
              color: '#29394D',
              fontSize: '12px',
              fontWeight: '500',
              marginLeft: '4px'
            }}>
              {value}
            </span>
          )}
        />
      </div>

    <PieChart width={550} height={220}>
      <Tooltip />
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
        fontSize={10}
        style={{ fontSize: '10px'}}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
    </div>
  );
}
