import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "../../i18n";
import {
  getUserNameForAvatar,
  generateFullName,
  formatAmount,
  convertDecimalToHoursMinutes,
  parsePreference,
} from "../../utils";
import {
  CheckIcon,
  BookMarkIcon,
  PullIcon,
  FlagIcon,
  SettingsIcon,
  ClipBoardIcon,
  AlertTriangleIcon,
  FolderIcon,
} from "../common/Icons";
import { getCorrectionCards } from "../../api";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { Tooltip } from "antd";
import styles from "./Item.module.scss";
import classNames from "classnames";
import DangerTriangleIcon from "../common/Icons/DangerTriangleIcon";

const Item = ({
  lng,
  item,
  key,
  responsables,
  preferences,
  year,
  isEntity,
  setCorrectionCardClient,
  setActiveAttachedClient,
  activeAttachedClient,
  loggedAs,
  spaceAs,
}) => {
  const active =
    item.departDates &&
    (item.departDates.depart_invoice ||
      item.departDates.depart_time_sheet ||
      item.departDates.communicated_departure)
      ? true
      : false;
  const renderAvatar = (avatarUrl, firstName, lastName) => {
    if (!avatarUrl && !firstName && !lastName) {
      return (
        <div className={styles.avatar_not_exist}>
          <p style={{ margin: "auto" }}>-</p>
        </div>
      );
    }
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className={styles.avatar_not_exist}>
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div className={styles.avatar_exist}>
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }

    return avatarDiv;
  };

  const [correctionCards, setCorrectionCards] = useState([]);
  const { token, navCommunity } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      const correctionCardClient = {
        ...item,
        responsables: responsables
          .filter((element) => ["MANAGER", "GE"].includes(element.role))
          .map((responsable) => {
            return {
              uid: responsable.uid,
              name: responsable.firstName + " " + responsable.lastName,
            };
          }),
      };
      // try {
      //   const organizationId = navCommunity ? navCommunity.id : null;
      //   const response = await getCorrectionCards(
      //     correctionCardClient.id,
      //     year,
      //     organizationId,
      //     token
      //   );

      //   if (
      //     response.data &&
      //     response.data.data &&
      //     response.data.data.length > 0
      //   ) {
      //     setCorrectionCards(response.data.data);
      //   }
      // } catch (error) {
      //   console.error("Error fetching correction cards:", error);
      // }
    };

    fetchData();
  }, []);

  return (
    <div
      key={key}
      className={classNames({
        [styles.item]: true,
        [styles.item_opened]: activeAttachedClient === item.id,
      })}
      style={{
        marginLeft: !isEntity && "83px",
        height: !isEntity && "100px",
        opacity: item.isDeleted && "0.5",
      }}
    >
      <div className={styles.item_infos}>
        <div className={styles.item_infos_header}>{item.name}</div>
        <div className={styles.item_infos_sub_header}>
          {item.uen ? `${item.uen} - ` : ""} {item.group}{" "}
        </div>
        <div className={styles.item_infos_sub_header}>
          {_("ID")} : {item.erp}
        </div>
      </div>
      {isEntity && (
        <Tooltip
          key={`entity-${key}`}
          placement="top"
          title={
            <div>
              <label>
                {_(item?.isEntityClient ? "hisAssociates" : "attachedTo")}
              </label>
              <ul>
                {item?.isEntityClient
                  ? item.attached.length > 0
                    ? item.attached.map((item) => <li>{item.name}</li>)
                    : _("none")
                  : item?.entityClient?.name}
              </ul>
            </div>
          }
        >
          <div className={styles.item_state}>
            {item.isEntityClient ? <PullIcon /> : <BookMarkIcon />}
            <div className={styles.item_state_label}>
              {_(item.isEntityClient ? "entity" : "attatched")}
            </div>
          </div>
        </Tooltip>
      )}
      <div
        className={classNames({
          [styles.item_progress]: true,
          [styles.bg_danger]: item.budget.hours.real > item.budget.hours.budget,
          [styles.bg_success]:
            item.budget.hours.real <= item.budget.hours.budget,
        })}
      >
        <div className={styles.item_progress_info}>
          <div>{_("hourBudget")}</div>
          <div>{convertDecimalToHoursMinutes(item.budget.hours.budget)}</div>
        </div>
        <div
          style={{
            height: "38px",
            display: "flex",
            marginTop: 5,
            backgroundColor: "transparent",
          }}
        >
          <div className={styles.item_progress_container}>
            <div
              className={styles.item_progress_bar}
              style={{
                width:
                  parseInt(item.budget.hours.real) >
                  parseInt(item.budget.hours.budget)
                    ? "100%"
                    : parseInt(item.budget.hours.budget) > 0 &&
                      parseInt(item.budget.hours.real) > 0
                    ? `${
                        (parseInt(item.budget.hours.real) * 100) /
                        parseInt(item.budget.hours.budget)
                      }%`
                    : 0,

                background:
                  parseFloat(item.budget.hours.real) >
                  parseFloat(item.budget.hours.budget)
                    ? "#EB5757"
                    : "#06D9B1",
              }}
            />
            <div
              className={styles.item_progress_index}
              style={{
                width:
                  parseInt(item.budget.hours.real) >
                  parseInt(item.budget.hours.budget)
                    ? "200%"
                    : parseInt(item.budget.hours.budget) > 0 &&
                      parseInt(item.budget.hours.real) > 0
                    ? `${
                        (parseInt(item.budget.hours.real) * 200) /
                        parseInt(item.budget.hours.budget)
                      }%`
                    : 0,
                color:
                  parseFloat(item.budget.hours.real) >
                  parseFloat(item.budget.hours.budget)
                    ? "#DE4848"
                    : "#02AF8E",
              }}
            >
              <TiArrowSortedDown />{" "}
              <div>
                {parseFloat(item.budget.hours.real) > 0
                  ? convertDecimalToHoursMinutes(item.budget.hours.real)
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames({
          [styles.item_progress]: true,
          [styles.bg_success]:
            parseFloat(item.budget.hours.real) > 0 &&
            parseFloat(item.budget.economic.real) /
              parseFloat(item.budget.hours.real) >
              80,
          [styles.bg_warning]:
            parseFloat(item.budget.hours.real) < 0 ||
            parseFloat(item.budget.economic.real) /
              parseFloat(item.budget.hours.real) <
              80,
        })}
      >
        <div className={styles.item_progress_info}>
          <div>{_("economicBudget")}</div>
          <div>
            {parseInt(item.budget.economic.budget) > 0
              ? formatAmount(item.budget.economic.budget)
              : 0}
          </div>
        </div>
        <div
          style={{
            height: "38px",
            display: "flex",
            marginTop: 5,
            backgroundColor: "transparent",
          }}
        >
          <div className={styles.item_progress_container}>
            <div
              className={styles.item_progress_bar}
              style={{
                width:
                  parseInt(item.budget.economic.real) >
                  parseInt(item.budget.economic.budget)
                    ? "100%"
                    : parseInt(item.budget.economic.budget) > 0 &&
                      parseInt(item.budget.economic.real) > 0
                    ? `${
                        (parseInt(item.budget.economic.real) * 100) /
                        parseInt(item.budget.economic.budget)
                      }%`
                    : 0,
                background:
                  parseFloat(item.budget.hours.real) > 0 &&
                  parseFloat(item.budget.economic.real) /
                    parseFloat(item.budget.hours.real) >
                    80
                    ? "#06D9B1"
                    : "#EA8124",
              }}
            />
            <div
              className={styles.item_progress_index}
              style={{
                width:
                  parseFloat(item.budget.economic.budget) > 0 &&
                  parseFloat(item.budget.economic.real) > 0
                    ? parseFloat(item.budget.economic.real) >
                      parseFloat(item.budget.economic.budget)
                      ? `inherit`
                      : `${
                          (parseFloat(item.budget.economic.real) * 200) /
                          parseFloat(item.budget.economic.budget)
                        }%`
                    : "fit-content",
                color:
                  parseFloat(item.budget.hours.real) > 0 &&
                  parseFloat(item.budget.economic.real) /
                    parseFloat(item.budget.hours.real) >
                    80
                    ? "#02AF8E"
                    : "#EA8124",
                alignItems:
                  parseFloat(item.budget.economic.real) >
                  parseFloat(item.budget.economic.budget)
                    ? "end"
                    : "center",
              }}
            >
              <TiArrowSortedDown />{" "}
              <div>
                {parseFloat(item.budget.economic.real) > 0
                  ? formatAmount(item.budget.economic.real)
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEntity && (
        <>
          <div className={styles.item_tags}>
            {item.budgetState === "BUDGET_COMPLETED" ? (
              <div className={`${styles.tag} ${styles.tag_success}`}>
                <CheckIcon fill="#02AF8E" /> {_("completedBudgetization")}
              </div>
            ) : (
              <div
                className={styles.pie_frame}
                style={
                  active ? { marginTop: "40px", marginBottom: "-20px" } : {}
                }
              >
                {active === false && (
                  <>
                    <div
                      className={styles.pie}
                      style={{
                        "--p": 100,
                        "--c": "rgba(109, 127, 146, 0.3)",
                        position: "relative",
                        top: 50,
                      }}
                    />
                    <div
                      className={styles.pie}
                      style={{
                        "--p": item.pourcentage,
                      }}
                    >
                      {`${item.pourcentage}%`}
                    </div>
                  </>
                )}
                <div className={`${styles.tag} ${styles.tag_danger}`}>
                  {item.budgetState === "UNAUTHORIZED_BUDGET" && (
                    <AlertTriangleIcon />
                  )}
                  {_(item.budgetState)}
                </div>
              </div>
            )}
            {item.departDates && item.departDates.communicated_departure && (
              <div className={`${styles.tag} ${styles.tag_danger}`}>
                <AlertTriangleIcon /> Client en partance
              </div>
            )}
            {item.leavingRiskObservation &&
              item.leavingRiskObservation !== "" && (
                <div className={`${styles.tag} ${styles.tag_warning}`}>
                  <DangerTriangleIcon fillColor="#bb9762" size={10} /> Client à
                  risque
                </div>
              )}
            {/* <div
              className={styles.infos_dates}
              style={{ display: active ? "" : "none" }}>
              <div className={styles.infos_dates_sub_header}>
                {_("departureCommuniquer")} :{" "}
                {item.departDates && item.departDates.communicated_departure
                  ? `${item.departDates.communicated_departure} `
                  : ""}
              </div>

              <div className={styles.infos_dates_sub_header}>
                {_("finFacturation")} :{" "}
                {item.departDates && item.departDates.depart_invoice
                  ? `${item.departDates.depart_invoice} `
                  : ""}
              </div>
              <div className={styles.infos_dates_sub_header}>
                {_("finPrestation")} :{" "}
                {item.departDates && item.departDates.depart_time_sheet
                  ? `${item.departDates.depart_time_sheet} `
                  : ""}
              </div>
            </div> */}
          </div>
        </>
      )}
      {isEntity && (
        <div className={styles.item_avatars}>
          {responsables.map((item, index) => (
            <Tooltip
              key={`role-${index}-${item.role}`}
              placement="top"
              title={_(item.role)}
            >
              <div className={styles.avatar} key={index}>
                {renderAvatar(item.avatar, item.firstName, item.lastName)}
              </div>
            </Tooltip>
          ))}
        </div>
      )}
      {isEntity && (
        <div className={styles.item_actions}>
          {item.attachedList.length > 0 && (
            <Tooltip
              key="tool-1"
              placement="top"
              title={_("Détails")}
              mouseLeaveDelay={0}
            >
              <div
                className={styles.action}
                onClick={() =>
                  setActiveAttachedClient(
                    activeAttachedClient !== item.id ? item.id : 0
                  )
                }
              >
                {activeAttachedClient !== item.id ? (
                  <TiArrowSortedDown />
                ) : (
                  <TiArrowSortedUp />
                )}
              </div>
            </Tooltip>
          )}
          {(loggedAs === "SUPERADMIN" ||
            spaceAs === "SUPERADMIN" ||
            loggedAs === "ADMIN" ||
            spaceAs === "ADMIN" ||
            loggedAs === "MANAGER" ||
            spaceAs === "MANAGER") &&
            preferences.financial &&
            parsePreference(preferences.financial).forfait.allowAccess && (
              <Fragment>
                <Tooltip
                  key="tool-1"
                  placement="top"
                  title={_("Analyse financière")}
                  mouseLeaveDelay={0}
                >
                  <Link to={`/${lng}/forfait/${item.id}`}>
                    <div className={styles.action}>
                      <FolderIcon fill="#18A0FB" />
                    </div>
                  </Link>
                </Tooltip>
              </Fragment>
            )}
          {(loggedAs === "SUPERADMIN" ||
            spaceAs === "SUPERADMIN" ||
            loggedAs === "ADMIN" ||
            spaceAs === "ADMIN" ||
            loggedAs === "MANAGER" ||
            spaceAs === "MANAGER") &&
            preferences &&
            (parsePreference(preferences.clients).allowUpdateBudgetConfig ||
              parsePreference(preferences.clients)
                .allowUpdateClientSettings) && (
              <Fragment>
                <Tooltip
                  key="tool-1"
                  placement="top"
                  title={_("manageBudget")}
                  mouseLeaveDelay={0}
                >
                  <Link to={`/${lng}/budget/${item.id}/${year}`}>
                    <div className={styles.action}>
                      <SettingsIcon />
                    </div>
                  </Link>
                </Tooltip>
              </Fragment>
            )}
          {correctionCards.length > 0 && (
            <>
              <Tooltip
                key="tool-1"
                placement="top"
                title={_("cor_card")}
                mouseLeaveDelay={0}
              >
                <div
                  className={styles.action}
                  onClick={() =>
                    setCorrectionCardClient({
                      ...item,
                      responsables: responsables
                        .filter((element) =>
                          ["MANAGER", "GE"].includes(element.role)
                        )
                        .map((responsable) => {
                          return {
                            uid: responsable.uid,
                            name:
                              responsable.firstName +
                              " " +
                              responsable.lastName,
                          };
                        }),
                    })
                  }
                >
                  <FlagIcon size={"15"} fill={"#18a0fb"} />
                </div>
              </Tooltip>
            </>
          )}
          <Tooltip
            key="tool-2"
            placement="top"
            title={_("clientForm")}
            mouseLeaveDelay={0}
          >
            <Link to={`/${lng}/fiche-client/${item.id}`}>
              <div className={styles.action}>
                <ClipBoardIcon />
              </div>
            </Link>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Item;
