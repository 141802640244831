import React from "react";

export const IconSend = ({ width = 26, className = {} }) => {
  const height = (width * 24) / 26;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 26 24`}
      fill="none"
      className={className}>
      <path
        d="M0.00228845 12.003C0.0341069 12.5386 0.389413 12.9893 0.90381 13.1431L10.4281 16.0227L10.7781 22.5879C10.794 22.8478 10.969 23.0758 11.2183 23.1606C11.2872 23.1872 11.3615 23.1978 11.4304 23.1978C11.616 23.1978 11.791 23.1235 11.9183 22.9803L15.2857 19.2523L20.0585 22.5561C20.2706 22.7046 20.5199 22.7841 20.7797 22.7841C21.379 22.7841 21.8987 22.3599 22.0259 21.7659L25.9873 1.57714C26.0351 1.34381 25.9449 1.09987 25.7646 0.95138C25.579 0.797591 25.3298 0.760469 25.107 0.850621L0.765931 10.7568C0.272745 10.9795 -0.02953 11.4674 0.00228845 12.003ZM11.9872 20.9599L11.7698 16.8182L14.2092 18.5045L11.9872 20.9599ZM20.7585 21.4637L12.0721 15.45L24.2532 3.66655L20.7585 21.4637ZM22.9169 3.14685L10.8736 14.7977L1.35987 11.9234L22.9169 3.14685Z"
        fill="currentColor"
      />
    </svg>
  );
};
