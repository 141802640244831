import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { getCollaboratorContributionByCategory } from "../../../api";
import _ from "../../../i18n";
import {
  setCategoryDefaultFilter,
  setSelectedEndDateFilter,
  setSelectedStartDateFilter,
} from "../../../store";
import {
  getMonthShortLabelObject,
  renderDurationDisplay,
  renderDurationMinutesDisplay,
} from "../../../utils";

import { Tooltip } from "antd";
import { Calendar } from "primereact/calendar";
import { Carousel } from "primereact/carousel";
import { useSearchParams } from "react-router-dom";
import SearchBox from "../../common/Filter/SearchBox";
import { AlertCircleIcon, CalendarIcon, IconClose } from "../../common/Icons";
import "./CollaboratorProductionTracking.local.scss";
import BalanceProgressionContent from "./EncodingProgression/BalanceProgressionContent";
import EncodingProgressionContent from "./EncodingProgression/EncodingProgressionContent";
import NonFacturableContent from "./EncodingProgression/NonFacturableContent";
import Progression6Content from "./EncodingProgression/Progression6Content";
import ProgressionCompensationContent from "./EncodingProgression/ProgressionCompensationContent";
import TVAProgressionContent from "./EncodingProgression/TVAProgressionContent";

const CollaboratorProductionTracking = ({
  id,
  year,
  lng,
  token,
  user,
  name,
  loggedAs,
  collaborator,
  organization,
}) => {
  const dispatch = useDispatch();
  const {
    categoryDefaultFilter,
    selectedStartDateFilter,
    selectedEndDateFilter,
  } = useSelector((state) => state.filters);
  const [searchParams] = useSearchParams();
  const activeCategory = JSON.parse(
    decodeURIComponent(searchParams.get("category")),
  );
  //Header
  const [filter, setFilter] = useState({
    filterTab: [
      {
        id: "MONTHLY",
        label: "monthly",
        subTab: getMonthShortLabelObject(lng),
      },
      {
        id: "TERMLY",
        label: "termly",
        subTab: [
          {
            id: 1,
            label: "1er trimestre (du 01/01 au 30/03)",
            disabled: false,
            start: "01-01",
            end: "03-30",
          },
          {
            id: 2,
            label: "2ème trimestre (du 01/04 au 30/06)",
            disabled: moment().quarter() < 2 && moment().year() == year,
            start: "04-01",
            end: "06-30",
          },
          {
            id: 3,
            label: "3ème trimestre (du 01/07 au 30/09)",
            disabled: moment().quarter() < 3 && moment().year() == year,
            start: "07-01",
            end: "09-30",
          },
          {
            id: 4,
            label: "4ème trimestre (du 01/10 au 30/12)",
            disabled: moment().quarter() < 4 && moment().year() == year,
            start: "10-01",
            end: "12-31",
          },
        ],
      },
      {
        id: "ANNUAL",
        label: "annual",
        start: year + "-01-01",
        end: year + "-12-31",
      },
    ],
    category: activeCategory || categoryDefaultFilter,
    period: null,
    subPeriod: null,
    start: selectedStartDateFilter,
    end: selectedEndDateFilter,
  });

  const [error, setError] = useState(null);
  const [categories, setCategories] = useState({});
  const balance_types = ["IPM", "ISOC"];
  const [balanceRefresh, setBalanceRefresh] = useState(false);

  let contributionByCategoryQuery = useQuery(
    [
      "contributionByCategoryQuery",
      token,
      year,
      filter.start,
      filter.end,
      filter.period,
      filter.subPeriod,
    ],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorContributionByCategory(
            id,
            organization,
            year,
            token,
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.start
                : year + "-" + filter.subPeriod.start
              : filter.start
              ? moment(filter.start).format("YYYY-MM-DD")
              : year+"-01-01",

            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.end
                : year + "-" + filter.subPeriod.end
              : filter.end
              ? moment(filter.end).format("YYYY-MM-DD")
              : year+"-12-31",
          );

          const data = response.data.data;
          const currentCategory = data.real.filter(
            (item) => item.id == filter.category.id,
          );

          setCategories(data);
          if (!currentCategory.length) {
            const category = {
              id: data.real[0]?.id,
              label: data.real[0]?.label,
            };
            setFilter({
              ...filter,
              category,
            });
            dispatch(setCategoryDefaultFilter(category));
          }
        } catch (e) {
          console.log(e);
          setCategories({});
          return null;
        }
      }
    },
  );

  useEffect(() => {
    if (year && year !== new Date().getFullYear().toString()) {
      setFilter({
        ...filter,
        start: null,
        end: null,
        period: {
          id: "ANNUAL",
          label: "annual",
          start: year + "-01-01",
          end: year + "-12-31",
        },
      });
    }
  }, [year]);

  const renderFilterFrame = () => {
    return (
      <div className="filter-frame">
        <div className="principal-line">
          <div className="interval-date-frame">
            <div
              className={`date-range ${
                filter.start || filter.end ? "date-range_fill" : ""
              }`}>
              <CalendarIcon fill="#6D7F92" />
              <span className="p-float-label start-date">
                <Calendar
                  className={filter.start ? "calendar-fill" : ""}
                  dateFormat="dd/mm/yy"
                  inputId="start_date"
                  value={filter.start}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      start: e.value,
                      end: filter.end
                        ? filter.end
                        : moment(e.value).add(15, "days")._d,
                      period: null,
                      subPeriod: null,
                    });
                    dispatch(setSelectedStartDateFilter(e.value));
                    dispatch(
                      setSelectedEndDateFilter(
                        filter.end
                          ? filter.end
                          : moment(e.value).add(15, "days")._d,
                      ),
                    );
                  }}
                />
                <label htmlFor="start_date">
                  {filter.start ? "" : _("startDate")}
                </label>
              </span>
              <span className="p-float-label">
                <Calendar
                  className={filter.start ? "calendar-fill" : ""}
                  dateFormat="dd/mm/yy"
                  inputId="end_date"
                  value={filter.end}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      end: e.value,
                      start: filter.start
                        ? filter.start
                        : moment(e.value).subtract(15, "days")._d,
                      period: null,
                      subPeriod: null,
                    });
                    dispatch(
                      setSelectedStartDateFilter(
                        filter.start
                          ? filter.start
                          : moment(e.value).subtract(15, "days")._d,
                      ),
                    );
                    dispatch(setSelectedEndDateFilter(e.value));
                  }}
                />
                <label htmlFor="end_date">
                  {filter.end ? "" : _("endDate")}
                </label>
              </span>
              {(filter.start || filter.end) && (
                <div
                  className="date-range_action"
                  onClick={() => {
                    setFilter({
                      ...filter,
                      start: null,
                      end: null,
                      period: {
                        id: "ANNUAL",
                        label: "annual",
                        start: year + "-01-01",
                        end: year + "-12-31",
                      },
                    });
                    dispatch(setSelectedStartDateFilter(null));
                    dispatch(setSelectedEndDateFilter(null));
                  }}>
                  <IconClose fill="#E1E4E8" size={6} />
                </div>
              )}
            </div>
          </div>
          <div className="mini-tabs-frame">
            {filter.filterTab.map((item) => (
              <div
                className={
                  filter.period &&
                  item.id === filter.period.id &&
                  "mini-tabs-frame_active"
                }
                onClick={() => {
                  setFilter({
                    ...filter,
                    period: item,
                    subPeriod:
                      "MONTHLY" === item.id
                        ? item.subTab[moment().month()]
                        : "TERMLY" === item.id
                        ? item.subTab[moment().quarter() - 1]
                        : null,
                    start: null,
                    end: null,
                  });
                }}>
                {_(item.label)}
              </div>
            ))}
          </div>
          <SearchBox
            placeholder={`${_("search")}...`}
            onChange={(item) => setFilter({ ...filter, keyWord: item })}
            filter={filter}
          />
        </div>
        {filter.period && "ANNUAL" !== filter.period.id && (
          <div className="additional-line">
            {filter.period.subTab.map((tab) => {
              return (
                <div
                  className={`tag ${
                    filter.subPeriod.id === tab.id ? "active" : ""
                  } ${
                    ((tab.disabled &&
                      filter.period &&
                      filter.period.id === "TERMLY") ||
                      (filter.period &&
                        filter.period.id === "MONTHLY" &&
                        moment().year() == year &&
                        tab.id > moment().month() + 1)) &&
                    "disabled"
                  }`}
                  onClick={() =>
                    !tab.disabled
                      ? setFilter({
                          ...filter,
                          subPeriod: tab,
                          start: null,
                          end: null,
                        })
                      : null
                  }>
                  {tab.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderCategoryCarousel = () => {
    let total = 0;
    categories.real &&
      categories.real.length > 0 &&
      categories.real.forEach((category) => {
        total += parseFloat(category.duration_minutes);
      });
    return (
      <Fragment>
        {categories.real && categories.real.length > 0 && (
          <div className="production-frame_tabs">
            <Carousel
              value={categories.real}
              numVisible={4}
              numScroll={1}
              responsiveOptions={[]}
              itemTemplate={(category, i) => {
                return (
                  <Tooltip
                    key={`tab-${i}`}
                    placement="top"
                    title={`${parseFloat(
                      parseFloat(
                        (category.duration_minutes * 100) / total,
                      ).toFixed(2),
                    )}%`}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}>
                      <div
                        key={i}
                        className={`tab_badge ${
                          filter.category.id === category.id
                            ? "tab_badge_active"
                            : ""
                        }`}
                        onClick={() => {
                          // if (["15"].includes(category.label.split(".")[0])) {
                          //   return;
                          // }
                          setFilter({ ...filter, category });
                          dispatch(setCategoryDefaultFilter(category));
                          setBalanceRefresh(true);
                        }}>
                        <div>
                          {category.label.substr(0, 24)}
                          {category.label.length > 25 ? "." : ""}
                        </div>
                      </div>
                      <div
                        className={`tab_tag ${
                          filter.category.id === category.id
                            ? "tab_tag_active"
                            : ""
                        }`}>
                        {renderDurationMinutesDisplay(
                          category.duration_minutes,
                        )}
                      </div>
                    </div>
                  </Tooltip>
                );
              }}
            />
            <div className="total">
              <div>
                <div>{_("total")}</div>
                <div
                  style={{
                    display: "flex",
                    margin: "5px 0px 3px 5px",
                    backgroundColor: "transparent",
                  }}>
                  <div
                    className="item_progress_container"
                    style={{ width: 80 }}>
                    <div
                      className="item_progress_bar"
                      style={{
                        width: `${
                          categories.theoric > 0
                            ? ((total / 60) * 100) / categories.theoric
                            : 0
                        }%`,
                        background: "#06D9B1",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div>{renderDurationDisplay(total)}</div>
                <Tooltip
                  key={`tab-20}`}
                  placement="bottom"
                  title={`${_("toAttributTheoricaly")} : ${parseFloat(
                    categories.theoric,
                  )}h`}>
                  <div>
                    <AlertCircleIcon />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const renderCategoryProductionTraking = () => {
    if (!filter.category.label) {
      return (
        <div className="no_data">
          <div className="title">{_("wip")}</div>
          <div className="subTitle">{_("wipWorkshop")}</div>
        </div>
      );
    }
    switch (filter.category.label.split(".")[0]) {
      case "04":
        return (
          <EncodingProgressionContent
            id={id}
            token={token}
            lng={lng}
            year={year}
            loggedAs={loggedAs}
            collaborator={collaborator}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "05":
        return (
          <TVAProgressionContent
            id={id}
            token={token}
            lng={lng}
            year={year}
            loggedAs={loggedAs}
            zoneId={collaborator.zoneId}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "06":
        return (
          <Progression6Content
            id={id}
            token={token}
            lng={lng}
            year={year}
            loggedAs={loggedAs}
            collaborator={collaborator}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "08":
        return (
          <BalanceProgressionContent
            id={id}
            token={token}
            lng={lng}
            year={year}
            loggedAs={loggedAs}
            zoneId={collaborator.zoneId}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "14":
      case "02":
        return (
          <ProgressionCompensationContent
            id={id}
            token={token}
            lng={lng}
            name={name}
            year={year}
            loggedAs={loggedAs}
            collaborator={collaborator}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      case "15":
        return (
          <NonFacturableContent
            id={id}
            token={token}
            lng={lng}
            name={name}
            year={year}
            loggedAs={loggedAs}
            collaborator={collaborator}
            organization={organization}
            filter={filter}
            user={user}
          />
        );
      default:
        return (
          <div className="no_data">
            <div className="title">{_("wip")}</div>
            <div className="subTitle">{_("wipWorkshop")}</div>
          </div>
        );
    }
  };

  const renderErrorMessage = () => {
    switch (error.code) {
      case 170047:
        return (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("collaboratorNotConfigured")}</div>
          </div>
        );
      case 170148:
        return (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("calendarNotConfigured")}</div>
          </div>
        );

      default:
        return (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("serverError")}</div>
          </div>
        );
    }
  };
  return (
    <Fragment>
      {renderFilterFrame()}
      {renderCategoryCarousel()}
      {contributionByCategoryQuery.isFetched && error ? (
        renderErrorMessage()
      ) : (
        <div className="production-frame">
          {renderCategoryProductionTraking()}
        </div>
      )}
    </Fragment>
  );
};

export default CollaboratorProductionTracking;
