import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { PAGINATION_CURRENT_PAGE, PAGINATION_START, SUB_TABS, SUB_TABS_COLUMNS } from "./constants";
import { DatePicker, Input, Switch, Tag, Tooltip } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import PencilIcon from "../../common/Icons/PencilIcon";
import './index.scss';
import _ from './../../../i18n';
import lodash from 'lodash';
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getNewClients, saveNewClients } from "../../../api";
import { toast } from "react-toastify";
import { AlertTriangleIcon, CalendarJanuary, CalendarSeptember, CheckIcon, ClipBoardIcon, IconClose, IconPencil } from "../../common/Icons";
import { formatAmount, parsePreference } from "../../../utils";
import AnimatedLoadingIcon from "../../common/Icons/AnimatedLoadingIcon";
import { Link } from "react-router-dom";
import CroissanceFilters from "../Components/CroissanceFilters/CroissanceFilters";

const MODAL_TEXT_COMMENT_TYPE = 1;
const MODAL_TEXT_1_MONTH_TYPE = 2;
const MODAL_TEXT_9_MONTHS_TYPE = 3;

export default function CroissanceClientsInPage({
  changeSelectedYear,
  collaborator,
  preferences
}) {
  const { token, navCommunity, user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const [selectedSubTab, setSelectedSubTab] = useState(SUB_TABS[0]);
  const [bindedSubTabsColumns, setBindedSubTabsColumns] = useState(SUB_TABS_COLUMNS);
  const [showTextModal, setShowTextModal] = useState(false);
  const [selectedTextType, setSelectedTextType] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowsToUpdateVolumetry, setSelectedRowsToUpdateVolumetry] = useState([]);
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [modifiedRows, setModifiedRows] = useState([]);
  const modifiedRowsRef = useRef(modifiedRows);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentPage, setCurrentPage] = useState(PAGINATION_CURRENT_PAGE);
  const [pageStart, setPageStart] = useState(PAGINATION_START);
  const [isSaving, setIsSaving] = useState({
    comments: false,
    rowData: false
  });
  const [totalsData, setTotalsData] = useState([{
    'id': 0,
    'month': '-',
    'clientName': 'Total',
    'status': '--',
    'manager': '--',
    'lmProposition': 0,
    'isMandatesActivated': '--',
    'oneShot': 0,
    'billingType': '--',
    'selectedPlan': '--',
    'feeYearN': 0,
    'feeYearN1': 0,
    'rdv': '--',
    'isPresentationDone': '--',
    'appDemo': '--',
    'oneMonth': '--',
    'nineMonths': '--',
    'volumetry': '--',
  }]);

  const [filters, setFilters] = useState({
    keyWord: "",
    clientGroup: [],
    bu: parsePreference(preferences.financial).croissance.allowViewDataBy === 100 &&
      collaborator
        ? [collaborator.bu]
        : [],
    zone: parsePreference(preferences.financial).croissance.allowViewDataBy === 101 &&
      collaborator
        ? collaborator.zoneId
        : null,
    partner:
      parsePreference(preferences.financial).croissance.allowViewDataBy === 11
        ? user?.id
        : null,
    collaborator: [10, 100, 101, 110].includes(
      parsePreference(preferences.financial).croissance.allowViewDataBy
    ) && collaborator
      ? Number(collaborator.id)
      : null,
    role: null,
    pageSize: 50,
    year: new Date().getFullYear().toString(),
  });

  let getNewClientsQuery = useQuery(
    ["getNewClients", token, navCommunity, currentPage, pageStart, filters],
    async () => {
      const allowViewDataBy = parsePreference(
        preferences.financial
      ).croissance.allowViewDataBy;
      if (
        [10, 110].includes(allowViewDataBy) &&
        (!filters || !filters.collaborator)
      ) {
        setIsLoadingData(false);
        setData([]);
        setDisplayedData([]);
        setTotalDataCount(0);
        return [];
      } else if (token && navCommunity) {
        setIsLoadingData(true);
        try {
          const response = await getNewClients({
            token,
            organizationId: navCommunity.id,
            start: pageStart,
            filters
          });
          const data = response.data.data.items
          const totals = response.data.data.totals;

          setData(data);
          setDisplayedData(data);
          setTotalDataCount(response.data.data.totalCount);
          setTotalsData(prevState => {
            const newVal = {...prevState[0], ...totals};
            return [newVal];
          });
        } catch (e) {
          console.error("Error while fetching new clients", {e})
          return null;
        } finally {
          setIsLoadingData(false);
        }
      }
    }
  );

  useEffect(() => {
    // bind body templates to columns
    const BINDED_SUB_TABS_COLUMNS = {};

    BINDED_SUB_TABS_COLUMNS.admin = SUB_TABS_COLUMNS.admin.map((col) => {
      if (col.field === 'month') {
        col.body = monthColumnBodyTemplate;
      } else if (col.field === 'clientName') {
        col.body = clientNameColumnBodyTemplate;
      } else if (col.field === 'status') {
        col.body = statusColumnBodyTemplate;
      } else  if (col.field === 'manager') {
        col.body = managerColumnBodyTemplate;
      } else if (col.field === 'billingType' || col.field === 'selectedPlan') {
        col.body = textColumnBodyTemplate;
      } else if (col.field === 'lmProposition') {
        col.body = checkUncheckColumnBodyTemplate;
      } else if (col.field === 'isMandatesActivated') {
        col.body = switchColumnsBodyTemplate;
      } else if (col.field === 'oneShot' || col.field === 'feeYearN' || col.field === 'feeYearN1') {
        col.body = amountColumnBodyTemplate;
      }
      return col;
    });

    BINDED_SUB_TABS_COLUMNS.actions = SUB_TABS_COLUMNS.actions.map((col) => {
      if (col.field === 'rdv') {
        col.body = rdvColumnBodyTemplate;
      } else if (col.field === 'isPresentationDone' || col.field === 'appDemo') {
        col.body = switchColumnsBodyTemplate;
      } else if (col.field === 'oneMonth' || col.field === 'nineMonths') {
        col.body = inputFieldColumnBodyTemplate
      } else if (col.field === 'volumetry') {
        col.body = volumetryProgressionColumnBodyTemplate;
      } else if (col.field === 'actions') {
        col.body = actionsColumnBodyTemplate;
      }
      return col;
    });
    setBindedSubTabsColumns(BINDED_SUB_TABS_COLUMNS);
  }, [selectedRow]);

  useEffect(() => {
    modifiedRowsRef.current = modifiedRows;
  }, [modifiedRows]);

  const renderSelectedColumns = () => {
    return bindedSubTabsColumns[selectedSubTab.key];
  }

  const monthColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span>#</span>);

    let month = rowData.registrationDate.split("-")[1];

    month = month.startsWith('0') ? month.slice(1) : month;

    return (<span>{month}</span>);
  }

  const clientNameColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span className="total-title">Total</span>);

    const clientName = rowData.clientName
    const clientGroup = rowData.clientGroup;
    const managerBU = rowData.managerBU;;
    const clientErpId = rowData.clientErpId;

    if (!clientName) return (
      <div className="client-name-cell">
        <div className="client-name-cell-data">
          <span className="client-name-text">
            ID : {clientErpId}
          </span>
          <div className="client-name-info">
            {clientGroup} - {managerBU}
          </div>
        </div>
      </div>
    );

    return (
      <div className="client-name-cell">
        <div className="client-name-cell-data">
          <span className="client-name-text">{clientName}</span>
          <div className="client-name-info">
            {clientGroup} - {managerBU}
          </div>
        </div>
        <div className="client-name-icons">
          <Link
            to={`/${language}/fiche-client/${rowData.clientId}`}
          >
            <Tooltip placement="topRight" title="Consulter la fiche client">
              <ClipBoardIcon
                size={18}
                className="icon"
                fill="#29394D"
              />
            </Tooltip>
          </Link>
        </div>
      </div>
    )
  }

  const statusColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span>--</span>);

    const active =
    rowData.departDates &&
    (rowData.departDates.depart_invoice ||
      rowData.departDates.depart_time_sheet ||
      rowData.departDates.communicated_departure)
      ? true
      : false;

    return (
      <div className="status-cell">
        <Tag className="status_tag">
          {_(rowData.status)}
        </Tag>
        {rowData.statusBudgetHoraire && (
          rowData.statusBudgetHoraire === "BUDGET_COMPLETED" ? (
            <div className="tag tag_success">
              <CheckIcon fill="#02AF8E" /> {_("completedBudgetization")}
            </div>
          ) : (
            <div
              className="pie_frame"
              style={
                active ? { marginTop: "40px", marginBottom: "-20px" } : {}
              }
            >
              {active === false && (
                <>
                  <div
                    className="pie"
                    style={{
                      "--p": 100,
                      "--c": "rgba(109, 127, 146, 0.3)",
                      position: "relative",
                      top: 50,
                    }}
                  />
                  <div
                    className="pie"
                    style={{
                      "--p": rowData.budgetPourcentage,
                    }}
                  >
                    {`${rowData.budgetPourcentage}%`}
                  </div>
                </>
              )}
              <div className="tag tag_danger">
                {rowData.statusBudgetHoraire === "UNAUTHORIZED_BUDGET" && (
                  <AlertTriangleIcon />
                )}
                {_(rowData.statusBudgetHoraire)}
              </div>
            </div>
          )
        )}
      </div>
    )
  }

  const managerColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) return (<span>--</span>);

    if (!rowData.managerName) return (<span>--</span>);

    return (
      <div>
        <Tooltip title={rowData.managerName}>
          {/* {renderAvatar(rowData.managerAvatarUrl, rowData.managerName)} */}
          {rowData.managerName}
        </Tooltip>
      </div>
    )
  }

  const rdvColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);

    if (rowData.rdv && rowData.id != selectedRow?.id) {
      const [year, month, day] = rowData.rdv.split("-");
      const date = new Date(year, month - 1, day);

      return (
        <div className="cell-input-field rdv-input-field">
          <Input
            className="input"
            value={moment(date, "YYYY-MM-DD").format("DD/MM")}
            name={args.field}
            disabled={true}
          />
          <span className="edit-icon">
            <IconPencil
              size={12}
              fill="#707f92"
              onClick={() => {
                setSelectedRow(rowData);
              }}
            />
          </span>
        </div>
      );
    }

    let date = null
    const keys = args.field.split('.');
    const hasValue = keys.reduce((obj, key) => obj && obj[key], rowData) &&
    keys.reduce((obj, key) => obj && obj[key], rowData) !== 'x';

    if (hasValue) {
      const [year, month, day] = rowData.rdv.split("-");
      date = new Date(year, month - 1, day);
    }

    return (
      <DatePicker
        value={!hasValue ? null : moment(date, "DD/MM")}
        placeholder="Selectionner"
        onChange={(date) => {
          const year = date._d.getFullYear();
          const month = date._d.getMonth() + 1;
          const day = date._d.getDate();
          handleRowModification(rowData, 'rdv', `${year}-${month}-${day}`);
        }}
        format={"DD/MM"}
        picker="day"
        className="cell-datepicker-input"
      />
    )
  }

  const switchColumnsBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);

    let classes = 'cell-switch-input';
    const keys = args.field.split('.');
    const isChecked = keys.reduce((obj, key) => obj && obj[key], rowData) === true || keys.reduce((obj, key) => obj && obj[key], rowData) === 'x';

    if (isChecked) {
      classes += ' cell-switch-input-checked';
    }

    return (
      <Switch
        size="small"
        checked={isChecked}
        className={classes}
        onChange={(checked) => {
          handleRowModification(rowData, args.field, checked);
        }}
      />
    )
  }

  const inputFieldColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);
    const fieldValue = rowData[args.field]

    return (
      <div className="cell-input-field">
        <Input
          className="input"
          value={fieldValue}
          name={args.field}
          onChange={(e) => {
            handleRowModification(rowData, args.field, e.target.value);
          }}
        />
      </div>
    )
  }

  const checkUncheckColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) {
      switch(args.field) {
        case "orderSent": return (<span>{rowData.orderSent}</span>);
        case "orderSigned": return (<span>{rowData.orderSigned}</span>);
        case "governance": return (<span>{rowData.governance}</span>);
        case "lmProposition": return (<span>{rowData.lmProposition}</span>);
        default: return (<span>--</span>);
      }
    }

    if (rowData[args.field]) {
      return <div className="centered-cell">
        <CheckIcon width="20" height="12" fill="#15B392" />
      </div>
    } else {
      return <div className="centered-cell">
        <IconClose size="11" fill="#C7253E" />
      </div>
    }
  }

  const amountColumnBodyTemplate = (rowData, args) => {
    if (['feeYearN', 'feeYearN1'].includes(args.field)) {
      return (<div>{formatAmount(rowData[args.field], true)}</div>);
    }
    return (<div>{formatAmount(rowData[args.field])}</div>);
  }

  const textColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) {
      return (<span>--</span>);
    }

    if (lodash.isEmpty(rowData[args.field])) {
      return (<span>--</span>);
    }

    return (<div>{rowData[args.field]}</div>);
  }

  const volumetryProgressionColumnBodyTemplate = (rowData) => {
    if (rowData.id === 0) {
      return <span>--</span>;
    }
    // const { real, budgeted } = rowData.volumetry;
    const budgetedPurchases = rowData.budgetedPurchases;
    const budgetedSales = rowData.budgetedSales;
    const budgetedFinancialTransactions = rowData.budgetedFinancialTransactions;
    const realPurchases = rowData.realPurchases;
    const realSales = rowData.realSales;
    const realFinancialTransactions = rowData.realFinancialTransactions;

    return (
      <div className="cell-volumetry">
        <div className="volumetry-grid">
          <div className="volumetry-header">
            <div className="volumetry-label"></div>
            <div className="volumetry-value">Budg</div>
            <div className="volumetry-value">Réal</div>
          </div>

          <div className="volumetry-row">
            <div className="volumetry-label">Achats</div>
            <div className="volumetry-value">
              <Input
                className="volumetry-input"
                value={lodash.isEmpty(budgetedPurchases) === false ? parseInt(budgetedPurchases) : null}
                name="budgetedPurchases"
                disabled
                onChange={(e) => {
                  if (!lodash.isNaN(e.target.value)) {
                    handleRowModification(rowData, 'budgetedPurchases', e.target.value);
                  }
                }}
              />
            </div>
            <div className="volumetry-value">
              {realPurchases != null ? parseInt(realPurchases) : '-'}
            </div>
          </div>

          <div className="volumetry-row">
            <div className="volumetry-label">Ventes</div>
            <div className="volumetry-value">
              <Input
                className="volumetry-input"
                value={lodash.isEmpty(budgetedSales) === false ? parseInt(budgetedSales) : null}
                name="budgetedSales"
                disabled
                onChange={(e) => {
                  if (!lodash.isNaN(e.target.value)) {
                    handleRowModification(rowData, 'budgetedSales', e.target.value);
                  }
                }}
              />
            </div>
            <div className="volumetry-value">
              {realSales != null ? parseInt(realSales) : '-'}
            </div>
          </div>

          <div className="volumetry-row">
            <div className="volumetry-label">Financiers</div>
            <div className="volumetry-value">
              <Input
                className="volumetry-input"
                value={lodash.isEmpty(budgetedFinancialTransactions) === false ? parseInt(budgetedFinancialTransactions) : null}
                name="budgetedFinancialTransactions"
                disabled
                onChange={(e) => {
                  if (!lodash.isNaN(e.target.value)) {
                    handleRowModification(rowData, 'budgetedFinancialTransactions', e.target.value);
                  }
                }}
              />
            </div>
            <div className="volumetry-value">
              {realFinancialTransactions != null ? parseInt(realFinancialTransactions) : '-'}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const actionsColumnBodyTemplate = (rowData, args) => {
    if (rowData.id === 0) return (<span>--</span>);
    return (
      <div className="edit-texts-icons">
        <Tooltip title="1 mois remarques">
          <span
            className="display-info-button"
            onClick={() => handleShowTextModal(true, rowData, MODAL_TEXT_1_MONTH_TYPE)}
          >
            <CalendarJanuary size={22} stroke="#3c4e64" strokeWidth="2" />
          </span>
        </Tooltip>

        <Tooltip title="9 mois remarques">
          <span
            className="display-info-button"
            onClick={() => handleShowTextModal(true, rowData, MODAL_TEXT_9_MONTHS_TYPE)}
          >
            <CalendarSeptember size={22} stroke="#3c4e64" strokeWidth="2" />
          </span>
        </Tooltip>

        <Tooltip title="Commentaires">
          <span
            className="display-info-button"
            onClick={() => handleShowTextModal(true, rowData, MODAL_TEXT_COMMENT_TYPE)}
          >
            <IconPencil size={17} fill="#3c4e64" />
          </span>
        </Tooltip>
      </div>
    );
  }

  const handleShowTextModal = (isVisible, rowData, type = MODAL_TEXT_COMMENT_TYPE) => {
    if (rowData) {
      setSelectedRow(rowData);
    } else {
      setSelectedRow(null);
    }
    setSelectedTextType(type);
    setShowTextModal(isVisible);
  }

  const handleRowModification = (rowData, modifiedPropertyName, modifiedPropertyValue) => {
    const keys = modifiedPropertyName.split('.');
    const updatedRowData = {
      ...rowData,
      [modifiedPropertyName]: modifiedPropertyValue
    };

    // Set the new value in the cloned rowData
    setValue({ ...rowData }, keys, modifiedPropertyValue);

    // Update the modifiedRows state

    setModifiedRows(prevState => {
      // Check if the row already exists in modifiedRows
      const modifiedRowIndex = prevState.findIndex(i => i.id === rowData.id);

      if (modifiedRowIndex > -1) {
        // Update existing modified row
        const updatedModifiedRows = [...prevState];
        updatedModifiedRows[modifiedRowIndex] = { ...updatedModifiedRows[modifiedRowIndex], ...updatedRowData };
        return updatedModifiedRows;
      } else {
        // Add new modified row
        return [...prevState, updatedRowData];
      }
    });

    // Update the displayedData state
    setDisplayedData(prevState =>
      prevState.map(i =>
        i.id === rowData.id ? { ...rowData, [modifiedPropertyName]: modifiedPropertyValue } : i
      )
    );
  }

  const setValue = (obj, keys, value) => {
    keys.reduce((accumulator, key, index) => {
      // If we're at the last key, set the value
      if (index === keys.length - 1) {
        accumulator[key] = value;
      } else {
        // If the key doesn't exist, create an empty object
        if (!accumulator[key]) {
          accumulator[key] = {};
        }
        return accumulator[key]; // Move to the next nested object
      }
      return accumulator;
    }, obj);
  };

  const handleSaveModifiedRows = () => {
    setIsSaving(prevState => ({...prevState, rowData: true}));

    saveNewClients({token, organizationId: navCommunity.id, items: modifiedRows}).then((res) => {
      if (res.status === 200) {
        setModifiedRows([]);
        setSelectedRow(null);
        toast.success("Les données ont été enregistrées avec succès");
        getNewClientsQuery.refetch();
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement des données");
      }
    }).catch(e => {
      toast.error("Une erreur est survenue lors de l'enregistrement des données");
      console.error("Error saving data : ", e);
    }).finally(() => {
      setIsSaving(prevState => ({...prevState, rowData: false}));
    });
  }

  const handleSaveComment = () => {
    const { id, clientId, comments, oneMonth, nineMonths } = selectedRow;

    setIsSaving(prevState => ({...prevState, comments: true}));
    saveNewClients({
      token,
      organizationId: navCommunity.id,
      items: [{id, clientId, comments, oneMonth, nineMonths}]
    }).then((res) => {
      if (res.status === 200) {
        setSelectedRow(null);
        toast.success("Commentaire enregistré avec succès.");
        getNewClientsQuery.refetch();
      } else {
        toast.error("Échec de l'enregistrement du commentaire. Veuillez réessayer.");
      }
    }).catch(e => {
      toast.error("Échec de l'enregistrement du commentaire. Veuillez réessayer.");
      console.error("Error saving comment : ", e);
    }).finally(() => {
      setIsSaving(prevState => ({...prevState, comments: false}));
    });
  }

  useEffect(() => {
    setCurrentPage(0);
    setPageStart(0);
  }, [filters.pageSize]);

  useEffect(() => {
    changeSelectedYear(filters.year);
  }, [filters.year]);

  return (
    <div className="clients-in-container">
       <div className="datatable-header">
        <div className="datatable-header-row">
          <div className="filters" style={{display: "flex", gap: "8px"}}>
            {collaborator && (
              <CroissanceFilters
                filterFormIsVisible={false}
                filter={filters}
                nbResult={totalDataCount}
                updateFilter={setFilters}
                fetching={getNewClientsQuery.isFetching}
                collaborator={collaborator}
                preferences={preferences}
              />
            )}
          </div>
        </div>
        <div className="datatable-header-row">
          <p className="data-count">
            Nombre de dossiers: <span>{totalDataCount}</span>
          </p>
          <div className="display-info-buttons">
            {SUB_TABS.map((tab) => (
              <button
                key={tab.key}
                className={`display-info-button ${selectedSubTab.key === tab.key ? 'active' : ''}`}
                onClick={() => setSelectedSubTab(tab)}>
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      </div>
      <DataTable
        value={displayedData}
        frozenValue={totalsData}
        lazy
        paginator
        totalRecords={totalDataCount}
        first={currentPage * filters.pageSize + 1}
        rows={filters.pageSize}
        onPage={(e) => {
          setCurrentPage(e.page);
          setPageStart(e.page * filters.pageSize);
        }}
        scrollable
        tableClassName="clients-in-datatable"
        loading={isLoadingData}
        loadingIcon={
          <div className="ripple_loading" style={{alignItems: 'center'}}>
            <img src={"/img/Ripple-1s-150px.svg"} alt="" width={80} height={80} />
          </div>
        }
      >
        {
          renderSelectedColumns().map(col => (
            <Column
              className="datatable-column"
              key={col.field}
              field={col.field}
              header={col.header}
              style={{width: col.style?.width ?? 'auto'}}
              sortable={col.sortable ?? false}
              body={col.body ?? null}
              alignHeader={col?.alignHeader ?? 'left'}
              resizeable={col?.resizeable ?? false}
              align={col?.align ?? 'center'}
            />
          ))
        }
      </DataTable>

      {/* save/cancel buttons */}
      {modifiedRows.length > 0 && (
        <div className="action-buttons">
          <Button
            label="Annuler"
            severity="secondary"
            size="small"
            onClick={() => {
              setModifiedRows([]);
              setDisplayedData(lodash.cloneDeep(data));
            }}
            disabled={isSaving.rowData}
          />
          <Button
            size="small"
            label={
              isSaving.rowData ? (
                <AnimatedLoadingIcon color="#ffffff" />
              ) : 'Enregistrer'
            }
            disabled={isSaving.rowData}
            severity="success"
            onClick={handleSaveModifiedRows}
          />
        </div>
      )}

      {/* comments modal dialog */}
      {showTextModal && selectedRow && (
        <Dialog
          visible={showTextModal && selectedRow}
          onHide={() => handleShowTextModal(false)}
          className="text-modal-dialog"
          header={
            selectedTextType === MODAL_TEXT_COMMENT_TYPE ? "Commentaire" :
            selectedTextType === MODAL_TEXT_1_MONTH_TYPE ? "1 mois remarques" :
            selectedTextType === MODAL_TEXT_9_MONTHS_TYPE ? "9 mois remarques" :
            "Commentaire"
          }
          footer={
            <div>
              <Button
                size="small"
                severity="success"
                onClick={handleSaveComment}
                disabled={isSaving.comments}
              >
                {
                  isSaving.comments ? (
                    <AnimatedLoadingIcon color="#ffffff" />
                  ):
                  'Enregistrer'
                }
              </Button>
            </div>
          }
        >
          <span className="p-float-label" style={{marginTop: '20px'}}>
            <InputTextarea
              id="comments"
              className="textarea"
              rows={5}
              value={
                selectedTextType === MODAL_TEXT_COMMENT_TYPE ? selectedRow.comments :
                selectedTextType === MODAL_TEXT_1_MONTH_TYPE ? selectedRow.oneMonth :
                selectedTextType === MODAL_TEXT_9_MONTHS_TYPE ? selectedRow.nineMonths :
                selectedRow.comments
              }
              disabled={isSaving.comments}
              onChange={(e) => {
                const value = e.target.value;
                if (selectedTextType === MODAL_TEXT_COMMENT_TYPE) {
                  setSelectedRow(prevState => ({...prevState, comments: value}));
                } else if (selectedTextType === MODAL_TEXT_1_MONTH_TYPE) {
                  setSelectedRow(prevState => ({...prevState, oneMonth: value}));
                } else if (selectedTextType === MODAL_TEXT_9_MONTHS_TYPE) {
                  setSelectedRow(prevState => ({...prevState, nineMonths: value}));
                }
              }}
            />
            <label htmlFor="comments">{
              selectedTextType === MODAL_TEXT_COMMENT_TYPE ? "Commentaire" :
              selectedTextType === MODAL_TEXT_1_MONTH_TYPE ? "1 mois remarques" :
              selectedTextType === MODAL_TEXT_9_MONTHS_TYPE ? "9 mois remarques" :
              "Commentaire"
            }</label>
          </span>
        </Dialog>
      )}
    </div>
  )
}
