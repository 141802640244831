import React, { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getCollaboratorsAbsence } from "../../../api";
import _ from "../../../i18n";
import {
  UserIcon,
  AttachmentIcon,
  AlertCircleIcon,
  CalendarIcon,
} from "../../common/Icons";
import { Tooltip } from "antd";
import {
  getMonths,
  getTemporaryAnnexeFileLink,
  parsePreference,
} from "../../../utils";
import ModalComponent from "../../common/Modal";

import AbsenceMatrixFilter from "./AbsenceMatrixFilter";
import "./AbsenceMatrix.local.scss";
import GroupFilter from "../GroupFilter";
import { useSelector } from "react-redux";
const AbsenceMatrix = ({
  token,
  navCommunity,
  parentFilter,
  language,
  collaborator,
  preferences,
}) => {
  const { user, loggedAs } = useSelector((state) => state.auth);
  const allowViewDataBy = parsePreference(preferences).vacation.allowViewDataBy;
  const [absences, setAbsences] = useState([]);
  const [filter, setFilter] = useState({
    ...parentFilter,
    keyWord: "",
    year: new Date().getFullYear().toString(),
    bu: allowViewDataBy === 100 && collaborator ? collaborator.bu : null,
    zone: allowViewDataBy === 101 && collaborator ? collaborator.zoneId : null,
    partner: loggedAs == "MANAGER" ? user?.id : null,
    collaborators:
      [10, 110].includes(allowViewDataBy) && collaborator
        ? [collaborator.id]
        : [],
  });
  const [columns, setColumns] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [isAttachmentModalVisible, setIsAttachmentModalVisible] =
    useState(null);
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  let absencesQuery = useQuery(
    ["getCollaboratorsAbsenceQuery", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsAbsence(
            navCommunity.id,
            token,
            {
              ...filter,
              bu: filter.bu ? filter.bu : null,
              zone: filter.zone ? filter.zone : null,
              months: filter.months,
            }
          );
          setAbsences(Object.values(response.data.data.data));
          let newColumns = [
            { key: "collaborator", label: _("collaborator"), width: "25%" },
          ];
          response.data.data.types.forEach((type) => {
            newColumns.push({
              key: type.id,
              label: type.label,
              abb: type.abbreviation,
              width: "200px",
            });
          });
          setColumns(newColumns);
          setSelectedCollaborator(null);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderFetchingLines = () => {
    let cardFetching = [];
    cardFetching.push(
      <div key={0} className="line_fetching_reporting header_fetching">
        <div className="infos"></div>

        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
      </div>
    );
    for (let i = 1; i <= 10; i++) {
      cardFetching.push(
        <div key={i} className="line_fetching">
          <div className="infos">
            <div className="infos_header gradiant" />
            <div className="infos_sub_header gradiant" />
          </div>

          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
        </div>
      );
    }
    return cardFetching;
  };

  const displayAttachments = (e, id, attachments) => {
    setIsAttachmentModalVisible(id);
    setSelectedAttachments(attachments);
    e.stopPropagation();
  };

  const handleCloseAttachmentModal = () => {
    setIsAttachmentModalVisible(null);
    setSelectedAttachments([]);
  };

  const renderAbsencesDetails = (id, data, column) => {
    return (
      <div className="list-detail">
        {Object.keys(data).map((month) => {
          return (
            <div>
              <div>{month}</div> :{" "}
              <div className="min-list">
                {data[month].map((list) => (
                  <div style={{ display: "flex" }}>
                    <span>
                      •
                      {list.days
                        .map((day) => day.split("T")[0].split("-")[2])
                        .join("-")}
                    </span>
                    {list.attachments ? (
                      <div
                        onClick={(e) =>
                          displayAttachments(
                            e,
                            id,
                            JSON.parse(list.attachments)
                          )
                        }
                        className="tag"
                      >
                        <AttachmentIcon size={11} fill="#6D7F92" />
                      </div>
                    ) : column.abb === "MAL" ? (
                      <Tooltip
                        key={`tab-${id}`}
                        placement="top"
                        title={_("needCertificat")}
                      >
                        <div className="tag">
                          <AlertCircleIcon size={11} fill="#f5222d" />
                        </div>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    {list.backup ? (
                      <Tooltip
                        key={`tab-${id}`}
                        placement="top"
                        title={`${_("backup")} : ${list.backup}`}
                      >
                        <div className="tag">
                          <UserIcon size={11} fill="#6D7F92" />
                        </div>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleModalAttachments = () => {
    return (
      <ModalComponent
        width={700}
        isOpen={isAttachmentModalVisible}
        close={() => handleCloseAttachmentModal()}
      >
        {selectedAttachments.map((file, index) => {
          return (
            <div className={"file_view"}>
              <iframe
                id={`annexe-${index}`}
                style={{ width: "100%" }}
                src={
                  getTemporaryAnnexeFileLink(
                    isAttachmentModalVisible,
                    file,
                    token
                  ) || ""
                }
              />
            </div>
          );
        })}
      </ModalComponent>
    );
  };

  return (
    <div className="matrix_container">
      {/* <AbsenceMatrixFilter
        filterFormIsVisible={false}
        filter={filter}
        lng={language}
        nbResult={absences.length}
        updateFilter={setFilter}
        fetching={absencesQuery.isFetching}
      /> */}
      <GroupFilter
        extraFilters={[
          {
            type: "SELECT",
            options: {
              isSimple: false,
              isMulti: true,
              isClearable: true,
              placeholder: _("months"),
              options: getMonths(language),
              values: filter.months,
              action: (value) => {
                if (value == null) {
                  let cleanFilter = { ...filter };
                  delete cleanFilter.months;
                  setFilter({ ...cleanFilter });
                } else {
                  setFilter({ ...filter, months: value });
                }
              },
            },
          },
        ]}
        filter={filter}
        nbResult={absences.length}
        updateFilter={setFilter}
        allowViewDataBy={parsePreference(preferences).vacation.allowViewDataBy}
        collaborator={collaborator}
        preferences={preferences}
        subPreference="vacation"
      />
      <div className="result_label">
        {_("nbrResult")} : {absences.length}
      </div>
      <div className="medium-11">
        {absencesQuery.isFetching ? (
          renderFetchingLines()
        ) : absences.length ? (
          <Fragment>
            <div className="table-container">
              <div className="table">
                <div className="table_header">
                  {columns.map((item, i) => {
                    return (
                      <div
                        className="table_header_cellule"
                        key={item.key}
                        style={{ minWidth: item.width, maxWidth: item.width }}
                      >
                        {item.key === "collaborator" ? (
                          _(item.label)
                        ) : (
                          <Fragment>
                            <span>{item.abb}</span>
                            <span>({item.label})</span>
                          </Fragment>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="table_body">
                  {absences.map((collaborator, i) => {
                    if (collaborator.collabTotalPeriod === 0) {
                      return;
                    }
                    return (
                      <div
                        className={`row ${
                          collaborator.id === selectedCollaborator && "active"
                        }`}
                        onClick={() => {
                          if (collaborator.id === selectedCollaborator) {
                            setSelectedCollaborator(null);
                          } else {
                            setSelectedCollaborator(collaborator.id);
                          }
                        }}
                      >
                        <div
                          className="table_body_cellule"
                          style={{ minWidth: "25%" }}
                        >
                          <span>{collaborator.name}</span>
                          <span>{`${collaborator.collabTotalPeriod}/${collaborator.collabTotalYear}`}</span>
                          <Link
                            to={`/${language}/fiche-collaborator/${collaborator.id}?tab=CALENDAR`}
                          >
                            <span className="tag">
                              <CalendarIcon size={11} fill="#6D7F92" />
                            </span>
                          </Link>
                        </div>
                        {columns.map((column, i) => {
                          if (column.key === "collaborator") {
                            return;
                          }
                          return (
                            <div
                              className="table_body_cellule"
                              style={{
                                minWidth: column.width,
                                maxWidth: column.width,
                              }}
                            >
                              {collaborator.data[column.key]
                                ? selectedCollaborator === collaborator.id
                                  ? renderAbsencesDetails(
                                      collaborator.id,
                                      collaborator.data[column.key].details,
                                      column
                                    )
                                  : `${
                                      collaborator.data[column.key].totalPeriod
                                    }/${
                                      collaborator.data[column.key].totalYear
                                    }`
                                : "-"}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {handleModalAttachments()}
          </Fragment>
        ) : (
          <div className="no_data">
            <div className="title">{_("noDataFound")}</div>
            <div className="subTitle">{_("noReportingsFound")}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AbsenceMatrix;
