import styles from './index.module.scss';

export default function CardContainer({ children, className = null }) {
  const classes = `${styles.card_container} ${className ? className : ''}`;

  return (
    <div className={classes}>
      {children}
    </div>
  );
}
