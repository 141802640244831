import { useMsal } from '@azure/msal-react';
import { useEffect, useRef, useState } from 'react';
import { loginRequest } from '../config/msal-config';

/**
 * Custom hook is used when we want to detect the click outside an element and do something when that happened.
 *
 * @param callback the callback to run when the outside click happened.
 * @param excludeElementsRefs elements refs that we want to exclude from the click detection.
 * @returns a reference to use it in the target element.
 */
export function useOuterClick(
  callback,
  excludeElementsRefs,
) {
  const innerRef = useRef(null);
  const callbackRef = useRef();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  }, [callback, excludeElementsRefs]);

  useEffect(() => {
    // read most recent callback and innerRef dom node from refs
    function handleClick(e) {
      if (innerRef?.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        if (
          !excludeElementsRefs ||
          !excludeElementsRefs.find((r) => r.current?.contains(e.target))
        ) {
          callbackRef.current(e);
        }
      }
    }

    document.addEventListener('click', handleClick, { capture: true }); // capture the click in the inner components
    return () => document.removeEventListener('click', handleClick);
  }, []); // no need for callback + innerRef dep

  return innerRef; // return ref; client can omit `useRef`
}


/**
 * This hook is used to check if the user is authenticated using the token.
 * This hook checks also the expiration date of the token which doesn't happen in the useIsAuthenticated hook of package.
 * @returns true if the user is authenticated, false otherwise.
 */
export const useIsAuthenticatedWithToken = () => {
  const { instance, accounts } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {

      if (accounts.length === 0) {
        setIsAuthenticated(false);
        return;
      }

      const tokenRequest = {
        scopes: loginRequest.scopes,
        account: accounts[0],
      };

      try {
        const response = await instance.acquireTokenSilent(tokenRequest);
        const expiresOn = response.expiresOn;
        const currentTime = new Date();

        // check token expiration date
        if (expiresOn <= currentTime) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, [accounts, instance]);

  return isAuthenticated;
};
