import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { TiArrowSortedDown } from "react-icons/ti";

import PageHeader from "../common/PageHeader";
import NotAuthorized from "../common/NotAuthorized";

import {
  getOrganisationPeriodSetting,
  runAdminIsInvoiceSynchronisation,
  runAdminIsTaskSynchronisation,
  runAdminIsTvaBalancesSynchronisation,
  runAdminIsBalancesSynchronisation,
  runAdminIsClientRolesSynchronisation,
  runAdminIsClientEntitiesSynchronisation,
  syncDebtors,
  syncInvoices,
  runAdminIsInvoicePlanningSynchronisation,
  sendTimeSheetReminders,
} from "../../api";

import { renderTeamYears } from "../../utils";
import styles from "./Synchronisation.module.scss";
import classNames from "classnames";
import _ from "../../i18n";
import AdminISLogo from "./assets/admin-is-logo.jpg";
import RecovrLogo from "./assets/recovr-logo.svg";
import { IconSend } from "../common/Icons";
import { Tooltip } from "antd";

const intergrations = [
  {
    id: "#admin-is",
    name: "Syneton",
    src: AdminISLogo,
  },
  {
    id: "#recovr",
    name: "Recovr",
    src: RecovrLogo,
  },
];

const Synchronisation = () => {
  const ref = useRef();
  const auth = useSelector((state) => state.auth);
  const syncTypes = [
    "tasks",
    "invoices",
    "invoiceLines",
    "tva",
    "IPM",
    "ISOC",
    "roles",
    "entities",
    "recovr_ID",
    "recovr_invoice",
  ];
  const [selected, setSelected] = useState("#admin-is");

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear(),
    month: null,
  });
  const [typeId, setTypeId] = useState(1);
  const [activeSyncType, setActiveSyncType] = useState(null);
  const [synchronisations, setSynchronisations] = useState({
    taskSync: false,
    invoiceSync: false,
    fixAmountSync: false,
    tvaSync: false,
    ipmSync: false,
    rolesSync: false,
    entitiesSync: false,
    recovrIDSync: false,
    recovrInvoiceSync: false,
  });
  const synchronisationOptions = [
    { id: 1, label: "TIME_SHEET" },
    { id: 2, label: "FACTURE" },
    { id: 3, label: "BALANCE ISOC" },
    { id: 4, label: "BALANCE IPM" },
    { id: 5, label: "RIC DECLARATION TVA" },
    { id: 6, label: "ROLES DES CLIENTS" },
    { id: 7, label: "RELATION ENTITÉ CLIENT" },
    { id: 8, label: "FACTURATION PLANNING" },
  ];

  const RECOVR_SynchronisationOptions = [
    {
      id: 1,
      label: "Identification des dossiers",
      highlightText:
        "ID Recovr, lé référence externe et le portail de paiement",
      description: "Synchroniser l'identification des dossiers dans Recovr",
    },
    {
      id: 2,
      label: "FACTURE",
      highlightText: "Paiements et Encours",
      description: "Synchroniser toutes les factures existantes dans Recovr",
    },
  ];

  const monthsOptions = Array.from({ length: 12 }, (_, i) => ({
    id: `m${i + 1}`,
    label: new Date(0, i)
      .toLocaleString("fr", { month: "long" })
      .replace(/^\w/, (c) => c.toUpperCase()),
  }));

  const handleMonthChange = (option) => {
    setFilter({ ...filter, month: option });
  };

  // const fetchPeriodSettings = async () => {
  //   if (auth.token && auth.navCommunity) {
  //     try {
  //       const response = await getOrganisationPeriodSetting(
  //         auth.navCommunity.id,
  //         filter.year,
  //         auth.token
  //       );
  //       const data = response.data.data;
  //       const synchronisations = syncTypes.reduce((acc, type) => {
  //         acc[`${type}Sync`] = {
  //           status: data?.synchroDetails?.synchronization?.is_running?.[type],
  //           last_update:
  //             data?.synchroDetails?.synchronization?.last_update?.[type],
  //           progress: data?.synchroDetails?.synchronization?.progress?.[type],
  //         };
  //         return acc;
  //       }, {});
  //       setSynchronisations(synchronisations);
  //     } catch (e) {
  //       toast.error(_("error_loading_data"));
  //     }
  //   }
  // };

  const fetchPeriodSettings = async () => {
    if (auth.token && auth.navCommunity) {
      try {
        const response = await getOrganisationPeriodSetting(
          auth.navCommunity.id,
          new Date().getFullYear(),
          auth.token
        );
        const data = response.data.data;
        const syncDetails = data?.synchroDetails?.synchronization || {};

        const updatedSync = Object.keys(syncDetails.is_running || {}).reduce(
          (acc, type) => {
            acc[type] = {
              status: syncDetails.is_running[type],
              last_update: syncDetails.last_update?.[type],
              progress: syncDetails.progress?.[type] || 0,
            };
            return acc;
          },
          {}
        );

        const activeType = Object.keys(updatedSync).find(
          (key) => updatedSync[key].status
        );
        setActiveSyncType(activeType || null);

        setSynchronisations(updatedSync);
      } catch (e) {
        toast.error(_("error_loading_data"));
      }
    }
  };

  useEffect(() => {
    fetchPeriodSettings();
    const intervalId = setInterval(fetchPeriodSettings, 20000);
    return () => clearInterval(intervalId);
  }, [auth.token, auth.navCommunity, filter.year]);

  const handleRunSynchronisation = async () => {
    const payload = {
      token: auth.token,
      organization: auth.navCommunity?.id,
      year: filter.year,
      type: typeId === 3 ? "ISOC" : typeId === 4 ? "IPM" : null,
      ...(filter.month?.id && { period: filter.month.id }),
    };

    const syncTypes = {
      1: { key: "taskSync", action: runAdminIsTaskSynchronisation },
      2: { key: "invoiceSync", action: runAdminIsInvoiceSynchronisation },
      3: { key: "isocSync", action: runAdminIsBalancesSynchronisation },
      4: { key: "ipmSync", action: runAdminIsBalancesSynchronisation },
      5: { key: "tvaSync", action: runAdminIsTvaBalancesSynchronisation },
      6: { key: "rolesSync", action: runAdminIsClientRolesSynchronisation },
      7: {
        key: "entitiesSync",
        action: runAdminIsClientEntitiesSynchronisation,
      },
      8: {
        key: "fixAmountSync",
        action: runAdminIsInvoicePlanningSynchronisation,
      },
    };

    if (!syncTypes[typeId]) return;

    try {
      const { key, action } = syncTypes[typeId];

      setSynchronisations((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], status: true },
      }));

      await action(payload);

      toast.success(_("successfully_added"));
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.[0]?.message || _("error");
      toast.error(errorMessage);
    }
  };

  const handleRunRecovrSynchronisation = async () => {
    const { token, navCommunity } = auth;
    if (typeId === 1) {
      try {
        setSynchronisations((prevState) => ({
          ...prevState,
          recovrIDSync: true,
        }));
        await syncDebtors(token, navCommunity.id);
        toast.success(_("successfully_added"));
      } catch (e) {
        toast.error(_("error"));
        console.error("Recovr >> Debtors - Err: ", e);
        return;
      }
    } else if (typeId === 2) {
      try {
        setSynchronisations((prevState) => ({
          ...prevState,
          recovrInvoiceSync: true,
        }));
        await syncInvoices(token, navCommunity.id);
        toast.success(_("successfully_added"));
      } catch (e) {
        toast.error(_("error"));
        console.error("Recovr >> Invoices - Err: ", e);
        return;
      }
    }
  };

  const handleSendTimeSheetReminders = async () => {
    const { token, navCommunity } = auth;
    const payload = {
      token: token,
      organization: navCommunity?.id,
    };
    try {
      await sendTimeSheetReminders(payload);
      toast.success(_("successfully_sent"));
    } catch (e) {
      toast.error(_("error"));
      console.error("TimeSheet >> Reminders", e);
    }
  };

  const renderYearSelect = () =>
    selected == "#admin-is" ? (
      <div
        ref={ref}
        className={classNames("customed_select", {
          customed_select_opened: yearDivIsVisible,
        })}
        style={{ background: "#6d7f92", color: "#ffffff" }}
      >
        <div
          className="customed_select_header"
          style={{ height: "100%" }}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className="actions">
            <TiArrowSortedDown />
          </div>
        </div>
        {yearDivIsVisible && (
          <div className="customed_select_body">
            {renderTeamYears().map((year, index) => (
              <div
                key={index}
                className="customed_select_body_option"
                onClick={() => setFilter({ ...filter, year })}
              >
                {year}
              </div>
            ))}
          </div>
        )}
      </div>
    ) : null;
  const formatDate = (dateString) => {
    if (!dateString) return "Date inconnue";
    const date = new Date(dateString);
    return (
      date.toLocaleDateString("fr-FR", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      }) +
      " à " +
      date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })
    );
  };

  const renderSynchronisationSection = () => {
    const syncStatus = [
      { id: 1, label: "Tâches", isRunning: synchronisations.tasks },
      { id: 2, label: "Factures", isRunning: synchronisations.invoices },
      { id: 3, label: "ISOC", isRunning: synchronisations.ISOC },
      { id: 4, label: "IPM", isRunning: synchronisations.IPM },
      { id: 5, label: "TVA", isRunning: synchronisations.tva },
      { id: 6, label: "Rôles", isRunning: synchronisations.roles },
      { id: 7, label: "Rôles", isRunning: synchronisations.entities },
      {
        id: 8,
        label: "Facture Planning",
        isRunning: synchronisations.fixAmountSync,
      },
    ];

    const recovrSyncStatus = [
      {
        id: 1,
        label: "Identification des dossiers",
        isRunning: synchronisations.recovrIDSync,
      },
      {
        id: 2,
        label: "Factures",
        isRunning: synchronisations.recovrInvoiceSync,
      },
    ];

    let status = selected == "#admin-is" ? syncStatus : recovrSyncStatus;

    const currentSync = status.find((sync) => sync.id === typeId);

    if (currentSync && currentSync.isRunning?.status) {
      return (
        <div className={styles.synchronisation_container}>
          <div
            className={classNames(
              styles.progress_bar,
              styles.gradiant_is_runing_synchro
            )}
          >
            {console.log("currentSync.isRunning=======", currentSync.isRunning)}
            {currentSync.isRunning?.progress === 0
              ? "Récupération des données"
              : currentSync.isRunning?.progress < 100
              ? currentSync.isRunning?.progress + "%"
              : "CLeaning data"}
          </div>
        </div>
      );
    }
    if (activeSyncType) {
      return (
        <div className={styles.synchronisation_container}>
          <div
            className={classNames(
              styles.progress_bar,
              styles.gradiant_is_runing_synchro
            )}
          >
            {"Synchronisation des " + _(activeSyncType) + " est en cours..."}
          </div>
          <div className={styles.last_update}>
            Dernière mise à jour :{" "}
            {formatDate(currentSync.isRunning?.last_update)}
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={styles.params}>
          {renderYearSelect()}
          {selected == "#admin-is" && (typeId === 1 || typeId === 2 || typeId === 8) && (
            <div className={styles.months}>
              <span>Sélectionner le mois souhaité : </span>
              <Select
                options={monthsOptions}
                value={
                  monthsOptions.find(
                    (option) => option.id === filter.month?.id
                  ) || null
                }
                onChange={(option) => setFilter({ ...filter, month: option })}
                getOptionLabel={(e) => `${e.label}`}
                getOptionValue={(e) => e.id}
                placeholder="Choisir un mois"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: "200px",
                    minWidth: "200px",
                  }),
                  menu: (base) => ({
                    ...base,
                    width: "200px",
                  }),
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.footer_actions}>
          <button
            className={styles.button_next}
            onClick={
              selected == "#admin-is"
                ? () => handleRunSynchronisation()
                : () => handleRunRecovrSynchronisation()
            }
          >
            Lancer
          </button>
        </div>
        <div className={styles.last_update}>
          Dernière mise à jour :{" "}
          {formatDate(currentSync.isRunning?.last_update)}
        </div>
      </>
    );
  };

  // const renderSynchronisationSection = () => {
  //   const syncStatus = [
  //     { id: 1, label: "Tâches", ...synchronisations.taskSync },
  //     { id: 2, label: "invoices", ...synchronisations.invoiceSync },
  //     { id: 3, label: "ISOC", ...synchronisations.isocSync },
  //     { id: 4, label: "IPM", ...synchronisations.ipmSync },
  //     { id: 5, label: "TVA", ...synchronisations.tvaSync },
  //     { id: 6, label: "Rôles", ...synchronisations.rolesSync },
  //     { id: 7, label: "Entités", ...synchronisations.entitiesSync },
  //     { id: 8, label: "Facture Planning", ...synchronisations.invoiceLineSync },
  //   ];

  //   const recovrSyncStatus = [
  //     {
  //       id: 1,
  //       label: "Identification des dossiers",
  //       ...synchronisations.recovrIDSync,
  //     },
  //     { id: 2, label: "Factures", ...synchronisations.recovrInvoiceSync },
  //   ];

  //   let status = selected === "#admin-is" ? syncStatus : recovrSyncStatus;
  //   const currentSync = status.find((sync) => sync.id === typeId);

  //   const isAnySyncRunning = status.some((sync) => sync.status);

  //   return (
  //     <>
  //     {console.log("currentSync?.status=========",currentSync, currentSync?.status)}
  //       {currentSync?.status && (
  //         <div
  //           className={classNames(
  //             styles.progress_bar,
  //             styles.gradiant_is_runing_synchro
  //           )}
  //         >
  //           Synchronisation {currentSync.label} en cours...
  //           <br />
  //           {currentSync.progress === 0
  //             ? "Récupération des données..."
  //             : `Progression : ${currentSync.progress || 0}%`}
  //         </div>
  //       )}

  //       <div className={styles.params}>
  //         {renderYearSelect()}
  //         {selected === "#admin-is" && (typeId === 1 || typeId === 2) && (
  //           <div className={styles.months}>
  //             <span>Sélectionner le mois souhaité : </span>
  //             <Select
  //               options={monthsOptions}
  //               value={
  //                 monthsOptions.find(
  //                   (option) => option.id === filter.month?.id
  //                 ) || null
  //               }
  //               onChange={(option) => setFilter({ ...filter, month: option })}
  //               getOptionLabel={(e) => `${e.label}`}
  //               getOptionValue={(e) => e.id}
  //               placeholder="Choisir un mois"
  //               styles={{
  //                 control: (base) => ({
  //                   ...base,
  //                   width: "200px",
  //                   minWidth: "200px",
  //                 }),
  //                 menu: (base) => ({ ...base, width: "200px" }),
  //               }}
  //             />
  //           </div>
  //         )}
  //       </div>

  //       <div className={styles.footer_actions}>
  //         <button
  //           className={styles.button_next}
  //           onClick={
  //             selected === "#admin-is"
  //               ? () => handleRunSynchronisation()
  //               : () => handleRunRecovrSynchronisation()
  //           }
  //         >
  //           Lancer
  //         </button>
  //         {selected === "#admin-is" && typeId === 1 && (
  //           <button
  //             className={styles.ts_reminder_btn}
  //             onClick={() => handleSendTimeSheetReminders()}
  //           >
  //             <span>{_("sendTS")}</span>
  //             <IconSend className={styles.ts_reminder_btn_icon} />
  //           </button>
  //         )}
  //       </div>

  //       <div>
  //         Dernière mise à jour :{" "}
  //         {currentSync?.last_update
  //           ? new Date(currentSync.last_update).toLocaleString()
  //           : "Aucune"}
  //       </div>
  //     </>
  //   );
  // };

  const preferences = auth.user?.userOrganizations?.find(
    (org) => org.id === auth.navCommunity?.id
  )?.authorisation?.preferences;

  if (preferences?.allowAccessModelSetting === "0") {
    return <NotAuthorized />;
  }

  const tabs =
    selected == "#admin-is"
      ? synchronisationOptions
      : RECOVR_SynchronisationOptions;

  return (
    <div>
      <PageHeader header={_("gestion des synchronisations")} />
      <div className={styles.page_content}>
        <div className={classNames(styles.content)}>
          <div className={styles.bloc}>
            <div className={styles.title}>Synchronisation</div>
            <div
              style={{
                display: "flex",
                gap: 15,
                alignItems: "center",
                padding: "10px 0",
              }}
            >
              {intergrations.map((int) => (
                <div
                  key={int.id}
                  className={`${styles.content_card} ${
                    selected == int.id && styles.content_card_active
                  }`}
                  onClick={() => {
                    setTypeId(1);
                    setSelected(int.id);
                  }}
                >
                  <img src={int.src} alt="admin-is" height={50} />
                  <span>{int.name}</span>
                </div>
              ))}
            </div>
            <div className={classNames(styles.content, "grid-x")}>
              <div className={styles.client_types}>
                <div className={styles.sub_title}>Type de synchronisation</div>
                {tabs.map((item) => (
                  <div
                    key={item.id}
                    className={classNames(styles.types_labels, {
                      [styles.selected_type]: typeId === item.id,
                    })}
                    onClick={() => setTypeId(item.id)}
                  >
                    <span className={styles.type_label}>{item.label}</span>
                    <span className={styles.type_icon}>{">"}</span>
                  </div>
                ))}
              </div>
              <div className={styles.synchronisation_section}>
                <div className={styles.synchronisation_header}>
                  <div className={styles.sub_title}>
                    <strong>
                      {tabs.find((opt) => opt.id === typeId)?.label || ""}
                    </strong>
                    <span>
                      {tabs.find((opt) => opt.id === typeId)?.highlightText ||
                        ""}
                    </span>
                  </div>
                  {selected == "#admin-is" && typeId === 1 && (
                    <button
                      className={styles.ts_reminder_btn}
                      onClick={() => handleSendTimeSheetReminders()}
                    >
                      <span>{_("sendTS")}</span>
                      <IconSend className={styles.ts_reminder_btn_icon} />
                    </button>
                  )}
                </div>
                <div className={styles.description}>
                  {selected == "#admin-is"
                    ? "Veuillez sélectionner les paramètres de synchronisation."
                    : tabs.find((opt) => opt.id === typeId)?.description || ""}
                </div>
                {renderSynchronisationSection()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Synchronisation;
// import React, { useRef, useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
// import Select from "react-select";
// import { TiArrowSortedDown } from "react-icons/ti";
// import classNames from "classnames";
// import _ from "../../i18n";
// import styles from "./Synchronisation.module.scss";

// import {
//   getOrganisationPeriodSetting,
//   runAdminIsInvoiceSynchronisation,
//   runAdminIsTaskSynchronisation,
//   runAdminIsTvaBalancesSynchronisation,
//   runAdminIsBalancesSynchronisation,
//   runAdminIsClientRolesSynchronisation,
//   runAdminIsClientEntitiesSynchronisation,
//   syncDebtors,
//   syncInvoices,
//   runAdminIsInvoiceLinesSynchronisation,
//   sendTimeSheetReminders,
// } from "../../api";

// const Synchronisation = () => {
//   const auth = useSelector((state) => state.auth);
//   const [typeId, setTypeId] = useState(1);
//   const [synchronisations, setSynchronisations] = useState({});
//   const [activeSyncType, setActiveSyncType] = useState(null);

//   const fetchPeriodSettings = async () => {
//     if (auth.token && auth.navCommunity) {
//       try {
//         const response = await getOrganisationPeriodSetting(auth.navCommunity.id, new Date().getFullYear(), auth.token);
//         const data = response.data.data;
//         const syncDetails = data?.synchroDetails?.synchronization || {};

//         const updatedSync = Object.keys(syncDetails.is_running || {}).reduce((acc, type) => {
//           acc[type] = {
//             status: syncDetails.is_running[type],
//             last_update: syncDetails.last_update?.[type],
//             progress: syncDetails.progress?.[type] || 0,
//           };
//           return acc;
//         }, {});

//         const activeType = Object.keys(updatedSync).find((key) => updatedSync[key].status);
//         setActiveSyncType(activeType || null);

//         setSynchronisations(updatedSync);
//       } catch (e) {
//         toast.error(_("error_loading_data"));
//       }
//     }
//   };

//   useEffect(() => {
//     fetchPeriodSettings();
//     const intervalId = setInterval(fetchPeriodSettings, 20000);
//     return () => clearInterval(intervalId);
//   }, [auth.token, auth.navCommunity]);

//   return (
//     <div>
//       {activeSyncType ? (
//         <div className={classNames(styles.progress_bar, styles.gradiant_is_runing_synchro)}>
//           Synchronisation en cours ({activeSyncType})...
//           <br />
//           {synchronisations[activeSyncType]?.progress === 0 ? "Récupération des données..." : `Progression : ${synchronisations[activeSyncType]?.progress}%`}
//         </div>
//       ) : (
//         <button onClick={() => toast.info("Démarrer la synchronisation")}>Lancer la synchronisation</button>
//       )}
//     </div>
//   );
// };

// export default Synchronisation;
