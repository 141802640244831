import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import './CroissanceFilters.scss';
import _ from "../../../../i18n";
import { CLIENT_GROUP_CRM_FILTER, COLLABORATOR_ROLE_FILTER } from "../../../../config";
import { useOnClickOutside, renderTeamYears, parsePreference, viewDataBy } from "../../../../utils";
import { getBU, getCollaboratorsSuggestions, getZonesSuggestions } from "../../../../api";
import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../../../common/Filter/TTPFilterHorizontalBar";
import { getPartners } from "../../../../api/partners";

const CroissanceFilters = ({
  filter,
  nbResult,
  updateFilter,
  resetFilter,
  collaborator,
  preferences,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);

  useEffect(() => {
    if (filter.partner) {
      const options = collaborators.filter(
        (c) => c.partner == filter.partner
      );
      setCollaboratorOptions(options);
    } else {
      setCollaboratorOptions(collaborators);
    }
  }, [filter.partner, collaborators]);

  let collaboratorsQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data;
          let list = viewDataBy(preferences.financial, data, collaborator, "croissance");
          data = list
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
                partner: t?.partner?.value,
                isActif: t.isActif,
              };
            });
          setCollaborators(list.map((c) => ({
              value: c.id,
              label: c.name,
              partner: c?.partner?.value,
              isActif: c.isActif,
            }),
          ));
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let buQuery = useQuery(["businessUnitsQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getBU({token, organizationId: navCommunity.id});
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setBuOptions(data);
      } catch (e) {
        setBuOptions([]);
        return null;
      }
    }
  });

  let zonesQuery = useQuery(["zonesQuery", navCommunity], async () => {
      if (token) {
        try {
          const response = await getZonesSuggestions(token, navCommunity.id);
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.label,
              };
            });
          setZoneOptions(data);
        } catch (e) {
          setZoneOptions([]);
          return null;
        }
      }
    });

  let partnersQuery = useQuery(["partnersQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getPartners({token, organizationId: navCommunity.id});
        let data = response.data.data.map(partner => (
          {
            value: partner.id,
            label: partner.name,
            zone: partner.zone
          }
        ))
        setPartnerOptions(data);
      } catch (e) {
        setPartnerOptions([]);
        return null;
      }
    }
  });

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => updateFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleClientGroupChange = (items) => {
    updateFilter({ ...filter, clientGroup: items });
  }

  const handleBuChange = (items) => {
    updateFilter({ ...filter, bu: items });
  };

  const handleZoneChange = (item) => {
    updateFilter({ ...filter, zone: item });
  };

  const handlePartnerChange = (item) => {
    updateFilter({ ...filter, partner: Number(item) });
  };

  const handleCollaboratorChange = (item) => {
    updateFilter({ ...filter, collaborator: Number(item) });
  };

  const handleCollaboratorRoleChange = (item) => {
    updateFilter({ ...filter, role: item });
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("clientsGroup"),
        options: CLIENT_GROUP_CRM_FILTER,
        values: filter.clientGroup,
        action: handleClientGroupChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        isSearch: true,
        isDisabled:
          parsePreference(preferences.financial).croissance.allowViewDataBy === 101,
        placeholder: _("zone"),
        options: zoneOptions,
        values: filter.zone,
        action: handleZoneChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        isSearch: true,
        isDisabled:
          parsePreference(preferences.financial).croissance.allowViewDataBy === 100,
        placeholder: _("bu"),
        options: buOptions,
        values: filter.bu,
        action: handleBuChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: parsePreference(preferences.financial).croissance.allowViewDataBy !== 10,
        isSearch: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborator,
        action: handleCollaboratorChange,
      },
    },
    filter.collaborator !== null && {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("folderAs"),
        options: COLLABORATOR_ROLE_FILTER,
        values: filter.role,
        action: handleCollaboratorRoleChange,
      },
    }
  ];

  if ([1, 11].includes(parsePreference(preferences.financial).croissance.allowViewDataBy)) {
      filters.unshift({
        type: "SELECT",
        options: {
          isSimple: true,
          isClearable: true,
          isDisabled:
          parsePreference(preferences.financial).croissance.allowViewDataBy === 11,
          placeholder: _("Partner"),
          options: partnerOptions,
          values: filter.partner,
          action: handlePartnerChange
        },
      });
    }
  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="clients-out-filters">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          bg="#FFF"
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={filter.pageSize}
          customAddOn={renderYearSelect()}
          updateFilterContent={updateFilter}
          resetFilterContent={resetFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default CroissanceFilters;
