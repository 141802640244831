import React, { useRef, useEffect } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import moment from "moment";
import { DragDropContext } from "react-beautiful-dnd";
import Item from "./Item.js";
const Calendar = ({
  token,
  organizationId,
  setOpenAddEvent,
  renderDurationDisplay,
  formatDuration,
  timeSheetByDays,
  holidays,
  durationsByDay,
  workingHours,
  dayMapping,
  selectedTab,
  events,
  backupEvents,
  setEvents,
  selectedPeriodTypeId,
  currentMonth,
  setCurrentMonth,
  handlePreviousWeek,
  handleNextWeek,
  weeks,
  currentDate,
  currentWeekIndex,
  collapsedWeeks,
  setCollapsedWeeks,
  setDurationsByDay,
  setSelectedCategory,
  selectedDateEvent,
  setSelectedDateEvent,
  selectedDurationHours,
  selectedDurationTravelHours,
  setSelectedDurationHours,
  setSelectedDurationTravelHours,
  setSelectedDuration,
  setSelectedColor,
  selectedDurationMin,
  selectedDurationTravelMin,
  setSelectedDurationMin,
  setSelectedDurationTravelMin,
  selectedStatus,
  setSelectedStatus,
  selectedPlaceRDV,
  setSelectedPlaceRDV,
  selectedDescription,
  setSelectedDescription,
  setSelectedTypeEvent,
  setSelectedClient,
  setSelectedEventId,
  setSelectedTitleEvent,
  selectedRecurrenceDetails,
  setSelectedRecurrenceDetails,
  isElementDragged,
  setIsElementDragged,
  saveCollaboratorPlanner,
  setSelectedEvent,
  weekAll,
  // startDate,
  // endDate,
  selectedEventId,
  setSelectedMeetingInfo,
  selectedCollaboratorBackupTabs,
  backupInformations,
  setFisrtDateOccurence,
  setSelectedCollaborators,
  setAdditionalEmails,
  setIsAutoSendClientMeetingLink,
  collaborator,
}) => {
  const weeklyViewRef = useRef(null);
  useEffect(() => {
    if (isElementDragged) {
      saveCollaboratorPlanner();
      setIsElementDragged(false);
    }
  }, [isElementDragged]);

  const toggleCollapseWeek = (weekIndex) => {
    setCollapsedWeeks((prevState) => ({
      ...prevState,
      [weekIndex]: !prevState[weekIndex],
    }));
  };
  const onClickEvent = (event, toOpen) => {
    setFisrtDateOccurence(
      event &&
        event.recurrenceDetails &&
        event.recurrenceDetails.recurrence_end_date
        ? new Date(event.recurrenceDetails.recurrence_end_date)
        : null
    );
    setSelectedEventId(event.id);
    setSelectedEvent(event);
    setSelectedClient({
      value: event.client_id,
      label: event.client_name,
      email: event.client_email,
    });
    setSelectedCategory({
      value: event.category_id,
      label: event.category_name,
    });
    if (typeof event.collaborators === "string") {
      setSelectedCollaborators(JSON.parse(event.collaborators));
    } else {
      setSelectedCollaborators(event.collaborators);
    }
    setSelectedRecurrenceDetails(event.recurrenceDetails);
    const selectedDate = moment(event.date.date);
    if (selectedDate.hour() < 7) {
      selectedDate.set({ hour: 7, minute: 0, second: 0, millisecond: 0 });
    }
    setSelectedDateEvent(selectedDate);
    setSelectedDurationHours(Math.floor(event.duration / 60));
    setSelectedDurationMin(event.duration % 60);
    setSelectedDurationTravelHours(Math.floor(event.durationTravel / 60));
    setSelectedDurationTravelMin(event.durationTravel % 60);
    setSelectedStatus(event.status);
    setSelectedPlaceRDV(event.meetingPlace);
    setSelectedDescription(event.description);
    setSelectedTypeEvent(event.type);
    setSelectedTitleEvent(event.title);
    setSelectedDuration(event.duration);
    setSelectedColor(event.color);
    setSelectedMeetingInfo({
      id: event.meetingId,
      url: event.meetingUrl,
    });
    if (toOpen) {
      setOpenAddEvent(true);
    } else {
      setIsElementDragged(true);
    }
    if (event.additionalEmails) {
      setAdditionalEmails(event.additionalEmails.split("|"));
    } else {
      setAdditionalEmails([]);
    }

    setIsAutoSendClientMeetingLink(event.autoSendClientMeetingLink);
  };
  useEffect(() => {
    if (selectedTab !== null && weeklyViewRef.current) {
      weeklyViewRef.current.scrollIntoView({ behavior: "smooth" });
      window.scrollBy(0, 400);
    }
  }, [selectedTab]);

  const handleOnDragEnd = async (result) => {
    const { destination, source } = result;

    if (!destination || !source) {
      console.warn("No destination or source found.");
      return;
    }

    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }

    const dateObject = new Date(destination.droppableId);
    const formattedDate = {
      date: moment(dateObject).format("YYYY-MM-DD HH:mm:ss.SSSSSS"),
      timezone: "UTC",
      timezone_type: 3,
    };

    let updatedEvent = null;

    const updatedEvents = events.map((event) => {
      if (event.id.toString() === result.draggableId) {
        const updatedDurationsByDay = durationsByDay.map((day) => {
          if (
            moment(day.date).format("YYYY-MM-DD") ===
            moment(selectedDateEvent).format("YYYY-MM-DD")
          ) {
            return {
              ...day,
              duration: day.duration - event.duration,
            };
          }
          return day;
        });
        const isDatePresent = updatedDurationsByDay.some(
          (day) =>
            moment(day.date).format("YYYY-MM-DD") ===
            moment(selectedDateEvent).format("YYYY-MM-DD")
        );
        if (!isDatePresent) {
          updatedDurationsByDay.push({
            date: selectedDateEvent,
            duration:
              parseInt(selectedDurationHours) * 60 +
              parseInt(selectedDurationMin),
          });
        }
        setDurationsByDay(updatedDurationsByDay);
        updatedEvent = { ...event, date: formattedDate };
        return updatedEvent;
      }
      return event;
    });
    setEvents(updatedEvents);

    onClickEvent(updatedEvent, false);
  };

  const renderWeek = (week, weekIndex, collapsedWeeks) => {
    return (
      <div className="week_row" key={weekIndex}>
        {week.map((day, dayIndex) => (
          <Item
            token={token}
            organizationId={organizationId}
            setOpenAddEvent={setOpenAddEvent}
            setSelectedDateEvent={setSelectedDateEvent}
            renderDurationDisplay={renderDurationDisplay}
            formatDuration={formatDuration}
            timeSheetByDays={timeSheetByDays}
            holidays={holidays}
            durationsByDay={durationsByDay}
            workingHours={workingHours}
            dayMapping={dayMapping}
            events={events}
            backupEvents={backupEvents}
            day={day}
            dayIndex={dayIndex}
            collapsedWeeks={collapsedWeeks}
            toggleCollapseWeek={toggleCollapseWeek}
            setSelectedCategory={setSelectedCategory}
            selectedDateEvent={selectedDateEvent}
            selectedDurationHours={selectedDurationHours}
            selectedDurationTravelHours={selectedDurationTravelHours}
            setSelectedDurationHours={setSelectedDurationHours}
            setSelectedDurationTravelHours={setSelectedDurationTravelHours}
            setSelectedDuration={setSelectedDuration}
            selectedDurationMin={selectedDurationMin}
            selectedDurationTravelMin={selectedDurationTravelMin}
            setSelectedDurationMin={setSelectedDurationMin}
            setSelectedDurationTravelMin={setSelectedDurationTravelMin}
            setSelectedTitleEvent={setSelectedTitleEvent}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedPlaceRDV={selectedPlaceRDV}
            setSelectedPlaceRDV={setSelectedPlaceRDV}
            selectedDescription={selectedDescription}
            setSelectedDescription={setSelectedDescription}
            setSelectedTypeEvent={setSelectedTypeEvent}
            setSelectedClient={setSelectedClient}
            setSelectedEventId={setSelectedEventId}
            selectedEventId={selectedEventId}
            isMonthVue={collapsedWeeks}
            setEvents={setEvents}
            selectedRecurrenceDetails={selectedRecurrenceDetails}
            setSelectedRecurrenceDetails={setSelectedRecurrenceDetails}
            onClickEvent={onClickEvent}
            setSelectedEvent={setSelectedEvent}
            setSelectedMeetingInfo={setSelectedMeetingInfo}
            selectedCollaboratorBackupTabs={selectedCollaboratorBackupTabs}
            backupInformations={backupInformations}
            collaborator={collaborator}
          />
        ))}
      </div>
    );
  };
  const renderWeeklyView = () => {
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const days = [];
    const nextWeekDays = [];

    for (let i = 0; i <= 4; i++) {
      const day = startOfWeek.clone().add(i, "days");
      const nextWeekDay = startOfWeek.clone().add(i + 7, "days");

      days.push(day);
      nextWeekDays.push(nextWeekDay);
      //  setCurrentWeek(days);
    }
    return (
      <div className="weekly_view" ref={weeklyViewRef}>
        {renderWeek(weekAll, currentWeekIndex, false)}
      </div>
    );
  };
  const startDate = moment(weekAll[0]);
  const endDate = startDate.clone().add(4, "days");
  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="calendar-header">
          <div
            className="actions"
            onClick={() =>
              selectedPeriodTypeId !== 1
                ? setCurrentMonth(currentMonth.clone().subtract(1, "month"))
                : handlePreviousWeek()
            }
          >
            {selectedPeriodTypeId !== 1
              ? "Mois précédent"
              : "Semaine précédente"}
          </div>

          {selectedPeriodTypeId === 1 ? (
            <div className="line_content calendar_informations">
              Du
              <h3>{startDate && startDate.format("dddd D MMMM")} </h3>au
              <h3> {endDate && endDate.format("dddd D MMMM YYYY")}</h3>
            </div>
          ) : (
            <h2>
              {selectedPeriodTypeId !== 1
                ? currentMonth.format("MMMM YYYY")
                : weekAll[0].format("MMMM YYYY")}
            </h2>
          )}
          <div
            className="actions"
            onClick={() =>
              selectedPeriodTypeId !== 1
                ? setCurrentMonth(currentMonth.clone().add(1, "month"))
                : handleNextWeek()
            }
          >
            {selectedPeriodTypeId !== 1 ? "Mois suivant" : "Semaine Suivante"}
          </div>
        </div>
        <div className="planner_calendar">
          {selectedPeriodTypeId === 1
            ? renderWeeklyView()
            : weeks.map((week, weekIndex) =>
                renderWeek(week, weekIndex, collapsedWeeks)
              )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default Calendar;
