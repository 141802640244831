import { Fragment, useEffect, useRef, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { useQuery } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import { CustumedMatrix } from "tamtam-components";
import {
  getCollaboratorOamCode,
  updateCollaboratorOamCode,
} from "../../../api/client";
import _ from "../../../i18n";
import { renderTeamYears, useOnClickOutside } from "../../../utils";
import { CheckIcon, IconClose, IconPencil } from "../../common/Icons";

const billingBonusOptions = [
  { value: "SENIOR", label: _("senior") },
  { value: "MEDIOR", label:  _("medior") },
  { value: "JUNIOR", label:  _("junior") },
  { value: "ADMIN", label:  _("admin") },
];

export default function InvoiceConfig({ organization, token, onClose }) {
  const [codes, setCodes] = useState([]);
  const [collaboratorCode, setCollaboratorCode] = useState(null);
  const [formFocus, setFormFocus] = useState(false);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear(),
  });
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [columns, setColumns] = useState([
    {
      id: "grade",
      label: _("seniority"),
      isEditable: true,
      widthPercentage: 40,
    },
    {
      id: "oam_code",
      label: _("attributedCode"),
      isEditable: true,
      widthPercentage: 20,
    },
    {
      id: "actions",
      label: "",
      isEditable: true,
      widthPercentage: 10,
    },
  ]);

  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));

  let getCollaboratorOamCodeQuery = useQuery(
    ["collaboratorOamCodeQuery", token, organization, filter.year],
    async () => {
      if ((token, organization, filter.year)) {
        try {
          const response = await getCollaboratorOamCode(
            token,
            organization.id,
            filter.year
          );

          const data = response.data.data;
          setCodes(data);
          return data;
        } catch (e) {
          console.log(e);
          setCodes([]);
          return null;
        }
      }
    },
    {
      enabled: !!token && !!organization && !!filter.year,
    }
  );

  useEffect(() => {
    getCollaboratorOamCodeQuery.refetch();
  }, [filter.year]);

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
          zIndex: 9999,
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => setFilter({ ...filter, year: item })}
                  style={{ zIndex: 4 }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleUpdateOamCode = async (line) => {
    const payload = {
      token,
      organizationId: organization && organization.id,
      year: filter.year,
      data: {
        [line.id]: {
          code: line.oam_code,
          grade: line.grade.value,
        },
      },
    };

    updateCollaboratorOamCode(payload)
      .then((resp) => {
        if (resp.data.result === "OK") {
          const newCodes = codes.map((c) =>
            c.id == line.id
              ? { ...c, oam_code: line.oam_code, grade: line.grade.label }
              : c
          );
          setCodes(newCodes);
          setCollaboratorCode(null);
          toast.success(_("successfully_updated"));
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  const handleConfigColumns = (column, line) => {
    if (column.id === "actions") {
      return collaboratorCode && collaboratorCode.id === line.id ? (
        <div className={`actions ${formFocus && "focus"}`}>
          <div
            className={`action ${
              collaboratorCode.oam_code !== "" ? "active" : "disabled"
            }`}
            onClick={() => handleUpdateOamCode(collaboratorCode)}
          >
            <CheckIcon size={12} />
          </div>
          <div className="action" onClick={() => setCollaboratorCode(null)}>
            <IconClose size={8} />
          </div>
        </div>
      ) : (
        <div className="actions">
          <div className="action" onClick={() => setCollaboratorCode(line)}>
            <IconPencil size={12} />
          </div>
        </div>
      );
    } else {
      return collaboratorCode && collaboratorCode.id === line.id ? (
        column.id == "oam_code" ? (
          <div className={collaboratorCode && "focus"}>
            <input
              value={collaboratorCode[column.id]}
              onChange={(event) =>
                setCollaboratorCode({
                  ...collaboratorCode,
                  [column.id]: event.target.value,
                })
              }
            />
          </div>
        ) : (
          <div className={collaboratorCode && "focus"}>
            <Select
              isMulti={false}
              value={collaboratorCode[column.id]}
              options={billingBonusOptions}
              onChange={(inputValue) =>
                setCollaboratorCode({
                  ...collaboratorCode,
                  [column.id]: inputValue,
                })
              }
              styles={
                {
                  control: (provided, state) => ({
                   ...provided,
                    width: "100%",
                    borderRadius: 4,
                    borderColor: state.isFocused ? "#18a0fb;" : "#cccccc",
                    borderWidth: state.isFocused ? 1 : .5
                  }),
                }
              }
            />
          </div>
        )
      ) : (
        <div className="matrix_title">{_(line[column.id]) ?? "N/A"}</div>
      );
    }
  };

  const handleConfigTitle = (title) => {
    return (
      <div
        className="matrix_title"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        {_(title.name)}
      </div>
    );
  };

  const renderCoefficientMatrix = () => {
    return (
      <Fragment>
        {getCollaboratorOamCodeQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : codes.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("noDataFound")}</div>
          </div>
        ) : (
          <div style={{ width: "100%", height: 600 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <h3>{_("configOamCode")}</h3>
              {renderYearSelect()}
            </div>
            <CustumedMatrix
              columns={columns}
              items={codes}
              handleCellule={handleConfigColumns}
              handleTitle={handleConfigTitle}
              headerTagBG={"#FFF"}
            />
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div
        style={{
          width: 900,
          height: "100%",
          position: "absolute",
          right: 0,
          background: "white",
          overflow: "hidden",
        }}
      >
        <div className="sidebar_header">
          <div className="sidebar_header_infos">
            <h3>{_("configuration")}</h3>
          </div>
          <div className="sidebar_header_action" onClick={onClose}>
            <IconClose size={12} />
          </div>
        </div>
        <div className="sidebar_body vertical_content">
          <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
            {renderCoefficientMatrix()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
