import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { DatePicker, Input } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import {
  formatAmount,
  renderDurationMinutesDisplay,
  convertDecimalToHoursMinutes,
} from "../../../utils";
import { ECHEANCES } from "../../../config";
import { getCollaboratorInProgressEcheance } from "../../../api";
import SearchBox from "../../common/Filter/SearchBox";
import { toast } from "react-toastify";
import classNames from "classnames";
import {
  IconClose,
  FilterIcon,
  PlannedIcon,
  VerticalEllipsisIcon,
} from "../../common/Icons";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import moment from "moment";
import "moment/locale/fr";
import "./Planner.local.scss";
import _ from "../../../i18n";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

const InProgressBalances = ({
  token,
  navCommunity,
  collaboratorId,
  selectedTab,
  setSelectedTab,
  inProgressFilteredData,
  setInProgressFilteredData,
  handleOptionClick,
  isPlannerPage,
  setOpenAddEvent,
}) => {
  const [inProgressDataFetching, setInProgressDataFetching] = useState(false);
  const [inProgressData, setInProgressData] = useState(null);
  const [countByStep, setCountByStep] = useState(null);
  const [countByVatPeriodicity, setCountByVatPeriodicity] = useState(null);
  const [selectedTvaType, setSelectedTvaType] = useState("ALL");
  const [selectedCurentStep, setSelectedCurentStep] = useState(null);
  const [selectedClosingDate, setSelectedClosingDate] = useState(null);
  const [inProgressIPMData, setInProgressIPMData] = useState(null);
  const [inProgressISOCData, setInProgressISOCData] = useState(null);
  const [inProgressTVAData, setInProgressTVAData] = useState(null);
  const [isVerticalEllipsisIsOpen, setIsVerticalEllipsisIsOpen] =
    useState(false);
  const [seletedBalanceToOpen, setSeletedBalanceToOpen] = useState(null);
  const [selectedPeriodicityTypeId, setSelectedPeriodicityTypeId] =
    useState(null);
  const [statusDivIsVisible, setStatusDivIsVisible] = useState(null);
  const [dateDivIsVisible, setDateDivIsVisible] = useState(null);
  const [filterInprogressData, setFilterInprogressData] = useState({
    keyWord: "",
    sort: "",
    year: 2025,
  });
  const [height, setHeight] = useState(470);
  const [isResizing, setIsResizing] = useState(false);
  const resizableRef = useRef(null);

  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isResizing) {
        const newHeight =
          resizableRef.current.getBoundingClientRect().bottom - e.clientY;
        setHeight(Math.max(100, Math.min(800, newHeight)));
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);
  const curentPlannedStep = [
    {
      id: "ALL",
      label: "tous",
    },

    {
      id: "NOT_PLANNED",
      label: "Non Planifié",
    },
    {
      id: "PLANNED",
      label: "Planifié",
    },
  ];
  const curentStepTabs =
    selectedTab === "TVA"
      ? [
          {
            id: "",
            label: "Tous les types",
          },
          {
            id: "PLANNED",
            label: "Planifier",
          },
          {
            id: "REMINDER_DOCS_EMAIL",
            label: "Reminder docs email",
          },
          {
            id: "ENCODAGE_VERIFICATION",
            label: "encodage verification",
          },
          {
            id: "MAIL_TVA_SEND",
            label: "mail tva envoye",
          },
          {
            id: "DECLARATIOM_TO_MAKE",
            label: "declaration to make",
          },
          {
            id: "DEPOT_INTERVAT",
            label: "depot intervat",
          },
        ]
      : [
          {
            id: null,
            label: "Tous les types",
          },
          { id: "HISTORIQUE", label: "historique" },
          {
            id: "BILAN",
            label: "bilan",
          },
          {
            id: "SUPERVISION",
            label: "SUPERVISION",
          },
          {
            id: "DECLARATION_FISCALE",
            label: "declaration fiscale",
          },
          {
            id: "BNB",
            label: "bnb",
          },
        ];
  const tvaTypesTabs = [
    { id: "ALL", label: "Tous" },
    { id: 0, label: "Trimestre" },
    { id: 1, label: "Mensuel" },
    { id: 2, label: "Non soumis" },
    { id: 4, label: "Annuellement" },
    { id: 11, label: "Mixte/Partiel" },
    { id: 12, label: "Unité TVA" },
    { id: 13, label: "Exempté art 44" },
    { id: 14, label: "Particulier" },
    { id: 15, label: "TVA spéciale" },
  ];

  const { data, refetch } = useQuery(
    [
      "getCollaboratorInProgressEcheance",
      token,
      navCommunity.id,
      selectedCurentStep,
      selectedClosingDate,
      selectedItems,
      selectedTab,
    ],
    async () => {
      if (token && navCommunity.id) {
        setInProgressDataFetching(true);
        try {
          const response = await getCollaboratorInProgressEcheance(
            navCommunity.id,
            collaboratorId,
            filterInprogressData.year,
            selectedCurentStep,
            selectedClosingDate,
            selectedItems,
            selectedTab,
            filterInprogressData.keyWord,
            token
          );
          setInProgressData(response.data.data.data);
          setInProgressFilteredData(response.data.data.data);
          setInProgressIPMData(response.data.data.IPM);
          setInProgressISOCData(response.data.data.ISOC);
          setInProgressTVAData(response.data.data.TVA);
          setCountByStep(response.data.data.countByStep);
          setCountByVatPeriodicity(response.data.data.countByVatPeriodicity);
          setInProgressDataFetching(false);
        } catch (e) {
          return null;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity.id,
    }
  );

  useEffect(() => {
    refetch();
  }, [selectedCurentStep, selectedTvaType, selectedTab]);

  const handleTabChange = (selected) => {
    setSelectedTab(selected);
    setSelectedCurentStep("ALL");
    setSelectedTvaType(null);
  };

  useEffect(() => {
    if (filterInprogressData.keyWord !== "") {
      const filteredData = inProgressData
        ? inProgressData.filter((item) =>
            item.client_name
              .toLowerCase()
              .includes(filterInprogressData.keyWord.toLowerCase())
          )
        : [];
      setInProgressFilteredData(filteredData);
    } else {
      setInProgressFilteredData(inProgressData);
    }
  }, [filterInprogressData.keyWord]);

  useEffect(() => {
    if (filterInprogressData.sort !== "") {
      const sortedData = [...inProgressFilteredData].sort((a, b) => {
        const dateA = new Date(formatClosingDate(a.closing_date, true));
        const dateB = new Date(formatClosingDate(b.closing_date, true));

        if (filterInprogressData.sort === "closing_date_acd") {
          if (dateA.getFullYear() === dateB.getFullYear()) {
            return dateA.getMonth() - dateB.getMonth();
          }
          return dateA - dateB;
        } else if (filterInprogressData.sort === "closing_date_desc") {
          if (dateA.getFullYear() === dateB.getFullYear()) {
            return dateB.getMonth() - dateA.getMonth();
          }
          return dateB - dateA;
        }
        return 0;
      });

      setInProgressFilteredData(sortedData);
    } else {
      setInProgressFilteredData(inProgressData);
    }
  }, [filterInprogressData.sort, inProgressData]);

  const formatClosingDate = (closingDate, ordre) => {
    const date = new Date(closingDate);

    const currentYear = new Date().getFullYear();
    date.setFullYear(currentYear);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return ordre ? `${year}-${month}-${day}` : `${day}/${month}`;
  };
  const toggleVerticalEllipsisDropdown = (item) => {
    setSeletedBalanceToOpen(item);
    setIsVerticalEllipsisIsOpen(!isVerticalEllipsisIsOpen);
  };
  const renderCustomTvaFilter = (selectedId, items, type) => {
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (itemId) => {
      setSelectedItems((prevSelected) =>
        prevSelected.includes(itemId)
          ? prevSelected.filter((id) => id !== itemId)
          : [...prevSelected, itemId]
      );
    };

    const resetSelection = () => {
      setSelectedItems([]);
    };

    const selectAll = () => {
      setSelectedItems(
        items
          .filter((item) => countByVatPeriodicity[item.id] !== 0)
          .map((item) => item.id)
      );
    };

    let count = countByVatPeriodicity;

    return (
      <div className="filter-container" ref={dropdownRef}>
        <button className="filter-button" onClick={toggleDropdown}>
          Filtrer <FilterIcon className="filter-icon" />
        </button>

        {isOpen && (
          <div className="filter-dropdown">
            {items.map((item, index) => (
              <div key={item.id} className="line_space_content">
                <label
                  className="filter-item"
                  style={{ color: count[item.id] === 0 && "#6d7f92" }}
                >
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item.id)}
                    onChange={() => {
                      handleCheckboxChange(item.id);
                      if (index === 0) {
                        if (selectedItems.includes(item.id)) {
                          resetSelection();
                        } else {
                          selectAll();
                        }
                      }
                    }}
                    disabled={count[item.id] === 0}
                  />
                  {item.label}
                </label>
                {inProgressDataFetching
                  ? " - "
                  : count &&
                    count[item.id] !== undefined && (
                      <label
                        className="filter-item"
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          color: count[item.id] === 0 && "#6d7f92",
                        }}
                      >
                        ({count[item.id]})
                      </label>
                    )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  //filters
  const renderCustomAddOnFooter = (selectedId, items, type) => {
    let count = type === "period" ? countByStep : countByVatPeriodicity;
    return (
      <div
        className={classNames(
          type === "period" ? "filter_custom" : "filter_tva",
          "line_content"
        )}
        style={{ width: "auto" }}
      >
        <div
          className={"mini-tabs-frame"}
          style={{
            background: "none",
            display: "flex",
            borderRadius: 0,
            borderRight: "0.5px solid var(--gris-1, #b2bcc6)",
          }}
        >
          {items.map((item) => (
            <div
              key={item.id}
              className={
                type === "period" && selectedCurentStep === item.id
                  ? "mini-tabs-frame_active"
                  : type === "tva_type" && selectedTvaType === item.id
                  ? "tva_filter_active"
                  : ""
              }
              style={{ width: "auto", marginRight: "10px" }}
              onClick={() => {
                type === "period"
                  ? setSelectedCurentStep(item.id)
                  : setSelectedTvaType(item.id);
              }}
            >
              {_(item.label)}
              {inProgressDataFetching
                ? " " + "( -- )"
                : count &&
                  count[item.id] !== undefined && (
                    <span style={{ marginLeft: "5px", fontWeight: "bold" }}>
                      ({count[item.id]})
                    </span>
                  )}
            </div>
          ))}
        </div>
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setStatusDivIsVisible(!statusDivIsVisible)}
          >
            {selectedCurentStep &&
            curentStepTabs.some((tab) => tab.id === selectedCurentStep)
              ? selectedCurentStep
              : _("Tous les types")}
            <TiArrowSortedDown />
          </div>
          {statusDivIsVisible && !inProgressDataFetching && (
            <div className={"rectangular_select_body"}>
              <div className={"rectangular_select_body_options"}>
                {curentStepTabs.map((item, index) => {
                  if (!item) {
                    return;
                  }
                  return (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        setSelectedCurentStep(item.id);
                        setStatusDivIsVisible(false);
                      }}
                    >
                      {item.label}{" "}
                      {count && count[item.id] !== undefined && (
                        <> ({count[item.id]})</>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className={"rectangular_select"}>
          <div
            className={"rectangular_select_header"}
            onClick={() => setDateDivIsVisible(!dateDivIsVisible)}
          >
            {selectedClosingDate === null
              ? _("Toutes les dates")
              : selectedClosingDate}
            <TiArrowSortedDown />
          </div>
          {dateDivIsVisible && !inProgressDataFetching && (
            <div className={"rectangular_select_body"}>
              <div className={"rectangular_select_body_options"}>
                {/* Parcourir les échéances */}
                {ECHEANCES[selectedTab].map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={"rectangular_select_body_option"}
                      onClick={() => {
                        // Mettre à jour selectedClosingDate avec l'élément sélectionné
                        setSelectedClosingDate(item);
                        setDateDivIsVisible(false);
                      }}
                    >
                      {/* Afficher "Toutes les dates" si l'élément est null */}
                      {item === null ? _("Toutes les dates") : item}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  // Filter 2
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [hasDate, setHasDate] = useState(false);
  // useEffect(() => {
  //   setHasDate(!!selectedDateEvent);
  // }, [selectedDateEvent]);

  const handleDateChange = (date, index, key) => {
    if (!date) return;

    const updatedData = [...inProgressFilteredData];
    updatedData[index] = {
      ...updatedData[index],
      [key]: date,
    };

    setInProgressFilteredData(updatedData);
  };
  const renderInProgressBalances = () => {
    return (
      <div>
        <div
          style={{
            marginLeft: "12px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {renderCustomAddOnFooter(
            selectedPeriodicityTypeId,
            curentPlannedStep,
            "period"
          )}
          <div className="line_content">
            <SearchBox
              placeholder={`${_("search")}...`}
              onChange={(item) =>
                setFilterInprogressData({
                  ...filterInprogressData,
                  keyWord: item,
                })
              }
              filter={filterInprogressData}
            />
            {renderCustomTvaFilter(
              selectedPeriodicityTypeId,
              tvaTypesTabs,
              "tva_type"
            )}
          </div>
        </div>
        {selectedItems.length > 0 && (
          <div
            style={{ marginLeft: "12px", marginTop: "10px", display: "flex" }}
          >
            {selectedItems.map((id) => {
              const label = tvaTypesTabs.find((item) => item.id === id)?.label;
              return label ? (
                <div
                  key={id}
                  className="selected_tva_filtre"
                  style={{ display: "flex", marginBottom: "5px" }}
                >
                  {label + " (" + countByVatPeriodicity[id] + ")"}
                  <div
                    style={{ marginLeft: "6px" }}
                    onClick={() =>
                      setSelectedItems((prevItems) =>
                        prevItems.filter((item) => item !== id)
                      )
                    }
                  >
                    <IconClose size="10" />{" "}
                  </div>
                </div>
              ) : null;
            })}
          </div>
        )}

        <div>
          {inProgressDataFetching ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : inProgressFilteredData && inProgressFilteredData.length > 0 ? (
            inProgressFilteredData.map((item, index) => (
              <div className="in_progress_balances_data">
                <div className="name client" style={{ width: "15%" }}>
                  {item.client_name}
                </div>
                <div className="name contant_values" style={{ width: "15%" }}>
                  {item.current_step}
                  {/* {curentStepTabs.find((tab) => tab.id === item.current_step)
                    ?.label || "Inconnu"} */}
                </div>
                {/* <div className="step name" key={index}> */}
                <div style={{ width: "15%" }}>
                  <div
                    className="item_progress_container name"
                    style={{ width: "120px" }}
                  >
                    <div
                      className="item_progress_bar"
                      style={{
                        width:
                          parseInt(item.duration_minutes / 60) >
                          parseInt(item.base_budget)
                            ? "100%"
                            : parseInt(item.base_budget) > 0 &&
                              parseInt(item.duration_minutes / 60) > 0
                            ? `${
                                (parseInt(item.duration_minutes / 60) * 100) /
                                parseInt(item.base_budget)
                              }%`
                            : 0,
                        background:
                          parseFloat(item.duration_minutes / 60) >
                          parseFloat(item.base_budget)
                            ? "#EB5757"
                            : "#06D9B1",
                      }}
                    />
                    <div
                      className="item_progress_index"
                      style={{
                        width:
                          parseInt(item.duration_minutes / 60) >
                          parseInt(item.base_budget)
                            ? "200%"
                            : parseInt(item.base_budget) > 0 &&
                              parseInt(item.duration_minutes / 60) > 0
                            ? `${
                                (parseInt(item.duration_minutes / 60) * 200) /
                                parseInt(item.base_budget)
                              }%`
                            : 0,
                      }}
                    ></div>
                    <div className="line_space_content">
                      <div
                        className="progress_budget"
                        style={{
                          color:
                            parseFloat(item.duration_minutes / 60) >
                            parseFloat(item.base_budget)
                              ? "#DE4848"
                              : "#02AF8E",
                        }}
                      >
                        {parseInt(item.duration_minutes) > 0
                          ? renderDurationMinutesDisplay(item.duration_minutes)
                          : 0}
                      </div>
                      <div className="progress_budget">
                        <div>
                          {convertDecimalToHoursMinutes(
                            item.base_budget,
                            false
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div
                  className="content_titles"
                  style={{
                    width: "12%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Clôture le:{" "}
                  <div className="contant_values">
                    {" "}
                    {formatClosingDate(item.closing_date, false)}
                  </div>
                </div>

                {selectedTab !== "TVA" && (
                  <div className="content" style={{ width: "15%" }}>
                    <div className="calendar_select content_titles">
                      Échéance Bilan:
                      {item.echeance_planing_date && <PlannedIcon />}
                      <Input.Group
                        compact
                        className={classNames(
                          "custom_datepicker_wrapper"
                          //    {
                          //   "has-date": hasDate,
                          // }
                        )}
                        style={{
                          width: "140px",
                          background:
                            item.echeance_planing_date &&
                            "rgba(6, 217, 177, 0.10)",
                        }}
                      >
                        <span className="calendar_icon">
                          <CalendarOutlined />
                        </span>
                        <DatePicker
                          className="datepicker_input"
                          format="DD/MM"
                          value={
                            item.echeance_planing_date
                              ? moment(
                                  item.echeance_planing_date,
                                  moment.ISO_8601
                                ).isValid()
                                ? moment(item.echeance_planing_date)
                                : null
                              : null
                          }
                          disabled={item.role !== "GD"}
                          locale="fr"
                          allowClear={false}
                          style={{ width: "140px" }}
                          placeholder="Sélectionner une date"
                          popupClassName="datepicker_popup"
                          suffixIcon={null}
                          onChange={(date) => {
                            if (moment.isMoment(date) && date.isValid()) {
                              handleDateChange(
                                date,
                                index,
                                "echeance_planing_date"
                              );
                              handleOptionClick(date, item, "balanceEcheance");
                            } else {
                              handleDateChange(
                                null,
                                index,
                                "echeance_planing_date"
                              );
                              handleOptionClick(
                                moment(),
                                item,
                                "balanceEcheance"
                              );
                            }
                          }}
                        />
                      </Input.Group>
                    </div>
                  </div>
                )}
                {selectedTab !== "TVA" && (
                  <div className="content" style={{ width: "15%" }}>
                    <div className="calendar_select">
                      <div
                        className="line_content content_titles"
                        style={{ width: "151px" }}
                      >
                        Intermediate Situation :
                        {item.intermediate_situation_date && <PlannedIcon />}
                      </div>
                      <Input.Group
                        compact
                        className={classNames("custom_datepicker_wrapper", {
                          "has-date": hasDate,
                        })}
                        style={{
                          width: "140px",
                          background:
                            item.intermediate_situation_date &&
                            "rgba(6, 217, 177, 0.10)",
                        }}
                      >
                        <span className="calendar_icon">
                          <CalendarOutlined />
                        </span>
                        <DatePicker
                          className="datepicker_input"
                          format="DD/MM"
                          value={
                            item.intermediate_situation_date
                              ? moment(
                                  item.intermediate_situation_date,
                                  moment.ISO_8601
                                ).isValid()
                                ? moment(item.intermediate_situation_date)
                                : null
                              : null
                          }
                           disabled={item.role !== "GD"}
                          locale="fr"
                          allowClear={false}
                          placeholder="Sélectionner une date"
                          popupClassName="datepicker_popup"
                          suffixIcon={null}
                          onChange={(date) => {
                            if (moment.isMoment(date) && date.isValid()) {
                              handleDateChange(
                                date,
                                index,
                                "intermediate_situation_date"
                              );
                              handleOptionClick(
                                date,
                                item,
                                "intermediateSituationBalance"
                              );
                            } else {
                              handleDateChange(
                                null,
                                index,
                                "intermediate_situation_date"
                              );
                              handleOptionClick(
                                moment(),
                                item,
                                "intermediateSituationBalance"
                              );
                            }
                          }}
                        />
                      </Input.Group>
                    </div>
                  </div>
                )}
                {selectedTab !== "TVA" && (
                  <div className="content" style={{ width: "15%" }}>
                    <div className="calendar_select content_titles">
                      Présentation Bilan:
                      {item.presentation_meeting_date && <PlannedIcon />}
                      <Input.Group
                        compact
                        className={classNames("custom_datepicker_wrapper", {
                          "has-date": hasDate,
                        })}
                        style={{
                          width: "140px",
                          background:
                            item.presentation_meeting_date &&
                            "rgba(6, 217, 177, 0.10)",
                        }}
                      >
                        <span className="calendar_icon">
                          <CalendarOutlined />
                        </span>
                        <DatePicker
                          className="datepicker_input"
                          format="DD/MM HH:mm"
                          value={
                            item.presentation_meeting_date
                              ? moment(
                                  item.presentation_meeting_date,
                                  moment.ISO_8601
                                ).isValid()
                                ? moment(item.presentation_meeting_date)
                                : null
                              : null
                          }
                          disabled={item.role !== "GD"}
                          locale="fr"
                          allowClear={false}
                          showHour={true}
                          showTime={true}
                          placeholder="Sélectionner une date"
                          popupClassName="datepicker_popup"
                          suffixIcon={null}
                          onChange={(date) => {
                            if (moment.isMoment(date) && date.isValid()) {
                              handleDateChange(
                                date,
                                index,
                                "presentation_meeting_date"
                              );
                              handleOptionClick(
                                date,
                                item,
                                "balancePresentation"
                              );
                            } else {
                              handleDateChange(
                                moment(),
                                index,
                                "presentation_meeting_date"
                              );
                              handleOptionClick(
                                moment(),
                                item,
                                "balancePresentation"
                              );
                            }
                          }}
                        />
                      </Input.Group>
                    </div>
                  </div>
                )}
                {selectedTab !== "TVA" && (
                  <div
                    className="content"
                    style={{ width: "4%" }}
                    onClick={() =>
                      toggleVerticalEllipsisDropdown(item.balance_id)
                    }
                  >
                    <VerticalEllipsisIcon />

                    {isPlannerPage &&
                      isVerticalEllipsisIsOpen &&
                      seletedBalanceToOpen &&
                      seletedBalanceToOpen === item.balance_id && (
                        <div className="balance-dropdown">
                          <div className="title">Ouvrir la fiche :</div>

                          <div
                            key={item.id}
                            className="rectangular_select_body_option"
                            onClick={() => {
                              // setOpenAddEvent(true);
                              handleOptionClick(
                                moment(),
                                item,
                                "balanceEcheance",
                                false
                              );
                              handleTabChange(null);
                            }}
                          >
                            Échéance Bilan
                          </div>
                          <div
                            key={item.id}
                            className="rectangular_select_body_option"
                            onClick={() => {
                              // setOpenAddEvent(true);
                              handleOptionClick(
                                moment(),
                                item,
                                "intermediateSituationBalance",
                                false
                              );
                              handleTabChange(null);
                            }}
                          >
                            Situation Intermediaire
                          </div>
                          <div
                            key={item.id}
                            className="rectangular_select_body_option"
                            onClick={() => {
                              handleOptionClick(
                                moment(),
                                item,
                                "balancePresentation",
                                false
                              );
                              handleTabChange(null);
                            }}
                          >
                            Présentation Bilan
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="no_data" style={{ height: "30px" }}>
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noBalanceFound")}</div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="fixed-buttons">
      <div className="header">
        <div className="tabs">
          <div
            className={`tab ${selectedTab === "ISOC" ? "selected-tab" : ""}`}
            onClick={() => handleTabChange("ISOC")}
          >
            <span>ISOC</span>
            <div className="content-header">
              <div className="circle-warning"></div>(
              {inProgressISOCData ? inProgressISOCData.length : 0})
            </div>
          </div>
          <div
            className={`tab ${selectedTab === "IPM" ? "selected-tab" : ""}`}
            onClick={() => handleTabChange("IPM")}
          >
            <span>IPM</span>
            <div className="content-header">
              <div className="circle-warning"></div>(
              {inProgressIPMData ? inProgressIPMData.length : 0})
            </div>
          </div>
          {isPlannerPage && (
            <div
              className={`tab ${selectedTab === "TVA" ? "selected-tab" : ""}`}
              onClick={() => handleTabChange("TVA")}
            >
              <span>TVA</span>
              <div className="content-header">
                <div className="circle-warning"></div>(
                {inProgressTVAData ? inProgressTVAData.length : 0})
              </div>
            </div>
          )}
        </div>
        {selectedTab !== null && (
          <div className="tabs_close" onClick={() => handleTabChange(null)}>
            <IconClose size="14" fill="#f1f2f4" />
          </div>
        )}
      </div>
      {selectedTab !== null && (
        <div
          className="in-progress-tasks-body"
          ref={resizableRef}
          style={{ height: `${height}px` }}
        >
          <div
            className="resize-handle"
            onMouseDown={() => setIsResizing(true)}
            style={{
              position: "absolute",
              top: 36,
              left: 0,
              right: 0,
              height: "3px",
              cursor: "ns-resize",
              backgroundColor: "#ffffff",
            }}
          ></div>
          {renderInProgressBalances()}
        </div>
      )}
    </div>
  );
};

export default InProgressBalances;
