import { getUserNameForAvatar, generateFullName } from "../utils";
import { BUDGET_STATUS, TTP_API_URL } from "../config";
import _ from "../i18n";
export const renderCollaboratorAvatar = (avatarUrl, firstName, lastName) => {
  if (!avatarUrl && !firstName && !lastName) {
    return (
      <div className="avatar_not_exist">
        <p style={{ margin: "auto" }}>-</p>
      </div>
    );
  }
  let avatarDiv = null;
  if (!avatarUrl) {
    avatarDiv = (
      <div className="avatar_not_exist">
        <span>{getUserNameForAvatar(firstName, lastName)}</span>
      </div>
    );
  } else {
    avatarDiv = (
      <div className="avatar_exist">
        <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
      </div>
    );
  }

  return avatarDiv;
};

export const formatDuration = (minutes) => {
  const hours = minutes ? Math.floor(minutes / 60) : 0;
  const remainingMinutes = minutes ? minutes % 60 : 0;
  const formattedMinutes =
    remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
  return `${hours}h ${formattedMinutes}min`;
};

export const formatAmount = (amount, addCurrency = true) => {
  if (!addCurrency) {
    return new Intl.NumberFormat("de-DE", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }
  return (
    new Intl.NumberFormat("de-DE", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount) + "€"
  );
};

export const formatTime = (minutes, removeZeroHours = false) => {
  const hours = minutes ? Math.floor(minutes / 60) : 0;
  let remainingMinutes = minutes ? minutes % 60 : 0;

  if (remainingMinutes < 0) {
    remainingMinutes *= -1;
  }
  remainingMinutes = Math.round(remainingMinutes);

  const formattedMinutes =
    remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;

  if (removeZeroHours && hours === 0) {
    return `${formattedMinutes}min`;
  }
  return `${hours}h ${formattedMinutes}min`;
};

export const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.abs(Math.round(minutes % 60));

  if (remainingMinutes === 0) {
    return `${hours}h`;
  }

  if (hours === 0) {
    return `${remainingMinutes}min`;
  }

  const formattedMinutes =
    remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
  return `${hours}h ${formattedMinutes}min`;
};

export const generateBudgetStatus = (id) => {
  switch (id) {
    case "all":
      return [
        BUDGET_STATUS.STATUS_UNAUTHORIZED_BUDGET,
        BUDGET_STATUS.STATUS_GLOBAL_BUDGET_AFFECTED,
        BUDGET_STATUS.STATUS_GLOBAL_BUDGET_NOT_AFFECTED,
        BUDGET_STATUS.STATUS_BUDGET_BY_ZONE_AFFECTED,
        BUDGET_STATUS.STATUS_BUDGET_ZONE_DELEGATION_AFFECTED,
        BUDGET_STATUS.STATUS_BUDGET_ZONE_BY_CATEGORY_NOT_COMPLETED,
        BUDGET_STATUS.STATUS_DELEGATION_NOT_AFFECTED,
        BUDGET_STATUS.STATUS_INFORMATIONS_CLIENT_COMPLETED,
        BUDGET_STATUS.STATUS_MANAGEMENT_TASKES_COMPLETED,
        BUDGET_STATUS.STATUS_BUDGET_COMPLETED,
      ];
    case "unbudgeted":
      return [BUDGET_STATUS.STATUS_GLOBAL_BUDGET_NOT_AFFECTED];
    case "inProgress":
      return [
        BUDGET_STATUS.STATUS_GLOBAL_BUDGET_AFFECTED,
        BUDGET_STATUS.STATUS_BUDGET_BY_ZONE_AFFECTED,
        BUDGET_STATUS.STATUS_BUDGET_ZONE_DELEGATION_AFFECTED,
        BUDGET_STATUS.STATUS_BUDGET_ZONE_BY_CATEGORY_NOT_COMPLETED,
        BUDGET_STATUS.STATUS_INFORMATIONS_CLIENT_COMPLETED,
        BUDGET_STATUS.STATUS_MANAGEMENT_TASKES_COMPLETED,
        BUDGET_STATUS.STATUS_DELEGATION_NOT_AFFECTED,
      ];
    case "budgeted":
      return [BUDGET_STATUS.STATUS_BUDGET_COMPLETED];
    default:
      break;
  }
};
export const inIframe = () => {
  return window.location !== window.parent.location;
};

export const toNumber = (value) => {
  return value !== "" || Number.isNaN(value) ? parseFloat(value) : 0;
};

export function onError(message) {
  if (!message) {
    message = "errorOccured";
  }
}
export const renderRatingStars = (id, value) => {
  let stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(i === 0 ? i < value.rate : i <= value.rate);
  }
  return stars.map((s, k) => (
    <img
      key={id + k}
      alt={""}
      src={
        s
          ? value.rate > 6.5
            ? "/img/icon/gold_star.svg"
            : "/img/icon/star.svg"
          : "/img/icon/light_star.svg"
      }
    />
  ));
};

export const toFixed = (value, precision) => {
  return Number(value).toFixed(precision);
};

function extractFirstLettre(arrayStr, length) {
  let result = "";
  for (let i = 0; i < length; i++) {
    if (arrayStr[i]) {
      result += arrayStr[i].substring(0, 1);
    }
  }
  return result.toUpperCase();
}

export const getFormatNumber = (number) => {
  return new Intl.NumberFormat("de-DE").format(number);
};

export const getTimeByDurationFormat = (duration, spaces) => {
  var sec_num = parseFloat(duration) * 3600;
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);

  if (hours < 10 && hours >= 0) {
    hours = "0" + getFormatNumber(hours);
  } else {
    hours = getFormatNumber(hours);
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (spaces) {
    return hours + "h " + minutes + " min";
  } else {
    return hours + "H" + minutes;
  }
};

export const formatDateToISO = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  // time
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return `${formattedDate}T${formattedTime}`;
};

export const prepareCollaboratorAbsencesForSave = (
  planning,
  month,
  monthNumber,
  options,
  collaborators
) => {
  const step1 = [];
  options.forEach(option => {
    planning[option][month] &&
      planning[option][month].forEach(item => {
        if (!step1[item.key]) step1[item.key] = [];
        step1[item.key].push(item);
      });
  });

  let step2 = Object.values(step1);
  let result = [];
  step2.forEach(absence => {
    let from = new Date(absence[0].date);
    let to = new Date(absence[absence.length - 1].date);
    let backupId = null;
    if (collaborators) {
      const collab = collaborators.find(col => col.label === absence[0].backup);
      if (collab) {
        backupId = collab.value;
      }
    }

    let object = {
      id: absence[0]["id"],
      from:
        from.getFullYear() + "-" + (from.getMonth() + 1) + "-" + from.getDate(),
      to: to.getFullYear() + "-" + (to.getMonth() + 1) + "-" + to.getDate(),
      reason: absence[0].label,
      rejectReason:
        absence[0]["vacationStatus"] === "REJECTED"
          ? absence[0].rejectReason
          : "",
      type: absence[0].type,
      backup: backupId,
      month: month,
      attachments: absence[0].attachments,
      numberDays: absence.length,
      numberHours: absence[0].numberHours,
      listOfDays: absence.map(dates => {
        return dates.date;
      }),
      outlookEvents: absence[0].outlookEvents
    };

    if (absence[0]["vacationStatus"] !== undefined) {
      object["vacationStatus"] = absence[0]["vacationStatus"];
    }

    result.push(object);
  });

  return result;
};
