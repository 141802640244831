import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "../../i18n";

import { BriefCaseIcon } from "../common/Icons";
import PageHeader from "../common/PageHeader/PageHeader";
import TTPTab2 from "../common/Tab/TTPTab2";
import CapacityPlaningMatrix from "./Matrix";
import NotAuthorized from "../common/NotAuthorized";
import { getCollaborator } from "../../api";
import { useQuery } from "react-query";

const CapacityPlaningPage = () => {
  const { token, navCommunity, user, collaborator } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};
  let tabs = [
    {
      id: "CAPACITY_PLANING",
      label: "capacityPlaning"
    },
    // {
    //   id: "SYNTHESE",
    //   label: "synthese",
    // },
  ];
  const [filter, setFilter] = useState({
    tab: tabs[0].id,
    year: new Date().getFullYear().toString(),
  });

  const renderCapacityPlaningTabContent = () => {
    switch (filter.tab) {
      case "CAPACITY_PLANING":
        return (
          <CapacityPlaningMatrix
            token={token}
            navCommunity={navCommunity}
            language={language}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "SYNTHESE":
        return (
          <div className="no_data">
            <div className="title">{_("wip")}</div>
            <div className="subTitle">{_("wipWorkshop")}</div>
          </div>
        );
      default:
        return;
    }
  };
  return (
    <div className="page-content">
      <PageHeader
        header={_("capacityPlaning")}
        subHeader={_("subTitleCapacityPlaning")}
        icon={<BriefCaseIcon fill="#29394D" size="36" />}
      />
      {/* <div className="tab-container">
        <TTPTab2
          tabs={tabs}
          width="fit-content"
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id });
          }}
        />
      </div> */}

      <div className="content-body large-content">
        {renderCapacityPlaningTabContent()}
      </div>
    </div>
  );
};

export default CapacityPlaningPage;
