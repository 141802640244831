import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ReferenceLine } from 'recharts';
import styles from './index.module.scss';

export default function ENBarChart({
  data,
  chartName,
  referenceLineY
}) {
  return (
    <>
      <div className={styles.legend_container}>
        <Legend
          align="left"
          verticalAlign="top"
          iconType="circle"
          iconSize={8}
          payload={[
            { value: chartName, color: '#18a0fb', type: 'circle' },
          ]}
          wrapperStyle={{
            marginTop: '60px',
          }}
          formatter={(value) => (
            <span style={{
              color: '#29394D',
              fontSize: '12px',
              fontWeight: '500',
              marginLeft: '4px'
            }}>
              {value}
            </span>
          )}
        />
      </div>

      <BarChart
        width={550}
        height={380}
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: -38,
          bottom: 0,
        }}
        barSize={20}
      >
        <CartesianGrid strokeDasharray="1 3" vertical={false} />
        <XAxis
          dataKey="name"
          tick={{ angle: -45, textAnchor: 'end', fontSize: 10 }}
          tickFormatter={(value) => (value.length > 7 ? `${value.substring(0, 7)}...` : value)}
          interval={1}
          height={80}
        />
        <YAxis tickLine={false} />
        <Tooltip />
        <Bar
          dataKey="ratio"
          name={chartName}
          fill="#18a0fb"
          radius={[4, 4, 0, 0]}
        >
          <LabelList dataKey="name" content={(props) => <span>{props.x}</span>} fontSize={5} />
        </Bar>
        <ReferenceLine alwaysShow y={referenceLineY} stroke="orange" position='middle' strokeWidth={1} />
      </BarChart>
    </>
  );
}
