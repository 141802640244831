import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/msal-config";
import { Button } from "antd";
import { MicrosoftIcon, OutlookIcon } from "../../common/Icons";

export const MsalSignInButton = ({ hideIcon = false }) => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <Button
      style={{ display: "flex", gap: "6px", alignItems: "center" }}
      onClick={() => handleLogin("popup")}
    >
      {!hideIcon && <OutlookIcon size={16} />}
      Associer Outlook
    </Button>
  );
};
