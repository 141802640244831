import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "../../i18n";

import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import {
  exportClientsVolumetry,
  exportProductionCapacity
} from "../../api";
import { parsePreference } from "../../utils";
import CollaboratorPlansBoard from "../CollaboratorSpace/PlansBoard/CollaboratorPlansBoard";
import { BriefCaseIcon, IconExport } from "../common/Icons";
import LigthPageHeader from "../common/PageHeader/LigthPageHeader";
import PageHeader from "../common/PageHeader/PageHeader";
import { ReportingCardsFilter } from "../common/ReportingCardsFilter";
import AbsenceMatrix from "./AbsenceMatrix/AbsenceMatrix";
import BalancesRecap from "./BalancesRecap";
import Bonuses from "./Bonuses";
import CalendarRecap from "./Calendar";
import Echeances from "./Echeances";
import ErrorReporting from "./ErrorReporting";
import PortfolioPlaning from "./Portfolio";
import PrestationSuivi from "./PrestationSuivi";
import ProductionCapacity from "./ProductionCapacity";
import Reporting from "./Reporting";
import TimeSheetProgression from "./TimeSheetProgression";

const ReportingPage = () => {
  const { token, navCommunity, user, loggedAs, collaborator } = useSelector(
    (state) => state.auth
  );
  const { language } = useSelector((state) => state.params);
  const [exporting, setExporting] = useState(false);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("tab");

  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences?.reporting
      : {};

  let tabs = [
    // {
    //   id: "REPORTING",
    //   label: "reporting",
    //   hasAccess: preferences.allowAccessReportingTab === "1"
    // },
    {
      id: "PRODUCTION_CAPACITY",
      label: "productionCapacity",
      hasAccess: parsePreference(preferences).production.allowAccess,
    },
    {
      id: "BONUSES",
      label: "Développement individuel",
      hasAccess:
        parsePreference(preferences).individualDevBe.allowAccess ||
        parsePreference(preferences).individualDevMa.allowAccess,
    },
    {
      id: "ECHEANCES",
      label: "echeances",
      hasAccess: parsePreference(preferences).echeance.allowAccess,
    },
    {
      id: "BALANCES_RECAP",
      label: "BalanceRecap",
      hasAccess: parsePreference(preferences).recapBalance.allowAccess,
    },
    {
      id: "CALENDAR",
      label: "calendar",
      hasAccess: parsePreference(preferences).calendar.allowAccess,
    },
    {
      id: "TS_PROGRESSION",
      label: "tsProgression",
      hasAccess: parsePreference(preferences).timesheet.allowAccess,
    },
    {
      id: "PORTFOLIO",
      label: "portfolios",
      hasAccess: parsePreference(preferences).portfolio.allowAccess,
    },
    {
      id: "SUPERVISION",
      label: "supervision",
      hasAccess: parsePreference(preferences).supervision.allowAccess,
    },
    {
      id: "ABSENCE_MATRIX",
      label: "absenceMatrix",
      hasAccess: parsePreference(preferences).vacation.allowAccess,
    },
    {
      id: "PLAN_BOARD",
      label: "planBoard",
      hasAccess: parsePreference(preferences).plans.allowAccess,
    },
    {
      id: "PRESTATION_SUIVI",
      label: "prestationSuivi",
      hasAccess: parsePreference(preferences).prestations.allowAccess,
    },
  ];

  const accessibleTabs = tabs.filter((tab) => tab.hasAccess);
  const [filter, setFilter] = useState({
    tab: activeTab ? activeTab : accessibleTabs[0] && accessibleTabs[0].id,
    year: new Date().getFullYear().toString(),
    keyword: "",
    category: 4,
  });

  const [showCardsFilter, setShowCardsFilter] = useState(true);

  const handleExportProductionCapacity = async (
    organization,
    token,
    filter
  ) => {
    try {
      setExporting(true);
      await exportProductionCapacity(token, organization, filter);
    } catch (e) {
      console.error("Error exporting production capacity:", e);
    } finally {
      setExporting(false);
    }
  };

  const handleExportClientsVolumetry = async () => {
    try {
      setExporting(true);
      await exportClientsVolumetry(token, navCommunity.id);
    } catch (e) {
      console.error("Error exporting clients volumetry:", e);
    } finally {
      setExporting(false);
    }
  };

  const renderReportingTabContent = () => {
    switch (filter.tab) {
      case "REPORTING":
        return <Reporting token={token} navCommunity={navCommunity} />;
      case "ECHEANCES":
        return (
          <Echeances
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "BALANCES_RECAP":
        return (
          <BalancesRecap
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "BONUSES":
        return (
          <Bonuses
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "SUPERVISION":
        return (
          <ErrorReporting
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            language={language}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "PORTFOLIO":
        return (
          <PortfolioPlaning
            token={token}
            navCommunity={navCommunity}
            language={language}
          />
        );
      case "TS_PROGRESSION":
        return (
          <TimeSheetProgression
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            language={language}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "CALENDAR":
        return (
          <CalendarRecap
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            lng={language}
            user={user ? user.firstName + " " + user.lastName : ""}
            loggedAs={loggedAs}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "ABSENCE_MATRIX":
        return (
          <AbsenceMatrix
            token={token}
            navCommunity={navCommunity}
            parentFilter={filter}
            language={language}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "PLAN_BOARD":
        return (
          <CollaboratorPlansBoard
            token={token}
            lng={language}
            year={filter.year}
            loggedAs={loggedAs}
            organization={navCommunity && navCommunity.id}
            parentFilter={filter}
            user={user ? user.firstName + " " + user.lastName : ""}
            collaborator={collaborator}
            reportingPreferences={preferences}
          />
        );
      case "PRODUCTION_CAPACITY":
        return (
          <ProductionCapacity
            filter={filter}
            setFilter={setFilter}
            collaborator={collaborator}
            preferences={preferences}
          />
        );
      case "PRESTATION_SUIVI":
        return (
          <PrestationSuivi
            token={token}
            navCommunity={navCommunity}
            lng={language}
            year={filter.year}
          />
        );
      default:
        return;
    }
  };

  const renderExportButton = () => {
    switch (filter.tab) {
      case "PRODUCTION_CAPACITY":
        return (
          <div
            className="export_btn"
            onClick={() =>
              handleExportProductionCapacity(navCommunity.id, token, filter)
            }
          >
            {exporting ? (
              <BeatLoader color="#3498db" size={10} />
            ) : (
              <>
                <IconExport size={20} />
                Exporter
              </>
            )}
          </div>
        );
      case "PRESTATION_SUIVI":
        return (
          <div
            className="export_btn"
            onClick={() => handleExportClientsVolumetry()}
          >
            {exporting ? (
              <BeatLoader color="#3498db" size={10} />
            ) : (
              <>
                <IconExport size={20} />
                Exporter la volumétrie
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="page-content">
      {showCardsFilter ? (
        <PageHeader
          header={_("reporting")}
          icon={<BriefCaseIcon fill="#29394D" size="36" />}
        />
      ) : (
        <LigthPageHeader
          title={{
            label: _("reporting"),
            value: !showCardsFilter
              ? _(
                  accessibleTabs.find((tab) => tab.id === filter.tab)?.label ||
                    _("reportingSection")
                )
              : "",
          }}
          goBack={null}
          onBack={() => setShowCardsFilter(true)}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 30 }}>
            {!showCardsFilter && renderExportButton()}
          </div>
        </LigthPageHeader>
      )}

      <div
        className="content-body large-content"
        style={{
          padding:
            (filter.tab === "ECHEANCES" || filter.tab === "BONUSES") &&
            !showCardsFilter
              ? 0
              : "20px",
          backgroundColor: showCardsFilter ? "#FAFBFB" : "",
          border: showCardsFilter ? "none" : "",
        }}
      >
        {showCardsFilter ? (
          <ReportingCardsFilter
            tabs={accessibleTabs}
            selectTab={(item) => {
              setFilter({ ...filter, tab: item.id });
              setShowCardsFilter(false);
            }}
          />
        ) : (
          collaborator && renderReportingTabContent()
        )}
      </div>
    </div>
  );
};

export default ReportingPage;
