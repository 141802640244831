import { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { TiArrowSortedDown, TiArrowUnsorted } from "react-icons/ti";
import { useQuery } from "react-query";
import { Cell, Pie, PieChart, Sector } from "recharts";
import {
  getLastHourCollaboratorsContribution,
  getPartnerStatistics,
} from "../../../api";
import _ from "../../../i18n";
import { formatNumber } from "../../../utils";
import { AnimatedBar } from "../components/AnimatedBar";
import styles from "./Dashboard.module.scss";

export default function Stats({
  token,
  loggedAs,
  organization,
  partner,
  year,
}) {
  const [timeRegistration, setTimeRegistration] = useState([]);
  const [stats, setStats] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [arrivalActive, setArrivalActive] = useState(false);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const lastHourContributionQuery = useQuery(
    ["lastHourCollaboratorsProductionQuery", token, organization, partner],
    async () => {
      if (token && partner && organization) {
        try {
          const response = await getLastHourCollaboratorsContribution(
            token,
            organization.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year
          );
          setTimeRegistration(response.data.data);
        } catch (e) {
          setTimeRegistration(null);
          return e;
        }
      }
    }
  );

  const partnerStats = useQuery(
    ["getPartnerStatistics", token, organization, partner],
    async () => {
      if (token && partner && organization) {
        try {
          const response = await getPartnerStatistics(
            token,
            organization.id,
            ["SUPERADMIN", "ADMIN"].includes(loggedAs)
              ? partner?.value
              : partner?.id,
            year
          );
          const data = response.data.data;
          setStats(data);
        } catch (e) {
          setStats(null);
          return e;
        }
      }
    }
  );

  useEffect(() => {
    lastHourContributionQuery.refetch();
    partnerStats.refetch();
  }, [partner, year]);

  const hours = stats.hours;
  const economic = stats.economic;
  const client = stats?.client;
  const departure = client?.departures;
  const arrival = client?.arrivals;

  return (
    <div className={styles.main_stats}>
      <AnimatedBar data={timeRegistration} />
      {/* Other stats */}
      {partnerStats.isFetching ? (
        <div style={{ margin: "10px auto" }}>Loading...</div>
      ) : (
        partnerStats.isFetched && (
          <div className={styles.main_stats_stats}>
            <div className={styles.main_stats_stats_pie_chart}>
              {stats && (
                <CustomPieChart
                  data={stats}
                  index={activeIndex}
                  action={onPieEnter}
                />
              )}
              <label>
                <strong>{`${stats?.client?.total}`}</strong>
                {_("clientFolders")}
              </label>
            </div>
            <CustomCard
              budget={hours.total}
              real={hours.real}
              unit="H"
              title="hourBudget"
              color={hours.real > hours.total ? "red" : "#06D9B1"}
              styles={styles}
            />
            <CustomCard
              budget={economic.total}
              real={economic.real}
              unit="€"
              title="economicBudget"
              color={economic.real > economic.total ? "red" : "#EA8124"}
              styles={styles}
            />
            <div className={styles.main_stats_stats_card}>
              <div className={styles.title}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setArrivalActive(!arrivalActive)}
                >
                  {arrivalActive
                    ? _(`${arrival?.title}`)
                    : _(`${departure?.title}`)}
                  <TiArrowUnsorted />
                </div>
                <div>
                  {arrivalActive
                    ? parseInt(arrival?.total) < 10
                      ? `0${arrival?.total} `
                      : arrival?.total
                    : parseInt(departure?.total) < 10
                    ? `0${departure?.total} `
                    : departure?.total}
                </div>
              </div>
              <div className={styles.departure_info}>
                <div>
                  {arrivalActive
                    ? `${formatNumber(arrival?.hours)} h`
                    : `${formatNumber(departure?.hours)} h`}
                </div>
                <div>
                  {arrivalActive
                    ? `${formatNumber(arrival?.economic)} €`
                    : `${formatNumber(departure?.economic)} €`}
                </div>
              </div>
            </div>
            <div className={styles.main_stats_stats_card}>
              <div className={styles.offshoring_stats}>
                <div>
                  {_("Offshoring")}{" "}
                  <Tooltip
                    key="offshoring-tooltip"
                    placement="top"
                    title={
                      <div>
                        {Object.entries(client.offshoring.group_counts_offshoring).map(([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value.percentage.toFixed(2)}% , {value.nbr_dossier} dossiers
                          </div>
                        ))}
                          <div>
                          <strong>Total des dossiers offshoring: </strong>{client.offshoring.total_dossiers_offshoring} dossiers
                        </div>
                      </div>
                    }
                    mouseLeaveDelay={0}
                  >
                  <span>{`${client.offshoring.total.toFixed(1)}%`}</span>
                  </Tooltip>
                </div>
                
                <div className={styles.bar_container}>
                  <div
                    className={styles.bar}
                    style={{ width: `${client.offshoring.total}%` }}
                  />
                </div>
              </div>

              <div className={styles.offshoring_stats}>
                <div>
                  {_("horusFolders")}{" "}
                  <Tooltip
                    key="horus-tooltip"
                    placement="top"
                    title={
                      <div>
                        {Object.entries(client.horus.group_counts_horus).map(([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value.percentage.toFixed(2)}% , {value.nbr_dossier} dossiers
                          </div>
                        ))}
                         <div>
                            <strong>Total des dossiers Horus: </strong>{client.horus.total_dossiers_horus} dossiers
                          </div>
                      </div>
                    }
                    mouseLeaveDelay={0}
                  >
                  <span>{`${client.horus.total.toFixed(1)}%`}</span>
                  </Tooltip>
                </div>
                <div className={styles.bar_container}>
                  <div
                    className={styles.bar}
                    style={{ width: `${client.horus.total}%` }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export function CustomPieChart({ data, index, action }) {
  const colors = ["#29394D", "#6D7F92", "#B2BCC6", "#18a0fb"];

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
    } = props;

    return (
      <g>
        <text
          x="50%"
          y="95%"
          dy={0}
          textAnchor="middle"
          fill={fill}
          style={{ textTransform: "capitalize", fontSize: 12 }}
        >
          {_(`${payload.name}`)} {`(${value})`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const pieData = data?.client?.children || [];

  return (
    <PieChart width={109} height={110}>
      <Pie
        activeIndex={index}
        activeShape={renderActiveShape}
        data={pieData}
        cx="50%"
        cy="50%"
        innerRadius={29}
        outerRadius={35}
        dataKey="value"
        nameKey="name"
        onMouseEnter={action}
        fill="#29394D"
        minAngle={5}
      >
        {pieData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}

export function CustomCard({ title, budget, real, unit, color, styles }) {
  return (
    <div className={styles.main_stats_stats_card}>
      <div className={styles.title}>
        <div style={{ width: "20%" }}>{_(`${title}`)}</div>
        <div>{`${formatNumber(budget)} ${unit}`}</div>
      </div>
      <div>
        <div className={styles.bar_container}>
          <div
            className={styles.bar}
            style={{
              width:
                real > budget
                  ? "100%"
                  : budget > 0
                  ? `${(real * 100) / budget}%`
                  : 0,
              background: color,
            }}
          />
          <div
            className={styles.bar_container_index}
            style={{
              width:
                budget > 0
                  ? real > budget
                    ? "100%"
                    : `${(real * 200) / budget}%`
                  : "fit-content",
              color: color,
              alignItems: real > budget ? "end" : "center",
            }}
          >
            <TiArrowSortedDown color={color} />
            <div style={{width: "50px", textAlign: "center"}}>{`${formatNumber(real)} ${unit}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
